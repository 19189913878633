import { createSlice } from "@reduxjs/toolkit";

import { manageField, getProductUnits, getListOfProducts, getListOfProductSizes, getProductsGroups, getProductListInGroup, getAllProductsForGroup, getProductDetails, customFieldSuggesion } from "../services/products";
import { getProductListInGroupTypes, getUnitResponse, ManagementFieldsResponse, ProductGroupType, productListData, ProductSizes, selectedProductTypes } from "../../types/productsType";

interface productGroupsData {
  productGroupId: number,
  groupName: string,
  totalProducts: string,
}

interface ProductState {
  isLoading: boolean;
  isSuccess: boolean;
  managementFields: ManagementFieldsResponse | null;
  productUnits: getUnitResponse | null;
  productsList: productListData | null;
  productGroups: productGroupsData[] | null;
  groupProductList: ProductGroupType | null;
  allProductsForGroup: getProductListInGroupTypes | null;
  selectedProductDetails: selectedProductTypes | null;
  productSizes: ProductSizes[] | null;
  error: string;
  suggestedField: any;
}

const initialState: ProductState = {
  isLoading: false,
  isSuccess: false,
  managementFields: null,
  productUnits: null,
  productsList: null,
  productGroups: null,
  groupProductList: null,
  allProductsForGroup: null,
  selectedProductDetails: null,
  productSizes: null,
  error: "",
  suggestedField: {
    1: null,
    2: null,
  },
};

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    // clearProducts: (state) => {
    //   state.productsList = initialState.productsList;
    // },
    clearSuggestion: (state) => {
      state.suggestedField = initialState.suggestedField;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(manageField.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(manageField.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.managementFields = payload;
    });
    builder.addCase(manageField.rejected, (state, { payload }: any) => {
      state.error = payload || "Failed to login";
      state.isSuccess = false;
      state.isLoading = false;
    });

    // Get Product Unit
    builder.addCase(getProductUnits.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProductUnits.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.productUnits = payload;
    });
    builder.addCase(getProductUnits.rejected, (state, { payload }: any) => {
      state.error = payload || "Failed to login";
      state.isSuccess = false;
      state.isLoading = false;
    });

    // Product List
    builder.addCase(getListOfProducts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListOfProducts.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.productsList = payload?.data;
    });
    builder.addCase(getListOfProducts.rejected, (state, { payload }: any) => {
      state.error = payload?.data || "Failed to login";
      state.isSuccess = false;
      state.isLoading = false;
    });

    // Product Size List
    builder.addCase(getListOfProductSizes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListOfProductSizes.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.productSizes = payload;
    });
    builder.addCase(getListOfProductSizes.rejected, (state, { payload }: any) => {
      state.error = payload?.data || "Failed to login";
      state.isSuccess = false;
      state.isLoading = false;
    });

    // Product Group

    builder.addCase(getProductsGroups.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProductsGroups.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.productGroups = payload?.productGroup;
    });
    builder.addCase(getProductsGroups.rejected, (state, { payload }: any) => {
      state.error = payload?.productGroup || "Failed to login";
      state.isSuccess = false;
      state.isLoading = false;
    });

    // get product list in group

    builder.addCase(getProductListInGroup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProductListInGroup.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.groupProductList = payload?.productGroup;
    });
    builder.addCase(getProductListInGroup.rejected, (state, { payload }: any) => {
      state.groupProductList = payload?.productGroup || "Failed to login";
      state.isSuccess = false;
      state.isLoading = false;
    });

    // get all products for group

    builder.addCase(getAllProductsForGroup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllProductsForGroup.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.allProductsForGroup = payload?.data;
    });
    builder.addCase(getAllProductsForGroup.rejected, (state, { payload }: any) => {
      state.error = payload?.data || "Failed to login";
      state.isSuccess = false;
      state.isLoading = false;
    });

    // selected Product Details

    builder.addCase(getProductDetails.pending, (state) => {
      state.selectedProductDetails = initialState.selectedProductDetails;
      state.isLoading = true;
    });
    builder.addCase(getProductDetails.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.selectedProductDetails = payload?.data;
    });
    builder.addCase(getProductDetails.rejected, (state, { payload }: any) => {
      state.error = payload?.data || "Failed to login";
      state.isSuccess = false;
      state.isLoading = false;
    });

    builder.addCase(customFieldSuggesion.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(customFieldSuggesion.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.suggestedField = payload;
    });
    builder.addCase(customFieldSuggesion.rejected, (state, { payload }: any) => {
      state.error = payload?.data || "Failed to login";
      state.isSuccess = false;
      state.isLoading = false;
    });

  },
});

export const { clearSuggestion } = productSlice.actions;
export default productSlice.reducer;
