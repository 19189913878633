import React, { useEffect, useRef } from "react";

import { handleKeyDown } from "../../utils/helper";

interface IProps {
  value?: string,
  placeholder: string,
  name: string,
  label?: string
  required?: boolean;
  type?: string;
  onChange?: any;
  className?: string | null;
  maxLength?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  showCount?: boolean;
  handleRemoveField?: () => void
}

export const InputField = ({ handleRemoveField, autoFocus = false, value, placeholder, name, label, required = false, onChange, className, maxLength = 50, type = "text", errorMessage, showCount = false, ...props }: IProps) => {

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <div className={className ? className + " input" : "input"}>
      <div className="input-container">
        <label htmlFor={name}>
          {label && (
            <div className="flex justifyBetween">
              <span className="flex gap-1">
                {label}
                {required && <span className="require-icon"> *</span>}
              </span>
              {handleRemoveField ? <button className="remove-field" onClick={() => handleRemoveField()}>Remove</button> : <></>}
            </div>
          )}
          <input
            ref={inputRef}
            value={value}
            type={type}
            name={name}
            placeholder={placeholder}
            {...props}
            onKeyDown={handleKeyDown}
            maxLength={maxLength}
            onChange={onChange}
            autoComplete="off"
            autoFocus={autoFocus}
          />
        </label>
        <div className="flex alignCenter justifyBetween">
          {errorMessage ? <p className="error-message">{errorMessage}</p> : ""}
          {showCount ? <p className="total-char-count">{inputRef.current?.value.length || value?.length || 0}/{maxLength}</p> : ""}
        </div>
      </div>
    </div>
  );
};