import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import RecentOrderIcon from "../../assets/images/recentOrderIcon";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { addToCart, clearCartItem, getCartDataByVendorID, reOrderByOrderId } from "../../redux/services/placeOrder";
import OrderCart from "../../pages/placeOrder/orderCart";

interface RecentOrderCardProps {
  orderNumber: number | null;
  status: string;
  itemCount: number;
  timeAgo: string;
  orderId: number;
}

const RecentOrderCard: React.FC<RecentOrderCardProps> = ({ orderNumber, status, itemCount, timeAgo, orderId }) => {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const dispatch = useAppDispatch();
  const location = useLocation()?.state;
  const { cart } = useAppSelector((state) => state.placeorder);

  const handleReorder = async () => {
    if (cart?.data?.cartItems?.length) await dispatch(clearCartItem({ cartId: cart?.data?.cartId || 0 })).then((result) => {
      if (result?.payload?.status === 200) {
        dispatch(reOrderByOrderId(orderId)).then((result) => {
          if (result?.payload?.status === 200) {
            if (result.payload?.data?.length) {
              const payload = {
                vendorId: location?.orderId,
                items: result?.payload?.data,
              };
              dispatch(addToCart(payload)).then((result) => {
                if (result.payload?.status === 200) {
                  dispatch(getCartDataByVendorID({ vendorId: location?.orderId })).then((result) => {
                    if (result.payload?.status === 200) setIsCartOpen(true);
                  }); 
                }
              });
            }
          }
        });
      }
    }); else {
      dispatch(reOrderByOrderId(orderId)).then((result) => {
        if (result?.payload?.status === 200) {
          if (result.payload?.data?.length) {
            const payload = {
              vendorId: location?.orderId,
              items: result?.payload?.data,
            };
            dispatch(addToCart(payload)).then((result) => {
              if (result.payload?.status === 200) {
                dispatch(getCartDataByVendorID({ vendorId: location?.orderId })).then((result) => {
                  if (result.payload?.status === 200) setIsCartOpen(true);
                }); 
              }
            });
          }
        }
      });
    }
  };
  return (
    <div className="RecentOrderCard">
      <div className="Content">
        <div className="order-status-container">
          <span className="order-text">
            Order: {orderNumber || "-"}
          </span>
          <span className="status">
            {status}
          </span>
        </div>
        <div className="item-time-container">
          <span className="item-text">
            {itemCount} Items
          </span>
          <span className="time-text">
            {timeAgo}
          </span>
        </div>
      </div>
      <button className="flex gap-2 reOrder-Button rounded-8" onClick={handleReorder}>
        <RecentOrderIcon />
        Re-order
      </button>
      <OrderCart isOpen={isCartOpen} setIsOpen={setIsCartOpen} />
    </div>
  );
};

export default RecentOrderCard;