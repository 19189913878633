import React, { useEffect, useState } from "react";
import { Image, Upload } from "antd";
import type { GetProp, UploadFile, UploadProps } from "antd";
import ImgCrop from "antd-img-crop";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import useMessageHook from "../../hooks/useMessageHook";

interface IProps {
  getFile?: any;
  value?: UploadFile[];
}

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const FileUpload = ({ getFile, value }: IProps) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);
  const { showMessage, contextHolder } = useMessageHook();

  useEffect(() => {
    if (value?.length) {
      setFileList(value);
    }
  }, [value]);

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    if (newFileList?.[0]?.originFileObj) {
      const isJpgOrPng = newFileList?.[0]?.originFileObj?.type === "image/jpeg" || newFileList?.[0]?.originFileObj?.type === "image/jpg" || newFileList?.[0]?.originFileObj?.type === "image/png";
      if (!isJpgOrPng) {
        showMessage("error", "You can only upload JPG/PNG file!");
        return;
      }

      const sizeLimit = (newFileList?.[0]?.originFileObj?.type === "image/jpeg" || newFileList?.[0]?.originFileObj?.type === "image/jpg") ? 400 : 600;
      if (newFileList?.[0]?.originFileObj.size / 1024 > sizeLimit) {
        showMessage("error", `Image must be smaller than ${sizeLimit}KB!`);
        return;
      }
      const file: any = newFileList;
      const formData = new FormData();
      formData.append("companyProfile", file[0]);
      setFileList(newFileList);
      getFile && getFile(newFileList?.[0]?.originFileObj);
      setLoading(false);
    } else {
      setFileList([]);
      getFile("");
    }
  };

  const onPreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const customRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("Image Preview");
    }, 0);
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <>
      {contextHolder}
      <ImgCrop rotationSlider>
        <Upload
          listType="picture-circle"
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
          className={fileList?.length ? "file-upload uploaded" : "file-upload"}
          customRequest={customRequest}
          accept="image/png, image/jpeg"
        >
          {!fileList.length ? <div className="logo-text-data">LOGO</div> : ""}
          {/* {fileList.length < 1 && <span className="upload-logo-text">Choose Image</span>} */}
        </Upload>
      </ImgCrop>
      {previewImage && (
        <Image
          wrapperStyle={{ display: "none" }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
    </>
  );
};

export default FileUpload;
