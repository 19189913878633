import React, { useEffect, useRef, useState } from "react";
import { Carousel, Rate } from "antd";

import WpIcon from "../../assets/images/wpIcon";
import { IndiaFlagIcon, ProfileIcon, User1, User2, User3, User4, User5, User6, User7, User8 } from "../../assets/images";

const carouselData = [
  {
    profileImage: User1,
    reviewText: "With Biizline, we've cut our order processing time in half. It's reliable, straightforward, and has become an integral part of our daily operations.",
    reviewerName: "Maruti Electricals",
    designation: "Owner (Electrical Equipment Manufacturer)",
    rating: 4.0,
  },
  {
    profileImage: User2,
    reviewText: "Biizline revolutionized our order management! It's fast, user-friendly, and saves us hours every day. A must-have for any growing business!",
    reviewerName: "Gopal Enterprise",
    designation: "Owner (Cable Manufacturer)",
    rating: 5.0,
  },
  {
    profileImage: User3,
    reviewText: "Our team's productivity has soared since adopting Biizline. It's intuitive and handles everything seamlessly. Perfect for our business needs.",
    reviewerName: "Panchal Corporation",
    designation: "Accountant (Chemical Manufacturer)",
    rating: 4.5,
  },
  {
    profileImage: User4,
    reviewText: "Switching to Bizline was the best decision. Our orders are now organized, and we can track everything in real-time. Highly recommended!",
    reviewerName: "A to Z Traders",
    designation: "Owner (Dryfruit Industries)",
    rating: 5.0,
  },
  {
    profileImage: User5,
    reviewText: "Bizline's live order updates have transformed our workflow. No more delays or miscommunications—just smooth, efficient operations.",
    reviewerName: "Riddhi Traders",
    designation: "Owner (Hardware industry)",
    rating: 4.0,
  },
  {
    profileImage: User6,
    reviewText: "⁠Efficient, reliable, and easy to use—Biizline is a game-changer for our business. We can't imagine going back to the old way of managing orders.",
    reviewerName: "Suksan Enterprise",
    designation: "Owner (Bearings Dealer)",
    rating: 5.0,
  },
  {
    profileImage: User7,
    reviewText: "Biizline makes managing bulk orders a breeze. The interface is clean, and the features are exactly what we needed. Exceptional tool for B2B!",
    reviewerName: "Shivam Store",
    designation: "Mitesh Zezariya - (FMCG Dealer)",
    rating: 5.0,
  },
  {
    profileImage: User8,
    reviewText: "Bizline has simplified our order management like never before. We now have complete control over our orders with minimal effort.",
    reviewerName: "Shree Bajrang Trading",
    designation: "Owner (Automobile Industry)",
    rating: 5.0,
  },
];

interface Props {
  isLoading: boolean;
  onLogin: () => void;
  handleChangeNumber: (value: string) => void;
  phoneNo: string;
}

const WhatsAppNumberScreen = ({ onLogin, handleChangeNumber, phoneNo, isLoading }: Props) => {
  const [phoneNumber, setPhoneNumber] = useState(phoneNo);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);

  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setPhoneNumber(value);
      handleChangeNumber(value);
    }
  };

  const handleLogin = () => {
    if (!isLoading) {
      onLogin();
    }
  };

  return (
    <div className="auth-card">
      <div className="wp-number-box white-box">
        <div className="wp-content flex justifyCenter alignCenter gap-6 direction-column">
          <div className="flex gap-2 alignCenter">
            <WpIcon />
            <h2 className="wp-text"><span className="text">WhatsApp</span> Number</h2>
          </div>
          <form className="wp-login-form gap-6" onSubmit={(e) => e.preventDefault()}>
            <label className="input-box">
              <IndiaFlagIcon />
              +91
              <input
                ref={inputRef}
                value={phoneNumber}
                className="input-field"
                placeholder="Enter Phone Number"
                minLength={10}
                maxLength={10}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onLogin();
                  }
                }}
              />
            </label>
            <button disabled={isLoading} type="button" className="rounded-12 primary large40" onClick={handleLogin}>Continue</button>
          </form>
        </div>
      </div>

      <div className="carousel-container">
        <Carousel autoplay>
          {carouselData.map((item, index) => (
            <div key={index}>
              <div className="carousel-card">
                <div className="content-box">
                  <h3 className="review-text">{item.reviewText}</h3>
                  <div className="user-details-box gap-4">
                    <img src={item.profileImage} alt="Profile Icon" className="user-profile" />
                    <div className="user-content">
                      <span className="reviewer-name">{item.reviewerName}</span>
                      <span className="designation">{item.designation}</span>
                    </div>
                  </div>
                  <Rate allowHalf defaultValue={item.rating} />
                </div>
              </div>
            </div>
          ))}
        </Carousel>

      </div>
    </div>
  );
};

export default WhatsAppNumberScreen;