import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

import endPoint from "./endPoints.json";
import axiosInstance from "../axios";

export const getComapnyDetailsById = createAsyncThunk("companyProfile/details", async (id: number, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${endPoint.getConnectionDetailsById}/${id}`);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const updateCompanyProfile = createAsyncThunk("companyProfile/update", async (args: { id: number, payload: { image: any, data: any } }, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    formData.append("companyProfileImages", args.payload?.image);
    formData.append("companyUpdateData", JSON.stringify(args.payload?.data));
    const response = await axiosInstance.put(`${endPoint.companyProfileUpdate}/${args.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});