import React from "react";

const StopIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 6.60938 2.67656 5.33125 3.49219 4.33125L11.6687 12.5078C10.6687 13.3234 9.39062 13.8125 8 13.8125ZM12.5078 11.6687L4.33125 3.49219C5.33125 2.67656 6.60938 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 9.39062 13.3234 10.6687 12.5078 11.6687Z" fill="black" fillOpacity="0.45"/>
    </svg>
  );
};

export default StopIcon;