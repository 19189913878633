import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import useMessageHook from "../hooks/useMessageHook";
import endPoints from "../redux/services/endPoints.json";
import { Header } from "../components/header";
import CreateCompany from "./createCompany";
import ConfirmationPopup from "../components/actionModals/confirmationPopup";
import { BG1, BG2, BG3, BG4 } from "../assets/images";
import NotificationDrawer from "../components/notification/notificationDrawer";

interface IProps {
  children?: React.ReactNode
}


const Layout = ({ children }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const createNewCompany = localStorage.getItem("createNewCompany");
  const isDraftProduct = localStorage.getItem("isDraftProduct");
  const productDataSource = localStorage.getItem("dataSource");
  const [isDraft, setIsDraft] = useState(false);
  const { showMessage, contextHolder } = useMessageHook();
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/home");
    }
    setIsLoading(false);
  }, [navigate]);

  useEffect(() => {
    if (isDraftProduct && location.pathname !== "/products/add") {
      setIsDraft(JSON.parse(isDraftProduct));
    }
  }, [isDraftProduct, location?.pathname]);

  const handleClose = () => {
    localStorage.removeItem("isDraftProduct");
    localStorage.removeItem("dataSource");
    setIsDraft(false);
  };

  const handleAddToDraft = async () => {
    if (productDataSource) {
      const { product, productData, token } = JSON.parse(productDataSource);
      const formData = new FormData();
      product?.forEach((file: string | Blob) => {
        formData.append("productImages", file);
      });
      const modifiedData = { ...productData, isDraft: true };
      formData.append("productData", JSON.stringify(modifiedData));
      await axios.post(baseURL + endPoints.addProduct, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      }).then((result) => {
        if (result.status === 200) {
          navigate(location.pathname, { replace: true });
          handleClose();
        } else {
          showMessage("error", result?.data?.message);
        }
      }).catch((errr) => { showMessage("error", errr); });
    }
  };

  if (isLoading) {
    return <div className="page-loader"><Spin /></div>; // or a spinner component
  }

  return (
    <div className="layout">
      <Header />
      {contextHolder}
      {!isDraft ?
        <>
          <div className="bg-images-container">
            <span className="bg1"><BG1 /></span>
            <span className="bg2"><BG2 /></span>
            <span className="bg3"><BG3 /></span>
            <span className="bg4"><BG4 /></span>
            <span className="glass-overlay"></span>
            {/* <img src={BackgroundImage} alt="background-image" /> */}
          </div>
          {/* <button onClick={handleLogout}>Logout</button> */}
          <div className="content container">
            {(createNewCompany && JSON.parse(createNewCompany)) ? <CreateCompany /> : <Outlet />}
          </div>
        </>
        : <><ConfirmationPopup
          open={isDraft || false}
          close={() => handleClose()}
          confirm={() => handleAddToDraft()}
          infoContent="Are you sure, you want to Draft this product?"
          cancelButtonText="No"
          confirmButtonText="Yes, Draft"
        /></>
      }
      <NotificationDrawer />
    </div>
  );
};

export default Layout;