import React, { useEffect, useState } from "react";
import { Popconfirm, Spin, Table, TableProps } from "antd";
import Slider from "react-slick";

import CommonModal from "../../components/commonModal";
import { CrossIcon, LocationIcon, Logo } from "../../assets/images";
import { AddressFormat, formatDate, formatRupees } from "../../utils/helper";
import { useAppSelector } from "../../hooks/useAppSelector";
import { AttachmentIcon, CheckIcon, CommentIcon, PdfIcon, UserIcon } from "../../assets/images/icons";
import { acceptOrRejectOrder, getNewOrders, updateOrderByViewer } from "../../redux/services/orders";
import { useAppDispatch } from "../../hooks/useAppDispatch";

interface Props {
  open: boolean;
  close: () => void;
  activeSlider: number;
  orderList: { customerId: number, isActive: boolean }[];
  setActiveSlider: any;
}

const NewOrderDetailsModal = ({ open, close, activeSlider, orderList, setActiveSlider }: Props) => {
  const { companyOrderData } = useAppSelector(state => state.manageOrders);
  const [active, setActive] = useState(0);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (companyOrderData?.data?.[activeSlider]?.orderId && activeSlider !== -1) {
      dispatch(updateOrderByViewer({ orderId: companyOrderData?.data?.[activeSlider]?.orderId || 0 }));
    }
  }, [dispatch, companyOrderData?.data, activeSlider]);

  const columns: TableProps<any>["columns"] = [
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      width: 280,
      render: (text, record: any) => {
        return (
          <div className="product-name-box flex alignCenter gap-3">
            <div className="product-image-container">
              {record?.product?.productImage?.thumbnailURL ? <img className="product-image" src={record?.product?.productImage?.thumbnailURL} alt="product-image" /> : <Logo />}
            </div>
            <span className="product-name">{record?.product?.productName || "-"}</span>
          </div>
        );
      },
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      width: 159,
      render: (text, record: any) => (
        <div className="size-text">{record?.productSize?.productSize} {record?.productSize?.productSizeUnit}</div>
      ),
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      width: 131,
      render: (text, record: any) => (
        <div className="qty-text flex gap-1">
          <span className="quantity">{record?.quantityOrdered}</span>
          <span className="unit">{record?.orderUnitConvertionUnit ? record?.orderUnitConvertionUnit : record?.orderUnit}</span>
        </div>
      ),
    },
    {
      title: "Rate per unit",
      dataIndex: "rate",
      key: "rate",
      width: 132,
      render: (text, record: any) => (
        <div className="price-per-unit-text flex gap-1">
          <span className="price">{formatRupees(record?.pricePerUnit)}</span>
          {record?.pricePerUnit ? <span className="unit">/ {record?.orderUnit}</span> : <></>}
        </div>
      ),
    },
    {
      title: "Subtotal",
      dataIndex: "subtotal",
      key: "subtotal",
      width: 82,
      render: (text) => (
        <div className="subtotal-text flex gap-1">
          <span className="price">{formatRupees(text)}</span>
        </div>
      ),
    },
  ];

  const handleAcceptOrRejectOrder = async (id: number, status: string) => {
    const payload = {
      orderId: id,
      action: status,
    };
    await dispatch(acceptOrRejectOrder(payload));
    await dispatch(getNewOrders());
    close();
  };

  // let nextCustomerId = 0;
  // let prevCustomerId = 0;

  // for (let i = 0; i < orderList.length; i++) {
  //   if (orderList[i].isActive === true) {
  //     // Find previous customerId if exists
  //     if (i - 1 >= 0) {
  //       prevCustomerId = orderList[i - 1].customerId;
  //     }

  //     // Find next customerId if exists
  //     if (i + 1 < orderList.length) {
  //       nextCustomerId = orderList[i + 1].customerId;
  //     }

  //     break;
  //   }
  // }

  // const CompanyOrderData = async () => {
  //   await dispatch(getCompanyOrderData({ customerId: nextCustomerId || prevCustomerId }));
  // };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    initialSlide: activeSlider === -1 ? 0 : activeSlider,
    beforeChange: (current: any, next: any) => {
      setActiveSlider(current);
      if (next > current) {
        setActive(current + 1);
      } else {
        setActive(next);
      }
      // if (((companyOrderData?.data?.length) === (current + 1))) {
      //   CompanyOrderData();
      // }
    },
  };

  const renderAttachment = (attachmentUrl: string) => {
    const isPdf = attachmentUrl.toLowerCase().endsWith(".pdf");

    return (
      <>
        <div className="image-box">
          <a href={attachmentUrl} target="_blank" rel="noopener noreferrer">
            {isPdf ? (
              <PdfIcon />
            ) :
              <img src={attachmentUrl} alt="" />
            }
          </a>
        </div>
      </>
    );
  };

  return (
    companyOrderData?.isLoading ? <div className="page-loader"><Spin /></div> : <CommonModal open={open} close={close} className="new-order-details-modal">
      <div className="flex justifyBetween">
        <div className="customer-details gap-2 flex textStart">
          <div className="image-box flex justifyCenter alignCenter">
            {companyOrderData?.data?.[active]?.customer?.profileThumbnailImg ?
              <img className="profile-image" src={companyOrderData?.data?.[active]?.customer?.profileThumbnailImg} alt="" />
              : <UserIcon />}
          </div>
          <div className="flex direction-column">
            <span className="company-name">{companyOrderData?.data?.[active]?.customer?.name}</span>
            <span className="user-name">{companyOrderData?.data?.[active]?.customer?.createdBy?.firstName} {companyOrderData?.data?.[active]?.customer.createdBy?.lastName}</span>
          </div>
        </div>
        <div className="flex gap-2">
          <div className="reference-info flex direction-column">
            <span className="reference-id">Reference id <span className="id">#{companyOrderData?.data?.[active]?.refId}</span></span>
            <span className="order-date">{formatDate(companyOrderData?.data?.[active]?.createdOn || "")}</span>
          </div>
          <div onClick={close} className="close-icon flex alignCenter flex justifyCenter">
            <CrossIcon width={24} height={24} />
          </div>
        </div>
      </div>
      <Slider {...settings}>
        {companyOrderData?.data?.map((item, index: number) => {
          return (
            <div className="model-container flex gap-5 direction-column" key={index}>
              <div className="order-details-container flex direction-column">
                <Table
                  columns={columns}
                  dataSource={item?.orderItems}
                  className="new-order-details-table"
                  pagination={false}
                // scroll={{ y: 300 }}
                />
                <div className="dispatch-details-box flex">
                  {companyOrderData?.data?.[active]?.message ? <div className="transportation-details-box flex gap-3 ">
                    <CommentIcon />
                    <div className="flex direction-column gap-2">
                      <h4 className="comments-header">Comments</h4>
                      <p className="comment-info">{companyOrderData?.data?.[active]?.message}</p>
                    </div>
                  </div> : <></>}
                  {companyOrderData?.data?.[active]?.attachments.length ? <div className="attachments-container flex gap-3">
                    <div className="attach-icon">
                      <AttachmentIcon />
                    </div>
                    <div className="content-container flex direction-column gap-2">
                      <h4 className="attachment-header-text">Attachments</h4>
                      <div className="attached-files flex gap-4">
                        {companyOrderData?.data?.[active]?.attachments.map((data) => renderAttachment(data))}
                      </div>
                    </div>
                  </div> : <></>}
                </div>
                {item?.deliveryAddress ?
                  <div className="address-section">
                    <div className="flex">
                      <div className="address-text flex alignCenter gap-3">
                        <LocationIcon />
                        <div className="address-input">
                          <h4 className="title-4">
                            Delivery Address
                          </h4>
                          <input type="text" className="selected-address" value={AddressFormat(item?.deliveryAddress) || ""} />
                        </div>
                      </div>
                    </div>
                  </div> : <></>}
                <div className="bill-details gap-4 flex direction-column">
                  <h4 className="section-header">Bill Details</h4>
                  <div>
                    {item?.totalDiscount && item?.totalDiscount > 0 ? <div className="sub-total flex justifyBetween">
                      <span>Subtotal</span>
                      <span>{formatRupees(item?.subtotal || 0)}</span>
                    </div> : <></>}
                    {item?.totalDiscount && item?.totalDiscount > 0 ? <div className="total-discount flex justifyBetween">
                      <span>Total Discount</span>
                      <span>{formatRupees(item?.totalDiscount || 0)}</span>
                    </div> : <></> }
                    <div className="grand-total flex justifyBetween">
                      <span>Grand Total</span>
                      <span>{formatRupees(item?.grandTotal || 0)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-action flex justifyEnd gap-3">
                <Popconfirm
                  title="Reject Order"
                  description="Are you sure, you want to reject this order?"
                  onConfirm={() => handleAcceptOrRejectOrder(item?.orderId || 0, "REJECTED")}
                  onCancel={() => []}
                  okText="Yes"
                  cancelText="No"
                >
                  <button className="reject-btn">Reject<CrossIcon width={24} height={24} /></button>
                </Popconfirm>
                <button onClick={() => handleAcceptOrRejectOrder(item?.orderId || 0, "ACCEPTED")} className="accept-btn">Accept<CheckIcon /></button>
              </div>
            </div>
          );
        })}
      </Slider>
    </CommonModal>
  );
};

export default NewOrderDetailsModal;