import React from "react";

const ThreeDots = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M7.99984 8.66634C8.36803 8.66634 8.6665 8.36786 8.6665 7.99967C8.6665 7.63148 8.36803 7.33301 7.99984 7.33301C7.63165 7.33301 7.33317 7.63148 7.33317 7.99967C7.33317 8.36786 7.63165 8.66634 7.99984 8.66634Z" stroke="black" strokeOpacity="0.45" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.6665 8.66634C13.0347 8.66634 13.3332 8.36786 13.3332 7.99967C13.3332 7.63148 13.0347 7.33301 12.6665 7.33301C12.2983 7.33301 11.9998 7.63148 11.9998 7.99967C11.9998 8.36786 12.2983 8.66634 12.6665 8.66634Z" stroke="black" strokeOpacity="0.45" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.33317 8.66634C3.70136 8.66634 3.99984 8.36786 3.99984 7.99967C3.99984 7.63148 3.70136 7.33301 3.33317 7.33301C2.96498 7.33301 2.6665 7.63148 2.6665 7.99967C2.6665 8.36786 2.96498 8.66634 3.33317 8.66634Z" stroke="black" strokeOpacity="0.45" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ThreeDots;