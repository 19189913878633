import { combineReducers } from "@reduxjs/toolkit";

import authSlice from "./slices/authSlice";
import productSlice from "./slices/productSlice";
import vendorSlice from "./slices/vendorSlice";
import connectionSlice from "./slices/connectionSlice";
import addressSlice from "./slices/addressSlice";
import tableSlice from "./slices/tableSlice";
import placeorderSlice from "./slices/placeorderSlice";
import orders from "./slices/orders";
import notificationSlice from "./slices/notificationSlice";
import companySlice from "./slices/companySlice";

const rootReducer = (state: any, action: any) => {
  if (action.type === "RESET_STATE") {
    state = undefined;
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  auth: authSlice,
  products: productSlice,
  vendors: vendorSlice,
  connections: connectionSlice,
  address: addressSlice,
  table: tableSlice,
  placeorder: placeorderSlice,
  manageOrders: orders,
  notification: notificationSlice,
  company: companySlice,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;