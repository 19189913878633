import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, Drawer, message, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { InputField } from "../../components";
import SearchBar from "../../components/searchBar";
import { CrossIcon, Logo } from "../../assets/images";
import { truncateString } from "../../utils/helper";
import { AppDispatch, RootState } from "../../redux/store";
import { createGroup, editGroup, getAllProductsForGroup, getListOfProducts, getProductListInGroup, getProductsGroups } from "../../redux/services/products";
import { ProductListForGroup } from "../../types/productsType";
import Limit from "../../utils/limits.json";
import { debounce } from "../../utils/debounce";
import NoDataFound from "../../components/NoDataFound";
import useMessageHook from "../../hooks/useMessageHook";
interface Props {
  open: boolean;
  close: () => void;
  groupId: number;
  setSelectedGroupId?: any;
  type: string;
}

const groupProductsByLetter = (products: ProductListForGroup[]) => {
  const grouped = products?.reduce((acc: { [key: string]: ProductListForGroup[] }, product) => {
    const firstLetter = product.productName[0].toUpperCase();
    if (!acc[firstLetter]) acc[firstLetter] = [];
    acc[firstLetter].push(product);
    return acc;
  }, {});
  return Object.keys(grouped).sort().map(letter => ({ letter, products: grouped[letter] }));
};

const classNames = {
  body: "my-drawer-body",
  mask: "my-drawer-mask",
  header: "my-drawer-header",
  content: "my-drawer-content",
};

const CreateGroup = ({ close, open, groupId, setSelectedGroupId, type }: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [value, setValue] = useState<{
    name: string;
    showToCustomer: boolean;
    selectedProducts: any[];
}>({
  name: "",
  showToCustomer: false,
  selectedProducts: [],
});

  const dispatch: AppDispatch = useDispatch();
  const { allProductsForGroup, groupProductList } = useSelector((state: RootState) => state.products);

  const { showMessage, contextHolder } = useMessageHook(); 

  const tableHeight = window.innerHeight;

  // Debounced function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchProducts = useCallback(
    debounce((query: string) => {
      const payload = {
        search: query,
        // groupId: 0,
        vendorId: 0,
        limit: 0,
        offset: 0,
        forGroup: true,
      };
      dispatch(getAllProductsForGroup(payload));
    }, 500), // 500ms debounce delay
    [dispatch],
  );

  useEffect(() => {
    debouncedFetchProducts(searchQuery);
  }, [searchQuery, debouncedFetchProducts]);

  useEffect(() => {
    if (groupId && type === "edit") {
      setValue({
        name: groupProductList?.groupName || "",
        showToCustomer: groupProductList?.showToCustomer || false,
        selectedProducts: groupProductList?.selectedProducts || [],
      });
    } else if (type === "create") {
      setValue({
        name: "",
        showToCustomer: false,
        selectedProducts: [],
      });
    }
  }, [groupId, groupProductList, type]);

  const toggleDrawer = () => {
    setValue((prev) => ({
      ...prev,
      selectedProducts: [],
    }));
    close();
  };

  const onSwitchChange = (checked: boolean) => {
    setValue((prev) => ({
      ...prev,
      showToCustomer: checked,
    }));
  };

  const handleCheckboxChange = (product: ProductListForGroup) => {
    setValue((prev) => {
      const isSelected = prev.selectedProducts.some((p: any) => p.productId === product.productId);
      return {
        ...prev,
        selectedProducts: isSelected
          ? prev.selectedProducts.filter((p: any) => p.productId !== product.productId)
          : [...prev.selectedProducts, product],
      };
    });
  };
  
  const handleRemoveSelectedProduct = (id: number) => {
    setValue((prev) => {
      // Filter out the product with the given id
      const updatedSelectedProducts = prev.selectedProducts.filter(
        (product: any) => product.productId !== id,
      );
  
      return {
        ...prev,
        selectedProducts: updatedSelectedProducts, // Update the state with the filtered array
      };
    });
  };
  

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const groupedFilteredProducts = groupProductsByLetter(allProductsForGroup?.products || []);

  const validationForCreateGroup = () => {
    if (value?.name === "") {
      showMessage("error", "Please enter Group Name");
      return true;
    }
    if (value?.selectedProducts.length === 0) {
      showMessage("error", "Please Select At least one product");
      return true;
    }
  };

  const handleFormSubmit = async () => {
    if (validationForCreateGroup()) {
      return;
    }
    const selectedProductIds = value?.selectedProducts.map(product => product.productId);
    const payload = {
      groupName: value?.name,
      showToCustomer: value?.showToCustomer,
      selectedProducts: selectedProductIds,
    };

    if (type === "edit") {
      await dispatch(editGroup({ productGroupId: groupId, ...payload })).then((result) => {
        if (result.payload.status === 200) {
          showMessage("success", result?.payload?.message);
          if (groupId !== null) {
            const payload = {
              productGroupId: groupId,
              search: "",
              forGroup: true,
            };
            dispatch(getProductListInGroup(payload));
            toggleDrawer();
          }
          const payload = {
            search: searchQuery,
            groupId: groupId,
            vendorId: 0,
            limit: Math.round((tableHeight || 0) / 52),
            offset: 0,
            forGroup: false,
          };
          dispatch(getListOfProducts(payload));
          dispatch(getProductsGroups({ search: "" }));
        }
        if (result.payload.status === 400) {
          showMessage("error", result?.payload.message || "Something went wrong");
        }
      }).catch((error) =>  showMessage("error", error || error.message || "Something went wrong"));
    } else {
      await dispatch(createGroup(payload)).then((result) => {
        if (result?.payload?.status === 200) {
          toggleDrawer();
          dispatch(getProductsGroups({ search: "" }));
        } else {
          showMessage("error", result?.payload.message || "Something went wrong");
        }
      }).catch((error) =>  showMessage("error", error || error.message || "Something went wrong"));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value: val } = e.target;
    setValue((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  return (
    <Drawer
      title={`${groupId && type === "edit" ? "Edit" : "Create"} Group`}
      placement="left"
      onClose={toggleDrawer}
      open={open}
      classNames={classNames}
      rootClassName="create-group-drawer"
      footer={
        <div className="product-list-group-drawer-footer flex gap-4">
          <button className="rounded-6 large secondary" type="button" onClick={toggleDrawer}>
            Cancel
          </button>
          <button className="rounded-6 primary large" type="button" onClick={handleFormSubmit}>
            {type === "edit" ? "Update" : "Create"}
          </button>
        </div>
      }
    >
      {contextHolder}
      <div className="group-container">
        <div className="group-name-container">
          <InputField
            name="name"
            value={value?.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
            placeholder="Enter Group Name"
            label="Group Name"
            required
            maxLength={Limit.groupName}
          />
        </div>
        <div className="show-to-customer-box flex justifyBetween">
          <span className="text-label">Show group to customers</span>
          <Switch value={value?.showToCustomer} onChange={onSwitchChange} className="switch-style" />
        </div>
        <div className="products-container">
          <div className="product-header-container gap-4 flex direction-column">
            <div className="flex alignCenter gap-1">
              <span className="selected-product-title">
                Selected Products
              </span>
              <span className="selected-product-count">
                {`${value.selectedProducts.length}/${groupProductList?.totalProductsCount ?? allProductsForGroup?.products.length}`}
              </span>
            </div>
            <SearchBar onChange={handleSearchChange} value={searchQuery} placeholder="Search Product" />
          </div>
          {value.selectedProducts.length > 0 &&
            <div className="selected-products-container flex gap-4">
              {value.selectedProducts.map((data, index) => (
                <div key={index} className="selected-image-box flex direction-column justifyCenter alignCenter" onClick={() => handleRemoveSelectedProduct(data.productId)}>
                  <div className="product-image">
                    {data?.productImage?.thumbnailURL ? <img src={data?.productImage?.thumbnailURL} alt="product-image" /> : <Logo />}
                  </div>
                  <span className="product-name">{truncateString(data.productName, 7)}</span>
                  <button className="remove-product-icon">
                    <CrossIcon />
                  </button>
                </div>
              ))}
            </div>
          }
          <div className="products-list-container">
            {groupedFilteredProducts.map((group) => (
              <div key={group.letter}>
                <h2 className="product-group-header">{group.letter}</h2>
                {group.products.map((product: any) => (
                  <div className="checkbox-input-box" key={product.productId}>
                    <div className="image-box">
                      {product.productImage?.thumbnailURL ? <img src={product.productImage?.thumbnailURL} alt={product.productName} /> : <Logo />}
                    </div>
                    <Checkbox
                      checked={value.selectedProducts.some(p => p.productId === product.productId)}
                      onChange={() => handleCheckboxChange(product)}
                    >
                      <div className="product-item">
                        <div className="product-details">
                          <span>{product.productName}</span>
                        </div>
                      </div>
                    </Checkbox>
                  </div>
                ))}
              </div>
            ))}
            {groupedFilteredProducts.length === 0 && <NoDataFound />}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default CreateGroup;