import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";

import { DeleteOutlinedIcon, StatusIcon, UploadOutlinedIcon } from "../../assets/images";

interface IProps {
  count?: number;
  clearData: () => void;
  handleAction?: any;
  actionFor?: string;
}

const SelectedTableRowArea = ({ count, clearData, handleAction, actionFor }: IProps) => {
  return (
    <div className="selected-table-row-area-container">
      <div className="flex area-content-container rounded-8 justifyBetween">
        <div className="flex selected-items-text-container gap-2">
          <p className="text">{count} {actionFor === "sizes" ? "Sizes" : "Products"} selected</p>
          <button onClick={() => clearData()}><CloseOutlined /></button>
        </div>
        <div className="actions-container flex gap-2">
          {/* <button className="status-button" onClick={() => handleAction({ action: "status", actionFor: actionFor })}><StatusIcon /> Status</button> */}
          {/* <button className="export-button" onClick={() => handleAction({ action: "export", actionFor: actionFor })}><UploadOutlinedIcon /> Export</button> */}
          <Popconfirm
            title=""
            description={`Are you sure to delete this ${count === 1 ? "product" : "products"}?`}
            onConfirm={() => handleAction({ action: "delete", actionFor: actionFor })}
            onCancel={() => clearData()}
          >
            <button className="delete-button"><DeleteOutlinedIcon /> Delete</button>
          </Popconfirm>
        </div>
      </div>
    </div>
  );
};

export default SelectedTableRowArea;