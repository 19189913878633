import React, { useEffect } from "react";

import Page from "../../components/page";
import NewOrdersList from "./newOrdersList";
import OrdersData from "./ordersData";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getNewOrders, orderSeen } from "../../redux/services/orders";
import { useAppSelector } from "../../hooks/useAppSelector";

const Orders = () => {
  const dispatch = useAppDispatch();
  const { newOrders } = useAppSelector(state => state.manageOrders);
  const companiesData = localStorage.getItem("companies");
  useEffect(() => {
    dispatch(orderSeen());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getNewOrders());
  }, [dispatch, companiesData]);

  return (
    <Page
      title={[]}
      header={{
        pageTitle: (
          newOrders?.data?.customers?.length ? <p className="title-3 flex alignCenter gap-2">
          New Orders
          </p> : <></>
        ),
      }}
      className={"orders-page flex gap-3 direction-column"}>
      <div className="orders-container">
        <NewOrdersList />

        {/* Table */}
        <OrdersData />
      </div>
    </Page>
  );
};

export default Orders;