import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UploadFile } from "antd";

import Page from "../../components/page";
import ProductsListTable from "./productsListTable";
import { AppDispatch, RootState } from "../../redux/store";
import { addProduct, checkExistingProduct, getProductUnits, manageField } from "../../redux/services/products";
import { DataType, FieldValues, infoDefaultField, ManagementField } from "../../types/productsType";
import ProductInfo from "./productInfo";
import useMessageHook from "../../hooks/useMessageHook";

const AddProduct = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [productManageFields, setProductManageFields] = useState<ManagementField[]>([]);
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [fieldValues, setFieldValues] = useState<FieldValues[]>([]);
  const [infoFields, setInfoFields] = useState<infoDefaultField>({
    productName: "",
    productDesciption: "",
    productAlias: [],
  });

  const [additionalColumnsData, setAdditionalColumnsData] = useState<any[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [errors, setErrors] = useState<{
    productName: boolean;
    dataSource: { productSize: boolean; pricePerUnit: boolean }[];
  }>({
    productName: false,
    dataSource: [],
  });

  const { showMessage, contextHolder } = useMessageHook();

  const { managementFields, productUnits } = useSelector((state: RootState) => state.products);
  const location = useLocation();
  const productData = location.state;
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (productUnits === null) {
      dispatch(getProductUnits({ search: "" }));
    }
  }, [productUnits, dispatch]);

  const AddProductData = () => {
    const product = fileList.map(file => file.originFileObj) || [];

    const convertToNumber = (value: string | number): number => {
      return typeof value === "string" && value.trim() ? parseFloat(value) : 0;
    };

    const productSizes = dataSource.map(({ key, productBulkRates, ...rest }) => {
      const hasBulkRateWithQuantity = productBulkRates?.some(rate => rate?.productQuantity > "0");

      return {
        ...rest,
        ...(hasBulkRateWithQuantity && { productBulkRates }),
      };
    });

    const formatedData = productSizes.filter((size) => size.productSize && size.pricePerUnit).map((size) => ({
      ...size,
      moq: convertToNumber(size.moq),
      pricePerUnit: convertToNumber(size.pricePerUnit),
      alternateUnitQuantity: convertToNumber(size.alternateUnitQuantity),
      productSizeCustomFieldsData: size.productSizeCustomFieldsData.map(({ id, ...rest }: any) => rest),
      ...(size.productBulkRates?.length && {
        productBulkRates: size.productBulkRates?.map((rate) => ({
          ...rate,
          productQuantity: convertToNumber(rate.productQuantity),
          productRate: convertToNumber(rate.productRate),
        })),
      }),
    }));
    const productData = {
      productName: infoFields?.productName?.trim(),
      productDescription: infoFields?.productDesciption?.trim(),
      productAlias: infoFields?.productAlias,
      // productCustomFieldsData: newFieldValues,
      // manageFieldsData: productManageFields ? productManageFields : [],
      productSizes: formatedData,
      productCustomFieldsData: fieldValues?.map(({ id, fieldType, ...rest }) => rest),
    };
    return {product, productData};
  };

  useEffect(() => {
    localStorage.setItem("dataSource", JSON.stringify({...AddProductData(), token}));
    localStorage.setItem("isDraftProduct", JSON.stringify("true"));
  }, [dataSource, AddProductData()]);

  useEffect(() => {
    if (managementFields !== null) {
      setProductManageFields(managementFields.data);
    }
  }, [managementFields]);

  useEffect(() => {
    const payload = {
      productId: 0,
      search: "",
      limit: 0,
      fieldType: 2,
    };
    dispatch(manageField(payload));
  }, [dispatch]);

  useEffect(() => {
    if (productData && dataSource?.length < 1) {
      setInfoFields({ ...infoFields, productName: productData.productName });
      const newData = {
        key: dataSource?.length || 0,
        productSize: productData.size,
        pricePerUnit: productData.rate,
        productSizeUnit: productData.sizeUnit || "",
        productSizeOrderUnit: productData.orderUnit,
        alternateUnitQuantity: "",
        alternateUnitPrimaryUnit: "",
        alternateUnitSecondaryUnit: productData.orderUnit,
        moq: "",
        isActive: true,
        isDelete: false,
        productBulkRates: [
          {
            isActive: true,
            isDelete: false,
            productRate: "",
            isRateInRupees: true,
            productQuantity: "",
          },
        ],
        productSizeCustomFieldsData: productManageFields
          .filter(field => !field.isDefault && field.fieldType === 2)
          .map(field => ({
            fieldName: field.fieldName,
            fieldValue: "",
            fieldType: 2,
            isDefault: field.isDefault,
            isActive: field.isActive,
            isDelete: field.isDelete,
            productCustomFieldsId: 0,
          })),
      };
      const blankData = {
        key: 1 || 0,
        productSize: "",
        pricePerUnit: "",
        productSizeUnit: productData.sizeUnit || "",
        productSizeOrderUnit: productData.orderUnit,
        alternateUnitQuantity: "",
        alternateUnitPrimaryUnit: "",
        alternateUnitSecondaryUnit: productData.orderUnit,
        moq: "",
        isActive: true,
        isDelete: false,
        productBulkRates: [
          {
            isActive: true,
            isDelete: false,
            productRate: "",
            isRateInRupees: true,
            productQuantity: "",
          },
        ],
        productSizeCustomFieldsData: productManageFields
          .filter(field => !field.isDefault && field.fieldType === 2)
          .map(field => ({
            fieldName: field.fieldName,
            fieldValue: "",
            fieldType: 2,
            isDefault: field.isDefault,
            isActive: field.isActive,
            isDelete: field.isDelete,
            productCustomFieldsId: 0,
          })),
      };
      setDataSource([...dataSource, newData, blankData]);
    }
  }, [productData]);

  const validateProductFields = (fields: DataType[]) => {
    let isValid = true;
    const fieldErrors = fields
      .map((field, index) => {
        const hasAnyValue = field.productSize || (field.pricePerUnit);
        return hasAnyValue
          ? {
            index,
            productSize: !field.productSize,
            pricePerUnit: (!field.pricePerUnit),
          }
          : {
            index,
            productSize: false,
            pricePerUnit: false,
          };
      });
    const hasAtLeastOneFilledField = fields.some(
      (field) => field.productSize || field.pricePerUnit,
    );

    if (!hasAtLeastOneFilledField) {
      fieldErrors[0] = {
        ...fieldErrors[0],
        productSize: true,
        pricePerUnit: true,
      };
    }

    const hasError = fieldErrors.some((error) => error.productSize || error.pricePerUnit);
    if (!hasAtLeastOneFilledField || hasError || !infoFields.productName) {
      isValid = false;
      setErrors({
        productName: !infoFields.productName,
        dataSource: fieldErrors,
      });
    } else {
      setErrors({
        productName: false,
        dataSource: [],
      });
    }

    return isValid;
  };

  const validatePricePerUnit = (fields: DataType[]) => {
    let isValid = true;
    const fieldErrors = fields
      .map((field, index) => {
        const hasAnyValue =
          field.productSize ||
          (field.pricePerUnit);

        return hasAnyValue
          ? {
            index,
            productSize: !field.productSize,
            pricePerUnit: (!field.pricePerUnit || (parseFloat(field?.pricePerUnit?.toString()) <= 0 ? true : false)),
          }
          : {
            index,
            productSize: false,
            pricePerUnit: false,
          };
      });

    const hasError = fieldErrors.some((error) => error.productSize || error.pricePerUnit);

    if (hasError || !infoFields.productName) {
      isValid = false;
      setErrors({
        productName: !infoFields.productName,
        dataSource: fieldErrors,
      });
    } else {
      setErrors({
        productName: false,
        dataSource: [],
      });
    }

    return isValid;
  };

  const handleAdd = () => {
    const newData: DataType = {
      key: dataSource[dataSource.length - 1].key + 1,
      productSize: "",
      pricePerUnit: "",
      productSizeUnit: dataSource[dataSource.length - 1].productSizeUnit || "",
      productSizeOrderUnit: dataSource[dataSource.length - 1].productSizeOrderUnit || "",
      alternateUnitQuantity: "",
      alternateUnitPrimaryUnit: "",
      alternateUnitSecondaryUnit: dataSource[dataSource.length - 1].productSizeOrderUnit || "",
      moq: "",
      isActive: true,
      isDelete: false,
      productBulkRates: [
        {
          isActive: true,
          isDelete: false,
          productRate: "",
          isRateInRupees: true,
          productQuantity: "",
        },
      ],
      productSizeCustomFieldsData: productManageFields
        .filter(field => !field.isDefault && field.fieldType === 2)
        .map(field => ({
          fieldName: field.fieldName,
          fieldValue: "",
          fieldType: 2,
          isDefault: field.isDefault,
          isActive: field.isActive,
          isDelete: field.isDelete,
          productCustomFieldsId: 0,
        })),
    };
    setDataSource([...dataSource, newData]);
  };

  const handleAddProduct = async () => {
    if (!validateProductFields(dataSource)) {
      showMessage("error", "Please enter mandatory fields");
      return;
    }
    if (!validatePricePerUnit(dataSource)) {
      showMessage("error", "Please enter valid values");
      return;
    }
    const { product, productData } = AddProductData();

    dispatch(checkExistingProduct(infoFields?.productName?.trim())).then((result) => {
      if (result?.payload?.data?.existing) {
        showMessage("error", result?.payload.message || "Something went wrong");
      } else {
        dispatch(addProduct({ product, productData })).then((result) => {
          if (result.payload.status === 200) {
            localStorage.removeItem("dataSource");
            localStorage.removeItem("isDraftProduct");
            navigate("/products", { replace: true });
          } else {
            dispatch(addProduct({ product, productData })).then((result) => {
              if (result.payload.status === 200) {
                navigate("/products", { replace: true });
              } else {
                showMessage("error", result?.payload.message || "Something went wrong");
              }
            }).catch((error) => { showMessage("error", error.message || "Something went wrong"); });
          }
        },
        );
      }
    },
    );
  };

  return (
    <Page title={[]} header={{
      pageTitle: "Add New Product", buttons: {
        saveProduct: true,
        onClick() {
          handleAddProduct();
        },
      },
    }} className="add-products flex gap-3 direction-column">
      <div className="flex direction-column add-products-container">
        {contextHolder}
        <ProductInfo
          fieldValues={fieldValues}
          setFieldValues={setFieldValues}
          productManageFields={productManageFields}
          // setToggleManageField={setToggleManageField}
          setInfoFields={setInfoFields}
          infoFields={infoFields}
          errors={errors}
          setFileList={setFileList}
          fileList={fileList}
          productId={0}
        />

        <ProductsListTable
          productManageFields={productManageFields}
          dataSource={dataSource}
          setDataSource={setDataSource}
          handleAdd={handleAdd}
          title="Product Information"
          setAdditionalColumnsData={setAdditionalColumnsData}
          setProductManageFields={setProductManageFields}
          additionalColumnsData={additionalColumnsData}
          errors={errors}
          setErrors={setErrors}
        />
      </div>
    </Page>
  );
};

export default AddProduct;
