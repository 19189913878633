import React from "react";
import { Modal } from "antd";

interface Props {
  onSave?: () => void
  close: () => void,
  onCancel?: () => void,
  header?: string,
  info?: string,
  open: boolean,
  children: React.ReactNode,
  className?: string,
  footer?: boolean,
  closeIcon?: boolean,
  cancelButtonText?: string
  saveButtonText?: string
}

const CommonModal = ({
  cancelButtonText = "Cancel",
  saveButtonText = "Save",
  header,
  info,
  closeIcon = false,
  footer = false,
  open, close,
  children,
  className,
  onSave,
  onCancel, 
}: Props) => {
  return (
    <Modal
      centered
      maskClosable={false}
      open={open}
      closeIcon={closeIcon}
      okText="Save"
      onCancel={close}
      className={"transparent-card common-modal-container " + className}
      footer={footer ? (
        <div className="flex gap-3 justifyEnd">
          <button
            onClick={onCancel || close}
            className="primary secondary rounded-8 large40 modal-button"
          >
            {cancelButtonText}
          </button>
          <button
            type="button"
            onClick={onSave} // or close(false) depending on your requirement
            className="primary rounded-8 large40 modal-button"
          >
            {saveButtonText}
          </button>
        </div>
      ) : null}
    >
      {header && <h1 className="header-title">{header}</h1>}
      {info && <p className="header-info">{info}</p>}
      {children}
    </Modal>
  );
};

export default CommonModal;