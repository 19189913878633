import React from "react";
import { Table, TableProps } from "antd";

import { AttachmentIcon, FileIcon, PdfIcon } from "../../assets/images/icons";
import { useAppSelector } from "../../hooks/useAppSelector";
import { Logo } from "../../assets/images";
import StatusSwitch from "./statusSwitch";

const DispatchDetailSummary = () => {
  const { orderDetails } = useAppSelector(state => state?.manageOrders);

  const columns: TableProps<any>["columns"] = [
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      width: 280,
      render: (text, record: any) => (
        <div className="product-name-box flex alignCenter gap-3">
          <div className="product-image-container">
            {record?.product?.productImage?.thumbnailURL ? <img className="product-image" src={record?.product?.productImage?.thumbnailURL} alt="product-image" /> : <Logo />}
          </div>
          <span className="product-name">{record?.product?.productName || "-"}</span>
        </div>
      ),
    },
    {
      title: "Dispatch Qty.",
      dataIndex: "qty",
      key: "qty",
      width: 131,
      render: (text, record: any) => (
        <div className="qty-text flex gap-1">
          <span className="quantity">{record?.quantityDispatched}</span>
          <span className="unit">{record?.orderUnitConvertionUnit ? record?.orderUnitConvertionUnit : record?.orderUnit}</span>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 131,
      render: (text, record: any) => (
        <StatusSwitch status="Dispatched" orderId={record?.orderId} />
      ),
    },
  ];

  const renderAttachment = (attachmentUrl: string) => {
    const isPdf = attachmentUrl.toLowerCase().endsWith(".pdf");

    return (
      <>
        <div className="image-box">
          <a href={attachmentUrl} target="_blank" rel="noopener noreferrer">
            {isPdf ? (
              <PdfIcon />
            ) :
              <img src={attachmentUrl} alt="" />
            }
          </a>
        </div>
      </>
    );
  };

  return (
    <div className="dispatch-details-container">
      <div className="dispatch-details-box flex ">
        {orderDetails.data?.transportation &&
          <div className="transportation-details-box flex gap-3 ">
            <FileIcon />
            <div className="flex direction-column gap-2">
              <h4 className="transportation-header">Transportation Details</h4>
              <div className="transportation-content flex direction-column">
                {orderDetails.data?.transportation.name ?
                  <h4 className="transportation-content-header">{orderDetails.data?.transportation.name}</h4> : <></>}
                {orderDetails.data.transportation.pincode ? <p className="details">{orderDetails.data.transportation.pincode}, {orderDetails.data.transportation.addressLine}.
                  <br />
                  {orderDetails.data?.transportation?.trakingId ? <><b>Tracking ID:</b> #{orderDetails.data?.transportation?.trakingId}</> : <></>}
                </p> : <></>}
              </div>
            </div>
          </div>}
        {orderDetails.data?.transportation?.attachments?.length ?
          <div className="attachments-container flex gap-3">
            <div className="attach-icon">
              <AttachmentIcon />
            </div>
            <div className="content-container flex direction-column gap-2">
              <h4 className="attachment-header-text">Attachments</h4>
              <div className="attached-files flex gap-4">
                {orderDetails.data?.transportation?.attachments?.map((data) => renderAttachment(data))}
              </div>
            </div>
          </div> : <></>}
      </div>

      <div className="order-details-table-container">
        <Table
          columns={columns}
          dataSource={orderDetails.data?.orderItems || []}
          pagination={false}
          className="order-details-table"
        />
      </div>
    </div>
  );
};

export default DispatchDetailSummary;