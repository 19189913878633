import React, { useState } from "react";
import { Popover, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";

import { CartIcon, MoreIconsDots, RoundClockIcon, StopIcon } from "../../assets/images";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { BlockUnblockRemoveConnection, getVendorList, RemoveVendor } from "../../redux/services/vendor";
import { DeleteIcon, UnblockIcon } from "../../assets/images/icons";
import { getProfileIcon } from "../../utils/helper";
import RecentOrdersDrawer from "./recentOrdersDrawer";
import OrderCart from "../placeOrder/orderCart";
import { getCartDataByVendorID } from "../../redux/services/placeOrder";

interface Props {
  data?: {
    companyId: number;
    name: string;
    createdBy: {
      firstName: string;
      lastName: string;
    };
    addressLine: string | null;
    city: string;
    district: string;
    state: string;
    country: string;
    cart: {
      totalItems: number;
      totalAmount: number;
    };
    connectionId: number;
    isBlock: boolean;
    isBlockAsCustomer: boolean;
    isBlockAsVendor: boolean;
    recentOrders: boolean;
    profileThumbnailImg: string;
    blockByCompany: number
  }
}


const VendorCard = ({ data }: Props) => {
  const [toggle, setToggle] = useState(false);
  const cartItems = data?.cart?.totalItems || 0;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const companyData = localStorage.getItem("companies");

  const blockVendor = async () => {
    await dispatch(BlockUnblockRemoveConnection({
      connectionId: data?.connectionId || 0,
      action: data?.isBlock ? "Unblock" : "Block",
    }));
    await dispatch(getVendorList());
  };

  const removeVendor = async () => {
    await dispatch(RemoveVendor({
      vendorIds: [data?.companyId || 0],
    }));
    await dispatch(getVendorList());
  };

  const content = () => {
    return (
      <div className="more-popup-items flex direction-column">
        {data?.recentOrders ? <button className="item" onClick={() => setToggle(!toggle)}>
          <RoundClockIcon /> <span>Recent Orders</span>
        </button> : ""}
        {(data?.blockByCompany === JSON.parse(companyData || "")?.companyId || !data?.isBlock) ? <button className="item" onClick={() => blockVendor()}>
          <span>{data?.isBlock ? <div className="flex gap-2 alignCenter"><UnblockIcon /> Unblock</div> : <div className="flex gap-2 alignCenter"><StopIcon /> Block</div>}</span>
        </button> : <></> }
        <button className="item remove" onClick={() => removeVendor()}>
          <DeleteIcon /> <span>Remove</span>
        </button>
      </div>
    );
  };

  const handleOpenCart = (orderId:number) => {
    setIsOpen(true);
    dispatch(getCartDataByVendorID({
      vendorId: orderId,
    }));
  };

  return (
    <div className="vendor-card-wrapper">
      {!toggle &&
        <Popover content={content} placement="bottomRight" trigger={"click"} rootClassName="vendor-action-modal">
          <button className="more-icon">
            <MoreIconsDots />
          </button>
        </Popover>}
      <div className="vendor-card">
        <div className="image-container">
          {data?.profileThumbnailImg ? <img src={data?.profileThumbnailImg} alt={data?.name} /> : <span className="profile-icon">{getProfileIcon(data?.name || "")}</span>}
        </div>
        <div className="info-container">
          <div className="business-info">
            <h3 className="business-name title-3">{data?.name}</h3>
            <p className="vendor-name">{data?.createdBy?.firstName + " " + data?.createdBy?.lastName}</p>
          </div>
          {(data?.district && data?.city) ? <p className="address-info">{(data?.district || data?.city || "-") + (data?.state ? ", " + (data?.state || "-") : "")}</p> : ""}
        </div>
        {data?.isBlock ?
          <div className="buttons-container">
            <p className="error-message">Blocked</p>
          </div> :
          <div className="buttons-container">
            {cartItems ? <button className="cart-button" onClick={() => handleOpenCart(data?.companyId || 0)}>
              <CartIcon /><Tooltip title={`Total ${cartItems > 1 ? cartItems + " Items" : cartItems + " Item"} in your cart`}><span className="cart-count">{cartItems > 99 ? "99+" : cartItems}</span></Tooltip>
            </button> : <></>}
            <button className="order-now-button" onClick={() => navigate("/placeorder", { state: { orderId: data?.companyId, companyName: data?.name, companyProfile: data?.profileThumbnailImg }, replace: true })}>Order Now</button>
          </div>
        }
      </div>
      <RecentOrdersDrawer setToggle={setToggle} toggle={toggle} data={data} setIsCartOpen={setIsOpen} />
      <OrderCart setIsOpen={setIsOpen} isOpen={isOpen} orderid={data} />
    </div>
  );
};

export default VendorCard;