import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import SearchBar from "../../components/searchBar";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getProductsGroups } from "../../redux/services/products";
import { useAppSelector } from "../../hooks/useAppSelector";
import { debounce } from "../../utils/debounce";

interface IProps {
  setGroupId: (e: number) => void;
}

const ListOfGroups = ({ setGroupId }: IProps) => {
  const [activeGroup, setActiveGroup] = useState(0);
  const { productGroups } = useAppSelector(state => state.products);

  const handleSelect = (id: number) => {
    setActiveGroup(id);
    setGroupId(id);
  };

  return (
    <aside className="sidebar">
      <h2 className="sidebar-title">List of Groups</h2>
      <ul className="group-list">
        {productGroups && productGroups?.map((item) => {
          return (
            <li className={`group-item${activeGroup === item.productGroupId ? " active" : ""}`} key={item.productGroupId}>
              <div className="group-item-name-count" onClick={() => handleSelect(item.productGroupId)}><p className="group-item-name">{item.groupName} </p><div className="group-item-count">{item.totalProducts}</div></div>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default ListOfGroups;
