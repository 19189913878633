import React from "react";

const CartIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
      <path d="M5.14421 12.3635H16.2897C17.149 12.3635 17.5787 12.3635 17.9208 12.2032C18.2221 12.0621 18.4753 11.8355 18.6488 11.5516C18.8459 11.2293 18.8933 10.8022 18.9882 9.94815L19.4846 5.48084C19.5136 5.21997 19.5281 5.08953 19.4861 4.98856C19.4493 4.89989 19.3834 4.82634 19.2994 4.77994C19.2037 4.7271 19.0724 4.7271 18.8099 4.7271H4.71996M2.59863 2.18164H3.65791C3.88244 2.18164 3.99471 2.18164 4.08254 2.22434C4.15986 2.26193 4.22394 2.32213 4.26628 2.39695C4.31437 2.48195 4.32137 2.594 4.33538 2.81809L5.10431 15.121C5.11832 15.345 5.12532 15.4571 5.17341 15.5421C5.21575 15.6169 5.27983 15.6771 5.35715 15.7147C5.44498 15.7574 5.55725 15.7574 5.78178 15.7574H17.0229M7.2653 18.7271H7.27378M14.9017 18.7271H14.9101M7.68954 18.7271C7.68954 18.9614 7.4996 19.1513 7.2653 19.1513C7.031 19.1513 6.84106 18.9614 6.84106 18.7271C6.84106 18.4928 7.031 18.3029 7.2653 18.3029C7.4996 18.3029 7.68954 18.4928 7.68954 18.7271ZM15.3259 18.7271C15.3259 18.9614 15.136 19.1513 14.9017 19.1513C14.6674 19.1513 14.4774 18.9614 14.4774 18.7271C14.4774 18.4928 14.6674 18.3029 14.9017 18.3029C15.136 18.3029 15.3259 18.4928 15.3259 18.7271Z" stroke="black" strokeOpacity="0.88" strokeWidth="1.35758" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default CartIcon;