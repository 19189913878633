import { Input, InputRef } from "antd";
import React, { useEffect, useRef } from "react";

import { handleKeyDown } from "../../utils/helper";


interface SizeInputProps {
  disabled?: boolean,
  name?: string,
  autofocus?: boolean,
  required?: boolean,
  label?: string;
  className?: string;
  placeholder?: string;
  value?: string | number;
  prefixSelector?: React.ReactNode | string;
  suffixSelector?: React.ReactNode | string;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  previewText?: string;
  maxLength?: number;
  suffix?: string
}

const InputWithDropdown: React.FC<SizeInputProps> = ({
  disabled = false,
  name,
  autofocus = false,
  required = false,
  label,
  className,
  placeholder = "Size (Ex. 100)",
  value,
  prefixSelector,
  suffixSelector,
  onChange,
  maxLength = 50,
  suffix,
}) => {
  const inputRef = useRef<InputRef | null>(null);

  useEffect(() => {
    if (autofocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autofocus]);

  return (
    <div className={className + " antd-input"}>
      <label htmlFor="">
        <span className="size-label flex gap-1">{label}{required && <span className="require-icon"> *</span>}</span>
        <Input
          disabled={disabled}
          ref={inputRef}
          addonBefore={prefixSelector}
          addonAfter={suffixSelector}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          maxLength={maxLength}
          name={name}
          autoComplete="off"
          suffix={suffix}
        />
      </label>
    </div>
  );
};

export default InputWithDropdown;