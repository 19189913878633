import React from "react";
import { Drawer } from "antd";

interface Props {
  title: string;
  onClose: () => void;
  open: boolean;
  rootClassName: string;
  children: React.ReactNode,
}

const classNames = {
  body: "my-drawer-body",
  mask: "my-drawer-mask",
  header: "my-drawer-header",
  content: "my-drawer-content",
};

const CommonDrawer = ({ title, onClose, open, rootClassName, children }: Props) => {
  return (
    <Drawer
      title={title}
      onClose={onClose}
      open={open}
      rootClassName={rootClassName}
      classNames={classNames}
    >
      {children}
    </Drawer>
  );
};

export default CommonDrawer;