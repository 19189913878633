import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { TransparentPlus } from "../../assets/images/icons";
import { getProfileIcon } from "../../utils/helper";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { selectCompany } from "../../redux/services/auth";
import useMessageHook from "../../hooks/useMessageHook";

interface Props {
  setStep: (value: number) => void;
  phoneNumber: string
}

const SelectCompany = ({ phoneNumber }: Props) => {
  const dispatch = useAppDispatch();
  const { showMessage, contextHolder } = useMessageHook(); 

  const { companyData } = useAppSelector((state) => state.auth);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Clear state by replacing current state with null
    if (location.state) {
      navigate(location.pathname, { replace: true, state: null });
    }
  }, [location, navigate]);

  const handleClick = async (value: number) => {
    dispatch(selectCompany(value)).then((result) => {
      if (result?.payload?.status === 200) {
        localStorage.setItem("companies", JSON.stringify(result.payload.data.company));
        localStorage.setItem("token", result.payload.data.token);
        localStorage.setItem("user", JSON.stringify(result.payload.data.user));
        navigate("/");
      }else {
        showMessage("error", result?.payload.message || "Something went wrong");
      }
    });
  };

  const handleAddNewComapny = () => {
    localStorage.setItem("token", companyData?.token || "");
    localStorage.setItem("companies", JSON.stringify([]));
    localStorage.setItem("createNewCompany", JSON.stringify(true));
    navigate("/", { state: { phoneNo: phoneNumber, isSelectCompany: true } });
  };

  return (
    <div className="select-company-profile-screen">
      {contextHolder}
      <div className="select-profile-card">
        <div className="card-header">
          <div className="flex gap-2 alignCenter justifyCenter">
            <h3 className="title-welcome">Welcome</h3>
            <h3 className="title-black">Back</h3>
          </div>
          <span className="select-profile-text">Select Profile</span>
        </div>
        <div className="company-selection">
          {companyData?.companies.length ? companyData.companies?.map((item: any) => (
            <button key={item?.companyId} className="company-card" onClick={() => handleClick(item?.companyId)}>
              <div className="content-container">
                <div className="image-container">
                  {item?.profileThumbnailImg ? <img src={item?.profileThumbnailImg} alt="" /> : getProfileIcon(item?.name)}
                </div>
                <div className="user-info">
                  <h5 className="company-name">{item?.name}</h5>
                  <span className="user-name">{item.city ? <>{item.city}, {item.state}</> : <></>}</span>
                </div>
              </div>
            </button>)) : <></>}
          <button className="company-card" onClick={handleAddNewComapny}>
            <div className="content-container">
              <div className="image-container">
                <TransparentPlus />
              </div>
              <div className="user-info">
                <h5 className="company-name">Add</h5>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectCompany;