import React from "react";

export const BG3 = () => {
  return (
    <svg width="719" height="636" viewBox="0 0 719 636" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f_2039_12147)">
        <ellipse cx="359.469" cy="318" rx="198.899" ry="158" fill="#FFDC82"/>
      </g>
      <defs>
        <filter id="filter0_f_2039_12147" x="0.570435" y="0" width="717.798" height="636" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="80" result="effect1_foregroundBlur_2039_12147"/>
        </filter>
      </defs>
    </svg>
  );
};