import { createSlice } from "@reduxjs/toolkit";

import { getAllSizesWithSearch, getCartDataByVendorID, getProductListByVendorId, recentOrders } from "../services/placeOrder";
import { OrderDetailsProps } from "../../types/ordersTypes";
import { ProductSizes } from "../../types/productsType";

interface CartProps {
  cartId: number,
  cartItems: {
    cartItemId: number,
    quantity: number,
    orderUnitConvertionQuantity: number,
    orderUnit: string,
    orderUnitConvertionUnit: string,
    productSizeUnit: string,
    pricePerUnit: number,
    discount: number,
    subtotal: number,
    product: {
        productId: number,
        productName: string,
        productImage: {
          thumbnailURL: string
        }
    },
    productSize: {
        productSizeId: number,
        productSize: string,
        productSizeUnit: string,
        productSizeOrderUnit: string,
        moq: number,
        productBulkRates: {
          isRateInRupees: boolean,
          placePosition: number,
          productBulkRateId: number,
          productQuantity: number,
          productRate: number
        }[]
    }
  }[]
}

const initialState: {
  vendorsProductList: any,
  cart: {
    isLoading: boolean,
    isSuccess: boolean,
    data: CartProps| null,
    message: string
  },
  recentOrdersData: {
    isLoading: boolean,
    isSuccess: boolean,
    data: OrderDetailsProps[] | null,
    message: string
  },
  getAllSizes: {
    isLoading: boolean,
    isSuccess: boolean,
    data: ProductSizes[] | null,
    message: string
  }
  
} = {
  vendorsProductList: {
    isLoading: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  cart: {
    isLoading: false,
    isSuccess: false,
    data: null,
    message: "",
  },
  recentOrdersData: {
    isLoading: false,
    isSuccess: false,
    data: null,
    message: "",
  },
  getAllSizes: {
    isLoading: false,
    isSuccess: false,
    data: null,
    message: "",
  },
};

const placeorderSlice = createSlice({
  name: "placeorder",
  initialState,
  reducers: {
    clearCart: (state) => {
      state.cart = initialState.cart;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductListByVendorId.pending, (state) => {
      state.vendorsProductList.data = null;
      state.vendorsProductList.isLoading = true;
    });
    builder.addCase(getProductListByVendorId.fulfilled, (state, { payload }: any) => {
      state.vendorsProductList.isSuccess = true;
      state.vendorsProductList.isLoading = false;
      state.vendorsProductList.data = payload?.data;
    });
    builder.addCase(getProductListByVendorId.rejected, (state, { payload }: any) => {
      state.vendorsProductList.data = payload;
      state.vendorsProductList.isLoading = false;
      state.vendorsProductList.isSuccess = false;
    });

    builder.addCase(getCartDataByVendorID.pending, (state) => {
      // state.cart.data = null;
      state.cart.isLoading = true;
      state.cart.isSuccess = false;
    });
    builder.addCase(getCartDataByVendorID.fulfilled, (state, { payload }: any) => {
      state.cart.isSuccess = true;
      state.cart.isLoading = false;
      state.cart.data = payload?.data;
    });
    builder.addCase(getCartDataByVendorID.rejected, (state, { payload }: any) => {
      state.cart.data = payload;
      state.cart.isLoading = false;
      state.cart.isSuccess = false;
    });

    // recentOrders
    builder.addCase(recentOrders.pending, (state) => {
      state.recentOrdersData.isLoading = true;
    });
    builder.addCase(recentOrders.fulfilled, (state, { payload }: any) => {
      state.recentOrdersData.isSuccess = true;
      state.recentOrdersData.isLoading = false;
      state.recentOrdersData.data = payload?.data;
    });
    builder.addCase(recentOrders.rejected, (state, { payload }: any) => {
      state.recentOrdersData.data = payload;
      state.recentOrdersData.isLoading = false;
      state.recentOrdersData.isSuccess = false;
    });

    // getAllSizesWithSearch
    builder.addCase(getAllSizesWithSearch.pending, (state) => {
      state.getAllSizes.isLoading = true;
    });
    builder.addCase(getAllSizesWithSearch.fulfilled, (state, { payload }: any) => {
      state.getAllSizes.isSuccess = true;
      state.getAllSizes.isLoading = false;
      state.getAllSizes.data = payload;
    });
    builder.addCase(getAllSizesWithSearch.rejected, (state, { payload }: any) => {
      state.getAllSizes.data = payload;
      state.getAllSizes.isLoading = false;
      state.getAllSizes.isSuccess = false;
    });
  },
});

export const { clearCart } = placeorderSlice.actions;
export default placeorderSlice.reducer;