import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./reducers";

// import counterReducer from "../features/counter/counterSlice";

const store = configureStore({
  reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;