import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ClockCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { DatePicker, Popover, Table, TableProps, Tabs, TabsProps, TimeRangePickerProps, Tooltip } from "antd";
import dayjs from "dayjs";

import {
  getConnectedCompanyOrder,
  getConnectionList,
  getConnectionRequests,
  getSentRequest,
  takeActionOnConnectionRequest,
} from "../../redux/services/connection";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import NoDataPage from "./noDataPage";
import Page from "../../components/page";
import { Button } from "../../components";
import { Logo, StopIcon } from "../../assets/images";
import { DeleteIcon, DownArrowFull, HumanIcon, UnblockIcon, UserIcon } from "../../assets/images/icons";
import ConnectionsListSidebar from "./connectionsListSidebar";
import InviteModal from "./inviteModal";
import NoDataFound from "../../components/NoDataFound";
import ThreeDots from "../../assets/images/threeDots";
import { BlockUnblockRemoveConnection } from "../../redux/services/vendor";
import { getDateLabel, groupDataByDate, formatRupees } from "../../utils/helper";
import StatusSwitch from "../orders/statusSwitch";
import { tableSortingIconToggle } from "../../utils/tableSortingIconToggle";
import SearchBar from "../../components/searchBar";
import { getOrderItemsByOrder } from "../../redux/services/orders";
import { debounce } from "../../utils/debounce";
import DispatchOrderModal from "../orders/dispatchOrderModal";
import TransportationDetails from "../orders/transportationDetails";

interface ConnectionInfoType {
  companyId: number,
  connectionId: number,
  isVendor: boolean,
  isCustomer: boolean,
  isBlock: boolean,
  blockByCompany: number,
}

interface dispatchType {
  isDispatch: boolean;
  orderId: number | null;
  isTransport: boolean
}


const Connection = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [connectionRequests, setConnectionRequests] = useState({
    isOpen: false,
    data: [],
  });
  const [pendingRequests, setPendingRequests] = useState({
    isOpen: false,
    data: [],
  });
  const [sort, setSort] = useState({
    order: "ASC",
    field: "createdOn",
  });
  const [date, setDate] = useState<{
    startDate: string,
    endDate: string,
  }>({
    startDate: (dayjs(new Date()).subtract(3, "months").format("DD/MM/YYYY")),
    endDate: (dayjs(new Date()).format("DD/MM/YYYY")),
  });
  const [isConnectionReq, setIsConnectionReq] = useState(true);
  const [connectionInfo, setConnectionInfo] = useState<ConnectionInfoType>();
  const [activeTab, setActiveTab] = useState(1);
  const [searchOrder, setSearchOrder] = useState<string>("");
  const [showSentRequest, setShowSentRequest] = useState(false);
  const [toggleDispatch, setToggleDispatch] = useState<dispatchType>({ isDispatch: false, isTransport: false, orderId: null });

  const { connectionDetails, connection, sentRequest, receivedRequest, companyOrders } = useAppSelector(state => state.connections);
  const { statusDetails } = useAppSelector(state => state.manageOrders);

  const location = useLocation()?.state;
  const dispatch = useAppDispatch();
  const { orderItems } = useAppSelector(state => state.manageOrders);

  const navigate = useNavigate();
  const { RangePicker } = DatePicker;

  const companyData = localStorage.getItem("companies");

  useEffect(() => {
    if (sentRequest.data.length) {
      setShowSentRequest(true);
    } else {
      setShowSentRequest(false);
      handlePendingRequest(false);
    }
  }, [sentRequest?.data]);

  useEffect(() => {
    setSearchOrder("");
  }, [connectionDetails]);


  const ListOfAllConnection = async () => {
    const payload = {
      search: searchOrder,
      sort: sort?.order,
      type: "All",
      limit: 0,
      offset: 0,
    };
    await dispatch(getConnectionList(payload));
  };

  const showModal = () => {
    setVisible(true);
  };

  useEffect(() => {
    getRecievedRequest();
    getSendedRequests();
    ListOfAllConnection();
  }, []);

  useEffect(() => {
    if (location?.invite) {
      setVisible(true);
    }
    setTimeout(() => {
      setIsConnectionReq(false);
    }, 6000);
  }, [location?.invite]);

  useEffect(() => {
    console.log("connectionDetails?.data?.companyId",connectionDetails?.data?.companyId);
    if (connectionDetails?.data?.companyId) {
      dispatch(getConnectedCompanyOrder({
        connectedCompanyId: connectionDetails?.data?.companyId || 0,
        endDate: date.endDate,
        limit: 0,
        startDate: date.startDate,
        search: searchOrder,
        sort: [sort],
        type: activeTab,
      }));
    }
  }, [dispatch, connectionDetails?.data?.companyId, activeTab, date, sort]);

  const getRecievedRequest = async () => {
    const payload = {
      search: "",
      limit: 0,
      offset: 0,
    };
    await dispatch(getConnectionRequests(payload));
  };

  const getSendedRequests = async () => {
    const payload = {
      search: "",
      limit: 0,
      offset: 0,
      forVendor: false,
    };
    await dispatch(getSentRequest(payload));
  };

  const requestAction = async (reqType: string, id: string | number) => {
    const payload = {
      requestId: id,
      action: reqType,
    };
    await dispatch(takeActionOnConnectionRequest(payload)).then((result) => {
      if (result?.payload?.status === 200) {
        if (reqType === "Cancel") {
          getSendedRequests();
        }
        else {
          getRecievedRequest();
          ListOfAllConnection();
        }
      } else {
        getRecievedRequest();
      }
    });
    // setVisible(false);
  };

  const handlePendingRequest = (value: boolean) => {
    setPendingRequests({
      isOpen: value,
      data: [],
    });
  };

  // Group the receivedRequest data by date
  const receivedRequestGroupedData = receivedRequest?.data ? groupDataByDate(receivedRequest?.data || []) : {};
  const sentRequestGroupedData = sentRequest.data ? groupDataByDate(sentRequest.data || []) : {};

  const receivedRequestContent = (
    (Object.keys(receivedRequestGroupedData).length && connectionRequests.isOpen) ? (
      Object.entries(receivedRequestGroupedData).map(([date, items]: any) => (
        <div key={date}>
          <div className="notification-time">{getDateLabel(new Date(date))}</div>
          {items && items?.map((item: any) => (
            <div className="notification-details-row" key={item?.sender?.id}>
              <div className="user-data">
                <div className="user-profile-icon">
                  {item?.sender?.profileThumbnailImg ? (
                    <img src={item?.sender?.profileThumbnailImg} alt="profile-img" />
                  ) : (
                    <UserIcon />
                  )}
                </div>
                <div className="user-details">
                  <h4 className="title-4">{item?.sender?.name}</h4>
                  <p className="caption">
                    {item?.sender?.createdBy?.firstName + " " + item?.sender?.createdBy?.lastName}
                  </p>
                </div>
              </div>
              <div className="buttons-container flex gap-3">
                <button
                  className="request-button"
                  type="button"
                  onClick={() => { requestAction("Reject", item?.requestId); }}
                >
                  Reject
                </button>
                <button
                  className="request-button active"
                  type="button"
                  onClick={() => { requestAction("Accept", item?.requestId); }}
                >
                  Accept
                </button>
              </div>
            </div>
          ))}
        </div>
      ))
    ) : <NoDataFound />
  );

  const sentRequestsContent = (
    Object.keys(sentRequestGroupedData).length ?
      Object.entries(sentRequestGroupedData).map(([date, items]: any) => {
        return (
          <div key={date}>
            <div className="notification-time">{getDateLabel(new Date(date))}</div>
            {items && items?.map((item: any) => (
              <div className="notification-details-row" key={item?.requestId}>
                <div className="user-data">
                  <div className="user-profile-icon">
                    {item?.receiver?.profileThumbnailImg ? <img src={item?.receiver?.profileThumbnailImg} alt="profile-img" /> : <UserIcon />}
                  </div>
                  <div className="user-details">
                    <h4 className="title-4">{item?.receiver?.name}</h4>
                    <p className="caption">{item?.receiver?.createdBy?.firstName + " " + item?.receiver?.createdBy?.lastName}</p>
                  </div>
                </div>
                <button
                  className="status pending"
                  type="button"
                >
                  <div className="flex gap-1"><ClockCircleOutlined /> Pending</div>
                  <div className="flex gap-1 hover" onClick={() => requestAction("Cancel", item?.requestId)}>Cancel</div>
                </button>
              </div>
            ))}
          </div>
        );
      }) : <NoDataFound />
  );

  const blockUnblockRemoveConnection = async (value: string) => {
    await dispatch(BlockUnblockRemoveConnection({
      connectionId: connectionInfo?.connectionId || 0,
      action: value,
    }));
    setIsPopoverOpen(!isPopoverOpen);
    ListOfAllConnection();
  };

  const orderItemsColumns: TableProps<any>["columns"] = [
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      width: 280,
      render: (_, record: any) => (
        <div className="product-name-box flex alignCenter gap-3">
          <div className="product-image-container">
            {record?.product?.productImage?.thumbnailURL ? <img className="product-image" src={record?.product?.productImage?.thumbnailURL} alt="product-img" /> : <Logo />}
          </div>
          <span className="product-name">{record?.product?.productName}</span>
        </div>
      ),
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      width: 159,
      render: (_, record: any) => (
        <div className="size-text">{record?.productSize?.productSize} {record?.productSize?.productSizeUnit}</div>
      ),
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      width: 131,
      render: (_, record: any) => (
        <div className="qty-text flex gap-1">
          <span className="quantity">{record?.qty}</span>
          <span className="unit">{record?.quantityOrdered} {record?.orderUnitConvertionUnit ? record?.orderUnitConvertionUnit : record?.orderUnit}</span>
        </div>
      ),
    },
  ];

  const getOrderedProducts = async (id: number) => {
    await dispatch(getOrderItemsByOrder(id));
  };

  const OrderItemContent = () => {
    return (
      <Table
        columns={orderItemsColumns}
        dataSource={orderItems.data || []} // orderItems?.data || 
        // dataSource={[]}
        className="order-items-table"
        pagination={false}
      />
    );
  };

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Today", value: [dayjs().add(0, "d"), dayjs()] },
    { label: "Yesterday", value: [dayjs().add(-1, "d"), dayjs()] },
    { label: "This Week", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last Week", value: [dayjs().add(-14, "d"), dayjs().add(-7, "d")] },
    { label: "This Month", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "This Year", value: [dayjs().add(-1, "y"), dayjs()] },
  ];

  const columns = [
    {
      title: "Order No",
      dataIndex: "orderId",
      key: "orderNumber",
      sorter: true,
      sortIcon: (sortOrder: any) => tableSortingIconToggle(sortOrder),
      render: (text: string, record: any) => {
        return (
          <p className="caption">
            {record?.orderNumber ? `${record?.orderNumber}` : "-"}
          </p>
        );
      },
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (_: string, record: any) => {
        return (
          <p className="caption">{dayjs(record?.createdOn).format("DD/MM/YYYY")}</p>
        );
      },
    },
    {
      title: "Ordered Items",
      dataIndex: "orderItem",
      key: "orderItem",
      render: (_: string, record: any) => {
        return (
          <Popover
            content={OrderItemContent}
            trigger="click"
            placement="bottomLeft"
            rootClassName="order-item-popover"
          >
            <button className="ordered-items-btn gap-1 flex alignCenter" onClick={() => getOrderedProducts(record?.orderId)}>
              {record?.orderItemsCount} items <DownArrowFull />
            </button>
          </Popover>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      sortIcon: (sortOrder: any) => tableSortingIconToggle(sortOrder),
      render: (_: string, record: any) => {
        return (
          <StatusSwitch
            status={record?.status === "In Progress" ? "inProgress" : record?.status}
            orderId={record?.orderId}
            onDispatch={() => setToggleDispatch({ orderId: record?.orderId, isDispatch: true, isTransport: false })}
            activeTab={activeTab === 1 ? "Customer-order" : "My-order"}
          // disabled
          />
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_: string, record: any) => {
        return (
          <p className="caption">{formatRupees(record?.subtotal)}</p>
        );
      },
    },
  ];

  const onRangeChange = (dates: null | (any | null)[], dateStrings: string[]) => {
    if (dates) {
      setDate({
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      });
    } else {
      setDate({
        startDate: (dayjs(new Date()).subtract(3, "months").format("DD/MM/YYYY")),
        endDate: (dayjs(new Date()).format("DD/MM/YYYY")),
      });
    }
  };

  const items: any = [
    {
      key: "1",
      label: (
        <span className="tab-label flex gap-2">
          Received Orders
        </span>
      ),
      children: (
        <Table
          columns={columns}
          dataSource={companyOrders?.data?.orders || []} // orderData.data?.orders || 
          pagination={false}
          className="orders-table"
          onChange={(pagination, filter, sorter: any) => {
            setSort({
              order: sorter?.order === "descend" ? "DESC" : "ASC",
              field: sorter?.columnKey || "createdOn",
            });
          }}
        />
      ),
    },
    {
      key: "0",
      label: (
        <span className="tab-label flex gap-2">
          Placed Orders
        </span>
      ),
      children: (
        <Table
          columns={columns}
          dataSource={companyOrders.data?.orders || []}
          pagination={false}
          className="orders-table"
          onChange={(pagination, filter, sorter: any) => {
            setSort({
              order: sorter?.order === "descend" ? "DESC" : "ASC",
              field: sorter?.columnKey || "createdOn",
            });
          }}
        />
      ),
    },
  ];

  // Debounced function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchProducts = useCallback(
    debounce((query: string) => {
      if (connectionDetails?.data?.companyId) {
        dispatch(getConnectedCompanyOrder({
          connectedCompanyId: connectionDetails?.data?.companyId || 0,
          endDate: date.endDate,
          limit: 0,
          startDate: date.startDate,
          search: query,
          sort: [sort],
          // type: (connectionDetails?.data?.placedOrders && connectionDetails?.data?.receivedOrders) ? activeTab : connectionDetails?.data?.placedOrders ? 0 : 1,
          type: activeTab,
        }));
      }
    }, 500),
    [dispatch, searchOrder, connectionDetails?.data?.companyId],
  );

  useEffect(() => {
    debouncedFetchProducts(searchOrder);
  }, [searchOrder, debouncedFetchProducts, statusDetails.isSuccess]);

  const handleSearchOrder = (query: string) => {
    setSearchOrder(query);
  };

  const bgColors = [
    "#FFF7E6",
    "#FA8C16",
    "#17B26A",
  ];

  return (
    <Page title={[]} className="connection-page">
      <div className="connection-content flex h-100 alignStart">
        <ConnectionsListSidebar setConnectionInfo={setConnectionInfo} />
        <div className="connection-details w-100">
          <div className="connection-header">
            <div className="page-title gap-2">
              <h3 className="title-3">Connection</h3>
              {(connection?.data?.length > 0) ?
                <button className="total-connection-count">
                  {connection?.data?.length} Connections
                </button> : ""}
            </div>
            <div className="invite-section gap-3 alignCenter">
              <Popover
                content={receivedRequestContent}
                open={receivedRequest?.data.length ? connectionRequests.isOpen : false}
                onOpenChange={() => setConnectionRequests({ isOpen: !connectionRequests.isOpen, data: connectionRequests.data })}
                trigger="click"
                rootClassName="request-notification-popover"
                placement="bottomRight"
              >
                {receivedRequest?.data?.length ? <Tooltip rootClassName="new-request-tooltip" title={<div className="flex gap-1 alignCenter"><HumanIcon /> New Requests</div>} open={isConnectionReq} color={"#F5222D"} placement="bottom" style={{ width: "100%" }} key={"#F5222D"}>
                  <div className="list-of-connections">
                    {receivedRequest?.data?.map((item, index) => {
                      return (
                        index < 3 ? <button className="connection-icon" style={{ backgroundColor: bgColors[index || 0] }} key={index}>
                          {item?.sender?.profileThumbnailImg ? <img src={item?.sender?.profileThumbnailImg} alt="user-profile" /> : <UserIcon />}
                        </button> : index < 4 ? <><button className="connection-icon icon-collepse">+{receivedRequest?.data?.length - 3}</button><span className="notification-indicator"></span></> : ""
                      );
                    })}
                  </div>
                </Tooltip> : ""}
              </Popover>
              <Button
                type="button"
                className="primary invite-button"
                onClick={showModal}
              >
                <PlusOutlined /> Invite
              </Button>
              {showSentRequest ?
                <Popover
                  content={sentRequestsContent}
                  trigger="click"
                  open={pendingRequests.isOpen}
                  onOpenChange={handlePendingRequest}
                  rootClassName="request-notification-popover"
                  placement="bottomRight"
                >
                  <button className="clock-button" type="button">
                    <ClockCircleOutlined />
                  </button>
                </Popover> : <>{ }</>}
            </div>
          </div>
          <div className={(connection?.data.length > 0) ? "card" : "no-data-screen"}>
            {visible ? <InviteModal
              setVisible={setVisible}
              visible={visible}
            /> : <></>}
            {(connection?.data.length > 0) ?
              <div className="details-card gap-8">
                <div className="profile-image-container">
                  <div className="profile-image flex justifyCenter alignCenter">
                    {connectionDetails?.data?.profileImg ? <img src={connectionDetails?.data?.profileImg} alt="avatar" /> : <UserIcon />}
                  </div>
                </div>
                <div className="profile-description">
                  <div className="product-summary-section">
                    <div className="summary">
                      <h3 className="title-3">{connectionDetails?.data?.name}</h3>
                      {/* <p className="caption">{connectionDetails?.data?.aboutCompany || <>We are leading manufucaturer in India of Wax Polish, Carpatch Quick Fix Polyester compund, Micro Shiner, FRP Mould releasing agent, Liquid shiner, Epoxy resin, scaratch resistent.</>}</p> */}
                    </div>
                    {/* <button className="notification-button" type="button">
                    <BellOutlined />
                  </button> */}
                  </div>
                  <div className="address-connection-section">
                    <p className="address-text caption">
                      {connectionDetails?.data?.city || "Ahmedabad"}, {connectionDetails?.data?.state || "Gujarat"}, India.
                    </p>
                    <button className="connections-count">
                      {connectionDetails?.data?.connectionsCount} Connections
                    </button>
                  </div>
                  <div className="orders-message-container">
                    <div className="orders-details">
                      {/* {(connectionInfo?.isVendor || connectionDetails?.data?.receivedOrders) ?  */}
                      <div className="received-order-details">
                        <p className="caption">Received Order</p>
                        <h4 className="title-4">{connectionDetails?.data?.receivedOrders || "0"}</h4>
                      </div>
                      {/* : <></>} */}
                      {/* {(connectionInfo?.isCustomer || connectionDetails?.data?.placedOrders) ?  */}
                      <div className="placed-order-details">
                        <p className="caption">Placed Order</p>
                        <h4 className="title-4">{connectionDetails?.data?.placedOrders || "0"}</h4>
                      </div>
                      {/* : <></>} */}
                    </div>
                    <div className="messages-container gap-3">
                      {(connectionInfo?.isVendor && !connectionInfo?.isBlock) &&
                        <Button onClick={() => navigate("/placeorder", { state: { orderId: connectionDetails?.data?.companyId, companyName: connectionDetails?.data?.name, companyProfile: connectionDetails?.data?.profileThumbnailImg }, replace: true })} className="placeorder-button primary large" type="button">
                          <PlusCircleOutlined /> Place Order
                        </Button>}
                      {/* <button className="message-button" type="button"><MessageOutlined /> Message</button> */}
                      {/* <button type="button" className="clock-button"><ClockCircleOutlined /></button> */}
                      <Popover
                        content={() => (
                          <>
                            <div>
                              {(connectionInfo?.blockByCompany === JSON.parse(companyData || "")?.companyId || !connectionInfo?.isBlock) ?
                                <button className="block" onClick={() => blockUnblockRemoveConnection(connectionInfo?.isBlock ? "Unblock" : "Block")}>
                                  {connectionInfo?.isBlock ?
                                    <><UnblockIcon /> Unblock</> :
                                    <><StopIcon />Block</>
                                  }
                                </button>
                                :
                                ""
                              }
                              <button className="remove" onClick={() => blockUnblockRemoveConnection("Remove")}><DeleteIcon />Remove</button>
                            </div>
                          </>
                        )}
                        open={isPopoverOpen}
                        onOpenChange={() => setIsPopoverOpen(!isPopoverOpen)}
                        trigger="click"
                        rootClassName="block-or-remove-container"
                        placement="bottomRight"
                      >
                        <button type="button" className="square-button"><ThreeDots /></button>
                      </Popover>
                    </div>
                  </div>
                </div>
              </div> :
              <NoDataPage onClick={showModal} />}
          </div>
          {/* {(((connectionDetails?.data?.receivedOrders) || (connectionDetails?.data?.placedOrders)) && (companyOrders?.data?.orders?.length || searchOrder?.length || !companyOrders?.isLoading)) ? */}

          {connection.data.length ? <div className="card orders-content">
            <Tabs
              defaultActiveKey="1"
              items={items}
              tabBarExtraContent={
                <div className="tabs-right-order-actions flex gap-4">
                  <SearchBar
                    onChange={(e) => handleSearchOrder(e.target.value)}
                    value={searchOrder}
                    placeholder="Search Order"
                  />
                  <div className="date-picker">
                    <RangePicker
                      presets={rangePresets}
                      onChange={onRangeChange}
                      height={36}
                      width={400}
                      placement="bottomRight"
                      format="DD/MM/YYYY"
                      defaultValue={[dayjs(new Date()).subtract(3, "months"), dayjs(new Date())]}
                      allowClear={false}
                      maxDate={dayjs(new Date())}
                    />
                  </div>
                </div>
              }
              onChange={(key) => setActiveTab(Number(key))}
            />
          </div> : <></>}
          {/* : <></>} */}
        </div>
        {toggleDispatch?.isDispatch &&
          <DispatchOrderModal
            open={toggleDispatch?.isDispatch}
            close={() => setToggleDispatch({ isDispatch: false, isTransport: false, orderId: null })}
            orderId={toggleDispatch.orderId || 0}
            handleConfirm={() => setToggleDispatch({ isDispatch: false, isTransport: true, orderId: toggleDispatch.orderId })}
          />
        }
        {toggleDispatch?.isTransport &&
          <TransportationDetails
            open={toggleDispatch.isTransport}
            close={() => setToggleDispatch({ isDispatch: false, isTransport: false, orderId: null })}
            orderId={toggleDispatch.orderId || 0}
          />}
      </div>
    </Page>
  );
};

export default Connection;