import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import OTPScreen from "./OTPScreen";
import WhatsAppNumberScreen from "./whatsAppNumberScreen";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { loginUser } from "../../redux/services/auth";
import SelectCompany from "../createCompany/selectCompany";
import useMessageHook from "../../hooks/useMessageHook";

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation()?.state;
  const { showMessage, contextHolder } = useMessageHook();

  // useEffect(() => {
  //   localStorage.clear();
  // }, []);

  useEffect(() => {
    if (step === 4) {
      navigate("/", { state: { phoneNo: phoneNumber } });
    }
    if (location?.step === 2) {
      setStep(2);
    }
  }, [step, location]);

  const validatePhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) {
      showMessage("error", "Please enter a number");
      setIsLoading(false);
      return false;
    }

    const isValidFormat = /^[6-9]\d{9}$/.test(phoneNumber);

    if (!isValidFormat) {
      showMessage("error", "Please enter a valid number");
      setIsLoading(false);
      return false;
    }

    return true;
  };

  const handleLogin = async () => {
    setIsLoading(true);
    if (!validatePhoneNumber(phoneNumber)) {
      return;
    }

    try {
      await dispatch(loginUser(phoneNumber)).then((result) => {
        if (result?.payload?.status === 200) {
          setStep(1);
          showMessage("success", result?.payload?.message);
        }
        else {
          showMessage("error", result?.payload.message || "Login failed");
        }
      });
    } catch (error: any) {
      showMessage("error", error.response?.data?.message || "Login failed");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeNumber = (value: string) => {
    setPhoneNumber(value);
  };

  return (
    <>
      {contextHolder}
      {step === 0 ? <WhatsAppNumberScreen isLoading={isLoading} onLogin={handleLogin} handleChangeNumber={handleChangeNumber} phoneNo={phoneNumber} /> : <></>}
      {step === 1 ? <OTPScreen setStep={setStep} phoneNumber={phoneNumber} onResend={handleLogin} /> : <></>}
      {step === 2 ? <SelectCompany setStep={setStep} phoneNumber={phoneNumber} /> : <></>}
    </>
  );
};

export default Login;