import { createSlice } from "@reduxjs/toolkit";

import { getAddressByPincode, getAddressListByCompany } from "../services/address";

const initialState = {
  isLoading: false,
  isSuccess: false,
  address: null,
  message: "",
  addressList: {
    isLoading: false,
    isSuccess: false,
    message: "",
    data: null,
  },
};

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    clearAddressData: (state) => {
      state.address = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAddressByPincode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAddressByPincode.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.address = payload?.data;
      state.message = payload?.message;
    });
    builder.addCase(getAddressByPincode.rejected, (state, { payload }: any) => {
      state.address = payload;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = payload?.message;
    });
    builder.addCase(getAddressListByCompany.pending, (state) => {
      state.addressList.isLoading = true;
      state.addressList.isSuccess = false;
    });
    builder.addCase(getAddressListByCompany.fulfilled, (state, { payload }: any) => {
      state.addressList.isSuccess = true;
      state.addressList.isLoading = false;
      state.addressList.data = payload?.data;
      state.addressList.message = payload?.message;
    });
    builder.addCase(getAddressListByCompany.rejected, (state, { payload }: any) => {
      state.addressList.data = payload;
      state.addressList.isSuccess = false;
      state.addressList.isLoading = false;
      state.addressList.message = payload?.message;
    });
  },
});

export const { clearAddressData } = addressSlice.actions;
export default addressSlice.reducer;