import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

import endPoints from "./endPoints.json";
import axiosInstance from "../axios";

export const loginUser = createAsyncThunk("auth/user", async (phoneNumber: string, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoints.getOtp, { phoneNo: phoneNumber });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const verifyOtp = createAsyncThunk("auth/verifyOtp", async (arg: { phoneNumber: string, otp: string }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoints.verifyOtp, { phoneNo: arg.phoneNumber, OTP: arg.otp });
    if (response.data.status === 200) {
      (response.data.data?.companies?.length === 1 || response.data.data?.companies?.length === 0) && localStorage.setItem("companies", JSON.stringify(response.data.data?.companies[0] || response.data.data?.companies));
      (response.data.data?.companies?.length === 1 || response.data.data?.companies?.length === 0) && localStorage.setItem("token", response.data.data?.token);
      (response.data.data?.companies?.length === 0) && localStorage.setItem("createNewCompany", JSON.stringify(true));
      localStorage.setItem("user", JSON.stringify(response.data.data?.user));
    }
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const selectCompany = createAsyncThunk("auth/selectProfile", async (companyId: number, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${endPoints.selectProfile}/${companyId}`);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const createCompanyProfile = createAsyncThunk("auth/createProfile", async (arg: {
  companyProfileImages: string,
  companyData: object,
}, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoints.createProfile, arg, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getAllCompany = createAsyncThunk("comapany/getByPhoneNumber", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoints.getAllCompany);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getCompanyBusinessType = createAsyncThunk("business/types", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(endPoints.getBusinessTypes);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});