import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";

// Extend dayjs with the relativeTime plugin
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

export const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLDivElement | HTMLTextAreaElement>) => {
  const { key, target } = e;
  const { value, selectionStart } = target as HTMLInputElement;

  // Ensure selectionStart is not null
  if (selectionStart === null) return;

  // Prevent space at the start
  if (key === " " && selectionStart === 0) {
    e.preventDefault();
    return;
  }

  // Prevent multiple consecutive spaces
  if (key === " " && value[selectionStart - 1] === " ") {
    e.preventDefault();
    return;
  }

  // Prevent Enter if the last character is a newline
  if (key === "Enter" && value[value.length - 1] === "\n") {
    e.preventDefault();
  }
};

export const cleanValue = (val: string): string => val.replace(/[^0-9.]/g, "");

export const truncateString = (str: string, maxLength: number): string => {
  return str.length > maxLength ? str.substring(0, maxLength) + "..." : str;
};

export const convertToNumber = (value: string | number): number => {
  return Number(value);
};

export const getProfileIcon = (name: string): string => {
  const words = name?.split(" ");
  if (words?.length === 1) {
    return words[0].charAt(0).toUpperCase();
  } else if (words?.length > 1) {
    return `${words[0].charAt(0).toUpperCase()}${words[1].charAt(0).toUpperCase()}`;
  }
  return "";
};

export const formatRupees = (number: number) => {
  return number ? ("₹" + number?.toLocaleString("en-IN", { maximumSignificantDigits: 15 })) : "0";
};

export const AddressFormat = (value: any) => {
  let address = "";
  if (!value?.addressLine) {
    address = `${value?.city}, ${value?.state}, ${value?.country}`;
  } else {
    address = `${value?.addressLine}, ${value?.city}, ${value?.district}, ${value?.state}, ${value?.pincode}`;
  }
  return address;
};

export function formatDate(inputDate: string): string {

  const date = dayjs(inputDate);

  // Format the date using dayjs
  const formattedDate = date.format("D MMMM, YYYY h:mmA");

  return formattedDate;
  // const date = new Date(inputDate);

  // // Define options for formatting date and time
  // const options: Intl.DateTimeFormatOptions = {
  //   day: "numeric",
  //   month: "long",
  //   year: "numeric",
  //   hour: "numeric",
  //   minute: "numeric",
  //   hour12: true,
  // };

  // // Format the date using the options
  // const formattedDate = date.toLocaleString("en-GB", options);

  // // Adjust the format to match the required output
  // return formattedDate.replace(/(\d{1,2}) (\w+), (\d{4}), (\d{1,2}:\d{2})(am|pm)/, "$1 $2, $3 $4$5");
}

export const getRelativeTime = (timestamp: string | number | Date): string => {
  return dayjs(timestamp).fromNow();
};

export const getDateLabel = (date: Date) => {
  const today = dayjs().startOf("day");
  const yesterday = dayjs().subtract(1, "day").startOf("day");
  const notificationDate = dayjs(date).startOf("day");

  if (notificationDate.isSame(today)) {
    return "Today";
  } else if (notificationDate.isSame(yesterday)) {
    return "Yesterday";
  } else {
    return dayjs(date).format("DD MMMM YYYY");
  }
};

export const groupDataByDate = (data: any[]) => {
  const grouped: Record<string, any[]> = data.reduce((acc, item) => {
    const date = dayjs(item.createdOn).format("YYYY-MM-DD"); // Format to 'YYYY-MM-DD'
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {} as Record<string, any[]>);

  return Object.fromEntries(
    Object.entries(grouped).sort(([dateA], [dateB]) => dayjs(dateB).diff(dayjs(dateA))), // Sort by date in descending order
  );
  // const grouped: Record<string, any[]> = data.reduce((acc, item) => {
  //   const date = new Date(item.createdOn).toISOString().split("T")[0];
  //   if (!acc[date]) {
  //     acc[date] = [];
  //   }
  //   acc[date].push(item);
  //   return acc;
  // }, {} as Record<string, any[]>);

  // return Object.fromEntries(
  //   Object.entries(grouped).sort(([dateA], [dateB]) => new Date(dateB).getTime() - new Date(dateA).getTime()),
  // );
};

export const formatDateTime = (dateString: string): string => {

  const date = dayjs(dateString);

  // Format date (Day, Month, Year)
  const formattedDate = date.format("DD MMMM, YYYY");

  // Format time (Hour:Minute AM/PM)
  const formattedTime = date.format("hh:mm A").toLowerCase();

  // Combine date and time
  return `${formattedDate} ${formattedTime}`;

  // const date = new Date(dateString);

  // // Options for date formatting
  // const dateOptions: Intl.DateTimeFormatOptions = {
  //   day: "2-digit",
  //   month: "long",
  //   year: "numeric",
  // };

  // // Options for time formatting
  // const timeOptions: Intl.DateTimeFormatOptions = {
  //   hour: "2-digit",
  //   minute: "2-digit",
  //   hour12: true,
  // };

  // // Format date and time
  // const formattedDate = new Intl.DateTimeFormat("en-GB", dateOptions).format(date);
  // const formattedTime = new Intl.DateTimeFormat("en-GB", timeOptions).format(date);

  // // Combine date and time
  // return `${formattedDate} ${formattedTime.toLowerCase()}`;
};

// convert Date into time

export function ConvertDateToTime(dateInput: Date | string): string {

  const date = dayjs(dateInput);

  // Format the time in 12-hour format with AM/PM
  return date.format("hh:mm A");

  // // Create a Date object from the input
  // const date = new Date(dateInput);

  // // Extract hours and minutes from the Date object
  // let hours = date.getHours();
  // const minutes = date.getMinutes();

  // // Determine AM/PM suffix
  // const ampm = hours >= 12 ? "PM" : "AM";

  // // Convert hours from 24-hour to 12-hour format
  // hours = hours % 12;
  // hours = hours ? hours : 12; // the hour '0' should be '12'

  // // Format hours and minutes with leading zeroes if needed
  // const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  // const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  // // Return the formatted time string
  // return `${formattedHours}:${formattedMinutes} ${ampm}`;
}