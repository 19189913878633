import React, { useEffect } from "react";
import { Table, TableProps } from "antd";

import CommonModal from "../../components/commonModal";
import InputWithDropdown from "../../components/inputWithDropdown";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getOrderItemsByOrder } from "../../redux/services/orders";
import { useAppSelector } from "../../hooks/useAppSelector";
import { Logo } from "../../assets/images";

interface Props {
  open: boolean;
  close: () => void;
  orderId: number;
  handleConfirm: () => void
}

const DispatchOrderModal = ({ open, close, orderId, handleConfirm }: Props) => {
  const dispatch = useAppDispatch();

  const { orderItems } = useAppSelector(state => state.manageOrders);

  const columns: TableProps<any>["columns"] = [
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      width: 280,
      render: (text, record: any) => (
        <div className="product-name-box flex alignCenter gap-3">
          <div className="product-image-container">
            {record?.product?.productImage?.thumbnailURL ? <img className="product-image" src={record?.product?.productImage?.thumbnailURL} alt="product-img" /> : <Logo />}
          </div>
          <span className="product-name">{record?.product?.productName}</span>
        </div>
      ),
    },
    {
      title: "Total Qty.",
      dataIndex: "qty",
      key: "qty",
      width: 131,
      render: (text, record: any) => (
        <div className="qty-text flex gap-1">
          <span className="quantity">{record?.quantityOrdered}</span>
          <span className="unit">{record?.orderUnitConvertionUnit ? record?.orderUnitConvertionUnit : record?.orderUnit}</span>
        </div>
      ),
    },
    {
      title: "Dispatch Qty.",
      dataIndex: "dispatchQty",
      key: "dispatchQty",
      width: 131,
      render: (text, record: any) => (
        <InputWithDropdown
          value={record.quantityOrdered}
          onChange={() => []}
          suffixSelector={record?.orderUnitConvertionUnit ? record?.orderUnitConvertionUnit : record?.orderUnit}
        />
      ),
    },
  ];

  useEffect(() => {
    dispatch(getOrderItemsByOrder(orderId));
  }, [dispatch, orderId]);


  return (
    <CommonModal
      open={open}
      close={close}
      closeIcon
      className="dispatch-order-modal"
      footer
      saveButtonText="Confirm"
      onSave={() => handleConfirm()}
    >
      <div className="dispatch-modal-container flex direction-column gap-5">
        <div className="header-container">
          <h3 className="title">Dispatch Order</h3>
          <span className="info-text">Please review the dispatch details below before confirming the order status change.</span>
        </div>
        <div className="dispatch-modal-content">
          <Table
            columns={columns}
            dataSource={orderItems.data || []}
            className="dispatch-details-table"
            pagination={false}
          // scroll={{ y: 300 }}
          />
        </div>
      </div>
    </CommonModal>
  );
};

export default DispatchOrderModal;