import { createSlice } from "@reduxjs/toolkit";

import { getProductListByVendorId, getVendorList } from "../services/vendor";
import { VendorListDataProps } from "../../types/vendorType";

interface InitialStateProps {
  vendor: {
    isLoading: boolean,
    isSuccess: boolean,
    data: any[] | null,
    message: string
  },
  vendorsProductList: {
    isLoading: boolean,
    isSuccess: boolean,
    data: VendorListDataProps | null,
    message: string
  }
}

const initialState: InitialStateProps = {
  vendor: {
    isLoading: false,
    isSuccess: false,
    data: [],
    message: "",
  },
  vendorsProductList: {
    isLoading: false,
    isSuccess: false,
    data: null,
    message: "",
  },
};

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVendorList.pending, (state) => {
      state.vendor.isLoading = true;
      state.vendor.isSuccess = false;
    });
    builder.addCase(getVendorList.fulfilled, (state, { payload }: any) => {
      state.vendor.isSuccess = true;
      state.vendor.isLoading = false;
      state.vendor.data = payload?.vendors;
      state.vendor.message = payload?.message;
    });
    builder.addCase(getVendorList.rejected, (state, { payload }: any) => {
      state.vendor.data = payload;
      state.vendor.isSuccess = false;
      state.vendor.isLoading = false;
      state.vendor.message = payload?.message;
    });
    builder.addCase(getProductListByVendorId.pending, (state) => {
      state.vendorsProductList.data = initialState.vendorsProductList.data;
      state.vendorsProductList.isLoading = true;
      state.vendorsProductList.isSuccess = false;
    });
    builder.addCase(getProductListByVendorId.fulfilled, (state, { payload }: any) => {
      state.vendorsProductList.isSuccess = true;
      state.vendorsProductList.isLoading = false;
      state.vendorsProductList.data = payload?.data;
    });
    builder.addCase(getProductListByVendorId.rejected, (state, { payload }: any) => {
      state.vendorsProductList.data = payload;
      state.vendorsProductList.isLoading = false;
      state.vendorsProductList.isSuccess = false;
    });
  },
});

export default vendorSlice.reducer;