import React, { createContext, useState, useContext } from "react";
import { ThemeConfig, ConfigProvider } from "antd";

const lightTheme: ThemeConfig = {
  token: {
    colorPrimary: "#1890ff",
    fontFamily: "SF Pro Text",
    colorBgLayout: "#FFF",
  },
  components: {
    Tabs: {
      itemActiveColor: "#0169E6",
      itemSelectedColor: "#0169E6",
      inkBarColor: "#0169E6",
      itemColor: "#667085",
      itemHoverColor: "#0169E6",
    },
  },
};

const darkTheme: ThemeConfig = {
  token: {
    colorPrimary: "#333333",
    colorBgLayout: "#FFFFFF",
    fontFamily: "SFMono-Regular",
  },
};

const lightColors = {
  primary: "#1890ff",
  primary100: "#e6f7ff",
  primary200: "#bae7ff",
  primary300: "#91d5ff",
  primary400: "#69c0ff",
  primary500: "#40a9ff",
  primary600: "#1890ff",
  primary700: "#096dd9",
  primary800: "#0050b3",
  primary900: "#003a8c",
  primary1000: "#002766",
};

const darkColors = {
  primary: "#333333",
  primary100: "#f0f0f0",
  primary200: "#d9d9d9",
  primary300: "#bfbfbf",
  primary400: "#a6a6a6",
  primary500: "#8c8c8c",
  primary600: "#737373",
  primary700: "#595959",
  primary800: "#404040",
  primary900: "#262626",
  primary1000: "#0d0d0d",
};

const ThemeContext = createContext({
  theme: lightTheme,
  colors: lightColors,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleTheme: () => { },
});

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState<ThemeConfig>(lightTheme);
  const [colors, setColors] = useState(lightColors);
  const toggleTheme = () => {
    setCurrentTheme(currentTheme === lightTheme ? darkTheme : lightTheme);
    setColors(currentTheme === lightTheme ? lightColors : darkColors);
  };

  return (
    <ThemeContext.Provider value={{ theme: currentTheme, colors, toggleTheme }}>
      <ConfigProvider theme={currentTheme}>{children}</ConfigProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);