import React from "react";
import { Steps, Table, TableProps } from "antd";

import { LocationIcon, Logo } from "../../assets/images";
import { AddressFormat, formatDateTime, formatRupees } from "../../utils/helper";
import { DefaultStepIcon } from "../../assets/images/icons";
import { useAppSelector } from "../../hooks/useAppSelector";

const columns: TableProps<any>["columns"] = [
  {
    title: "Product Name",
    dataIndex: "productName",
    key: "productName",
    width: 280,
    render: (_: string, record: any) => (
      <div className="product-name-box flex alignCenter gap-3">
        <div className="product-image-container">
          {record.product?.productImage?.thumbnailURL ? <img className="product-image" src={record.product?.productImage?.thumbnailURL} alt="" /> : <Logo />}

        </div>
        <span className="product-name">{record?.product?.productName}</span>
      </div>
    ),
  },
  {
    title: "Size",
    dataIndex: "size",
    key: "size",
    width: 159,
    render: (text, record: any) => (
      <div className="size-text">{record?.productSize?.productSize} {record?.productSize?.productSizeUnit}</div>
    ),
  },
  {
    title: "Qty",
    dataIndex: "qty",
    key: "qty",
    width: 131,
    render: (_: string, record: any) => (
      <div className="qty-text flex gap-1">
        <span className="quantity">{record?.quantityOrdered}</span>
        <span className="unit">{record?.orderUnitConvertionUnit}</span>
      </div>
    ),
  },
  {
    title: "Rate per unit",
    dataIndex: "rate",
    key: "rate",
    width: 132,
    render: (_: string, record: any) => (
      <div className="price-per-unit-text flex gap-1">
        <span className="price">{formatRupees(record?.pricePerUnit)}</span>
        <span className="unit">/ {record?.orderUnit}</span>
      </div>
    ),
  },
  {
    title: "Subtotal",
    dataIndex: "subtotal",
    key: "subtotal",
    width: 82,
    render: (text: string, record: any) => (
      <div className="subtotal-text flex gap-1">
        <span className="price">{formatRupees(record?.subtotal)}</span>
      </div>
    ),
  },
];

const EntireOrderSummary = () => {

  const { orderDetails } = useAppSelector(state => state?.manageOrders);

  const { Step } = Steps;

  function getStatusNumber(status: string) {
    switch (status.toLowerCase()) {
    case "accepted":
      return 0;
    case "in progress":
      return 1;
    case "dispatched":
      return 2;
    case "completed":
      return 3;
    default:
      return -1;
    }
  }

  return (
    <div className="entire-order-details-container flex direction-column">
      <div className="time-tracking-container flex justifyCenter">
        <Steps
          current={getStatusNumber(orderDetails?.data?.status || "")}
          // current={0}
          labelPlacement="vertical"
        >
          <Step
            icon={<DefaultStepIcon />}
            title="Accepted"
            description={orderDetails?.data?.acceptedOn ? formatDateTime(orderDetails?.data?.acceptedOn || "") : ""}
            className="step-accepted custom-step"
          />
          <Step
            icon={<DefaultStepIcon />}
            title="In Progress"
            description={orderDetails?.data?.inProgressOn ? formatDateTime(orderDetails?.data?.inProgressOn || "") : ""}
            className="step-in-progress custom-step"
          />
          <Step
            icon={<DefaultStepIcon />}
            title="Dispatched"
            description={orderDetails?.data?.dispatchedOn ? formatDateTime(orderDetails?.data?.dispatchedOn || "") : "" }
            className="step-dispatched custom-step"
          />
          <Step
            icon={<DefaultStepIcon />}
            title="Delivered"
            description={orderDetails.data?.completedOn ? formatDateTime(orderDetails?.data?.completedOn || "") : ""}
            className="step-delivered custom-step"
          />
        </Steps>
      </div>

      <div className="Order-details-table-container">
        <Table
          columns={columns}
          dataSource={orderDetails?.data?.orderItems}
          className="Order-details-table"
          pagination={false}
        // scroll={{ y: 300 }}
        />
      </div>
      {orderDetails.data?.deliveryAddress ? <div className="address-section">
        <div className="flex">
          <div className="address-text flex alignCenter gap-3">
            <LocationIcon />
            <div className="address-input">
              <h4 className="title-4">
                Delivery Address
              </h4>
              <input type="text" className="selected-address" value={AddressFormat(orderDetails.data?.deliveryAddress) || ""} />
            </div>
          </div>
        </div>
      </div> : <></>}

      <div className="bill-details gap-4 flex direction-column">
        <h4 className="section-header">Bill Details</h4>
        <div>
          {orderDetails?.data && orderDetails?.data?.totalDiscount > 0 ? <div className="sub-total flex justifyBetween">
            <span>Subtotal</span>
            <span>{formatRupees(orderDetails?.data?.subtotal || 0)}</span>
          </div> : <></>}
          {orderDetails?.data && orderDetails?.data?.totalDiscount > 0 ? <div className="total-discount flex justifyBetween">
            <span>Total Discount</span>
            <span>{formatRupees(orderDetails?.data?.totalDiscount || 0)}</span>
          </div> : <></> }
          <div className="grand-total flex justifyBetween">
            <span>Grand Total</span>
            <span>{formatRupees(orderDetails?.data?.grandTotal || 0)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntireOrderSummary;