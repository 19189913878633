import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getCompanyBusinessType } from "../../redux/services/auth";
import { getAddressByPincode } from "../../redux/services/address";
import { getComapnyDetailsById, updateCompanyProfile } from "../../redux/services/settings";
import { useAppSelector } from "../../hooks/useAppSelector";
import Page from "../../components/page";
import FileUpload from "../../components/fileUpload";
import { Button, InputField, SelectInput, Textarea } from "../../components";
import useMessageHook from "../../hooks/useMessageHook";

const SettingsPage = () => {
  const [fileList, setFileList] = useState<any>([]);
  const [value, setValue] = useState<any>({});
  const [error, setError] = useState<any>({});
  const [isChanged, setIsChanged] = useState(false);

  const { showMessage, contextHolder } = useMessageHook();

  const location = useLocation()?.state;
  
  const dispatch = useAppDispatch();
  const { businessTypes } = useAppSelector(state => state.auth);
  const { companyProfile } = useAppSelector(state => state.company);

  useEffect(() => {
    dispatch(getCompanyBusinessType());
    dispatch(getComapnyDetailsById(location?.companyId));
  }, [dispatch, location]);

  useEffect(() => {
    if (fileList[0]?.url !== companyProfile?.data?.profileImg) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [fileList, companyProfile]);

  useEffect(() => {
    if (companyProfile?.data) {
      setValue((prev: any) => ({
        ...prev,
        company_name: companyProfile?.data?.name,
        pincode: companyProfile?.data?.pincode,
        about_company: companyProfile?.data?.aboutCompany,
        company_type: companyProfile?.data?.companyType,
        address: (companyProfile?.data?.city && companyProfile?.data?.state) ? {
          city: companyProfile?.data?.city,
          district: companyProfile?.data?.district,
          state: companyProfile?.data?.state,
          country: companyProfile?.data?.country,
        } : {},
        addressLine: companyProfile?.data?.addressLine,
        orderNumberPrefix: companyProfile?.data?.orderNumberPrefix,
        orderNumberSuffix: companyProfile?.data?.orderNumberSuffix,
      }));
      const temp = [];
      if (companyProfile?.data?.profileImg) {
        const imagespliting = companyProfile?.data?.profileImg.split("/");
        const imageName = imagespliting[imagespliting?.length - 1];
        temp.push({
          uid: 1,
          name: imageName,
          status: "done",
          url: companyProfile?.data?.profileImg,
        });
      }
      setFileList(temp);
    }
  }, [companyProfile]);

  const getPincode = (value: string) => {
    const validPincode = value.replace(/[^0-9]/g, "");
    if (validPincode.length <= 6) {
      setValue((prev: any) => ({
        ...prev,
        pincode: validPincode,
        address: {},
      }));
      setError((prev: any) => ({
        ...prev,
        pincode: false,
        pincodeMessage: "",
      }));
      if (validPincode?.length === 6) {
        dispatch(getAddressByPincode({ pincode: value })).then((result) => {
          if (result.payload?.status === 200) {
            setValue((prev: any) => ({
              ...prev,
              address: result?.payload?.data,
            }));
            setError((prev: any) => ({
              ...prev,
              pincodeMessage: "",
              pincode: false,
            }));
            setIsChanged(true);
          } else {
            showMessage("error", result?.payload?.message);

            setError((prev: any) => ({
              ...prev,
              pincode: true,
              pincodeMessage: result?.payload?.message || "Something Went Wrong !!!",
            }));
            setIsChanged(false);
          }
        });
      }
    } else {
      setValue((prev: any) => ({
        ...prev,
        address: {},
      }));
    }
  };

  const handleSelect = (e: any, item: any) => {
    setValue((prev: any) => ({
      ...prev,
      company_type: item?.map((data: { key: number, value: string }) => ({
        businessTypeId: data?.key,
        businessType: data?.value,
      })),
    }));
    setIsChanged(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setIsChanged(true);
    const { value, name } = e.target;
    setValue((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    setError({});
    const errorObj = {};
    if (!value?.company_name?.length) {
      Object.assign(errorObj, { company_name: true });
    }
    if (value?.pincode?.length !== 6) {
      Object.assign(errorObj, { pincode: true });
    }
    if (!value?.company_type?.length) {
      Object.assign(errorObj, { company_type: true });
    }
    if (!value?.address) {
      Object.assign(errorObj, { address: true });
    }
    if (Object.keys(errorObj)?.length) {
      setError(errorObj);
    }
    if (!Object.keys(errorObj)?.length) {
      setError({});
      const payload = {
        name: value?.company_name?.trim(),
        email: companyProfile?.data?.email || "",
        addressLine: value?.pincode === companyProfile?.data?.pincode ? (companyProfile?.data?.addressLine || "") : "",
        city: value?.address?.city,
        state: value?.address?.state,
        district: value?.address?.district,
        pincode: value?.pincode,
        country: value?.address?.country,
        companyType: value?.company_type?.map((item: any) => (item?.businessTypeId)),
        aboutCompany: value?.about_company?.trim(),
      };
      await dispatch(updateCompanyProfile({id: location?.companyId, payload: { image: fileList, data: payload }})).then((result) => {
        if (result?.payload?.status === 200) {
          localStorage.setItem("companies", JSON.stringify(result?.payload?.data));
          showMessage("success", result?.payload?.message);
          dispatch(getComapnyDetailsById(location?.companyId));
          setIsChanged(false);
        } else {
          showMessage("error", result?.payload?.message);
        }
      });
    }
  };

  return (
    <Page
      title={[]}
      className="settings-page"
      header={{
        pageTitle: "Company Settings",
      }}
    >
      <>{contextHolder}</>
      <section className="flex settings-layout gap-8 alignStart">
        <div className="text-container">
          <h4 className="heading-text">Company info</h4>
          <p className="caption small">Please update your company information on this page to ensure that your details are current and accurate.</p>
        </div>
        <div className="setting-form-container">
          <form action="" className="setting-form">
            <div className="profile-image-row">
              <div className="image-upload-input">
                <FileUpload getFile={setFileList} value={fileList} />
              </div>
            </div>
            <div className="fields-container flex gap-2">
              <InputField
                name="company_name"
                placeholder="Enter your company name"
                label="Company Name"
                required
                onChange={handleChange}
                value={value?.company_name}
                maxLength={50}
                showCount
                type="text"
                className={error?.company_name ? "error" : ""}
              />
              <Textarea
                label="About Your Business"
                name="about_company"
                onChange={handleChange}
                placeholder="Enter about your company"
                value={value?.about_company}
                maxLength={300}
                rows={5}
              />
              <SelectInput
                className="company-type-input"
                mode="multiple"
                name="company_type"
                label="Company Type"
                placeholder="Please Select"
                required
                value={value?.company_type?.map((item: any) => { return ({ key: item?.businessTypeId, value: item?.businessType }); })}
                options={businessTypes?.data?.map((item) => { return ({ key: item.businessTypeId, value: item.businessType }); })}
                status={error?.company_type ? "error" : null}
                onChange={(e: any, item: any) => handleSelect(e, item)}
                maxTagCount="responsive"
              />
              <div className="flex w-100 gap-4">
                <InputField
                  name="pincode"
                  placeholder="Enter Pincode"
                  label="Pincode"
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => getPincode(e.target.value)}
                  value={value?.pincode}
                  type="text"
                  className={(error?.pincode || error?.pincodeMessage) ? "error" : ""}
                  errorMessage={error?.pincodeMessage}
                  maxLength={6}
                />
                <InputField
                  name="location"
                  placeholder=""
                  label="Location"
                  onChange={(e: React.ChangeEvent) => e.preventDefault()}
                  value={value?.address?.city || value?.address?.district ? (value?.address?.city || value?.address?.district || "") + ", " + (value?.address?.state || "") : ""}
                  type="text"
                  disabled
                />
              </div>
            </div>
            {(isChanged && !error?.pincodeMessage)? <div className="buttons-container">
              <Button className="primary" type="button" onClick={handleSave}>Save</Button>
            </div> : <></>}
          </form>
        </div>
      </section>
    </Page>
  );
};

export default SettingsPage;