import React, { useEffect, useState } from "react";
import { PlusOutlined, HolderOutlined } from "@ant-design/icons";
import { Popconfirm, Select, List } from "antd";

import CommonModal from "../commonModal";
import InputWithDropdown from "../inputWithDropdown";
import { BulkRateEntry } from "../../types/productsType";
import { cleanValue } from "../../utils/helper";
import Limit from "../../utils/limits.json";
import { DeleteIcon } from "../../assets/images/icons";

interface DataType {
  [key: string]: any;
}

interface Props<T> {
  open: boolean;
  close: () => void;
  onSave: (data: BulkRateEntry[]) => void;
  selectedRowIndex: number | null;
  dataSource: T[];
}

interface ErrorState {
  productQuantity: boolean;
  productRate: boolean;
}

const { Option } = Select;

const BulkRateModal = <T extends DataType>({ onSave, open, close, selectedRowIndex, dataSource }: Props<T>) => {
  const [errors, setErrors] = useState<ErrorState[]>([]);
  const [bulkRates, setBulkRates] = useState<BulkRateEntry[]>([
    {
      isActive: true,
      isDelete: false,
      isRateInRupees: true,
      productQuantity: "" || 0,
      productRate: "" || 0,
    },
  ]);

  useEffect(() => {
    const bulkRateData = selectedRowIndex !== null ? dataSource[selectedRowIndex].productBulkRates : [{}];
    setBulkRates([...bulkRateData]);
    setErrors(bulkRateData?.map(() => ({ productQuantity: false, productRate: false })));
  }, [dataSource]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target;
    const newBulkRates = [...bulkRates];
    const newErrors = [...errors];
    const regexQuantity = /^\d{0,5}?$/;
    if (name === "productRate") {
      const regex = /^\d{0,6}(\.\d{0,2})?$/;
      if (regex.test(value) && bulkRates[index].isRateInRupees) {
        newBulkRates[index] = {
          ...newBulkRates[index],
          [name]: cleanValue(value),
        };
        newErrors[index][name as keyof ErrorState] = !value && value.length > 0;
      } else {
        const regexPercentage = /^\d{0,2}(\.\d{0,2})?$/;

        if (regexPercentage.test(value)) {
          newBulkRates[index] = {
            ...newBulkRates[index],
            [name]: cleanValue(value),
          };
          newErrors[index][name as keyof ErrorState] = !value && value.length > 0;
        }
      }
    } else if (name === "productQuantity" && regexQuantity.test(value)) {
      newBulkRates[index] = {
        ...newBulkRates[index],
        [name]: cleanValue(value),
      };
      newErrors[index][name as keyof ErrorState] = !value && value.length > 0;
    }

    setBulkRates(newBulkRates);
    // setErrors(newErrors);
  };

  const handleSuffixChange = (index: number, value: string) => {
    const newBulkRates = [...bulkRates];
    newBulkRates[index].isRateInRupees = value === "rupees";
    newBulkRates[index].productRate = "";

    setBulkRates(newBulkRates);
  };

  const addBulkRate = () => {
    if (validateFields()) {
      setBulkRates([
        ...bulkRates,
        {
          isActive: true,
          isDelete: false,
          isRateInRupees: true,
          productQuantity: "",
          productRate: "",
        },
      ]);
      setErrors([
        ...errors,
        {
          productQuantity: false,
          productRate: false,
        },
      ]);
    }
  };

  const handleDeleteField = (index: number) => {
    const newBulkRates = [...bulkRates];
    newBulkRates[index] = {
      ...newBulkRates[index],
      isDelete: true,
    };
    setBulkRates(newBulkRates);
  };

  const validateFields = (): boolean => {
    const newErrors = bulkRates?.map(rate => ({
      productQuantity: rate.isDelete ? false : !rate.productQuantity || Number(rate.productQuantity) === 0,
      productRate: rate.isDelete ? false : !rate.productRate || Number(rate.productRate) === 0,
    }));
    setErrors(newErrors);

    return newErrors.every(error => !error.productQuantity && !error.productRate);
  };

  const handleSave = () => {
    if (validateFields()) {
      onSave(bulkRates);
    }
  };

  const onDragEnd = (fromIndex: number, toIndex: number) => {
    const newBulkRates = [...bulkRates];
    const draggedItem = newBulkRates.splice(fromIndex, 1)[0];
    newBulkRates.splice(toIndex, 0, draggedItem);
    setBulkRates(newBulkRates);

    const newErrors = [...errors];
    const draggedError = newErrors.splice(fromIndex, 1)[0];
    newErrors.splice(toIndex, 0, draggedError);
    setErrors(newErrors);
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    e.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    const fromIndex = parseInt(e.dataTransfer.getData("text/plain"), 10);
    if (fromIndex !== index) {
      onDragEnd(fromIndex, index);
    }
  };

  const filteredData = bulkRates.filter((data) => !data.isDelete);

  const bulkRateQuantityUnit = selectedRowIndex !== null ? dataSource[selectedRowIndex].alternateUnitPrimaryUnit ? dataSource[selectedRowIndex].alternateUnitPrimaryUnit : dataSource[selectedRowIndex].productSizeOrderUnit : "BOX";

  return (
    <CommonModal
      onSave={handleSave}
      header="Bulk Rate"
      info="Give discounts for large orders. Enter the number of items and the price or discount."
      open={open}
      close={close}
      footer
      closeIcon
    >
      <div className="modal-bulkrate-box">
        <List
          dataSource={filteredData.length > 0 ? bulkRates : []}
          renderItem={(rate, index) => {
            return (
              !rate.isDelete &&
              <List.Item
                key={index}
              >
                <div
                  className="draggable-item flex"
                  draggable
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, index)}
                >
                  <div className="bulk-rate-inputs flex gap-3">
                    <div className="dragable-icon flex justifyCenter alignCenter">
                      <HolderOutlined />
                    </div>
                    <InputWithDropdown
                      label="Quantity"
                      required
                      name="productQuantity"
                      suffixSelector={bulkRateQuantityUnit}
                      onChange={(e) => handleInputChange(e, index)}
                      placeholder="Size (Ex. 100)"
                      value={rate.productQuantity}
                      autofocus
                      maxLength={Limit.qtyBulkRate}
                      className={errors[index]?.productQuantity ? "error" : ""}
                    />
                    <span className="flex justifyBetween alignCenter equal-to">=</span>
                    <InputWithDropdown
                      label={`Rate per ${dataSource[selectedRowIndex || 0].productSizeOrderUnit}`}
                      required
                      name="productRate"
                      suffixSelector={
                        <Select
                          value={rate.isRateInRupees ? "rupees" : "percentage"}
                          defaultValue={rate.isRateInRupees ? "rupees" : "percentage"}
                          onChange={(value) => handleSuffixChange(index, value)}
                        >
                          <Option value="rupees">₹</Option>
                          <Option value="percentage">%</Option>
                        </Select>
                      }
                      onChange={(e) => handleInputChange(e, index)}
                      placeholder="Rate (Ex. 100)"
                      value={rate.productRate}
                      maxLength={Limit.rateBulkRate}
                      className={errors[index]?.productRate ? "error" : ""}
                    />
                    <div className="action-buttons flex alignCenter">
                      {/* <button className="status-box flex alignCenter gap-1" onClick={() => toggleActiveStatus(index)}>
                            <div className={rate.isActive ? "status-active-icon" : "status-inactive-icon"} />
                            {rate.isActive ? "Active" : "Inactive"}
                          </button> */}
                      <Popconfirm
                        title="Delete the Bulk Rate"
                        description="Are you sure to delete this bulkrate?"
                        onConfirm={() => handleDeleteField(index)}
                        onCancel={() => []}
                        okText="Yes"
                        cancelText="No"
                      >
                        <div className="delete-box">
                          <DeleteIcon />
                        </div>
                      </Popconfirm>
                    </div>
                  </div>
                </div>
              </List.Item>
            );
          }}
        />
        <button className="add-bulkrate-button" onClick={addBulkRate}>
          <PlusOutlined color="#0169E6" width={16} height={16} /> Add Bulk Rate
        </button>
      </div>
    </CommonModal>
  );
};

export default BulkRateModal;