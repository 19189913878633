import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import Page from "../../components/page";
import VendorCard from "./vendorCard";
import { Button } from "../../components";
import { useAppSelector } from "../../hooks/useAppSelector";
import ChooseVendors from "./chooseVendor";

const Vendors = ({ open, toggle }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const { vendor } = useAppSelector(state => state.vendors);
  const { connection } = useAppSelector(state => state.connections);

  return (
    <Page
      title={[]}
      className="vendors-page"
      header={{
        pageTitle: <p className="flex alignCenter gap-2">Vendors<button className="total-count-button"><span>{(vendor?.data && vendor?.data?.length > 1) ? `${vendor?.data?.length} Vendors` : `${vendor?.data?.length} Vendor`}</span></button></p>,
        buttons: {
          addVendor: true,
          onClick() {
            setIsOpen(!isOpen);
          },
        },
      }}
    >
      {(vendor.isLoading || connection.isLoading) ? <div className="page-loader"><Spin /></div> : <></>}
      {isOpen ? <ChooseVendors data={vendor?.data} open={isOpen} close={() => { setIsOpen(false); }} /> : ""}
      {/* <OrderCart setIsOpen={setIsOpen} isOpen={isOpen} /> */}
      <div className="vendors-container">
        {vendor?.data?.map((item, index) => {
          return (
            <VendorCard key={index} data={item} />
          );
        })}
      </div>
    </Page >
  );
};

export default Vendors;