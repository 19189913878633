import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface Props {
  descriptionValue: string;
  getvalue: (data: string) => void;
  maxLength: number;
}

const RichTextEditor = ({ descriptionValue, getvalue, maxLength }: Props) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(descriptionValue);
  }, [descriptionValue]);


  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ "list": "ordered" }, { "list": "bullet" }],
    ],
  };

  const formats = [
    "bold", "italic", "underline", "list", "bullet",
  ];

  const handleChange = (data: string) => {
    if (data.replace(/<\/?[^>]+(>|$)/g, "").length <= maxLength) {
      setValue(data);
      getvalue(data);
    } else {
      setValue(data.replace(/<\/?[^>]+(>|$)/g, "").slice(0, maxLength));
      getvalue(data.replace(/<\/?[^>]+(>|$)/g, "").slice(0, maxLength));
    }
  };
  const handleKeyDown = (e: any) => {
    if (value.replace(/<\/?[^>]+(>|$)/g, "").length >= maxLength) {
      // Get the key value
      const key = e.key;

      // Regular expression to match 0-9, a-z, space, and special characters
      const regex = /^[0-9a-zA-Z\s!-"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~]$/;

      // Check if the key matches the regular expression
      if (regex.test(key)) {
        // Prevent the default action
        e.preventDefault();
      }
    }
  };

  return (
    <>
      <div>
        <div className="editText-container">
          <ReactQuill
            theme="snow"
            value={value}
            onChange={handleChange}
            modules={modules}
            formats={formats}
            placeholder="Enter Product Description"
            onKeyDown={(e) => handleKeyDown(e)}
          />
        </div>
      </div>
    </>
  );
};

export default RichTextEditor;