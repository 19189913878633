import React, { useCallback, useEffect, useState } from "react";
import { DatePicker, Popover, Table, TableProps, Tabs, TabsProps, TimeRangePickerProps } from "antd";
import dayjs from "dayjs";
import { NavLink, useNavigate } from "react-router-dom";

import SearchBar from "../../components/searchBar";
import { DownArrowFull, UserIcon } from "../../assets/images/icons";
import TransportationDetails from "./transportationDetails";
import StatusSwitch from "./statusSwitch";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getOrderItemsByOrder, getOrdersData } from "../../redux/services/orders";
import { useAppSelector } from "../../hooks/useAppSelector";
import { formatRupees } from "../../utils/helper";
import { debounce } from "../../utils/debounce";
import { Logo } from "../../assets/images";
import DispatchOrderModal from "./dispatchOrderModal";
import { tableSortingIconToggle } from "../../utils/tableSortingIconToggle";

const rangePresets: TimeRangePickerProps["presets"] = [
  { label: "Today", value: [dayjs().add(0, "d"), dayjs()] },
  { label: "Yesterday", value: [dayjs().add(-1, "d"), dayjs()] },
  { label: "This Week", value: [dayjs().add(-7, "d"), dayjs()] },
  { label: "Last Week", value: [dayjs().add(-14, "d"), dayjs().add(-7, "d")] },
  { label: "This Month", value: [dayjs().add(-30, "d"), dayjs()] },
  { label: "This Year", value: [dayjs().add(-1, "y"), dayjs()] },
];

interface dispatchType {
  isDispatch: boolean;
  orderId: number | null;
  isTransport: boolean
}

const OrdersData = () => {
  const { RangePicker } = DatePicker;
  const [searchOrder, setSearchOrder] = useState<string>("");
  const [toggleDispatch, setToggleDispatch] = useState<dispatchType>({ isDispatch: false, isTransport: false, orderId: null });
  const [date, setDate] = useState<{
    startDate: string,
    endDate: string,
  }>({
    startDate: (dayjs(new Date()).subtract(3, "months").format("DD/MM/YYYY")),
    endDate: (dayjs(new Date()).format("DD/MM/YYYY")),
  });
  const [sort, setSort] = useState({
    order: "ASC",
    field: "orderNumber",
  });
  const [activeTab, setActiveTab] = useState(0);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const companiesData = localStorage.getItem("companies");

  const { orderData, orderItems, statusDetails, acceptOrRejectOrderData } = useAppSelector(state => state.manageOrders);

  const newOrdersHeight = document.querySelector(".order-card-container")?.clientHeight;

  const columns = [
    {
      title: (activeTab === 1 ? "Customer Name" : "Vendor Name"),
      dataIndex: "customerName",
      key: "customerName",
      render: (_: string, record: any) => {
        return (
          <div className="customer-details-box gap-2 flex alignCenter">
            <div className="img-box">
              {(record?.customer?.profileThumbnailImg || record?.vendor?.profileThumbnailImg) ? <img className="profile-img" src={record?.customer?.profileThumbnailImg || record?.vendor?.profileThumbnailImg} alt="profile-image" /> : <UserIcon />}
            </div>
            <div className="customer-info flex direction-column" onClick={() => (record.status === "Rejected" || record.status === "Canceled") ? {} : navigate(`/orders/${record.orderId}`, { state: { activeTab: activeTab === 1 ? "Customer-order" : "My-order" } })} style={{ cursor: (record.status === "Rejected" || record.status === "Canceled") ? "default" : "pointer" }}>
              <span>{record?.customer?.name || record?.vendor?.name}</span>
              <span className="user-name">{(record?.customer?.createdBy?.firstName || record?.vendor?.createdBy?.firstName) + " " + (record?.customer?.createdBy?.lastName || record?.vendor?.createdBy?.lastName)}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Order No",
      dataIndex: "orderId",
      key: "orderId",
      sorter: orderData.data?.orders?.length ? true : false,
      sortIcon: (sortOrder: any) => tableSortingIconToggle(sortOrder),
      render: (text: string, record: any) => {
        return (
          <p className="caption">
            {record?.orderNumber ? `${record?.orderNumber}` : "-"}
          </p>
        );
      },
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (_: string, record: any) => {
        return (
          <p className="caption">{dayjs(record?.createdOn).format("DD/MM/YYYY")}</p>
        );
      },
    },
    {
      title: "Ordered Items",
      dataIndex: "orderItem",
      key: "orderItem",
      render: (_: string, record: any) => {
        return (
          <Popover
            content={OrderItemContent}
            trigger="click"
            placement="bottomLeft"
            rootClassName="order-item-popover"
          >
            <button className="ordered-items-btn gap-1 flex alignCenter" onClick={() => getOrderedProducts(record?.orderId)}>
              {record?.orderItemsCount} items <DownArrowFull />
            </button>
          </Popover>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: orderData.data?.orders?.length ? true : false,
      sortIcon: (sortOrder: any) => tableSortingIconToggle(sortOrder),
      render: (_: string, record: any) => {
        return (
          <StatusSwitch
            status={record?.status === "In Progress" ? "inProgress" : record?.status}
            orderId={record?.orderId}
            onDispatch={() => setToggleDispatch({ orderId: record?.orderId, isDispatch: true, isTransport: false })}
            activeTab={activeTab === 1 ? "Customer-order" : "My-order"}
          />
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "subtotal",
      sorter: orderData.data?.orders?.length ? true : false,
      sortIcon: (sortOrder: any) => tableSortingIconToggle(sortOrder),
      render: (_: string, record: any) => {
        return (
          <p className="caption">{formatRupees(record?.subtotal)}</p>
        );
      },
    },
  ];

  const orderItemsColumns: TableProps<any>["columns"] = [
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      width: 280,
      render: (_, record: any) => (
        <div className="product-name-box flex alignCenter gap-3">
          <div className="product-image-container">
            {record?.product?.productImage?.thumbnailURL ? <img className="product-image" src={record?.product?.productImage?.thumbnailURL} alt="product-img" /> : <Logo />}
          </div>
          <span className="product-name">{record?.product?.productName}</span>
        </div>
      ),
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      width: 159,
      render: (_, record: any) => (
        <div className="size-text">{record?.productSize?.productSize} {record?.productSize?.productSizeUnit}</div>
      ),
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      width: 131,
      render: (_, record: any) => (
        <div className="qty-text flex gap-1">
          <span className="quantity">{record?.qty}</span>
          <span className="unit">{record?.quantityOrdered} {record?.orderUnitConvertionUnit ? record?.orderUnitConvertionUnit : record?.orderUnit}</span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const path = window.location.pathname;
    const segments = path.split("/").join("");
    setActiveTab(segments === "my-orders" ? 2 : 1);
  }, []);


  useEffect(() => {
    if (activeTab) {
      const payload = {
        type: activeTab === 1 ? 1 : 0,
        search: searchOrder,
        startDate: date.startDate,
        endDate: date.endDate,
        limit: 0,
        sort: [sort],
      };
      dispatch(getOrdersData(payload));
    }
  }, [dispatch, date, activeTab, statusDetails.isSuccess, acceptOrRejectOrderData.isSuccess, sort, companiesData]);

  const getOrderedProducts = async (id: number) => {
    await dispatch(getOrderItemsByOrder(id));
  };

  // Debounced function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchProducts = useCallback(
    debounce((query: string) => {
      if (activeTab) {
        const payload = {
          type: activeTab === 1 ? 1 : 0,
          search: query,
          startDate: date.startDate,
          endDate: date.endDate,
          limit: 0,
          sort: [sort],
        };
        dispatch(getOrdersData(payload));
      }
    }, 500),
    [dispatch],
  );

  useEffect(() => {
    debouncedFetchProducts(searchOrder);
  }, [searchOrder, debouncedFetchProducts]);

  const OrderItemContent = () => {
    return (
      <Table
        columns={orderItemsColumns}
        dataSource={orderItems?.data || []}
        className="order-items-table"
        pagination={false}
      />
    );
  };

  const handleSearchOrder = (query: string) => {
    setSearchOrder(query);
  };

  const onRangeChange = (dates: null | (any | null)[], dateStrings: string[]) => {
    if (dates) {
      setDate({
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      });
    } else {
      setDate({
        startDate: (dayjs(new Date()).subtract(3, "months").format("DD/MM/YYYY")),
        endDate: (dayjs(new Date()).format("DD/MM/YYYY")),
      });
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <NavLink to={"/customer-orders"}>
          <span className="tab-label flex gap-2">
            Customer&apos;s Orders
            <span className="count">{orderData?.data?.customerOrdersCount || 0}</span>
          </span>
        </NavLink>
      ),
      children: (
        <Table
          columns={columns}
          dataSource={orderData.data?.orders || []}
          pagination={false}
          className="orders-table"
          onChange={(pagination, filter, sorter: any) => {
            setSort({
              order: sorter?.order === "descend" ? "DESC" : "ASC",
              field: sorter?.columnKey || "orderNumber",
            });
          }}
        />
      ),
    },
    {
      key: "2",
      label: (
        <NavLink to={"/my-orders"}>
          <span className="tab-label flex gap-2">
            My Orders
            <span className="count">{orderData?.data?.vendorOrdersCount}</span>
          </span>
        </NavLink>
      ),
      children: (
        <Table
          columns={columns}
          dataSource={orderData.data?.orders || []}
          pagination={false}
          className="orders-table"
          onChange={(pagination, filter, sorter: any) => {
            setSort({
              order: sorter?.order === "descend" ? "DESC" : "ASC",
              field: sorter?.columnKey || "orderNumber",
            });
          }}
        />
      ),
    },
  ];

  return (
    <div className="orders-data-container flex direction-column" style={{ height: `calc(100vh - (${newOrdersHeight}px + 120px))`, maxHeight: "100vh" }}>
      <div className="card manage-orders">
        <Tabs
          // defaultActiveKey="1"
          activeKey={activeTab.toString()}
          items={items}
          tabBarExtraContent={
            <div className="tabs-right-order-actions flex gap-4">
              <SearchBar
                onChange={(e) => handleSearchOrder(e.target.value)}
                value={searchOrder}
                placeholder="Search Order"
              />
              <div className="date-picker">
                <RangePicker
                  presets={rangePresets}
                  onChange={onRangeChange}
                  height={36}
                  width={400}
                  placement="bottomRight"
                  format="DD/MM/YYYY"
                  defaultValue={[dayjs(new Date()).subtract(3, "months"), dayjs(new Date())]}
                  allowClear={false}
                  maxDate={dayjs(new Date())}
                />
              </div>
            </div>
          }
          onChange={(key) => setActiveTab(Number(key))}
        />
      </div>
      {toggleDispatch?.isDispatch &&
        <DispatchOrderModal
          open={toggleDispatch?.isDispatch}
          close={() => setToggleDispatch({ isDispatch: false, isTransport: false, orderId: null })}
          orderId={toggleDispatch.orderId || 0}
          handleConfirm={() => setToggleDispatch({ isDispatch: false, isTransport: true, orderId: toggleDispatch.orderId })}
        />
      }
      {toggleDispatch?.isTransport &&
        <TransportationDetails
          open={toggleDispatch.isTransport}
          close={() => setToggleDispatch({ isDispatch: false, isTransport: false, orderId: null })}
          orderId={toggleDispatch.orderId || 0}
        />}
    </div>
  );
};

export default OrdersData;