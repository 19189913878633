
export enum NotificationModules {
  CONNECTION = "Connection Notifications",
  PRODUCT = "Product Notification",
  ORDER = "Order Notifications",
  RATE = "Rate Notification",
  SUBSCRIPATION = "Subscription-Reminder",
  USER_IMPORTANT_UPDATE = "User Update",

}

export enum NotificationTypes {
  CONNECTION_REQUEST_RECEIVED = "New Request",
  CONNECTION_REQUEST_ACCEPTED = "Request Accepted",
  CONNECTION_REQUEST_REJECTED = "Request Rejected",
  CONNECTION_CONNECTED = "Congratulations",
  ORDER_PLACED_BY_CUSTOMER = "Place Order",
  ORDER_ACCEPTED = "Order Accept",
  ORDER_ACCEPTED_BY_VENDOR = "Order Accepted",
  ORDER_REJECTED_BY_VENDOR = "Order Rejected",
  ORDER_IN_PROCESS = "Order Tracking In-Process",
  ORDER_DISPATCHED = "Order Dispatched",
  ORDER_RECEIVED = "Order Received",
  RATE_INCREASE = "Rate Change (Increase)",
  RATE_DECEREASE = "Rate Change (Decrease)",
  MINIMUM_ORDER_QUANTITY_CHANGED = "Minimum Order Quantity Changed",
  MINIMUM_ORDER_QUANTITY_ADDED = "Minimum Order Quantity Added",
  MINIMUM_ORDER_QUANTITY_REMOVED = "Minimum Order Quantity Removed",
  PRODUCT_ACTIVE = "Product Active",
  PRODUCT_INACTIVE = "Product inactive",
  PRODUCT_SIZE_ACTIVE = "Product Size Active",
  PRODUCT_SIZE_INACTIVE = "Product Size Inactive",
  ORDER_TRACKING_PARTIALLY_DISPATCH = "Order Tracking Partially Dispatch",
  ORDER_TRACKING_DISPATCH = "Order Tracking Dispatch",
  ORDER_PARTIALLY_DISPATCHED = "Order Partially Dispatched",
  RENEW_NOW = "Renew Now",
  COMMENT_ON_ORDER = "Comment on Order",
  ORDER_CANCELLED = "Order Cancelled",
  NEW_OFFER = "New offer",
  SPECIAL_RATE_FOR_YOU = "Special Rate for You",
  IMPORTANT_UPDATE = "Important Update"
}

export interface NotificationData {
  actionParams: {
    requestId: number;
    customerId: number;
    orederId: number;
  };
  notificationHistoryId: number;
  message: string;
  module: NotificationModules;
  notificationTypes: NotificationTypes;
  senderImage: string | null;
  senderName: string;
  isNew: boolean;
  isRead: boolean;
  createdOn: string;
}