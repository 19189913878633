import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, InputRef, message, Modal } from "antd";
import { CheckCircleOutlined, ClockCircleOutlined, UserAddOutlined } from "@ant-design/icons";

import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getCompanyByPhoneNumber, getSentRequest, sendInvitationRequest, takeActionOnConnectionRequest } from "../../redux/services/connection";
import axiosInstance from "../../redux/axios";
import { DownArrow, UserIcon } from "../../assets/images/icons";
import NoDataFound from "../../components/NoDataFound";
import { clearCompanyList } from "../../redux/slices/connectionSlice";
import { AddVendor, BlockUnblockRemoveConnection, getVendorList } from "../../redux/services/vendor";
import WpIcon from "../../assets/images/wpIcon";
import useMessageHook from "../../hooks/useMessageHook";

interface Props {
  setVisible: (data: boolean) => void;
  visible: boolean;
}

const InviteModal = ({ setVisible, visible }: Props) => {
  const [error, setError] = useState<any>();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isInvited, setIsInvited] = useState({
    message: "",
    isOpen: false,
  });
  const [sentRequests, setSentRequests] = useState({
    isToggle: false,
    data: [],
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const inputRef = useRef<InputRef | null>(null);

  const { sentRequest, companyList: connectionList } = useAppSelector(state => state.connections);
  const { showMessage, contextHolder } = useMessageHook(); 

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 200);
    const payload = {
      search: "",
      limit: 0,
      offset: 0,
      forVendor: false,
    };
    dispatch(getSentRequest(payload));
  }, [dispatch]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setPhoneNumber(value);
      // if (value?.length === 9 || value?.length === 1) {
      dispatch(clearCompanyList());
      // }
      // Check if the number starts with 6, 7, 8, or 9
      if (!/^[6-9]/.test(value) && value.length === 10) {
        showMessage("error", "Please enter a valid number");
        setError({ phone: true });
      } else {
        setError({});
      }

      if (value.length === 10 && /^[6-9]/.test(value)) {
        await dispatch(getCompanyByPhoneNumber({ phoneNo: value })).then((result) => {
          if (result.payload.status === 200) {
            // setConnectionList(result.payload?.data);
            setIsInvited({
              message: "",
              isOpen: false,
            });
            setError({});
          } else {
            setIsInvited({
              isOpen: true,
              message: result?.payload?.message,
            });
            setError({ phone: true });
          }
        }).catch(error => {
          setIsInvited({
            isOpen: true,
            message: error?.message,
          });
          setError({ phone: true });
        });
      } else {
        // setIsInvited({
        //   message: "",
        //   isOpen: false,
        // });
        // setError({});
      }
    }
  };

  const sendInvitation = async () => {
    if (!error?.phone) {
      await dispatch(sendInvitationRequest({
        phoneNo: phoneNumber,
        inviteAsVendor: false,
      })).then((result) => {
        setIsInvited({
          message: result.payload?.message,
          isOpen: true,
        });
        setPhoneNumber("");
      });
    }
  };

  const sendConnection = async (data: any) => {
    const payload = {
      receiverId: data?.companyId,
      requestAsVendor: true,
    };
    await axiosInstance.post("/request/send", payload).then((result) => {
      if (result.status === 200) {
        setIsInvited({
          message: result.data.message,
          isOpen: true,
        });
        // setConnectionList([]);
        setPhoneNumber("");
        getSendedRequests();
      }
    });
  };

  const getSendedRequests = async () => {
    const payload = {
      search: "",
      limit: 0,
      offset: 0,
      forVendor: false,
    };
    await dispatch(getSentRequest(payload)).then((result) => {
      if (result.payload.status === 200) {
        // setSendedRequests({ isOpen: false, data: result.payload?.data });
        setSentRequests({
          isToggle: !sentRequests.isToggle,
          data: result.payload?.data,
        });
      }
    });
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const InviteSuccess = () => {
    // if (isInvited.isOpen) {
    setTimeout(() => {
      setIsInvited({
        message: "",
        isOpen: false,
      });
    }, 5000);
    // }
    return (
      <div className={`${error?.phone ? "invitation-message-wrapper error" : "invitation-message-wrapper"}`}>
        <CheckCircleOutlined />
        <p className="caption">{isInvited.message}</p>
      </div>
    );
  };

  const requestAction = async (reqType: string, id: string | number) => {
    const payload = {
      requestId: id,
      action: reqType,
    };
    await dispatch(clearCompanyList());
    await dispatch(takeActionOnConnectionRequest(payload)).then((result) => {
      if (result?.payload?.status === 200 && reqType === "Cancel") {
        // setConnectionList([]);
        getSendedRequests();
        setSentRequests({
          isToggle: false,
          data: sentRequests?.data,
        });
        if (result?.payload?.data?.receiver?.createdBy?.phoneNo) dispatch(getCompanyByPhoneNumber({ phoneNo: result?.payload?.data?.receiver?.createdBy?.phoneNo })).then((result) => {
          if (result.payload.status === 200) {
            // setPhoneNumber(result?.payload?.data?.receiver?.createdBy?.phoneNo);
            // setConnectionList(result.payload?.data);
          }
        });
      }
    });
  };

  const handleAddVendor = async (id: number) => {
    const payload = {
      selectedVendors: [id],
    };
    await dispatch(AddVendor(payload)).then((result) => {
      if (result?.payload?.status === 200) {
        dispatch(getCompanyByPhoneNumber({ phoneNo: phoneNumber }));
      }
    });
  };

  const unBlockVender = async (value: string, id: number) => {
    await dispatch(BlockUnblockRemoveConnection({
      connectionId: id,
      action: value,
    })).then((result) => {
      if (result.payload.status === 200) {
        dispatch(getCompanyByPhoneNumber({ phoneNo: phoneNumber }));
        dispatch(getVendorList());
      }
    });
  };

  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      className="invite-modal"
      footer={false}
      rootClassName="invite-modal-wrapper"
    >
      {contextHolder}
      {isInvited.isOpen ? <InviteSuccess /> : ""}
      <div className="modal-header">
        <div className="modal-text-container">
          <h3 className="title-3">Invite Vendor</h3>
          <p className="caption">Invite your vendor and start collaborating today.</p>
        </div>
      </div>
      <div className="invite-form-body">
        {/* <input name="wp-number" className="wp-number" type="text" minLength={10} maxLength={10} onChange={handleChange} /> */}
        <div className="custom-input-container">
          <WpIcon />
          <Input
            className={error?.phoneNo ? "custom-input error" : "custom-input"}
            ref={inputRef}
            // suffix={<CloseCircleOutlined />}
            defaultValue=""
            onChange={handleChange}
            value={phoneNumber}
            placeholder="Invite by WhatsApp Number"
            autoFocus
            allowClear
          />
        </div>
        {/* <div className="invitation-buttons-container">
          <button className="invitation-link-button">
            Invitation link
          </button>
          <button className="copy-link" onClick={() => { navigator?.clipboard?.writeText("http://biizlinedevops.com/"); message.info("Link Copied !!!"); }}>
            Copy Link
          </button>
        </div> */}
        {sentRequest.data?.length ? <div className="accordian-container">
          <button className="accordian-header flex justifyBetween">
            <h4 className="title-4">Pending Request</h4>
            <button className={sentRequests.isToggle ? "collapse-button flex alignCenter active" : "collapse-button flex alignCenter"} onClick={() => setSentRequests({ isToggle: !sentRequests.isToggle, data: [] })}>
              {sentRequests.isToggle ? "Hide All" : "Show All"}<DownArrow />
            </button>
          </button>
          <div className="numbers-list-wrapper">
            {(sentRequests.isToggle) ? sentRequest?.data?.map((item: any, index) => {
              return (
                <div className="numbers-list" key={index}>
                  <div className="filterd-number flex rounded-8">
                    <div className="mobile-number flex gap-2">
                      <div className="image-container">
                        {item?.receiver?.profileThumbnailImg ? <img src={item?.receiver?.profileThumbnailImg} alt="profile-icon" /> : <UserIcon />}
                      </div>
                      <div className="user-info">
                        <p className="company-name">{item?.receiver?.name}</p>
                        <p className="user-name">{item?.receiver?.createdBy?.firstName + " " + item?.receiver?.createdBy?.lastName}</p>
                      </div>
                    </div>
                    <button
                      className="status pending"
                      type="button"
                    >
                      <div className="flex gap-1"><ClockCircleOutlined /> Pending</div>
                      <div className="flex gap-1 hover" onClick={() => requestAction("Cancel", item?.requestId)}>Cancel</div>
                    </button>
                  </div>
                </div>
              );
            }) : sentRequests?.isToggle ? <NoDataFound /> : ""}
          </div>
        </div> : <></>}
        {phoneNumber?.length > 5 && connectionList?.data?.length ?
          <div className="search-result-body">
            <p className="heading">Result found</p>
            <div className="numbers-list-wrapper">
              {connectionList?.data?.map((item: any, index: string) => {
                return (
                  <div className="numbers-list" key={index}>
                    <div className="filterd-number flex rounded-8">
                      <div className="mobile-number flex gap-2">
                        <div className="image-container">
                          {item?.profileThumbnailImg ? <img src={item?.profileThumbnailImg} alt="profile-icon" /> : <UserIcon />}
                        </div>
                        <div className="user-info">
                          <p className="company-name">{item.name}</p>
                          <p className="user-name">{item?.createdBy?.firstName + " " + item?.createdBy?.lastName}</p>
                        </div>
                      </div>
                      {item?.isConnected || item?.isVendor
                        ?
                        item.isBlock ?
                          (item.blockByCompany !== item?.companyId ?
                            <button
                              className="status pending"
                              type="button"
                              onClick={() => unBlockVender("Unblock", item?.connectionId)}
                            >
                              Unblock
                            </button> : <span>You are Blocked</span>)
                          :
                          <button className="connect-button flex alignCenter gap-1" onClick={() => navigate("/placeorder", { state: { orderId: item?.companyId, companyName: item?.name, companyProfile: item?.profileThumbnailImg }, replace: true })}>Order Now</button>
                        :
                        item?.sentRequest
                          ?
                          <button
                            className="status pending"
                            type="button"
                          >
                            <div className="flex gap-1"><ClockCircleOutlined /> Pending</div>
                            <div className="flex gap-1 hover" onClick={() => requestAction("Cancel", item?.requestId)}>Cancel</div>
                          </button>
                          :
                          item.receivedRequest
                            ?
                            <div className="buttons-container flex gap-3">
                              <button
                                className="request-button"
                                type="button"
                                onClick={() => { requestAction("Reject", item?.requestId); }}
                              >
                                Reject
                              </button>
                              <button
                                className="request-button active"
                                type="button"
                                onClick={() => { requestAction("Accept", item?.requestId); }}
                              >
                                Accept
                              </button>
                            </div>
                            :
                            item?.isConnected && !item?.isVendor
                              ?
                              <button
                                className="connect-button flex alignCenter gap-1"
                                onClick={() => handleAddVendor(item?.companyId)}
                              >
                                <UserAddOutlined /> Add Vendor
                              </button>
                              :
                              <button
                                className="connect-button flex alignCenter gap-1"
                                onClick={() => sendConnection(item)}
                              >
                                <UserAddOutlined /> Connect
                              </button>
                      }
                    </div>
                  </div>
                );
              })
              }
            </div>
          </div>
          : ""
        }
        {phoneNumber && phoneNumber.length === 10 && !connectionList?.data?.length && !error?.phone ? <div className="search-result-body">
          <p className="heading">Result found</p>
          <div className="numbers-list-wrapper">
            <div className="numbers-list">
              <div className="filterd-number flex rounded-8">
                <div className="mobile-number flex gap-2">
                  <div className="image-container">
                    <UserIcon />
                  </div>
                  <p className="caption bold">{phoneNumber}</p>
                </div>
                <button className="invite-button" onClick={sendInvitation}>invite</button>
              </div>
            </div>
          </div>
        </div> : ""}
      </div>
    </Modal>
  );
};

export default InviteModal;