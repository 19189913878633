import React from "react";
import { Select } from "antd";

export const SelectInput = ({ className, options, defaultOption, style, label, required, status, placeholder, onChange, value, ...props }: any) => {
  return (
    <div className="select-input-wrapper">
      <label htmlFor="">
        <span className="label-text">{label}{required && <span className="require-icon"> *</span>}</span>
        <Select
          className={className}
          defaultOpen={defaultOption}
          options={options}
          value={value}
          style={style}
          status={status}
          placeholder={placeholder}
          onChange={onChange}
          filterOption={false}
          searchValue=""
          {...props}
          suffixIcon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path d="M12.6667 6.5L8.00004 10.5L3.33337 6.5" stroke="#1C274C" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
          </svg>}
        >
          {/* {options?.map((item: { value: number, label: string }) => {
            return <label key={item.value}>
              <input type="checkbox" />
              <Select.Option key={item.value}> {item.label}</Select.Option>
            </label>;
          })} */}
        </Select>
      </label>
    </div>
  );
};