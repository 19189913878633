import { Select, SelectProps } from "antd";
import React, { CSSProperties, useEffect, useRef } from "react";

import { DownArrow } from "../../assets/images/icons";

interface Props {
  suffixIcon?: boolean,
  autoFocus?: boolean,
  value?: string,
  required?: boolean,
  label: string,
  placeholder?: string;
  className?: string;
  options: { value: string; label: string }[];
  onChange: (value: string) => void;
  onSearch?: (value: string) => void;
  optionRender?: SelectProps<any>["optionRender"];
  dropdownStyle?: CSSProperties;
  labelRender?: SelectProps<any>["labelRender"]
  disabled?: boolean;
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const SelectDropdown: React.FC<Props> = ({
  suffixIcon = true,
  autoFocus = false,
  value,
  required,
  label,
  placeholder,
  options,
  className,
  onChange,
  onSearch,
  optionRender,
  dropdownStyle,
  labelRender,
  disabled = false,
  onClick,
  onFocus,
  onBlur,
  ...props
}) => {
  const selectRef = useRef<any>(null);

  useEffect(() => {
    if (autoFocus && selectRef.current) {
      selectRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <div className={className + " select-dropdown-box"}>
      <label htmlFor="">
        {label && <span className="flex gap-1">{label}{required && <span className="require-icon"> *</span>}</span>}
        <Select
          disabled={disabled}
          value={value}
          ref={selectRef}
          suffixIcon={suffixIcon ? <DownArrow /> : ""}
          style={{ width: "100%" }}
          placeholder={placeholder}
          showSearch
          optionFilterProp="children"
          onChange={onChange}
          onSearch={onSearch}
          options={options}
          onClick={onClick}
          onBlur={onBlur}
          onFocus={onFocus}
          dropdownStyle={dropdownStyle}
          optionLabelProp="label"
          filterOption={(input, option) =>
            option
              ? option.label.toLowerCase().includes(input.toLowerCase()) ||
                option.value.toLowerCase().includes(input.toLowerCase())
              : false
          }
          optionRender={(option) => (
            <>
              <span className="unit-dropdown-styles">
                {option.value} <span className="sub-label">({option.label})</span>
              </span>
            </>
          )}
          labelRender={(option) => (
            <span className="custom-label-render">
              {option.value}
            </span>
          )}
          {...props}
        />
      </label>
    </div>
  );
};

export default SelectDropdown;