import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { message, Modal, Popconfirm, Table, TableProps } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { CrossRoundedIcon, DeleteOutlinedIcon, LocationIcon, Logo } from "../../assets/images";
import { InputField } from "../../components";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { addToCart, clearCartItem, getCartDataByVendorID, placeOrder, recentOrders, removeCartItem } from "../../redux/services/placeOrder";
import { useAppSelector } from "../../hooks/useAppSelector";
import CounterButton from "../../components/counterButton";
import { AttachmentIcon, DeleteIcon, EditIcon, PdfIcon } from "../../assets/images/icons";
import { addAddress, deleteDeliveryAddress, getAddressByPincode, getAddressListByCompany, setDefaultAddress, updateDeliveryAddress } from "../../redux/services/address";
import { AddressFormat, formatRupees } from "../../utils/helper";
import { clearCart } from "../../redux/slices/placeorderSlice";
import { clearAddressData } from "../../redux/slices/addressSlice";
import { getVendorList } from "../../redux/services/vendor";
import useMessageHook from "../../hooks/useMessageHook";

interface IDataTypes {
  cartItemId: number,
  quantity: number,
  orderUnitConvertionQuantity: number,
  orderUnit: string,
  orderUnitConvertionUnit: string,
  productSizeUnit: string,
  pricePerUnit: number,
  discount: number,
  subtotal: number,
  product: {
    productId: number,
    productName: string,
    productImage: {
      thumbnailURL: string
    }
  },
  productSize: {
    productSizeId: number,
    productSize: string,
    productSizeUnit: string,
    productSizeOrderUnit: string,
    moq: number,
    productBulkRates: {
      isRateInRupees: boolean,
      placePosition: number,
      productBulkRateId: number,
      productQuantity: number,
      productRate: number
    }[]
  }
}

interface AddressProps {
  addressLine: string;
  state: string;
  city: string;
  country?: string;
  pincode: string;
  addressId?: number;
  district?: string;
  isActive?: boolean;
  isDefault?: boolean;
  isDelete?: false;
  createdOn?: string;
  modifiedOn?: string | null;
}

interface IProps {
  isOpen: boolean;
  setIsOpen: (e: boolean) => void;
  orderid?: any
}

interface SelectedFile {
  name: string;
  type: string;
  file: File;
}

const OrderCart = ({ isOpen, setIsOpen, orderid }: IProps) => {
  const [billDetails, setBillDetails] = useState({
    subTotal: 0,
    discount: 0,
    total: 0,
  });
  const [editAddress, setEditAddress] = useState<{
    isEdit: boolean,
    isList: boolean,
    data: AddressProps,
  }>({
    isEdit: false,
    isList: false,
    data: {
      addressLine: "",
      city: "",
      pincode: "",
      state: "",
      country: "",
    },
  });
  const [isToggleOpen, setIsToggleOpen] = useState({
    toggle: true,
    id: 0,
  });
  const [isCount, setIsCount] = useState(false);

  const [sizes, setSizes] = useState<any>([]);
  const [addressList, setAddressList] = useState<AddressProps[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
  const [cartMessage, setCartMessage] = useState<string>("");
  const navigate = useNavigate();
  const [cartBasicInfo, setCartBasicInfo] = useState({
    totalItems: 0,
    totalAmount: 0,
  });
  const [error, setError] = useState<any>();

  const { cart } = useAppSelector(state => state.placeorder);
  const { addressList: ListOfAddresses, address }: any = useAppSelector(state => state.address);

  const dispatch = useAppDispatch();
  const location = useLocation()?.state;

  const { showMessage, contextHolder } = useMessageHook();

  const orderId = location?.orderId || orderid?.companyId;

  const columns: TableProps<IDataTypes>["columns"] = [
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "product",
      render: (_, item) => <div className="flex alignCenter gap-3"><div className="product-img">{item.product.productImage?.thumbnailURL ? <img src={item.product.productImage?.thumbnailURL} alt="product" /> : <Logo />}</div>{item.product.productName}</div>,
      width: "264px",
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "productSize",
      render: (_, item) => <div className="order-unit">
        <span className="box"></span> {item.productSize.productSize} {item.productSize.productSizeUnit}
      </div>,
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      render: (_, item, index: number) => {
        return (
          <CounterButton
            value={((isCount && item?.quantity) || sizes?.[index]?.quantity || 0)}
            handleDecrement={() => handleCount("Minus", item.productSize.productSizeId, item.productSize.moq === 0 ? 1 : item.productSize.moq, item.product.productId, ((isCount && item?.quantity - 1) || sizes?.[index]?.quantity - 1), item?.cartItemId)}
            handleIncrement={() => handleCount("Plus", item.productSize.productSizeId, item.productSize.moq, item.product.productId)}
            handleInputChange={(e) => handleInputChange(item.productSize.productSizeId, e.target.value, item.productSize.moq)}
            onFocus={() => setIsCount(false)}
            onBlur={() => handleBlur(item)}
            key={item.cartItemId}
            moq={item.productSize.moq || 0}
            isTooltip={isToggleOpen.toggle && (item.productSize.productSizeId === isToggleOpen.id) ? true : false}
            setIsToggleOpen={setIsToggleOpen}
            sizeId={item.productSize.productSizeId}
            isToggleOpen={isToggleOpen}
          />
        );
      },
    },
    {
      title: "Order Unit",
      dataIndex: "orderUnit",
      key: "orderUnit",
      render: (_, item) => <div className="order-unit">
        {item.orderUnitConvertionUnit ? <><span className="box">{item.orderUnitConvertionUnit}</span>{item.orderUnit !== item.orderUnitConvertionUnit ? <span className="gray-text"> ({item.orderUnitConvertionQuantity} {item.orderUnit})</span> : <></>}</> : item.orderUnitConvertionUnit}
      </div>,
    },
    {
      title: "Rate per unit",
      dataIndex: "rateperunit",
      key: "rateperunit",
      render: (_, item) => {
        const newRate: any = item.productSize.productBulkRates?.length ? item.productSize.productBulkRates.filter(product => item.quantity >= product.productQuantity).sort((a, b) => b.productQuantity - a.productQuantity)[0] : {};
        return <div className="rate-per-unit flex alignStart gap-2">
          <span>{formatRupees(newRate?.productRate || item.pricePerUnit)} / {item.orderUnit}</span>
          {/* <button className="price-fluc flex alignCenter gap-1"><PriceHike />8</button> */}
        </div>;
      },
    },
    // {
    //   title: "Discount",
    //   dataIndex: "discount",
    //   key: "discount",
    //   render: (_, item) => { return <p className="discount">{item.discount || "-"}</p>; },
    // },
    {
      title: "Subtotal",
      dataIndex: "subtotal",
      key: "subtotal",
      render: (_, item) => {
        return (
          <div className="sub-total flex alignCenter justifyBetween gap-2">
            <p className="caption">{formatRupees(item.subtotal)}</p>
            <Popconfirm
              title="Delete cart Item"
              description="Are you sure, you want to delete this item?"
              onConfirm={() => handleRemoveItem(item.cartItemId)}
              onCancel={() => []}
              okText="Yes"
              cancelText="No"
            >
              <button className="remove-item">
                <DeleteIcon />
              </button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const newArr: any[] = [];
  // Count Bill Details Totals
  useEffect(() => {
    let total = 0, totalDiscount = 0, totalAmount = 0, totalItems = 0;
    cart.data?.cartItems.map((item) => {
      newArr.push({
        cartItem: {
          id: item.cartItemId,
          quntity: item.quantity,
        },
        productId: item.product.productId,
        productSize: item.productSize,
      });
      return (
        setBillDetails({
          subTotal: total += item.subtotal,
          discount: totalDiscount += item.discount,
          total: total - totalDiscount,
        })
      );
    });
    const temp = cart.data?.cartItems.map((item) => ({
      quantity: item?.quantity || 0,
      productSizeId: item.productSize?.productSizeId,
    }));
    setSizes(temp);
    cart.data?.cartItems.forEach((item) => {
      totalAmount += item.subtotal - item.discount;
      totalItems += item.quantity;
    });

    setCartBasicInfo({
      totalItems: totalItems,
      totalAmount: totalAmount,
    });
  }, [cart]);

  useEffect(() => {
    if (ListOfAddresses?.data) {
      setAddressList(ListOfAddresses?.data);
    }
  }, [ListOfAddresses]);

  useEffect(() => {
    if (editAddress.data.pincode.length === 6) {
      dispatch(getAddressByPincode({ pincode: editAddress.data.pincode })).then((result) => {
        if (result.payload.status !== 200) {
          showMessage("error", result?.payload.message || "Something went wrong");
        }
        setEditAddress((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            district: result.payload?.data?.district,
            country: result?.payload?.data?.country,
          },
        }));
      });
    }
  }, [editAddress.data.pincode, dispatch]);

  useEffect(() => {
    if (address?.status === 400) {
      setError((prev: any) => ({
        ...prev,
        pincodeMessage: address?.message,
      }));
    } else {
      setError((prev: any) => ({
        ...prev,
        pincodeMessage: "",
        pincode: false,
      }));
    }
  }, [address]);

  const handleBlur = async (item: IDataTypes) => {
    let tempNew = null;
    // setIsCount(true);
    tempNew = sizes.map((size: any) =>
      size.productSizeId === item.productSize.productSizeId && (size?.quantity > 0) ? { ...size, quantity: size?.quantity <= item.productSize.moq ? item.productSize.moq : size?.quantity } : size,
    );
    setSizes(tempNew);
    if (tempNew?.length) {
      const removeData = tempNew?.filter((size: any) => size?.quantity === 0 && item.productSize.productSizeId === size?.productSizeId && item.cartItemId);
      const getCartId = removeData?.map((data: any) => data?.productSizeId === item?.productSize?.productSizeId && item?.cartItemId);
      if (getCartId?.length) await handleRemoveItem(getCartId?.[0]); else {
        const updateData = tempNew?.filter((item: any) => {
          return item.quantity !== 0 && item;
        });
        handleUpdateCart(updateData, item?.product?.productId);
      }
    } else {
      const updateData = tempNew?.filter((item: any) => {
        return item.quantity === 0 && item;
      });
      await handleUpdateCart(updateData, item?.product?.productId);
    }
    // handleUpdateCart(tempNew, item?.product?.productId);
  };

  const handleCount = (type: "Plus" | "Minus", itemId: number, maxValue: number, productId: number, qty?: number, cartItemId?: number) => {
    let tempNew = null;
    setIsCount(true);
    switch (type) {
    case "Plus":
      tempNew = sizes.map((size: any) =>
        size?.productSizeId === itemId ? { ...size, quantity: (size.quantity >= maxValue ? size.quantity + 1 : maxValue) } : size,
      );
      handleUpdateCart(tempNew, productId);
      setSizes(tempNew);
      setIsToggleOpen({
        toggle: false,
        id: itemId || 0,
      });
      break;
    case "Minus":
      tempNew = sizes.map((size: any) =>
        size?.productSizeId === itemId ? { ...size, quantity: (size.quantity - 1 >= maxValue ? size.quantity - 1 : maxValue) } : size,
      );
      if (Number(qty) === maxValue) {
        setIsToggleOpen({
          toggle: true,
          id: itemId || 0,
        });
      }
      if (qty && qty >= maxValue) {
        handleUpdateCart(tempNew, productId);
      } else if (cartItemId) {
        handleRemoveItem(cartItemId);
      }
      setSizes(tempNew);
      break;
    default:
      // code block
    }
  };

  const handleUpdateCart = (sizes: any, productId: number) => {
    dispatch(addToCart({
      vendorId: orderId,
      items: [{
        productId: productId,
        productSizes: sizes,
      }],
    })).then((result) => {
      if (result.payload.status === 200) {
        dispatch(getCartDataByVendorID({
          vendorId: orderId,
        }));
      }
    });
  };

  // const handleInputChange = (id: number, qty: string) => {
  //   const regex = /^\d{0,6}?$/;
  //   if (regex.test(qty)) {
  //     setSizes(sizes ? sizes.map((size: any) =>
  //       size.productSizeId === id && size.quantity >= 0 ? { ...size, quantity: Number(qty) } : size,
  //     ) : sizes);
  //   }
  // };

  const timeoutRef: any = useRef(null);
  useEffect(() => {
    if (isToggleOpen.toggle) {
      timeoutRef.current = setTimeout(() => {
        setIsToggleOpen((prev) => ({ ...prev, toggle: false }));
      }, 3000);
    }

    // Clean up the timeout if the component re-renders before the timeout completes
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isToggleOpen]);

  const handleInputChange = (id: number, qty: string, moq: number) => {
    const regex = /^\d{0,6}?$/;
    if (Number(qty) === moq) {
      setIsToggleOpen({
        toggle: true,
        id: id || 0,
      });
    } else if (Number(qty) !== moq) {
      setIsToggleOpen({
        toggle: false,
        id: id || 0,
      });
    }
    setIsCount(false);
    if (regex.test(qty)) {
      setSizes(sizes ? sizes.map((size: any) =>
        size.productSizeId === id && size.quantity >= 0 ? { ...size, quantity: Number(qty) } : size,
      ) : sizes);
    }
  };
  const handleAddMoreCart = () => {
    setIsOpen(false);
    if (orderid) {
      navigate("/placeorder", { state: { orderId: orderid?.companyId, companyName: orderid?.name, companyProfile: orderid?.profileThumbnailImg }, replace: true });
    }
  };

  const handleClearCart = () => {
    setIsOpen(false);
    dispatch(clearCartItem({ cartId: cart.data?.cartId || 0 })).then((result) => {
      if (result.payload?.status === 200) {
        dispatch(getCartDataByVendorID({
          vendorId: orderId,
        }));
        handleCloseCart();
        showMessage("success", result?.payload?.message);
      } else {
        showMessage("error", result?.payload.message || "Something went wrong");
      }
    }).catch(error => showMessage("error", error.message || "Something went wrong"));
  };

  const handleRemoveItem = (cartItemId: number) => {
    dispatch(removeCartItem({
      cartId: cart.data?.cartId || 0,
      cartItemIds: [cartItemId],
    })).then((result) => {
      if (result.payload?.status === 200) {
        dispatch(getCartDataByVendorID({
          vendorId: orderId,
        })).then((result) => {
          if (result?.payload?.status === 200) {
            if (result?.payload?.data?.cartItems?.length === 0) {
              handleCloseCart();
            }
          }
        });
        showMessage("success", result?.payload?.message);
      } else {
        showMessage("error", result?.payload.message || "Something went wrong");
      }
    }).catch(error => showMessage("error", error.message || "Something went wrong"));
  };

  const handlePlaceOrder = async () => {
    if (cart.data?.cartId) {
      const payload = {
        placeOrderDetails: {
          cartId: cart.data?.cartId,
          message: cartMessage,
        },
        orderAttachments: selectedFiles.map((data) => data.file),
      };

      if (addressList?.length && addressList?.filter((item) => (item.isActive))[0]?.addressId) {
        Object.assign(payload?.placeOrderDetails, { deliveryAddressId: addressList?.filter((item) => (item.isActive))[0].addressId });
      }
      await dispatch(placeOrder(payload)).then((result) => {
        const vendorId = orderId;
        if (result.payload.status === 200) {
          if (vendorId) {
            dispatch(getCartDataByVendorID({
              vendorId: vendorId,
            }));
            dispatch(recentOrders(vendorId));
          }
          showMessage("success", result?.payload?.message);
          dispatch(clearCart());
          setIsOpen(false);
          setCartMessage("");
          setSelectedFiles([]);
        } else {
          showMessage("error", result?.payload.message || "Something went wrong");
        }
      });
    }
  };

  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "pincode") {
      const validPincode = value.replace(/[^0-9]/g, "");
      if (validPincode.length <= 6) {
        setEditAddress((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            [name]: validPincode,
            city: "",
            state: "",
            country: "",
            district: "",
          },
        }));
      }
    } else {
      setEditAddress((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          [name]: value,
        },
      }));
    }
    setError({
      ...error,
      [name]: false,
    });
  };

  const handleAddAddress = (id: number) => {
    const newError: any = {};
    setError(newError);
    // setError({...error, newError});
    if (!editAddress.data.addressLine?.length) {
      Object.assign(newError, { addressLine: true });
    }
    if (!editAddress?.data?.city?.length && !address?.city?.length) {
      Object.assign(newError, { city: true });
    }
    if (!editAddress?.data?.state?.length && !address?.state?.length) {
      Object.assign(newError, { state: true });
    }
    if (!editAddress?.data?.pincode?.length) {
      Object.assign(newError, { pincode: true });
    }
    if (error.pincodeMessage || error.pincode) {
      Object.assign(newError, { pincode: true });
    }
    if (Object.keys(newError)?.length && !error.pincodeMessage) {
      setError(newError);
    }
    if (!Object.keys(newError)?.length) {
      const payload = {
        ...(id && { addressId: id }),
        addressLine: editAddress.data.addressLine,
        city: editAddress.data.city || address?.city,
        state: editAddress.data.state || address?.state,
        country: editAddress.data?.country || address?.country,
        pincode: editAddress.data.pincode,
        district: editAddress.data?.district || address?.district,
      };
      if (id) {
        dispatch(updateDeliveryAddress(payload)).then((result) => {
          if (result.payload?.status === 200) {
            dispatch(getAddressListByCompany());
            dispatch(clearAddressData());
            setEditAddress({
              isEdit: false,
              isList: false,
              data: {
                addressLine: "",
                city: "",
                pincode: "",
                state: "",
                country: "",
              },
            });
          }
        });
      } else {
        dispatch(addAddress(payload)).then((result) => {
          if (result.payload?.status === 200) {
            setEditAddress({
              isEdit: false, isList: false, data: {
                addressLine: "",
                state: "",
                city: "",
                pincode: "",
              },
            });
            dispatch(clearAddressData());
            dispatch(getAddressListByCompany()).then((result) => {
              if (result?.payload?.status === 200) {
                setEditAddress({
                  isEdit: false,
                  isList: false,
                  data: {
                    addressLine: "",
                    city: "",
                    pincode: "",
                    state: "",
                    country: "",
                  },
                });
                setAddressList(result?.payload?.data);
              }
            });
          }
        });
      }
    }
    // setEditAddress({
    //   isEdit: false,
    //   isList: false,
    //   data: {
    //     addressLine: "",
    //     city: "",
    //     pincode: "",
    //     state: "",
    //     country: "",
    //   },
    // });
  };

  const handleSetDefaultAddress = (id: number) => {
    dispatch(setDefaultAddress(id)).then((result) => {
      if (result.payload?.status === 200) {
        dispatch(getAddressListByCompany());
      }
    });
  };

  const handleEditAddress = (id: number) => {
    const address = addressList?.filter((item) => item?.addressId === id);
    setEditAddress({
      isEdit: true,
      isList: false,
      data: address[0],
    });
  };

  const handleDeleteAddress = (id: number) => {
    dispatch(deleteDeliveryAddress(id)).then((result) => {
      if (result.payload.status === 200) {
        dispatch(getAddressListByCompany());
      }
    });
  };

  const handleSelectAddress = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (e.target.checked) {
      dispatch(updateDeliveryAddress({ isActive: true, addressId: id })).then((result) => {
        if (result.payload?.status === 200) {
          dispatch(getAddressListByCompany());
        }
      });
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);

    const validFormats = ["image/jpeg", "image/png", "application/pdf"];

    const invalidFiles = files.filter((file) => !validFormats.includes(file.type));
    if (invalidFiles.length > 0) {
      showMessage("error", "Invalid file format. Only JPEG, PNG, and PDF are allowed.");
    }

    const validFiles = files.filter(
      (file) => validFormats.includes(file.type),
    );

    if (validFiles.length + selectedFiles.length > 3) {
      showMessage("error", "You can only upload up to 3 files.");
    } else {
      setSelectedFiles((prevFiles) => [
        ...prevFiles,
        ...validFiles.map((file) => ({
          name: file.name,
          type: file.type,
          file: file,
        })),
      ]);
    }

    event.target.value = ""; // Clear the input to allow re-uploading the same file if needed
  };

  const handleDelete = (fileName: string) => {
    setSelectedFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
  };

  const handleCloseCart = () => {
    setIsOpen(false);
    if (orderid) {
      dispatch(getVendorList());
    }
  };

  return (
    <Modal
      centered
      open={isOpen}
      width={1300}
      rootClassName="recent-order-modal"
      footer={null}
      onCancel={() => handleCloseCart()}
    >
      <div className="flex gap-5 recent-order-cart-page direction-column">
        {contextHolder}
        <div className="cart-header">
          <h2 className="title-2">My Cart</h2>
          {/* <button><CloseOutlined /></button> */}
        </div>
        <div className="cart-body">
          <div className="cart-body-section1">
            {cart?.data?.cartItems?.length ?
              <p className="caption">
                {cartBasicInfo?.totalItems || 0} items in your cart
                <Popconfirm
                  title="Remove all cart Items"
                  description="Are you sure, you want to clear your cart?"
                  onConfirm={handleClearCart}
                  onCancel={() => []}
                  okText="Yes"
                  cancelText="No"
                >
                  <button className="remove-button">
                    Remove All
                  </button>
                </Popconfirm>
              </p> : <></>
            }
            <button className="add-more-button" onClick={handleAddMoreCart}><PlusOutlined /> Add more</button>
          </div>
          <div className="cart-body-section2">
            <Table
              key="cartItemId"
              columns={columns}
              dataSource={cart?.data?.cartItems}
              pagination={false}
            />
          </div>
          <div className="cart-body-section3 flex gap-5 justifyBetween">
            <div className="form-section">
              <div className="address-section">
                <div className="flex">
                  <div className="address-text flex alignCenter gap-3">
                    <div className="location-icon-box">
                      <LocationIcon />
                    </div>
                    <div className="address-input">
                      <h4 className="title-4">
                        {(addressList?.filter((item) => (item.isActive))[0]?.isActive) ? "Delivery Address" : "Delivery Address"}
                      </h4>
                      {(addressList?.filter((item) => (item.isActive))[0]?.isActive) ? <input type="text" className="selected-address" value={AddressFormat(addressList?.filter((item) => (item.isActive))[0])} disabled onChange={(e) => e.preventDefault()} /> : <></>}
                    </div>
                    {editAddress.isEdit ?
                      <div className="form-buttons">
                        <button className="cancel" onClick={() => {
                          setError({}); setEditAddress({
                            isEdit: false,
                            isList: false,
                            data: {
                              addressLine: "",
                              city: "",
                              pincode: "",
                              state: "",
                              country: "",
                            },
                          }); dispatch(clearAddressData());
                        }}>Cancel</button>
                        <button className="save" onClick={() => { handleAddAddress(editAddress.data?.addressId || 0); }}>Save</button>
                      </div> : (editAddress.isList || !addressList?.length) ?
                        <div className="form-buttons">
                          {editAddress.isList ? <button className="cancel" onClick={() => setEditAddress({ isEdit: false, isList: false, data: editAddress.data })}>Cancel</button> : <></>}
                          {addressList?.length < 3 ? <button
                            className="add-address"
                            onClick={() => setEditAddress({
                              isEdit: true, isList: false, data: {
                                addressLine: "",
                                state: "",
                                city: "",
                                pincode: "",
                              },
                            })}
                          >
                            <PlusOutlined /> Add Address
                          </button> : <></>}
                        </div> : <div className="form-buttons">
                          <button className="change-address" onClick={() => setEditAddress({ isEdit: false, isList: true, data: editAddress.data })}>Change</button>
                        </div>
                    }
                  </div>
                </div>
                {editAddress.isEdit ?
                  <div className="address-form">
                    <InputField
                      name="addressLine"
                      onChange={handleChangeAddress}
                      placeholder="Enter Address"
                      label="Address 1"
                      required
                      value={editAddress.data.addressLine}
                      className={error?.addressLine ? "error" : ""}
                      maxLength={100}
                    />
                    <div className="flex gap-6">
                      <InputField
                        name="city"
                        onChange={handleChangeAddress}
                        placeholder="Enter City"
                        label="City"
                        required
                        value={editAddress.data.city || address?.district}
                        className={error?.city ? "error" : ""}
                      />
                      <InputField
                        name="state"
                        onChange={handleChangeAddress}
                        placeholder="Enter State"
                        label="State"
                        required
                        value={editAddress.data.state || address?.state}
                        className={error?.state ? "error" : ""}
                      />
                      <InputField
                        type="text"
                        name="pincode"
                        onChange={handleChangeAddress}
                        placeholder="Enter Pincode"
                        label="Pincode"
                        required
                        maxLength={6}
                        value={editAddress.data.pincode}
                        className={(error?.pincode || error?.pincodeMessage) ? "error" : ""}
                      />
                    </div>
                  </div> :
                  editAddress.isList ?
                    <div className="address-options">
                      {addressList && addressList?.map((item, index) => {
                        return (
                          <div className="address-item flex justifyBetween alignStart" key={index}>
                            <div className="flex address">
                              <label htmlFor={`address${index}`} className="address-label flex alignStart">
                                <input type="radio" name="address" value={AddressFormat(item)} id={`address${index}`} checked={item?.isActive} onClick={(e: any) => handleSelectAddress(e, (item?.addressId || 0))} defaultChecked={(item?.isActive) || false} />
                                <span>{AddressFormat(item)}</span>
                              </label>
                              {item?.isDefault ?
                                <button className="badge active">Default</button> :
                                <button className="badge" onClick={() => handleSetDefaultAddress(item?.addressId || 0)}>Set as Default</button>
                              }
                            </div>
                            <div className="action-buttons flex gap-4">
                              <button className="edit" onClick={() => { setEditAddress({ isEdit: true, isList: false, data: editAddress.data }); handleEditAddress(item?.addressId || 0); }}>
                                <EditIcon />
                              </button>
                              {!item?.isDefault ?
                                <Popconfirm
                                  title=""
                                  description="Are you sure, you want to delete this address?"
                                  onConfirm={() => handleDeleteAddress(item?.addressId || 0)}
                                  onCancel={() => []}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <button className="delete">
                                    <DeleteOutlinedIcon />
                                  </button>
                                </Popconfirm> : <></>}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    : ""
                }
              </div>
              {(editAddress.isList) ? <></> : <div className="description-section">
                <textarea
                  name="productDescription"
                  placeholder="Type your message here..."
                  onChange={(e) => setCartMessage(e.target.value)}
                  value={cartMessage}
                ></textarea>
                <button className="attachment-icon" onClick={() => document.getElementById("fileInput")?.click()}>
                  <AttachmentIcon />
                </button>
                <div className="attachments-files-container flex gap-2">
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*,.pdf"
                    multiple
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />

                  {selectedFiles && selectedFiles.map((file, index) => (
                    <div key={index} className="selected-file">
                      {file.type === "application/pdf" ? (
                        <div className="file-preview flex direction-column justifyCenter alignCenter">
                          <a href={URL.createObjectURL(file.file)} target="_blank" rel="noopener noreferrer">
                            <div className="file-box">
                              <PdfIcon />
                            </div>
                          </a>
                          <span className="file-name">{file.name}</span>
                        </div>
                      ) : (
                        <div className="file-preview flex direction-column">
                          <a href={URL.createObjectURL(file.file)} target="_blank" rel="noopener noreferrer">
                            <div className="file-box">
                              <img src={URL.createObjectURL(file.file)} alt={file.name} className="image-preview" />
                            </div>
                          </a>
                          <span className="file-name">{file.name}</span>
                        </div>
                      )}
                      <button onClick={() => handleDelete(file.name)} className="delete-icon">
                        <CrossRoundedIcon />
                      </button>
                    </div>
                  ))}
                </div>
              </div>}
            </div>
            <div className="biling-details">
              <div className="biling-text">
                <h3 className="title-3">Bill Details</h3>
                {/* <div className="subtotal">
                  <p className="text">Subtotal</p>
                  <p className="price">{formatRupees(billDetails.subTotal)}</p>
                </div> */}
                {/* <div className="total-discount">
                  <p className="text">Total Discount</p>
                  <p className="price">{formatRupees(billDetails.discount)}</p>
                </div> */}
              </div>
              <div className="bill-total">
                <div className="grand-total">
                  <h3 className="grand-total-text title-3">Grand Total</h3>
                  <h3 className="grand-total-price">{formatRupees(billDetails.total)}</h3>
                </div>
                <button className="place-order primary" onClick={handlePlaceOrder}>
                  Place Order
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OrderCart;