import React, { useCallback, useEffect, useState } from "react";
import { Select, Tabs, TabsProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { getConnectionDetailsById, getConnectionList } from "../../redux/services/connection";
import { FilterIcon, UserIcon } from "../../assets/images/icons";
import SearchBar from "../../components/searchBar";
import { debounce } from "../../utils/debounce";
import { companyId } from "../../utils/handleLocalStorage";

const ConnectionsListSidebar = ({ setConnectionInfo }: any) => {
  const [activeButton, setActiveButton] = useState(0);
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("ASC");
  const [activeTab, setActiveTab] = useState("All");
  const location = useLocation()?.state;
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { connection } = useAppSelector(state => state.connections);

  useEffect(() => {
    const payload = {
      search: search,
      sort: sort,
      type: activeTab,
      limit: 0,
      offset: 0,
    };
    dispatch(getConnectionList(payload));
  }, [dispatch, sort, activeTab]);

  useEffect(() => {
    if (location?.isSelectedConnection) {
      if (connection?.data) {
        setIndex(connection?.data.length);
        navigate(location.pathname, { replace: true, state: null });
      }
    }
  }, [location]);

  useEffect(() => {
    if (connection.data?.length) {
      getConnectionDetails(connection.data[index] || connection?.data[index - 1]);
    }
  }, [connection, index]);

  const handleSearch = useCallback(
    debounce((query: string) => {

      const payload = {
        search: query,
        sort: sort,
        type: activeTab,
        limit: 0,
        offset: 0,
      };
      dispatch(getConnectionList(payload));

    }, 500), // 500ms debounce delay
    [dispatch],
  );

  useEffect(() => {
    handleSearch(search);
  }, [search, handleSearch]);

  const getConnectionDetails = async (item: any) => {
    await dispatch(getConnectionDetailsById(item?.companyId));
    setActiveButton(item?.companyId);
    setConnectionInfo({
      companyId: item?.companyId,
      connectionId: item?.connectionId,
      isVendor: item?.isVendor,
      isCustomer: item?.isCustomer,
      isBlock: item?.isBlock,
      blockByCompany: item?.blockByCompany,
    });
  };

  const renderChip = (item: any) => {
    const isVendor = item?.isVendor;
    const isCustomer = item?.isCustomer;
    const isBoth = item?.isVendor && item?.isCustomer;
    const isBlocked = item?.isBlock && item.blockByCompany === companyId;

    // Determine the className based on the conditions
    const buttonClass = `user-role ${(isBlocked ? "block" : isBoth ? "both" : isVendor ? "vendor" : "customer")}`;

    // Determine the button text based on the conditions
    const buttonText = (isBlocked ? "Blocked" : isBoth ? "Both" : isVendor ? "Vendor" : "Customer");
    // Render the button only if any condition is met
    return (isVendor || isCustomer || isBlocked) ? (
      <button className={buttonClass} type="button">
        {buttonText}
      </button>
    ) : null;

  };
  const items: TabsProps["items"] = [
    {
      key: "All",
      label: "All",
      children: (
        <div className="connection-tabs-container">
          {connection?.data?.map((item, index) => {
            const activeConnection = activeButton === item?.companyId;
            return (
              <button
                className={`user-details-row ${activeConnection && "active"}`}
                key={item?.companyId}
                onClick={() => activeConnection ? {} : [setIndex(index)]}
              >
                <div className="user-data">
                  <div className="user-profile-icon">
                    {item.profileThumbnailImg ? <img src={item.profileThumbnailImg} alt="profile-img" /> : <UserIcon />}
                  </div>
                  <div className="user-details">
                    <h4 className="title-4">{item?.name}</h4>
                    <p className="caption">{item?.createdBy?.firstName + " " + item?.createdBy?.lastName}</p>
                  </div>
                </div>
                {renderChip(item)}
                {/* {(item?.isVendor || item?.isCustomer || (item.isBlock && item.blockByCompany === companyId)) ?
                  <button className={item?.isVendor ? "user-role vendor" : (item?.isCustomer) ? "user-role customer" :  (item.isBlock && item.blockByCompany === companyId)  ? "vendor user-role" : "user-role"} type="button">
                    {item?.isVendor ? (item?.isCustomer && !item?.isBlock) ? "Vendor" : (item.isBlock && item.blockByCompany === companyId) ? "Blocked" : "Vendor" : (item.isBlock && item.blockByCompany === companyId) ? "Blocked" : "Customer"}
                  </button> : ""} */}
              </button>
            );
          })}
        </div>
      ),
    },
    {
      key: "Vendors",
      label: "Vendor",
      children: (
        <div className="connection-tabs-container">
          {connection?.data?.map((item) => {
            const activeConnection = activeButton === item?.companyId;
            return (
              <label
                className={`user-details-row ${activeButton === item?.companyId && "active"}`}
                key={item?.companyId}
                onClick={() => activeConnection ? {} : getConnectionDetails(item)}
              >
                <div className="user-data">
                  <div className="user-profile-icon">
                    {item.profileThumbnailImg ? <img src={item.profileThumbnailImg} alt="profile-img" /> : <UserIcon />}
                  </div>
                  <div className="user-details">
                    <h4 className="title-4">{item?.name}</h4>
                    <p className="caption">{item?.createdBy?.firstName + " " + item?.createdBy?.lastName}</p>
                  </div>
                </div>
                {(item?.isVendor) ?
                  <button className="vendor user-role" type="button">
                    {item?.isVendor && "Vendor"}
                  </button> : ""}
              </label>
            );
          })}
        </div>
      ),
    },
    {
      key: "Customers",
      label: "Customer",
      children: (
        <div className="connection-tabs-container">
          {connection?.data?.map((item) => {
            const activeConnection = activeButton === item?.companyId;
            return (
              <label
                className={`user-details-row ${activeButton === item?.companyId && "active"}`}
                key={item?.companyId}
                onClick={() => activeConnection ? {} : getConnectionDetails(item)}
              >
                <div className="user-data">
                  <div className="user-profile-icon">
                    {item.profileThumbnailImg ? <img src={item.profileThumbnailImg} alt="profile-img" /> : <UserIcon />}
                  </div>
                  <div className="user-details">
                    <h4 className="title-4">{item?.name}</h4>
                    <p className="caption">{item?.createdBy?.firstName + " " + item?.createdBy?.lastName}</p>
                  </div>
                </div>
                {(item?.isCustomer) ?
                  <button
                    className="user-role customer" type="button">
                    {item?.isCustomer && "Customer"}
                  </button> : ""
                }
              </label>
            );
          })}
        </div>
      ),
    },
  ];

  const onChange = (key: string) => {
    setActiveTab(key);
    const payload = {
      search: search,
      sort: sort,
      type: key,
      limit: 0,
      offset: 0,
    };
    dispatch(getConnectionList(payload));
  };

  return (
    ((!connection?.data.length && !search.length) && (activeTab === "All") ? <></> :
      <div className="card connection-list-sidebar-wrapper">
        <div className="sidebar-heading flex justifyBetween">
          <h2 className="title-2">Connections</h2>
          <Select
            style={{ width: 74 }}
            value={sort}
            onChange={(value) => setSort(value)}
            options={[
              { label: "ASC", value: "ASC" },
              { label: "DESC", value: "DESC" },
            ]}
            rootClassName="sorting-selection"
            suffixIcon={<FilterIcon />}
          />
        </div>
        <div className="sidebar-body">
          <SearchBar onChange={(e) => setSearch(e.target.value)} value={search} placeholder="Search Connection" />
          <Tabs defaultActiveKey="All" items={items} onChange={onChange} />
        </div>
      </div>
    )
  );
};

export default ConnectionsListSidebar;