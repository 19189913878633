import React, { useRef, useState } from "react";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CommonModal from "../../components/commonModal";
import { InputField } from "../../components";
import InputWithDropdown from "../../components/inputWithDropdown";
import SelectDropdown from "../../components/selectDropdown";
import { AppDispatch, RootState } from "../../redux/store";
import { cleanValue } from "../../utils/helper";
import { checkExistingProduct } from "../../redux/services/products";
import Limits from "../../utils/limits.json";

interface Props {
  open: boolean,
  close: () => void
}

interface ProductModalValues {
  productName: string,
  size: string,
  sizeUnit: string,
  rate: string,
  orderUnit: string
}

interface ErrorObj {
  productName?: boolean;
  size?: boolean;
  sizeUnit?: string,
  rate?: boolean;
  orderUnit?: boolean;
  error?: string
}

const AddProductModal = ({ open, close }: Props) => {
  const [error, setError] = useState<ErrorObj>({});
  const [value, setValue] = useState<ProductModalValues>({ productName: "", sizeUnit: "GMS", size: "", rate: "" } as ProductModalValues);
  const { productUnits } = useSelector((state: RootState) => state.products);
  const dispatch: AppDispatch = useDispatch();
  const hiddenSpanRef = useRef<any>();

  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value: val } = e.target;
    if (name === "productName") {
      setError((prev) => ({
        ...prev,
        error: "",
      }));
    }
    if (name === "rate") {
      const regex = /^\d{0,6}(\.\d{0,2})?$/;
      if (regex.test(val)) {
        setValue((prev: any) => ({
          ...prev,
          rate: /^\d{1,6}(\.\d{0,2})?$/.test(val) ? cleanValue(val.toString()) : "",
        }));
      }
      setError((prev) => ({
        ...prev,
        rate: false,
      }));
    } else {
      setValue((prev: any) => ({
        ...prev,
        [name]: val,
      }));

      setError((prev) => ({
        ...prev,
        [name]: false,
      }));
    }
  };

  const handleSelect = (name: string, e: string) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: e,
    }));
    setError((prev) => ({
      ...prev,
      [name]: false,
    }));
  };

  const suffixSelector = (
    <Select
      className={error.sizeUnit ? "error" : ""}
      style={{ minWidth: 70, width: "auto" }}
      showSearch
      placeholder="Select a unit"
      onChange={(value) => handleSelect("sizeUnit", value)}
      dropdownStyle={{ minWidth: 200 }}
      value={value.sizeUnit}
      labelInValue={false}
      options={productUnits?.data ? [{ label: "None", value: "NA" }, ...productUnits.data] : [{ label: "None", value: "NA" }]}
      optionLabelProp="label"
      filterOption={(input, option) =>
        option
          ? option.label.toLowerCase().includes(input.toLowerCase()) ||
          option.value.toLowerCase().includes(input.toLowerCase())
          : false
      }
      optionRender={(option) => (
        <span className="unit-dropdown-styles">
          {option.value === "NA" ? "" : option.value} <span className="sub-label">({option.label})</span>
        </span>
      )}
      labelRender={(option) => (
        <span className="custom-label-render">
          {option.value === "NA" ? "" : option.value}
        </span>
      )}
    />
  );

  const handleSubmit = () => {
    const errorObj = {};
    setError({});
    if (!value?.productName) {
      Object.assign(errorObj, { productName: true });
    }
    if (!value?.size && value.size !== "NA") {
      Object.assign(errorObj, { size: true });
    }
    if (!value?.sizeUnit) {
      Object.assign(errorObj, { sizeUnit: true });
    }
    if (!value?.rate || parseFloat(value?.rate) <= 0) {
      Object.assign(errorObj, { rate: true });
    }
    if (!value?.orderUnit) {
      Object.assign(errorObj, { orderUnit: true });
    }
    if (Object.keys(errorObj)?.length) {
      setError(errorObj);
    } else {
      // Navigate to the desired route
      dispatch(checkExistingProduct(value.productName)).then((result) => {
        if (result?.payload?.data?.existing) {
          setError((prev) => ({
            ...prev,
            error: result?.payload?.message,
          }));
        } else {
          navigate("/products/add", { state: value });
          close();
        }
      },
      );
    }
  };

  return (
    <CommonModal
      close={() => close()}
      onSave={handleSubmit}
      header="Add Product"
      open={open}
      className="add-product-modal"
      footer
      closeIcon
    >
      <div className="modal-container gap-8">
        <div className="flex direction-column gap-6 modal-content">
          {/* Product Name */}
          <div className="flex direction-column">
            <InputField
              onChange={handleChange}
              required
              placeholder="Product Name"
              label="Product Name"
              name="productName"
              className={error.productName ? "error productNameInput" : "productNameInput"}
              autoFocus
              maxLength={Limits.productName}
              showCount
              errorMessage={error.error}
            />
          </div>

          {/* Size */}
          <div>
            <div className="flex gap-2">
              <InputWithDropdown
                value={value?.size}
                onChange={handleChange}
                required
                label="Size"
                suffixSelector={suffixSelector}
                placeholder="Size (Ex. 100)"
                maxLength={Limits.productSize}
                className={(error.size ? "error" : "") + " size-input-style"}
                name="size"
                suffix={value.sizeUnit === "NA" ? "" : value.sizeUnit}
                autofocus={false}
              />
              <div className="rate-box">
                <InputWithDropdown
                  value={value.rate}
                  prefixSelector={"₹"}
                  onChange={handleChange}
                  required
                  placeholder="Rate"
                  label="Rate"
                  maxLength={Limits.productRate}
                  className={error.rate ? "error" : ""}
                  name="rate"
                />
              </div>
            </div>
            <p className="preview-text flex gap-1">
              <span className="info-text">Size Preview: </span>
              <span className="size-preview-text" ref={hiddenSpanRef}>{value.size || " e.g. 100 "}</span>
              <span className="size-unit-preview-text">{" " + value?.sizeUnit || "GM"}</span>
            </p>
          </div>

          {/* Order Unit */}
          <SelectDropdown
            value={value.orderUnit}
            required
            label="Order Unit"
            onChange={(value) => handleSelect("orderUnit", value)}
            options={productUnits?.data ? productUnits?.data : []}
            placeholder="Order Unit (Ex.: Pcs, Nos)"
            className={error.orderUnit ? "error" : ""}
            optionRender={(option) => (
              <>
                <span className="unit-dropdown-styles">
                  {option.value} <span className="sub-label">({option.label})</span>
                </span>
              </>
            )}
          />
        </div>
      </div>
    </CommonModal>
  );
};

export default AddProductModal;