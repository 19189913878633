import React from "react";
import { useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";

import UserInfo from "../userInfo";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { selectCompany } from "../../redux/services/auth";
import { PlusIcons } from "../../assets/images";
import useMessageHook from "../../hooks/useMessageHook";
import { EditIcon, UserIcon } from "../../assets/images/icons";

interface Props {
  handleOpenChange: () => void;
  isSetting?: boolean;
}

const CompanyListAndSetting = ({ handleOpenChange, isSetting }: Props) => {
  const { showMessage, contextHolder } = useMessageHook();
  const dispatch = useAppDispatch();
  const { allCompanies } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const companiesData = localStorage.getItem("companies");
  const userData = localStorage.getItem("user");

  const Company = companiesData && JSON.parse(companiesData || "");
  const ProfileData = userData && JSON.parse(userData || "");

  const handleSwitchCompany = async (value: number) => {
    dispatch(selectCompany(value)).then((result) => {
      if (result?.payload?.status === 200) {
        localStorage.setItem("companies", JSON.stringify(result.payload.data.company));
        localStorage.setItem("token", result.payload.data.token);
        localStorage.setItem("user", JSON.stringify(result.payload.data.user));
        localStorage.setItem("createNewCompany", JSON.stringify(false));
        navigate("/");
      } else {
        showMessage("error", result?.payload.message || "Something went wrong");
      }
    });
    handleOpenChange();
  };

  const handleAddNewComapny = () => {
    localStorage.setItem("createNewCompany", JSON.stringify(true));
    navigate("/", { state: { phoneNo: ProfileData.phoneNo } });
    handleOpenChange();
  };

  const handleCurrentCompany = () => {
    localStorage.setItem("createNewCompany", JSON.stringify(false));
    handleOpenChange();
    navigate("/");
  };

  const goToSetting = (name: string) => {
    navigate(`/settings/${name}`, { state: { companyId: Company?.companyId } });
    handleOpenChange();
  };

  // const settings = [
  //   { icon: <BellOutLined />, name: "Notification" },
  //   { icon: <CartIcon />, name: "Orders" },
  //   { icon: <ProductIcon />, name: "Products" },
  //   { icon: <User />, name: "Connections" },
  // ];

  return (
    <div className="company-list-popover">
      {contextHolder}
      <div className="selected-company-box">
        <div className="company-box">
          <div className="user-box gap-2 flex alignCenter">
            <div className="img-box">
              {Company.profileImg ? <img className="profile-img" src={Company.profileImg} alt="" /> : <UserIcon />}
            </div>
            <div className="customer-info flex direction-column">
              {Company.name && <span>{Company.name}</span>}
              {Company.city && <span className="user-name">{`${Company.city}, ${Company.state}`}</span>}
            </div>
          </div>
          {isSetting ? <button className="icon-button edit-icon" onClick={() => goToSetting(Company?.name)}><EditIcon /></button> : <></>}
        </div>
      </div>
      {/* {settings.map((data, index) => (
        <div key={index} className="company-profile-setting-container">
          <div className="setting-container">
            <button className="item-box-button">{data.icon} {data.name}</button>
          </div>
        </div>
      ))} */}
      {allCompanies && allCompanies.filter((data) => data.companyId !== Company.companyId).length ?
        <div className="switch-company-container">
          <div className="switch-company-box">
            Switch company
          </div>
        </div> : <></>}
      {allCompanies && allCompanies.filter((data) => data.companyId !== Company.companyId).map((data, index) => (
        <button key={index} className="switch-company-profile-container" onClick={() => handleSwitchCompany(data.companyId)}>
          <div className="inner-box">
            <UserInfo
              profileImage={data.profileThumbnailImg || ""}
              comanyName={data.name}
              userName={`${data.city}, ${data.state}`}
            />
          </div>
        </button>
      ))}
      {isSetting ?
        <></>
        // <button className="logout-button" onClick={handleAddNewComapny}>
        //   <span className="flex gap-2 alignCenter justifyStart">
        //     <LogoutOutlined />
        //     <span className="button-text">Logout</span>
        //   </span>
        // </button>
        :
        <button className="add-company-container" onClick={handleAddNewComapny}>
          <div className="inner-box">
            <PlusIcons />
            Add Company
          </div>
        </button>
      }
    </div>
  );
};

export default CompanyListAndSetting;