import React from "react";

export const BG1 = () => {
  return (
    <svg width="765" height="582" viewBox="0 0 765 582" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f_2039_12145)">
        <ellipse cx="382.183" cy="245.5" rx="222.188" ry="176.5" fill="#5E44FF"/>
      </g>
      <defs>
        <filter id="filter0_f_2039_12145" x="-0.00476074" y="-91" width="764.376" height="673" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="80" result="effect1_foregroundBlur_2039_12145"/>
        </filter>
      </defs>
    </svg>
  );
};