import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Vendors from "../vendors";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getVendorList } from "../../redux/services/vendor";
import { useAppSelector } from "../../hooks/useAppSelector";
import ChooseVendors from "../vendors/chooseVendor";

const SelectVendors = () => {
  const [toggleSelectVendors, setToggleSelectVendors] = useState(false);
  const dispatch = useAppDispatch();
  // const location = useLocation();
  const navigate = useNavigate();
  const { vendor } = useAppSelector(state => state.vendors);

  useEffect(() => {
    dispatch(getVendorList());
  }, [dispatch]);

  useEffect(() => {
    if (!vendor?.data?.length && vendor?.isSuccess && !vendor?.isLoading) {
      setToggleSelectVendors(true);
    } else {
      setToggleSelectVendors(false);
    }
  }, [vendor]);

  const BackButton = () => {
    navigate(-1);
  };

  return (
    (vendor?.isSuccess && !vendor?.isLoading) ? (vendor?.data?.length ?
      <Vendors open={toggleSelectVendors} toggle={() => { setToggleSelectVendors(true); }} />
      :
      <ChooseVendors data={vendor?.data} open={toggleSelectVendors} close={() => { setToggleSelectVendors(false); BackButton(); }} />) : <></>
  );
};

export default SelectVendors;