import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { BiizlineLogo, HeroImage, HeroImageMobile } from "../../assets/images";
import WpIcon from "../../assets/images/wpIcon";
import { InputField, SelectInput } from "../../components";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getCompanyBusinessType } from "../../redux/services/auth";
import { useAppSelector } from "../../hooks/useAppSelector";
import useMessageHook from "../../hooks/useMessageHook";
import axiosInstance from "../../redux/axios";

const LandingPage = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<any>({});
  const [error, setError] = useState<any>({});
  const [phoneNo, setPhoneNo] = useState<string>("");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { showMessage, contextHolder } = useMessageHook();

  const { businessTypes } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (step === 2) {
      dispatch(getCompanyBusinessType());
    }
    setPhoneNo("");
    setFormData({});
  }, [dispatch, step]);

  const isRegistered = localStorage.getItem("isRegistered");
  const phoneNumber = localStorage.getItem("phoneNo");

  useEffect(() => {
    if (JSON.parse(isRegistered || "false") || (phoneNumber && JSON.parse(phoneNumber || "")?.length)) {
      setStep(2);
    }
  }, [isRegistered, phoneNumber]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelect = (e: React.ChangeEvent) => {
    setFormData((prev: any) => ({
      ...prev,
      companyType: e,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errorObj = {};
    setError({});
    if (!formData?.firstName?.length) {
      Object.assign(errorObj, { firstName: true });
    }
    if (!formData?.lastName?.length) {
      Object.assign(errorObj, { lastName: true });
    }
    if (!formData?.companyType?.length) {
      Object.assign(errorObj, { companyType: true });
    }
    if (!formData?.companyName?.length) {
      Object.assign(errorObj, { companyName: true });
    }
    if (Object.keys(errorObj)?.length) {
      setError(errorObj);
    }
    if (!Object.keys(errorObj)?.length) {
      const payload = {
        phoneNo: JSON.parse(phoneNumber || ""),
        companyName: formData?.companyName,
        firstName: formData?.firstName,
        lastName: formData?.lastName,
        companyType: formData?.companyType,
      };
      await axiosInstance.post("leads/submitProfileDetails", payload).then((result) => {
        if (result.status === 200) {
          showMessage("success", result.data?.message);
          navigate("/home");
          setStep(1);
          localStorage.clear();
        } else {
          showMessage("error", result.data?.message);
        }
      });
    }
  };

  const handleCheckPhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (/^\d{0,10}$/.test(value)) {
      setPhoneNo(value);
    }
  };

  const validatePhoneNumber = (phoneNo: string) => {
    if (!phoneNo) {
      showMessage("error", "Please enter your Whatsapp Number");
      return false;
    }

    const isValidFormat = /^[6-9]\d{9}$/.test(phoneNo);

    if (!isValidFormat) {
      showMessage("error", "Please enter a valid whatsapp number");
      return false;
    }

    return true;
  };

  const handleBookDemo = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validatePhoneNumber(phoneNo)) {
      return;
    } else {
      await axiosInstance.post("leads/submitPhoneNo", {
        phoneNo: phoneNo,
      }).then((result) => {
        if (result.status === 200) {
          showMessage("success", result.data?.message);
          localStorage.setItem("isRegistered", "true");
          localStorage.setItem("phoneNo", JSON.stringify(phoneNo));
          setPhoneNo("");
          setStep(2);
        } else {
          showMessage("error", result.data?.message);
        }
      });
    }
  };

  return (
    <div className="landing-page-wrapper">
      {contextHolder}
      <div className="container flex direction-column h-100 gap-4">
        <header className="header-container flex justifyBetween alignCenter">
          <div className="logo-container">
            <Link to={"/home"} onClick={() => {localStorage.clear(); setStep(1);}}><img src={BiizlineLogo} alt="logo" /></Link>
          </div>
          <span className="beta-chip">Beta Version</span>
          <button className="login-button primary-button rounded-16" onClick={() => navigate("/login")}>Login</button>
        </header>
        {step === 1 ?
          <section className="hero-section">
            <h1 className="title-1 hero-heading">Manage Your <span className="text-bg">Orders</span></h1>
            <p className="caption hero-description">Simplifying order management for Indian micro-businesses with a user-friendly platform that boosts efficiency, accuracy, and secure communication.</p>
            <form onSubmit={handleBookDemo} className="booking-form flex alignCenter justifyCenter gap-5">
              <div className="input-container flex gap-2 rounded-16 alignCenter">
                <span className="whatsapp-icon">
                  <WpIcon />
                </span>
                <input type="text" className="input" value={phoneNo} placeholder="Enter WhatsApp Number" onChange={handleCheckPhoneNumber} />
              </div>
              <button className="form-button primary-button rounded-16 flex alignCenter" onClick={handleBookDemo}>Request For Demo</button>
            </form>
            <div className="hero-image">
              <img src={HeroImage} alt="hero-image" className="desktop" />
              <img src={HeroImageMobile} alt="hero-image" className="mobile" />
            </div>
          </section>
          :
          <section className="details-form-section">
            <h2 className="form-heading">Submit Details</h2>
            <form onSubmit={handleSubmit} className="details-form flex gap-8 direction-column">
              <InputField
                name="companyName"
                placeholder="Enter Company Name"
                label="Company Name"
                required
                onChange={handleChange}
                value={formData?.companyName}
                className={error?.companyName ? "error" : ""}
                autoFocus
              />
              <SelectInput
                options={businessTypes.data.map((item) => ({ value: item.businessTypeId, label: item.businessType }))}
                placeholder="Choose Company Type"
                label="Company Type"
                required
                className="company-type-input"
                mode="multiple"
                name="company_type"
                status={error?.companyType ? "error" : ""}
                onChange={handleSelect}
                maxTagCount="responsive"
              />
              <div className="flex gap-8">
                <InputField
                  name="firstName"
                  placeholder="Enter First Name"
                  label="First Name"
                  required
                  onChange={handleChange}
                  value={formData?.firstName}
                  className={error?.firstName ? "error" : ""}
                />
                <InputField
                  name="lastName"
                  placeholder="Enter Last Name"
                  label="Last Name"
                  required
                  onChange={handleChange}
                  value={formData?.lastName}
                  className={error?.lastName ? "error" : ""}
                />
              </div>
              <button className="primary-button rounded-16" onClick={handleSubmit}>Submit</button>
            </form>
          </section>
        }
      </div>
      <footer className="footer flex alignCenter justifyBetween">
        <p className="copyrights-text">©2024 Biizline.com All Rights Reserved.</p>
        <p className="call-text"><Link to={"tel:+91 96 3800 2500"}>Call us: +91 96 3800 2500</Link></p>
      </footer>
    </div>
  );
};

export default LandingPage;