import React, { useEffect, useState } from "react";

import ProoductsData from "./productsData";
import ListOfGroups from "./listOfGroups";
import { getProductsGroups, getProductUnits } from "../../redux/services/products";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";

const Products = () => {
  const dispatch = useAppDispatch();
  const [groupId, setgroupId] = useState(0);

  const { productUnits, productGroups } = useAppSelector((state) => state.products);

  useEffect(() => {
    if (!productUnits?.data?.length) {
      dispatch(getProductUnits({ search: "" }));
    }
    dispatch(getProductsGroups({ search: "" }));
  }, [dispatch]);

  return (
    <div className="flex products-list-container">
      {productGroups?.length && productGroups?.length > 1 ? <div className="card sidebar-container">
        <ListOfGroups setGroupId={(e: number) => setgroupId(e)} />
      </div> : <></>}
      <ProoductsData getGroupId={groupId} className={productGroups?.length && productGroups?.length > 1 ? "" : "active-list"} />
    </div>
  );
};

export default Products;
