import React from "react";

export const BG2 = () => {
  return (
    <svg width="814" height="880" viewBox="0 0 814 880" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f_2039_12146)">
        <ellipse cx="302.006" cy="440" rx="351.85" ry="280" fill="#E548FF"/>
      </g>
      <defs>
        <filter id="filter0_f_2039_12146" x="-209.844" y="0" width="1023.7" height="880" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="80" result="effect1_foregroundBlur_2039_12146"/>
        </filter>
      </defs>
    </svg>
  );
};
