import React from "react";

import { UserIcon } from "../../assets/images/icons";

interface Props {
  profileImage: string;
  comanyName?: string;
  userName?: string;
}

const UserInfo = ({ profileImage, comanyName, userName }: Props) => {
  return (
    <div className="user-box gap-2 flex alignCenter">
      <div className="img-box">
        {profileImage ? <img className="profile-img" src={profileImage} alt="" /> : <UserIcon />}
      </div>
      <div className="customer-info flex direction-column">
        {comanyName && <span>{comanyName}</span>}
        {userName && <span className="user-name">{userName}</span>}
      </div>
    </div>
  );
};

export default UserInfo;