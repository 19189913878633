import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import { Button, InputField, SelectInput } from "../../components";
import Page from "../../components/page";
import FileUpload from "../../components/fileUpload";
import axiosInstance from "../../redux/axios";
import { useDebounce } from "../../utils/debounce";
import { getAddressByPincode } from "../../redux/services/address";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { clearAddressData } from "../../redux/slices/addressSlice";
import { REGEX_STRINGS } from "../../utils/constant";
import ConfirmationPopup from "../../components/actionModals/confirmationPopup";
import useMessageHook from "../../hooks/useMessageHook";

interface valueType {
  company_name: string,
  company_type: number[],
  pincode: string,
  location: string,
  f_name: string,
  l_name: string,
  email?: string
}

const CreateCompany = () => {
  const [error, setError] = useState<any>({});
  const [value, setValue] = useState<valueType>({
    company_name: "",
    company_type: [],
    pincode: "",
    location: "",
    f_name: "",
    l_name: "",
  });
  const [companyTypeList, setCompanyTypeList] = useState<any[]>([]);
  const [imgFile, setImgFile] = useState<any>();
  const [isShowProfileInfo, setProfileInfo] = useState(true);
  const [isConfirmToggle, setIsConfirmToggle] = useState(false);

  const { address }: any = useAppSelector(state => state.address);
  const { companyData } = useAppSelector(state => state.auth);
  const { showMessage, contextHolder } = useMessageHook(); 

  const location = useLocation().state;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const debounce = useDebounce(value?.pincode, 200);

  const UserData = localStorage.getItem("user");
  const companiesData = localStorage.getItem("companies");
  const createNewCompany = localStorage.getItem("createNewCompany");

  const Company = companiesData && JSON.parse(companiesData || "");
  const isNewCompany = createNewCompany && JSON.parse(createNewCompany || "");

  useEffect(() => {
    if (UserData) {
      const data = JSON.parse(UserData);

      if (data?.firstName) {
        setValue({
          ...value,
          f_name: data?.firstName || "",
          l_name: data?.lastName || "",
        });
        setProfileInfo(false);
      }
    }
  }, [UserData]);

  useEffect(() => {
    getBusinessType();
  }, []);

  useEffect(() => {
    if (value?.pincode?.length === 6) dispatch(getAddressByPincode({ pincode: value?.pincode }));
  }, [dispatch, debounce]);

  useEffect(() => {
    if (address?.status === 400) {
      showMessage("error", address.message || "Something went wrong");
      setError((prev: any) => ({
        ...prev,
        pincodeMessage: address?.message,
      }));
    } else {
      setError((prev: any) => ({
        ...prev,
        pincodeMessage: "",
        pincode: false,
      }));
    }
  }, [address]);

  const handleChange = (e: any) => {
    const { name, value: val } = e.target;

    if (name === "pincode") {
      const validPincode = val.replace(/[^0-9]/g, "");
      if (validPincode.length <= 6) {
        setValue((prev: any) => ({
          ...prev,
          [name]: validPincode,
        }));
        setError((prev: any) => ({
          ...prev,
          [name]: false,
        }));
      }
    } else {
      setValue((prev: any) => ({
        ...prev,
        [name]: val,
      }));
      setError((prev: any) => ({
        ...prev,
        [name]: false,
      }));
    }
    if (name === "pincode") dispatch(clearAddressData());
  };

  const handleSelect = (e: any) => {
    setValue((prev: any) => ({
      ...prev,
      company_type: e,
    }));
    setError((prev: any) => ({
      ...prev,
      company_type: false,
    }));
  };

  const handleSubmit = async () => {
    const errorObj = {};
    if (!value?.company_name) {
      Object.assign(errorObj, { company_name: true });
    }
    if (!value?.f_name) {
      Object.assign(errorObj, { first_name: true });
    }
    if (!value?.l_name) {
      Object.assign(errorObj, { last_name: true });
    }
    if (value?.company_type.length === 0) {
      Object.assign(errorObj, { company_type: true });
    }
    if (!value?.pincode || error.pincodeMessage || value.pincode.length < 6 ) {
      Object.assign(errorObj, { pincode: true });
    }
    if (value?.pincode && address?.status === 400 && address?.message) {
      Object.assign(errorObj, { pincodeMessage: address?.message });
    }
    if (value?.email && !REGEX_STRINGS.EMAIL.test(value?.email)) {
      Object.assign(errorObj, { errorMessage: "Email address must be contain @, ., and domain name" });
    }
    if (Object.keys(errorObj)?.length) {
      setError(errorObj);
    } else {
      const paylod = {
        companyProfileImages: imgFile || "",
        companyData: JSON.stringify({
          name: value?.company_name?.trim(),
          phoneNo: location?.phoneNo?.trim(),
          firstName: value?.f_name?.trim(),
          lastName: value?.l_name?.trim(),
          companyType: value?.company_type,
          email: value?.email?.trim(),
          pincode: value?.pincode,
          city: address?.city || "",
          district: address?.district || "",
          state: address?.state || "",
          country: address?.country || "",
        }),
      };

      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/createProfile`, paylod, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((result) => {
        if (result.status === 200) {
          localStorage.setItem("user", JSON.stringify(result.data.data.user));
          localStorage.setItem("companies", JSON.stringify(result.data.data.company));
          localStorage.setItem("createNewCompany", JSON.stringify(false));
          localStorage.setItem("token", result.data.token);
          showMessage("success", result?.data.message || "Profile created successfully");
          navigate("/");
        }
      }).catch((error) => showMessage("error", error?.response?.data?.message || "Something went wrong"));
    }
  };

  const handleLogout = () => {
    dispatch(clearAddressData());
    localStorage.clear();
    navigate("/login");
  };

  const getBusinessType = async () => {
    const data: any[] = [];
    await axiosInstance.get("/auth/getBusinessTypes").then((result) => {
      if (result.status === 200) {
        result?.data?.data?.map((item: { businessType: string, businessTypeId: string }) => (
          data.push({ value: item?.businessTypeId, label: item?.businessType })
        ));
        setCompanyTypeList(data);
      }
    }).catch((error) => console.log(error));
  };

  const handleCurrentCompany = () => {
    localStorage.setItem("createNewCompany", JSON.stringify(false));
    navigate("/");
  };

  const handleBack = () => {
    if (companyData) {
      if (Object.entries(Company)?.length) {
        handleCurrentCompany();
      } else {
        localStorage?.removeItem("token");
        localStorage?.removeItem("companies");
        localStorage?.removeItem("createNewCompany");
        navigate("/login", { state: { step: 2 }, replace: true });
      }
    } else {
      navigate("/login", { replace: true });
      if(Object.entries(Company)?.length){
        handleCurrentCompany();
      }else {
        localStorage?.removeItem("token");
        localStorage?.removeItem("companies");
        localStorage?.removeItem("createNewCompany");
      }
    }
  };

  return (
    <Page title={[]} className="create-company-profile-page">
      {contextHolder}
      <div className="create-company-profile-card-outer">
        <div className="profile-card">
          <div className="card-header">
            <h2 className="title-2">Complete Your Profile</h2>
            <p className="caption">Fill out your details to enhance your experience more effectively.</p>
          </div>
          <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
            <div className="company-profile-section">
              <h3 className="title-3">Company info</h3>
              <p className="caption">Update your photo and personal details here.</p>
              <div className="image-upload-input">
                <FileUpload getFile={setImgFile} />
              </div>
              <div className="inputs-container">
                <InputField
                  name="company_name"
                  label="Company Name"
                  type="text"
                  placeholder="Company name"
                  required
                  onChange={handleChange}
                  className={error?.company_name ? "error" : null}
                />
                <SelectInput
                  className="company-type-input"
                  mode="multiple"
                  name="company_type"
                  label="Company Type"
                  placeholder="Please Select"
                  required
                  options={companyTypeList}
                  status={error?.company_type ? "error" : null}
                  onChange={handleSelect}
                  maxTagCount="responsive"
                />
              </div>
              <div className="inputs-container">
                <InputField
                  value={value?.pincode}
                  name="pincode"
                  label="Pincode"
                  type="text"
                  placeholder="Pincode"
                  required
                  onChange={handleChange}
                  maxLength={6}
                  className={(error?.pincode || error?.pincodeMessage) ? "error" : null}
                  errorMessage={error?.pincodeMessage}
                />
                <InputField
                  name="location"
                  label="Location"
                  type="text"
                  placeholder="Location"
                  value={(address?.district && address?.state) ? (address?.district + ", " + address?.state) : ""}
                  disabled
                  className={error?.location ? "error" : null}
                />
              </div>
            </div>
            {isShowProfileInfo ? <div className="user-profile-section">
              <h3 className="title-3">Personal Info</h3>
              <p className="caption">Update your photo and personal details here.</p>
              <div className="inputs-container">
                <InputField
                  label="First Name"
                  name="f_name"
                  type="text"
                  placeholder="First name"
                  required
                  onChange={handleChange}
                  className={error?.first_name ? "error" : null}
                />
                <InputField
                  label="Last Name"
                  name="l_name"
                  type="text"
                  placeholder="Last name"
                  required
                  onChange={handleChange}
                  className={error?.last_name ? "error" : null}
                />
              </div>
              <div className="inputs-container">
                <InputField
                  label="Email Address"
                  name="email"
                  type="email"
                  placeholder="Enter your email address"
                  onChange={handleChange}
                  className={error?.errorMessage ? "error" : null}
                  errorMessage={error?.errorMessage}
                />
              </div>
            </div> : <></>}
            <div className="flex alignCenter justifyBetween">
              {(isNewCompany && Object.entries(Company)?.length === 0) ? <Button type="button" className="text red logout-button" onClick={() => setIsConfirmToggle(true)}>Logout</Button>: <div></div>}
              <div className="flex gap-3">
                {!isShowProfileInfo ? <Button type="button" className="secondary large-button" onClick={handleBack}>Cancel</Button> : <></>}
                <Button type="button" className="primary large-button" onClick={handleSubmit}>Save</Button>
              </div>
            </div>
          </form>
        </div>
        <ConfirmationPopup
          open={isConfirmToggle}
          close={() => setIsConfirmToggle(false)}
          confirm={() => handleLogout()}
          infoContent="Are you sure, you want to Logout?"
          cancelButtonText="No"
          confirmButtonText="Yes, Logout"
        />
      </div>
    </Page >
  );
};

export default CreateCompany;