import React from "react";

const IndiaFlagIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path d="M15.3017 0H1.69828C1.03647 0 0.5 0.818821 0.5 1.82896V5.33333H16.5V1.82896C16.5 0.818821 15.9635 0 15.3017 0Z" fill="#FAB446" fillOpacity="0.88" />
      <path d="M0.5 14.1709C0.5 15.181 1.03647 15.9998 1.69828 15.9998H15.3017C15.9635 15.9998 16.5 15.181 16.5 14.1709V10.6665H0.5V14.1709Z" fill="#73AF00" />
      <path d="M0.5 5.3335H16.5V10.6665H0.5V5.3335Z" fill="#F5F5F5" />
      <path d="M8.49999 10.2631C7.68237 10.2631 7.01724 9.24792 7.01724 7.99996C7.01724 6.75201 7.68237 5.73682 8.49999 5.73682C9.31762 5.73682 9.98274 6.75201 9.98274 7.99996C9.98274 9.24792 9.31762 10.2631 8.49999 10.2631ZM8.49999 6.15784C7.83443 6.15784 7.29309 6.9841 7.29309 7.99996C7.29309 9.01582 7.83443 9.84208 8.49999 9.84208C9.16555 9.84208 9.7069 9.01582 9.7069 7.99996C9.7069 6.9841 9.16555 6.15784 8.49999 6.15784Z" fill="#41479B" />
      <path d="M8.5 8.25678C8.59284 8.25678 8.6681 8.14191 8.6681 8.00022C8.6681 7.85852 8.59284 7.74365 8.5 7.74365C8.40717 7.74365 8.33191 7.85852 8.33191 8.00022C8.33191 8.14191 8.40717 8.25678 8.5 8.25678Z" fill="#41479B" />
      <path d="M8.49999 8.03831L8.07281 8.10414L7.15518 8.03831V7.96133L8.07281 7.89551L8.49999 7.96133V8.03831ZM8.49999 8.03831L8.92718 8.10414L9.84481 8.03831V7.96133L8.92718 7.89551L8.49999 7.96133V8.03831Z" fill="#41479B" />
      <path d="M8.4748 7.99988L8.43167 7.34785L8.4748 5.94727H8.52523L8.56836 7.34785L8.52523 7.99988H8.4748ZM8.4748 7.99988L8.43167 8.6519L8.4748 10.0525H8.52523L8.56836 8.6519L8.52523 7.99988H8.4748Z" fill="#41479B" />
      <path d="M8.48219 8.02729L8.14962 7.61285L7.53125 6.57591L7.56691 6.52148L8.24628 7.46532L8.51781 7.97292L8.48219 8.02729Z" fill="#41479B" />
      <path d="M8.48218 8.02703L8.75371 8.53463L9.43308 9.47846L9.46874 9.42404L8.85037 8.3871L8.5178 7.97266L8.48218 8.02703Z" fill="#41479B" />
      <path d="M8.48218 7.97292L8.75371 7.46532L9.43308 6.52148L9.46874 6.57591L8.85037 7.61285L8.5178 8.02729L8.48218 7.97292Z" fill="#41479B" />
      <path d="M8.48219 7.97266L8.14962 8.3871L7.53125 9.42404L7.56691 9.47846L8.24628 8.53463L8.51781 8.02703L8.48219 7.97266Z" fill="#41479B" />
      <path d="M8.50964 8.03552L8.13146 8.34584L7.26721 8.821L7.24789 8.74988L8.07918 8.15309L8.49033 7.96436L8.50964 8.03552Z" fill="#41479B" />
      <path d="M8.50967 8.03536L8.92083 7.84662L9.75211 7.24983L9.73279 7.17871L8.86854 7.65387L8.49036 7.96419L8.50967 8.03536Z" fill="#41479B" />
      <path d="M8.47669 8.0147L8.27338 7.43746L7.96206 6.11834L8.00866 6.08887L8.39966 7.35767L8.52331 7.98522L8.47669 8.0147Z" fill="#41479B" />
      <path d="M8.47668 8.01483L8.60034 8.64238L8.99134 9.91118L9.03793 9.88171L8.72662 8.56259L8.52331 7.98535L8.47668 8.01483Z" fill="#41479B" />
      <path d="M8.49033 8.03536L8.07918 7.84662L7.24789 7.24983L7.26721 7.17871L8.13146 7.65387L8.50964 7.96419L8.49033 8.03536ZM8.49033 8.03536L8.86852 8.34568L9.73277 8.82084L9.75208 8.74972L8.9208 8.15293L8.50964 7.96419L8.49033 8.03536Z" fill="#41479B" />
      <path d="M8.47669 7.98522L8.60034 7.35767L8.99134 6.08887L9.03794 6.11834L8.72663 7.43746L8.52331 8.0147L8.47669 7.98522ZM8.47669 7.98522L8.27338 8.56245L7.96206 9.88157L8.00866 9.91105L8.39966 8.64225L8.52331 8.0147L8.47669 7.98522Z" fill="#41479B" />
      <path d="M8.5 8.34599C8.62524 8.34599 8.72676 8.19104 8.72676 7.9999C8.72676 7.80876 8.62524 7.65381 8.5 7.65381C8.37477 7.65381 8.27325 7.80876 8.27325 7.9999C8.27325 8.19104 8.37477 8.34599 8.5 8.34599Z" fill="#F5F5F5" />
      <path d="M8.50001 8.20754C8.57511 8.20754 8.63598 8.11463 8.63598 8.00001C8.63598 7.8854 8.57511 7.79248 8.50001 7.79248C8.42492 7.79248 8.36404 7.8854 8.36404 8.00001C8.36404 8.11463 8.42492 8.20754 8.50001 8.20754Z" fill="#41479B" />
    </svg>
  );
};

export default IndiaFlagIcon;