import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import { useNavigate } from "react-router-dom";

import CommonModal from "../../components/commonModal";
import { CrossIcon } from "../../assets/images";
import { getCompanyByPhoneNumber, getConnectionList, takeActionOnConnectionRequest } from "../../redux/services/connection";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { AddVendor, getVendorList } from "../../redux/services/vendor";
import NoDataFound from "../../components/NoDataFound";
import InviteModal from "./inviteModal";

interface Props {
  open: boolean;
  close: () => void;
  data: any;
}

const ChooseVendors = ({ open, close, data }: Props) => {
  const [selectedVendors, setSelectedVendors] = useState<number[]>([]);
  const [error, setError] = useState<any>({});
  const [connectionList, setConnectionList] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const { connection } = useAppSelector(state => state?.connections);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const payload = {
      search: "",
      sort: "ASC",
      type: "All",
      limit: 0,
      offset: 0,
    };
    dispatch(getConnectionList(payload)).then((result) => {
      if (result.payload?.data) {
        setConnectionList(result?.payload?.data);
      }
    });
  }, [dispatch, data]);

  const onChange = (id: number, checked: boolean) => {
    setError({});
    if (checked) {
      setSelectedVendors((prev) => [...prev, id]);
    } else {
      setSelectedVendors((prev) => prev.filter((vendorId) => vendorId !== id));
    }
  };

  const ClearAll = () => {
    setSelectedVendors([]);
  };

  const handleSave = async () => {
    if (selectedVendors?.length) {
      const payload = {
        selectedVendors: selectedVendors,
      };
      await dispatch(AddVendor(payload));
      await dispatch(getVendorList());
      navigate("/vendors");
      close();
    } else {
      setError({ message: "Please Select Vendor !!!" });
    }
  };



  return (
    <CommonModal onSave={handleSave} close={close} open={open} footer={(connectionList?.connections?.length === connectionList?.connections?.filter((data: any) => data?.isVendor)?.length) ? false : true} saveButtonText="Confirm" cancelButtonText="Cancel">
      <div className="vendors-modal-container">
        <header className="vendors-header-container flex justifyBetween alignCenter">
          <div className="flex direction-column">
            {isOpen ? <InviteModal setVisible={setIsOpen} visible={isOpen} /> : <></>}
            <h2 className="choose-vendor-header">Choose Vendor</h2>
            <p className="user-connections">{connection.total} Connections</p>
          </div>
          <div className="flex gap-3">
            <button type="button" className="rounded-8 primary invite-button" onClick={() => setIsOpen(true)}>
              <PlusOutlined />Invite Vendor
            </button>
            <button onClick={close} type="button" className="close-icon-button flex alignCenter justifyCenter">
              <CrossIcon width={24} height={24} />
            </button>
          </div>
        </header>
        {selectedVendors?.length ? <div className="button-container">
          <button className="clear-all-button primary" onClick={ClearAll}>
            Clear All
          </button>
        </div> : ""}
        {error?.message ? <p className="error-message">{error?.message}</p> : ""}
        {connectionList?.total ? connectionList?.connections?.map((item: any, index: number) => (
          !item.isVendor ? <label className="connection-list-row flex alignCenter justifyBetween" key={item?.companyId}>
            <div className="user-data flex alignCenter gap-2">
              <div className="user-profile-icon">
                {item?.profileThumbnailImg ? <img src={item?.profileThumbnailImg} alt="profile-img" /> : <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" fill="none">
                  <path d="M5.45671 6.36589C5.45671 3.31039 7.94287 0.824219 10.9984 0.824219C14.0539 0.824219 16.54 3.31039 16.54 6.36589C16.54 9.42139 14.0539 11.9076 10.9984 11.9076C7.94287 11.9076 5.45671 9.42139 5.45671 6.36589ZM14.4984 13.6576H7.49837C3.80004 13.6576 0.790039 16.6676 0.790039 20.3659C0.790657 21.2166 1.12888 22.0323 1.73042 22.6338C2.33197 23.2354 3.14766 23.5736 3.99837 23.5742H17.9984C18.8491 23.5736 19.6648 23.2354 20.2663 22.6338C20.8679 22.0323 21.2061 21.2166 21.2067 20.3659C21.2067 16.6676 18.1967 13.6576 14.4984 13.6576Z" fill="black" fillOpacity="0.15"/>
                </svg>}
              </div>
              <div className="user-details">
                <h4 className="company-name">{item?.name}</h4>
                <p className="caption">{item?.createdBy?.firstName + " " + item?.createdBy?.lastName}</p>
              </div>
            </div>
            <Checkbox checked={selectedVendors.includes(item?.companyId)} onChange={(e) => onChange(item?.companyId, e.target.checked)} />
          </label> : (connectionList?.connections?.length === connectionList?.connections?.filter((data: any) => data?.isVendor)?.length) ? (index === 0 ? (<NoDataFound text="No More Connection Data Found" />) : "") : ""
        )) : <NoDataFound />}
      </div>
    </CommonModal>
  );
};

export default ChooseVendors;