import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import AuthLayout from "../pages/auth/authLayout";
import Layout from "../pages/layout";
import PlaceOrder from "../pages/placeOrder";
import Home from "../pages/home";
import Login from "../pages/auth/login";
import Products from "../pages/products";
import AddProduct from "../pages/products/AddProduct";
import Connection from "../pages/connections";
import SelectVendors from "../pages/placeOrder/selectVendor";
import Orders from "../pages/orders";
import OrderDetails from "../pages/orders/orderDetails";
import SettingsPage from "../pages/settings";
import LandingPage from "../pages/auth/LandingPage";
import ProductDetails from "../pages/placeOrder/productDetails";


const Routing = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* Protected Routes */}
          <Route path="/" element={<Layout />}>
            <Route path="*" element={<Orders />} />
            <Route path="/" element={<Orders />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/:id" element={<Products />} />  {/* WHY ???????????????*/}
            <Route path="/products/add" element={<AddProduct />} />
            <Route path="/placeorder" element={<PlaceOrder />} />
            <Route path="/placeorder/:id" element={<ProductDetails />} />
            <Route path="/vendors" element={<SelectVendors />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/orders/customer-orders" element={<Orders />} />
            <Route path="/orders/my-orders" element={<Orders />} />
            <Route path="/orders/:id" element={<OrderDetails />} />
            <Route path="/settings/:id" element={<SettingsPage />} />

            {/* <Route path="/placeorder/:id" element={<PlaceOrder />} /> */}
            <Route path="/connection" element={<Connection />} />
            <Route path="/chat" element={<Home />} />
            {/* <Route path="/vendors" element={<Vendors />} /> */}
          </Route>
          {/* Public Routes */}
          <Route path="/" element={<AuthLayout />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route path="/home" element={<LandingPage />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default Routing;