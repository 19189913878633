import React from "react";
import { PlusOutlined } from "@ant-design/icons";

import Breadcrumbs from "../breadcrumb";
import CartIcon from "../../assets/images/cartIcon";
import { ProductsGroupIcon } from "../../assets/images";

interface IProps {
  children: React.ReactNode,
  title: Array<BreadcrumbItem>,
  header?: {
    pageTitle: string | React.ReactNode,
    profileIcon?: any,
    profileTitle?: string,
    buttons?: {
      addVendor?: boolean
      addProduct?: boolean
      saveProduct?: boolean
      cart?: boolean
      onClick?: () => void
      createGroup?: boolean
      onCreate?: () => void
    }
  },
  className?: string
}

interface BreadcrumbItem {
  title: string | React.ReactNode;
  href?: string;
}

interface ISubHeaderProps {
  props?: {
    pageTitle: string | React.ReactNode,
    profileIcon?: any,
    profileTitle?: string,
    buttons?: {
      addVendor?: boolean
      addProduct?: boolean
      saveProduct?: boolean
      cart?: boolean
      onClick?: () => void
      createGroup?: boolean
      onCreate?: () => void
    }
  }
}

const SubHeader = ({ props }: ISubHeaderProps) => {
  return (
    <div className="flex header-container alignCenter">
      <div className="flex profile-box">
        {props?.profileIcon && <img src={props.profileIcon} alt="profile-icon" />}
        {props?.profileTitle && <h3 className="profile-title">{props.profileTitle}</h3>}
        {props?.pageTitle && <h2>{props?.pageTitle}</h2>}
      </div>
      {
        props?.buttons?.addProduct &&
        <button type="button" className="rounded-8 primary large add-product" onClick={props.buttons.onClick}>
          <PlusOutlined /> Add Product
        </button> 
      }
      {
        props?.buttons?.addVendor &&
        <button type="button" className="rounded-8 primary large add-vender" onClick={props.buttons.onClick}>
          <PlusOutlined /> Add Vender
        </button> 
      }
      {
        props?.buttons?.createGroup &&
        <button type="button" className="rounded-8 large bg-light gap-1" onClick={props.buttons.onCreate}>
          <ProductsGroupIcon /> Create Group
        </button> 
      }
      {
        props?.buttons?.saveProduct &&
        <button type="button" className="rounded-8 primary large" onClick={props.buttons.onClick}>
          Save Product
        </button> 
      }
      {props?.buttons?.cart &&
        <button type="button" className="rounded-8 primary large flex gap-2 direction-row">
          <CartIcon />
          <span className="flex direction-column cart-text">
            2 items
            <br />
            8444
          </span>
        </button>
      }
    </div>
  );
};

const Page = ({ children, title, header, className }: IProps) => {
  return (
    <div className={className ? className + " page" : className}>
      <div className="header-section flex direction-column gap-5">
        <Breadcrumbs items={title} />
        <SubHeader props={header} />
      </div>
      {children}
    </div>
  );
};

export default Page;