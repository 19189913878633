import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import { Popover } from "antd";

import { RootState } from "../../redux/store";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import CommonModal from "../../components/commonModal";
import { Logo } from "../../assets/images";
import { ProductSizes } from "../../types/productsType";
import { getListOfProductSizes } from "../../redux/services/products";
import { addToCart, getCartDataByVendorID, removeCartItem } from "../../redux/services/placeOrder";
import CounterButton from "../../components/counterButton";
import { DownArrow } from "../../assets/images/icons";
import { formatRupees } from "../../utils/helper";

interface Props {
  productInfo: {
    productImage: string,
    productName: string,
    productId: number,
    close: () => void
  };
  data?: CompressedItem,
  getSizes: (data: { quantity: number, productSizeId: number }[]) => void
}

interface CompressedItem {
  productId: number;
  productName: string;
  productImage: string;
  sizes: {
    quantity: number;
    productSizeId: number;
    productSize: string;
    productSizeUnit: string;
    productSizeOrderUnit: string;
    productBulkRates: any[];
  }[];
}

const ProductSizesModal = ({ productInfo, getSizes, data }: Props) => {
  const [sizes, setSizes] = useState<{ quantity: number, productSizeId: number }[]>([]);
  const [tempData, setTempData] = useState<any>([]);
  const [bulkRate, setBulkRate] = useState<any>([]);
  const [bulkRateData, setBulkRateData] = useState<any>([]);
  const [isToggleOpen, setIsToggleOpen] = useState({
    toggle: true,
    id: 0,
  });
  const location = useLocation()?.state;

  const dispatch = useAppDispatch();
  const { productSizes } = useAppSelector((state: RootState) => state.products);
  const { cart } = useAppSelector(state => state.placeorder);

  const payload: ProductSizes[] | null = productSizes?.length ? [...productSizes] : [];

  useEffect(() => {
    dispatch(getListOfProductSizes(productInfo.productId));
  }, [dispatch, productInfo.productId]);

  const newArr: any = [];

  useEffect(() => {
    payload?.map((size) => {
      newArr.push(
        size?.productBulkRates?.filter((item) => item.productQuantity && item.productRate && size.moq <= item.productQuantity).sort((a:any, b:any) => b.productQuantity - a.productQuantity),
      );
      setBulkRateData(newArr);
    });
  }, [productSizes]);

  useEffect(() => {
    if (payload) {
      const newArr = payload?.map((product) => {
        const tempData = data?.sizes?.find((item) => (item.productSizeId === product.productSizeId));
        return {
          quantity: tempData?.quantity || 0,
          productSizeId: product.productSizeId,
        };
      });
      setSizes(newArr);
      setTempData(newArr);
    }
  }, [productSizes, data]);

  function findIndexBetween(arr: any[], value: any) {
    // if (arr?.length === 0) return -1; // Handle empty array
  
    // // Handle case where the value is less than the first productQuantity
    // if (value < arr?.[0]?.productQuantity) return -1; // Return -1 or 0 based on your needs
  
    // // Handle case where the value is greater than the last productQuantity
    // if (value > arr?.[arr.length - 1]?.productQuantity) return arr.length - 1;
  
    // for (let i = 0; i < arr?.length - 1; i++) {
    //   if (value >= arr?.[i]?.productQuantity && value <= arr[i + 1]?.productQuantity) {
    //     return i; // Return the index where the value is between arr[i] and arr[i+1]
    //   }
    // }
    
    // return -1; // If not found, return -1


    // Find the applicable bulk rate
    let applicableRate = null;
    if (arr?.length) {
      for (const bulkRate of arr) {
        if (bulkRate.productQuantity !== 0) {
          const quantity = bulkRate.productQuantity ?? 0; // Default to 0 if undefined
          if (quantity <= value) {
            applicableRate = bulkRate;
            break;
          }
        } else {
          applicableRate = null;
          break;
        }
      }
      return applicableRate;
    }
  }


  const handleIncrement = (id: number, maxValue: number) => {
    setTempData(sizes.map(size =>
      size.productSizeId === id ? { ...size, quantity: (size.quantity >= maxValue ? size.quantity + 1 : maxValue) } : size,
    ));
    setSizes(sizes.map(size =>
      size.productSizeId === id ? { ...size, quantity: (size.quantity >= maxValue ? size.quantity + 1 : maxValue) } : size,
    ));
  };

  const handleDecrement = (id: number, maxValue: number) => {
    setTempData(sizes.map(size =>
      size.productSizeId === id ? { ...size, quantity: (size.quantity - 1 >= maxValue ? size.quantity - 1 : 0) } : size,
    ));
    setSizes(sizes.map(size =>
      size.productSizeId === id ? { ...size, quantity: (size.quantity - 1 >= maxValue ? size.quantity - 1 : 0) } : size,
    ));
  };

  const handleInputChange = (id: number, qty: string) => {
    const regex = /^\d{0,6}(\.\d{0,2})?$/;
    if (!qty) {
      setTempData(sizes ? sizes.map(size =>
        size.productSizeId === id && size.quantity >= 0 ? { ...size, quantity: "0" } : size,
      ) : sizes);
    } if (regex.test(qty)) {
      if (Number(qty) === 0) {
        setTempData(sizes ? sizes.map(size =>
          size.productSizeId === id && size.quantity >= 0 ? { ...size, quantity: "0" } : size,
        ) : sizes);
      }
      setTempData(sizes ? sizes.map(size =>
        size.productSizeId === id && size.quantity >= 0 ? { ...size, quantity: "0" } : size,
      ) : sizes);
      setSizes(sizes ? sizes.map(size =>
        size.productSizeId === id && size.quantity >= 0 ? { ...size, quantity: Number(qty) || 0 } : size,
      ) : sizes);
    }
  };

  function updateOrPush(array: any, newItem: any) {
    const index = array?.findIndex((item: any) => item.sizeId === newItem.sizeId);

    if (index !== -1 && array?.length) {
      // Update the existing item
      array[index] = newItem;
    } else {
      // Push the new item if sizeId not found
      array?.push(newItem);
    }
    setBulkRate(array);
    return array;
  }

  const handleSelectBulkRate = (sizeId: number, rate: any) => {
    setSizes(sizes ? sizes.map(size =>
      size.productSizeId === sizeId && size.quantity >= 0 ? { ...size, quantity: Number(rate?.productQuantity) || 0 } : size,
    ) : sizes);
    setTempData(sizes ? sizes.map(size =>
      size.productSizeId === sizeId && size.quantity >= 0 ? { ...size, quantity: Number(rate?.productQuantity) || 0 } : size,
    ) : sizes);
    updateOrPush(bulkRate, { sizeId: sizeId, rate: rate });
  };

  function getMatchingCartItemIds(abc: any, xyz: any) {
    const cartItemIds: any = [];

    xyz.forEach((xyzItem: any) => {
      const matchingItem = abc?.find((abcItem: any) => abcItem?.productSize?.productSizeId === xyzItem?.productSizeId);
        
      if (matchingItem) {
        cartItemIds.push(matchingItem?.cartItemId);
      }
    });

    return cartItemIds;
  }

  function calculatePrice(oldPrice: number, newPrice?: number) {
    const price = oldPrice ?? 0; // Original price
    const percentage = newPrice ?? 0; // Percentage to subtract

    // Calculate the amount to subtract
    const amountToSubtract = (price * percentage) / 100;
    return formatRupees(newPrice ? price - amountToSubtract : oldPrice);
  }

  const handleSave = async () => {
    const filterdSizes = sizes.filter((data) => data.quantity > 0);
    const matchingCartItemIds = getMatchingCartItemIds(cart?.data?.cartItems, sizes.filter((data) => data.quantity === 0));

    if (filterdSizes.length) {
      const payload = {
        vendorId: location?.orderId,
        items: [{
          productId: productInfo.productId,
          productSizes: filterdSizes,
        }],
      };
      getSizes(filterdSizes);
      await dispatch(addToCart(payload));
      if (location?.orderId) {
        dispatch(getCartDataByVendorID({
          vendorId: location?.orderId,
        }));
      }
    }
    if (matchingCartItemIds?.length) {
      dispatch(removeCartItem({ cartId: cart?.data?.cartId || 0, cartItemIds: matchingCartItemIds })).then((result) => {
        if (result?.payload?.status === 200) {
          dispatch(getCartDataByVendorID({
            vendorId: location?.orderId,
          }));
        }
      });
    }
    productInfo.close();
  };

  return (
    <CommonModal
      footer
      cancelButtonText="Cancel"
      saveButtonText="Save"
      close={() => productInfo.close()}
      open={true}
      className="product-size-modal"
      closeIcon
      onSave={handleSave}
    >
      <div className="product-size-modal-container gap-5 flex direction-column">
        <div className="modal-header flex alignCenter">
          <div className="image-container flex justifyCenter alignCenter">
            {productInfo?.productImage ?
              <img src={productInfo?.productImage} alt={productInfo?.productName} />
              : <Logo />}
            {/* {true && <div className="offer">{100}<br /> off</div>} */}
          </div>
          <h2 className="product-name">{productInfo?.productName}</h2>
        </div>
        <div className="product-sizes-container flex direction-column">
          {payload?.map((data, index) => 
          {
            const activeBulkRate = (findIndexBetween(bulkRateData?.[index], sizes[index]?.quantity));
            return(
              <div key={data.productSizeId} className="size-box flex gap-5 justifyBetween alignCenter">
                <span className="size">{data.productSize} {data.productSizeUnit}</span>
                <div className="quantity-box flex">
                  <span className="price-text">{activeBulkRate?.isRateInRupees ? formatRupees(activeBulkRate?.productRate || data?.pricePerUnit) : calculatePrice(data?.pricePerUnit, activeBulkRate?.productRate)} <span className="unit-text">/ {data.productSizeOrderUnit}</span></span>
                  {bulkRateData?.[index]?.length ? (
                    <Popover
                      placement="bottom"
                      content={(
                        <div className="bulkrate-inner-container">
                          {bulkRateData?.[index]?.map((rate: any, ind: any) => {
                            return (
                              <label
                                key={rate.productBulkRateId}
                                className={`bulkrate-box flex justifyBetween ${0 >= rate?.productQuantity && "selected"}`}
                                onClick={() => handleSelectBulkRate(data?.productSizeId, rate)}
                              >
                                {rate?.productQuantity} {data?.alternateUnitPrimaryUnit || data?.productSizeOrderUnit} = {rate?.isRateInRupees ? formatRupees(rate?.productRate) : calculatePrice(data?.pricePerUnit, rate?.productRate)} / per {data?.alternateUnitSecondaryUnit || data?.productSizeOrderUnit}
                                {activeBulkRate?.productBulkRateId && activeBulkRate?.productQuantity === rate?.productQuantity && <CheckOutlined className="selected-icon" />}
                              </label>
                            );
                          })}
                        </div>
                      )}
                      trigger="click"
                      rootClassName="bulkrate-list-popover-container"
                    >
                      <button
                        className="bulkrate-button flex alignCenter">
                      Bulk Rate <DownArrow />
                      </button>
                    </Popover>
                  ) : <></>}
                </div>
                {data?.isActive ? (!tempData[index]?.quantity) ?
                  (<button className="add-size-button" onClick={() => handleIncrement(data.productSizeId, data.moq)}>Add</button>) :
                  (<div className="counter-button-container flex alignStart">
                    {<CounterButton
                      handleDecrement={() => handleDecrement(data.productSizeId, data.moq)}
                      handleIncrement={() => handleIncrement(data.productSizeId, data.moq)}
                      value={sizes[index]?.quantity || 0}
                      handleInputChange={(e) => handleInputChange(data.productSizeId, e.target.value)}
                      onBlur={() => {
                        setSizes(sizes ? sizes.map(size =>
                          size.productSizeId === data.productSizeId && size?.quantity >= 0 ? { ...size, quantity: (size?.quantity > 0 && size?.quantity <= data.moq) ? data.moq : size?.quantity } : size,
                        ) : sizes);
                        setTempData(sizes ? sizes.map(size =>
                          size.productSizeId === data.productSizeId && size?.quantity >= 0 ? { ...size, quantity: (size?.quantity > 0 && size?.quantity <= data.moq) ? data.moq : size?.quantity === 0 ? "" : size?.quantity } : size,
                        ) : sizes);
                      }}
                      moq={data?.moq}
                      setIsToggleOpen={setIsToggleOpen}
                      isToggleOpen={isToggleOpen}
                      sizeId={sizes[index]?.productSizeId}
                    />}
                  </div>) : <div className="counter-button-container"><div className="unavailable-size"><span className="badge">Currently Unavailable</span></div></div>}
              </div>
            );})}
        </div>
      </div>
    </CommonModal>
  );
};

export default ProductSizesModal;