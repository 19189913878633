import React, { useEffect } from "react";
import { message, notification } from "antd";
import { useNavigate } from "react-router-dom";

import socket from "../../socket";
import { PushNotificationCard } from "../notification/notificationCard";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { notificationOrderData } from "../../redux/slices/notificationSlice";
import { NotificationData, NotificationTypes } from "../notification/NotificationTypes";
import { getConnectionList, getConnectionRequests, takeActionOnConnectionRequest } from "../../redux/services/connection";
import { companyId } from "../../utils/handleLocalStorage";

const PushNotification = () => {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const closePushNotification = () => {
    api.destroy();
  };

  const ListOfAllConnection = async () => {
    const payload = {
      search: "",
      sort: "ASC",
      type: "All",
      limit: 0,
      offset: 0,
    };
    await dispatch(getConnectionList(payload));
  };

  const getRecievedRequest = async () => {
    const payload = {
      search: "",
      limit: 0,
      offset: 0,
    };
    await dispatch(getConnectionRequests(payload));
  };

  const handleAction = async (action: string, type: NotificationTypes, notification: NotificationData) => {
    if (type === NotificationTypes.ORDER_PLACED_BY_CUSTOMER) {
      dispatch(notificationOrderData(notification));
      navigate("/");
      closePushNotification();
    } else if (type === NotificationTypes.CONNECTION_REQUEST_RECEIVED) {
      const payload = {
        requestId: notification.actionParams.requestId,
        action: action,
      };
      await dispatch(takeActionOnConnectionRequest(payload)).then((result) => {
        socket.emit("newNotificationCount", companyId);
        socket.emit("allNotificationCount", companyId);
        socket.emit("notificationHistory", companyId);
        if (result.payload.status === 200) {
          closePushNotification();
          ListOfAllConnection();
          getRecievedRequest();
          if(action === "Accept") {
            navigate("/connection", { state: { isSelectedConnection: true } });
          }
        } else {
          // showMessage("error", result?.payload.message || "Something went wrong");
          message.error(result?.payload.message || "Something went wrong");
        }
      });

    }
  };

  useEffect(() => {
    const companiesData = localStorage.getItem("companies");
    const companyId = companiesData ? JSON.parse(companiesData || "[]")?.[0]?.companyId ? JSON.parse(companiesData || "[]")?.[0]?.companyId : JSON.parse(companiesData || "{}")?.companyId : 0;

    const handleNotification = (data: any) => {
      socket.emit("notificationHistory", companyId);
      socket.emit("allNotificationCount", companyId);
      socket.emit("newNotificationCount", companyId);

      api.open({
        message: "",
        className: "push-notification",
        description: (
          <div className="push-notification-container">
            {/* {renderNotificationCard(data, closePushNotification)} */}
            {PushNotificationCard({ notification: data, handleAction: handleAction})}
          </div>
        ),
        placement: "topRight",
        duration: 5,
      });
    };

    const notificationEvent = companyId ? `notification_${companyId}` : "notification";
    socket.on(notificationEvent, handleNotification);

  }, [socket]);

  return (
    <div>{contextHolder}</div>
  );
};

export default PushNotification;