import React, { useState } from "react";
import { Popover } from "antd";
import { InfoCircleOutlined, RightOutlined } from "@ant-design/icons";

import { CheckedRounedOutlineIcon, ClockIcon, TimerIcon } from "../../assets/images/icons";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { updateStatusByOrderId } from "../../redux/services/orders";

interface StatusSwitchProps {
  status: string;
  orderId: number;
  onDispatch?: () => void;
  activeTab?: string;
  disabled?: boolean; // Optional prop with default value
}

const StatusSwitch = ({ status, orderId, onDispatch, activeTab, disabled = false }: StatusSwitchProps) => {
  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();

  const changeOrderStatus = (nextStatus: string) => {
    if (nextStatus === "DISPATCHED") {
      setOpen(false);
      onDispatch && onDispatch();
    } else {
      dispatch(updateStatusByOrderId({ orderId: orderId, action: nextStatus }));
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const content = () => (
    <div className="status-popover-content">
      {(status === "Accepted" || status === "inProgress") && (activeTab === "Customer-order") &&
        <div className={`status-box in-progress ${status === "inProgress" && "active"}`}>
          <button onClick={() => status === "inProgress" ? [] : changeOrderStatus("IN_PROGRESS")}><ClockIcon /> In Process</button>
        </div>}

      {status === "inProgress" && (activeTab === "Customer-order") &&
        <>
          {/* <div className="status-box partially-dispatch">
            <button onClick={() => changeOrderStatus("Partially Dispatched")}><CheckedRounedOutlineIcon /> Partially Dispatched</button>
          </div> */}
          <div className="status-box dispatch">
            <button onClick={() => changeOrderStatus("DISPATCHED")}><CheckedRounedOutlineIcon /> Dispached</button>
          </div>
        </>}

      {/* {status === "Partially Dispatched" && (activeTab === "My-order") &&
        <div className="status-box partially-received">
          <button onClick={() => changeOrderStatus("Partially Received")}><CheckedRounedOutlineIcon /> Partially Received</button>
        </div>} */}

      {status === "Dispatched" && (activeTab === "My-order") &&
        <div className="status-box received">
          <button onClick={() => changeOrderStatus("COMPLETED")}><CheckedRounedOutlineIcon /> Received</button>
        </div>}

      {status === "Pending" &&
        <div className="status-box cancel-order">
          <button onClick={() => changeOrderStatus("CANCELED")}><InfoCircleOutlined /> Cancel Order</button>
        </div>}
    </div>
  );

  return (
    <div className="status-switch-wrapper">
      {status === "Accepted" ?
        <div className={activeTab === "Customer-order" ? "switch accepted" : "switch accepted outlined"}>
          <Popover content={content} trigger={!disabled && activeTab === "Customer-order" ? "click" : "contextMenu"} placement="bottomLeft" rootClassName="status-popover">
            <button className={activeTab === "Customer-order" ? "accepted" : "accepted outlined"}>Accepted</button>
          </Popover>
          {activeTab === "Customer-order" ? <button className="arrow-down-button" onClick={() => activeTab === "Customer-order" ? changeOrderStatus("IN_PROGRESS") : []}>
            {activeTab === "Customer-order" ? <RightOutlined /> : <div className="my-order-accepted"><CheckedRounedOutlineIcon /></div>}
          </button> : <></>}
        </div> : <></>}

      {(status === "In Progress" || status === "inProgress") ?
        <div className="switch inProgress">
          <Popover open={open} onOpenChange={handleOpenChange} content={content} trigger={!disabled && activeTab === "Customer-order" ? "click" : "contextMenu"} placement="bottomLeft" rootClassName="status-popover">
            <button className={activeTab === "Customer-order" ? "inProgress" : "inProgress outlined"}>In Process</button>
          </Popover>
          <button className="arrow-down-button" onClick={() => !disabled && activeTab === "Customer-order" ? changeOrderStatus("DISPATCHED") : []}><TimerIcon /></button>
        </div> : <></>}

      {status === "Dispatched" ?
        <div className="switch dispatched">
          <Popover content={content} trigger={!disabled && activeTab === "My-order" ? "click" : "contextMenu"} placement="bottomLeft" rootClassName="status-popover">
            <button className="outlined">Dispatched</button>
          </Popover>
          {/* <button className="arrow-down-button"><CheckedRounedOutlineIcon /></button> */}
        </div> : <></>}

      {status === "Rejected" ?
        <div className="switch rejected">
          <button className="rejected outlined">Rejected</button>
          {/* <button className="arrow-down-button"><InfoCircleOutlined /></button> */}
        </div> : <></>}
      {status === "Completed" ?
        <div className={activeTab === "Customer-order" ? "switch completed outlined" : "switch completed"}>
          <Popover content={content} trigger={!disabled && activeTab === "Customer-order" ? "click" : "contextMenu"} placement="bottomLeft" rootClassName="status-popover">
            <button className={activeTab === "Customer-order" ? "completed" : "completed"}>{activeTab === "Customer-order" ? "Delivered" : "Received"}</button>
          </Popover>
          {/* <button className="arrow-down-button"><CheckedRounedOutlineIcon /></button> */}
        </div> : <></>}

      {status === "Pending" ?
        <div className="switch pending">
          <Popover content={content} trigger={!disabled ? "click" : "contextMenu"} placement="bottomLeft" rootClassName="status-popover">
            <button className="pending">Pending</button>
          </Popover>
          <button className="arrow-down-button"><ClockIcon /></button>
        </div> : <></>}

      {status === "Partially Dispatched" ?
        <Popover content={content} trigger={!disabled ? "click" : "contextMenu"} placement="bottomLeft" rootClassName="status-popover">
          <div className="switch partially-dispatched">
            <button className="pending">Partially Dispatched</button>
            <button className="arrow-down-button"><RightOutlined /></button>
          </div>
        </Popover>
        : <></>}

      {status === "Canceled" ?
        <div className="switch canceled">
          <button className="canceled outlined">Cancelled</button>
          {/* <button className="arrow-down-button"><InfoCircleOutlined /></button> */}
        </div> : <></>}
    </div>
  );
};

export default StatusSwitch;