import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

import endPoins from "./endPoints.json";
import axiosInstance from "../axios";

export const getProductListByVendorId = createAsyncThunk("product/byVendorId", async (args: {
  search: string,
  groupId: number,
  vendorId: number,
  limit: number,
  offset: number
}, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.getProductsList, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const addToCart = createAsyncThunk("cart/addToCart", async (args: {
  vendorId: number,
  items: {
    productId: number;
    productSizes: {
      productSizeId: number;
      quantity: number;
    }[]
  }[]
}, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.addToCart, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getCartDataByVendorID = createAsyncThunk("cart/getData", async (args: { vendorId: number }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.getCartDataByVendor, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const removeCartItem = createAsyncThunk("cart/itemRemove", async (args: { cartId: number, cartItemIds: number[] }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.removeCartItem, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const clearCartItem = createAsyncThunk("cart/clear", async (args: { cartId: number }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.clearCart, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const placeOrder = createAsyncThunk("order/placeOrder",
  async (
    args: {
      placeOrderDetails: {
        cartId: number,
        deliveryAddressId?: number,
        message: string,
      },
      orderAttachments: any,
    }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      args.orderAttachments.forEach((file: string | Blob) => {
        formData.append("orderAttachments", file);
      });
      formData.append("placeOrderDetails", JSON.stringify(args.placeOrderDetails));

      const response = await axiosInstance.post(endPoins.placeOrder, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        message.error(error?.response?.data?.message || error.message);
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  });

export const recentOrders = createAsyncThunk("order/recentOrders", async (args: number, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.recentOrders, {
      limit: 6,
      vendorId: args,
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const reOrderByOrderId = createAsyncThunk("order/re_order", async (args: number, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`${endPoins.reOrderByRecentOrderId}/${args}`);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getAllSizesWithSearch = createAsyncThunk(endPoins.getAllSizesWithSearch, async (args: { productId: number, search: string, limit: number }, { rejectWithValue }) => {
  try {
    const payload = {
      "search": args.search,
      "limit": args.limit,
    };
    const response = await axiosInstance.post(`${endPoins.getAllSizesWithSearch}/${args.productId}`, payload);
    return response.data.data.productSizes;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});