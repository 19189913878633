import React from "react";
import { Tooltip } from "antd";

import { MinusIcon, PlusIcons } from "../../assets/images";

interface Props {
  handleDecrement: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleIncrement: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onBlur: () => void;
  value: number;
  isTooltip?: boolean;
  onFocus?: () => void;
  moq?: number;
  setIsToggle?: any;
  productId?: number;
  setIsToggleOpen?: any;
  sizeId?: number;
  isToggleOpen?: any;
}

const CounterButton = ({ handleDecrement, handleInputChange, handleIncrement, onBlur, value, isTooltip = false, onFocus, moq, setIsToggle, productId, setIsToggleOpen, sizeId, isToggleOpen }: Props) => {
  return (
    <div className="counter-button flex alignStart">
      <Tooltip
        open={moq === value && (isTooltip || (isToggleOpen?.toggle && isToggleOpen?.id === sizeId))}
        title={`Minimum Order Quantity ${moq}`}
      >
        <button
          className="minus flex justifyCenter alignCenter"
          onClick={(e) => handleDecrement(e)}
          onMouseEnter={() => { moq === value && ((setIsToggle && setIsToggle(productId)) || (setIsToggleOpen && setIsToggleOpen({ toggle: true, id: sizeId }))); }}
          onMouseLeave={() => (setIsToggle && setIsToggle(0)) || (setIsToggleOpen && setIsToggleOpen({ toggle: false, id: sizeId }))}
        >
          <MinusIcon />
        </button>
      </Tooltip>
      <input
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
        type="text"
        pattern="\d*"
        value={value}
        maxLength={5}
        onBlur={onBlur}
        onFocus={onFocus}
        onMouseEnter={() => { moq === value && ((setIsToggle && setIsToggle(productId)) || (setIsToggleOpen && setIsToggleOpen({ toggle: true, id: sizeId }))); }}
        onMouseLeave={() => (setIsToggle && setIsToggle(0)) || (setIsToggleOpen && setIsToggleOpen({ toggle: false, id: sizeId }))}
      />
      <button
        onClick={(e) => handleIncrement(e)}
        className="plus flex justifyCenter alignCenter"
      >
        <PlusIcons />
      </button>
    </div>
  );
};

export default CounterButton;