import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Popover } from "antd";
import { PlusCircleOutlined, LogoutOutlined } from "@ant-design/icons";

import { getAllCompany } from "../../redux/services/auth";
import { Button } from "../button";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getProfileIcon } from "../../utils/helper";
import socket from "../../socket";
import { handleNotificationDrawer } from "../../redux/slices/notificationSlice";
import { useAppSelector } from "../../hooks/useAppSelector";
import { NotificationData } from "../notification/NotificationTypes";
import { DownArrow, UserIcon, SettingOutlinedFilledIcon } from "../../assets/images/icons";
import CompanyListAndSetting from "../companyListAndSetting";
import ConfirmationPopup from "../actionModals/confirmationPopup";
import PushNotification from "../pushNotification";
import { BellOutlinedIcon, SquareLogo } from "../../assets/images";
import "../../assets/styles/common.scss";

export const Header = () => {
  const [isNotification, setNotification] = useState(false);
  const [isToggleCompany, setToggleCompany] = useState(false);
  const [isConfirmToggle, setIsConfirmToggle] = useState(false);
  const [isToggleProfile, setToggleProfile] = useState(false);
  const [isOpenPopover, setIsOpenPopover] = useState({
    isSetting: false,
  });
  const [company, setCompany] = useState<any>({});

  const [notificationCount, setNotificationCount] = useState({
    newNotifications: "",
    totalNotifications: "",
  });
  const [navItems, setNavItems] = useState([
    // {
    //   icon: "",
    //   text: "Home",
    //   url: "/",
    //   checked: true,
    //   isDisable: true,
    // },
    {
      icon: "",
      text: "Orders",
      url: "/",
      checked: true,
      isDisable: true,
    },
    {
      icon: "",
      text: "Products",
      url: "/products",
      checked: true,
      isDisable: true,
    },
    {
      icon: "",
      text: "Connections",
      url: "/connection",
      checked: true,
    },
    // {
    //   icon: "",
    //   text: "Analytics",
    //   url: "/analytics",
    //   checked: true,
    // },
    // {
    //   icon: "",
    //   text: "Chat",
    //   url: "/chat",
    //   checked: true,
    //   isDisable: true,
    // },
    // {
    //   icon: "",
    //   text: "Discount & Offers",
    //   url: "/discount&offers",
    //   checked: true,
    // },
  ]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { companyProfile } = useAppSelector(state => state.company);

  const companiesData = localStorage.getItem("companies");
  const userData = localStorage.getItem("user");
  const createCompany = localStorage.getItem("createNewCompany");

  const companyId = companiesData ? JSON.parse(companiesData || "[]")?.[0]?.companyId ? JSON.parse(companiesData || "[]")?.[0]?.companyId : JSON.parse(companiesData || "{}")?.companyId : 0;
  const ProfileData = userData ? JSON.parse(userData || "") : {};
  
  const { isNotificationDrawerOpen } = useAppSelector((state) => state.notification);

  useEffect(() => {
    if (companyProfile.data) {
      setCompany(companyProfile.data);
    } else {
      setCompany(JSON.parse(companiesData || ""));
    }
  }, [companyProfile, companiesData]);

  useEffect(() => {
    if (companiesData?.length && !JSON.parse(createCompany || "false")) {
      dispatch(getAllCompany());
    }
  }, [companiesData?.length, createCompany, dispatch]);

  useEffect(() => {
    socket.emit("newNotificationCount", companyId);
    socket.on("newNotificationCountResponse", (data: any) => {
      setNotificationCount((prev) => ({
        newNotifications: data,
        totalNotifications: prev.totalNotifications,
      }));
    });

    // socket.emit("notificationHistory", companyId);

    socket.on("readNotificationResponse", (data: any) => {
      if (data?.isRead) {
        socket.emit("notificationHistory", companyId);
      }
    });

    socket.emit("allNotificationCount", companyId);

    socket.on("allNotificationCountResponse", (data: any) => {
      setNotificationCount((prev) => ({
        newNotifications: prev.newNotifications,
        totalNotifications: data,
      }));
    });
  }, [socket]);

  const handleToggleNotification = () => {
    socket.emit("notificationHistory", companyId);
    dispatch(handleNotificationDrawer());
    setNotification(isNotificationDrawerOpen);
  };

  const handleOpenChange = () => {
    setToggleCompany(!isToggleCompany);
  };

  const handleProfileChange = (newOpen: boolean) => {
    setToggleProfile(newOpen);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
    dispatch({ type: "RESET_STATE" });
  };

  const handleChange = () => {
    setIsOpenPopover((prev) => ({
      ...prev,
      isSetting: !isOpenPopover.isSetting,
    }));
  };

  return (
    <>
      {!isNotification ? <PushNotification /> : <></>}

      <header className="header container">
        <div className="flex gap-4 justifyBetween alignCenter h-100 overflow-auto">
          <NavLink to={"/"} className="logo-icon">
            <img src={SquareLogo} alt="Logo" />
            {/* <SquareLogo /> */}
          </NavLink>
          <Button type="button" className="primary" onClick={() => navigate("/vendors", { state: { vendorId: null } })}> <PlusCircleOutlined /> Place Order </Button>
          {/* <Button type="button" className="primary" onClick={() => setToggleSelectVendors(true)}> <PlusCircleOutlined /> Place Order </Button> */}
          <nav className="navbar flex justifyStart">
            <ul className="flex gap-4 nav-items">
              {navItems && navItems?.map((item, index) => {
                return (
                  item.checked ? <li className="item" key={index}>
                    <NavLink to={item.url}>{item.icon}{item.text}</NavLink>
                  </li> : ""
                );
              })}
            </ul>
            {/* <Popover content={content} trigger="click">
            <Button type="button" name="other" className="text primary flex alignCenter"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
              <path d="M2.16669 4.33332C2.16669 2.86056 3.36059 1.66666 4.83335 1.66666C6.30611 1.66666 7.50002 2.86056 7.50002 4.33332C7.50002 5.80608 6.30611 6.99999 4.83335 6.99999C3.36059 6.99999 2.16669 5.80608 2.16669 4.33332Z" stroke="#0958D9" strokeWidth="1.5" />
              <path d="M9.5 11.6667C9.5 10.1939 10.6939 9 12.1667 9C13.6394 9 14.8333 10.1939 14.8333 11.6667C14.8333 13.1394 13.6394 14.3333 12.1667 14.3333C10.6939 14.3333 9.5 13.1394 9.5 11.6667Z" stroke="#0169E6" strokeWidth="1.5" />
              <path d="M14.8333 4.33332C14.8333 2.86056 13.6394 1.66666 12.1667 1.66666C10.6939 1.66666 9.5 2.86056 9.5 4.33332C9.5 5.80608 10.6939 6.99999 12.1667 6.99999C13.6394 6.99999 14.8333 5.80608 14.8333 4.33332Z" stroke="#0169E6" strokeWidth="1.5" />
              <path d="M7.50002 11.6667C7.50002 10.1939 6.30611 9 4.83335 9C3.36059 9 2.16669 10.1939 2.16669 11.6667C2.16669 13.1394 3.36059 14.3333 4.83335 14.3333C6.30611 14.3333 7.50002 13.1394 7.50002 11.6667Z" stroke="#0169E6" strokeWidth="1.5" />
            </svg> <span>Others</span></Button>
          </Popover> */}
          </nav>
          <div className="flex buttons-container gap-4">
            {((company && company?.length) || Object.entries(company)?.length) ?
              <Popover
                onOpenChange={handleOpenChange}
                open={isToggleCompany}
                rootClassName="select-company-popover"
                content={() => CompanyListAndSetting({ handleOpenChange })}
                trigger="click"
                placement="bottom">
                <button className="selected-company-btn gap-1">
                  <div className="image-container">
                    {company.profileImg ? <img src={company.profileImg} alt="companyImage" /> : getProfileIcon(company.name)}
                  </div>
                  <span className="company-name">{company?.name}</span>
                  <div className="action-dropdown">
                    <DownArrow />
                  </div>
                </button>
              </Popover> : <></>}
            {/* <ButtonIcon className="rounded-100"><SearchOutlinedIcon /></ButtonIcon> */}
            {((company && company?.length) || Object.entries(company)?.length) ? 
              <button className="notification-icon flex alignCenter" onClick={() => handleToggleNotification()}>
                <BellOutlinedIcon />
                {notificationCount?.newNotifications ? <span className="count">{notificationCount?.newNotifications > "10" ? "10+" : notificationCount?.newNotifications}</span> : <></>}
              </button> : <></>}
            {/* <ButtonIcon className="rounded-100"><SettingOutlinedIcon /></ButtonIcon> */}
            {((company && company?.length) || Object.entries(company)?.length) ?
              <Popover
                onOpenChange={handleChange}
                open={isOpenPopover.isSetting}
                rootClassName="select-company-popover"
                content={() => CompanyListAndSetting({ handleOpenChange: handleChange, isSetting: true })}
                trigger="click"
                placement="bottomLeft">
                <button className="setting-icon icon-button"><SettingOutlinedFilledIcon /></button>
              </Popover> : <></>}
            <Popover
              rootClassName="profile-popover"
              content={
                <div className="profile-popup">
                  {((company && company?.length) || Object.entries(company)?.length) ? <div className="profile-info">
                    <ButtonIcon className="rounded-100 profile-image">
                      {(company?.profileThumbnailImg) ?
                        <img src={(company?.profileThumbnailImg)} alt="profile-icon" /> : getProfileIcon(company?.name)}
                    </ButtonIcon>
                    <div className="profile-text">
                      <h4 className="title-4">{company?.name}</h4>
                      <p className="profile-name">{(company?.createdBy?.firstName || ProfileData?.firstName) + " " + (company?.createdBy?.lastName || ProfileData?.lastName)}</p>
                    </div>
                  </div> : <></>}
                  <button
                    className="logout-button"
                    onClick={() => { setIsConfirmToggle(true); setToggleProfile(false); }}>
                    <LogoutOutlined />
                    Logout
                  </button>
                </div>
              }
              trigger="click"
              open={isToggleProfile}
              onOpenChange={handleProfileChange}
              placement="bottomRight">
              <div>
                <ButtonIcon className="rounded-100 profileIcon">
                  {(company?.profileThumbnailImg) ?
                    <img src={(company?.profileThumbnailImg)} alt="profile-icon" /> : company?.name ? getProfileIcon(company?.name) : <UserIcon />}
                </ButtonIcon>
              </div>
            </Popover>
          </div>
        </div>
        <ConfirmationPopup
          open={isConfirmToggle}
          close={() => { setIsConfirmToggle(false); setToggleProfile(false); }}
          confirm={() => handleLogout()}
          infoContent="Are you sure, you want to Logout?"
          cancelButtonText="No"
          confirmButtonText="Yes, Logout"
        />
      </header>
    </>
  );
};

interface IButtonIconProps {
  className: string,
  children: any
}

const ButtonIcon = ({ className, children }: IButtonIconProps) => {
  return (
    <button className={className ? className + " button" : "button"}>
      {children}
    </button>
  );
};