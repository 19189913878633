import React from "react";
import { Tabs, TabsProps } from "antd";
import { useNavigate } from "react-router-dom";

import CommonDrawer from "../commonDrower";
import { useAppSelector } from "../../hooks/useAppSelector";
import { getDateLabel, groupDataByDate } from "../../utils/helper";
import { NotificationCard } from "./notificationCard";
import { NotificationData, NotificationModules, NotificationTypes } from "./NotificationTypes";
import NoDataFound from "../NoDataFound";
import socket from "../../socket";
import { CrossIcon } from "../../assets/images";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { handleNotificationDrawer, notificationOrderData } from "../../redux/slices/notificationSlice";
import { getConnectionList, getConnectionRequests, takeActionOnConnectionRequest } from "../../redux/services/connection";
import useMessageHook from "../../hooks/useMessageHook";

const NotificationDrawer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showMessage, contextHolder } = useMessageHook();

  const companiesData = localStorage.getItem("companies");
  const companyId = companiesData ? JSON.parse(companiesData || "[]")?.[0]?.companyId ? JSON.parse(companiesData || "[]")?.[0]?.companyId : JSON.parse(companiesData || "{}")?.companyId : 0;

  const { notifications, isNotificationDrawerOpen } = useAppSelector((state) => state.notification);
  
  const isUnReadData = notifications?.filter((data) => data.isRead === false);
  
  const ListOfAllConnection = async () => {
    const payload = {
      search: "",
      sort: "ASC",
      type: "All",
      limit: 0,
      offset: 0,
    };
    await dispatch(getConnectionList(payload));
  };

  const getRecievedRequest = async () => {
    const payload = {
      search: "",
      limit: 0,
      offset: 0,
    };
    await dispatch(getConnectionRequests(payload));
  };

  const handleAction = async (action: string, type: NotificationTypes, notification: NotificationData) => {
    if (type === NotificationTypes.ORDER_PLACED_BY_CUSTOMER) {
      handleCloseNotification();
      dispatch(notificationOrderData(notification));
      navigate("/");
    } else if (type === NotificationTypes.CONNECTION_REQUEST_RECEIVED) {

      const payload = {
        requestId: notification.actionParams.requestId,
        action: action,
      };
      await dispatch(takeActionOnConnectionRequest(payload)).then((result) => {
        socket.emit("newNotificationCount", companyId);
        socket.emit("allNotificationCount", companyId);
        socket.emit("notificationHistory", companyId);
        if (result.payload.status === 200) {
          ListOfAllConnection();
          getRecievedRequest();
          if(action === "Accept") {
            navigate("/connection", { state: { isSelectedConnection: true } });
            handleCloseNotification();
          }
        } else {
          showMessage("error", result?.payload.message || "Something went wrong");
        }
      });

    }
  };

  const handleCloseNotification = () => {
    dispatch(handleNotificationDrawer());
    socket.emit("updateNewBadge", companyId);
    socket.emit("newNotificationCount", companyId);
  };

  const renderGroupedNotifications = (groupedNotifications: Record<string, NotificationData[]>) => {
    return Object.entries(groupedNotifications).map(([date, notifications]) => {
      const sortedNotifications = notifications.sort((a, b) => {
        if (a.isNew === b.isNew) return 0;
        return a.isNew ? -1 : 1;
      });
      return (
        <div key={date}>
          <div className="notification-time">{getDateLabel(new Date(date))}</div>
          {sortedNotifications.map((notification) => (
            <div key={notification.notificationHistoryId}>
              {/* {renderNotificationCard(notification, undefined, handleClose)} */}
              {NotificationCard({ notification: notification, handleAction: handleAction })}
            </div>
          ))}
        </div>
      );
    });
  };

  const getNotificationsByModule = (module: NotificationModules) => (
    notifications.filter((notification) => notification.module === module)
  );

  const handleReadAllNotification = () => {
    socket.emit("readAllNotification", companyId);
    socket.emit("newNotificationCount", companyId);
    socket.emit("notificationHistory", companyId);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <span className="tab-label flex gap-2">
          All
          {/* <span className="count">{notifications.length}</span> */}
        </span>
      ),
      children: notifications.length ? renderGroupedNotifications(groupDataByDate(notifications)) : <NoDataFound />,
    },
    {
      key: "2",
      label: (
        <span className="tab-label flex gap-2">
          Products
          <span className="count">
            {getNotificationsByModule(NotificationModules.PRODUCT).length}
          </span>
        </span>
      ),
      children: getNotificationsByModule(NotificationModules.PRODUCT).length ? renderGroupedNotifications(groupDataByDate(getNotificationsByModule(NotificationModules.PRODUCT))) : <NoDataFound />,
    },
    {
      key: "3",
      label: (
        <span className="tab-label flex gap-2">
          Orders
          <span className="count">
            {getNotificationsByModule(NotificationModules.ORDER).length}
          </span>
        </span>
      ),
      children: getNotificationsByModule(NotificationModules.ORDER).length ? renderGroupedNotifications(groupDataByDate(getNotificationsByModule(NotificationModules.ORDER))) : <NoDataFound />,
    },
  ];

  return (
    <>
      <CommonDrawer
        title=""
        onClose={handleCloseNotification}
        open={isNotificationDrawerOpen}
        rootClassName="notification-drawer"
      >
        <div className="notification-header-container flex alignCenter justifyBetween">
          <div className="gap-1 flex alignCenter">
            <h1 className="title">Notification</h1>
            {isUnReadData.length ? <span className="count flex justifyCenter alignCenter">
              {isUnReadData.length > 10 ? "10+" : isUnReadData.length}
            </span> : <></>}
          </div>

          <div className="actions-container">
            {isUnReadData.length ? <button className="mark-all-read" onClick={handleReadAllNotification}>Mark as all read</button> : <></>}
            {/* <button className="setting-btn flex alignCenter"><SettingOutlinedIcon /></button> */}
            <button className="close-btn flex alignCenter" onClick={handleCloseNotification}><CrossIcon /></button>
          </div>
        </div>
        <Tabs
          defaultActiveKey="1"
          items={items}
          rootClassName="tabs-container"
        />
      </CommonDrawer>
      {contextHolder}
    </>
  );
};

export default NotificationDrawer;