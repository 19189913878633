import React, { useState } from "react";
import { Upload, UploadFile, UploadProps, Image } from "antd";
import ImgCrop from "antd-img-crop";

import { productPlaceholderImage } from "../../assets/images";
import useMessageHook from "../../hooks/useMessageHook";

type FileType = any

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

interface Props {
  fileList: UploadFile[],
  setFileList: (data: UploadFile[]) => void;
}
const ImageUpload = ({ fileList, setFileList }: Props) => {
  const { showMessage, contextHolder } = useMessageHook();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    // Only perform validations when a file is added (not when it's removed)
    const lastFile = newFileList?.[(newFileList?.length || 1) - 1];
    const file = lastFile?.originFileObj;

    if (file) {
      const isJpgOrPng = file?.type === "image/jpeg" || file?.type === "image/png";
      if (!isJpgOrPng) {
        showMessage("error", "You can only upload JPG/PNG file!");
        return;
      }

      const sizeLimit = file.type === "image/jpeg" ? 400 : 600;
      if (file.size / 1024 > sizeLimit) {
        showMessage("error", `Image must be smaller than ${sizeLimit}KB!`);
        return;
      }
    }
    setFileList(newFileList);
    setFileList && setFileList(newFileList);
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      +
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const customRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };


  const itemRender = (originNode: any, file: any) => {
    return (
      <div
        style={{
          width: "104px",
          height: "104px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "0.87px solid #EAECF0",
          borderRadius: "8px",
        }}
      >
        <img
          src={file.url || file.preview || productPlaceholderImage}
          alt="uploaded"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.src = productPlaceholderImage;
          }}
        />
      </div>
    );
  };

  return (
    <>
      {contextHolder}
      <ImgCrop rotationSlider={false} showGrid={true} aspect={1 / 1}>
        <Upload
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          customRequest={customRequest}
          accept="image/png, image/jpeg"
        // itemRender={itemRender}
        >
          {fileList.length >= 3 ? null : uploadButton}
        </Upload>
      </ImgCrop>
      {previewImage && (
        <Image
          wrapperStyle={{ display: "none" }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
          fallback={productPlaceholderImage}
        />
      )}
    </>
  );
};

export default ImageUpload;
