import React, { useEffect, useState } from "react";
import { Select, Space, Input, Checkbox, Popconfirm } from "antd";

import { CrossRoundedIcon } from "../../assets/images";
import { DeleteIcon, PencileOutlined } from "../../assets/images/icons";
import { ManagementField } from "../../types/productsType";
import { handleKeyDown } from "../../utils/helper";

const { Option } = Select;

interface IProps {
  setOptions: any;
  options: ManagementField[];
  fieldType?: number;
  isOpen: boolean;
  setIsOpen: () => void;
  setSuggestedOptions?: any;
  suggestedOption?: any;
}

const CreateCustomField = ({ setOptions, options, fieldType, isOpen, setIsOpen, setSuggestedOptions, suggestedOption }: IProps) => {
  const [searchInput, setSearchInput] = useState({
    fieldName: "",
  });
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editValue, setEditValue] = useState({
    fieldType: fieldType || 2,
    fieldName: "",
    isActive: true,
  });

  const [isDeleteConfirmPopup, setIsDeleteConfirmPopup] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);

  const defaultOptions: ManagementField[] = [];

  useEffect(() => {
    if (!editIndex && !isDropdownOpen) {
      setIsOpen();
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    if (!isOpen) {
      setEditValue({
        fieldType: fieldType || 2,
        fieldName: "",
        isActive: true,
      });
    }
    if (!defaultOptions?.length) {
      options.map((item) => defaultOptions.push(item));
    }
  }, [isOpen, defaultOptions]);

  const handleSelectChange = (selectedValues: string[]) => {
    const isVariantExists = options?.some(option => option?.fieldName?.toLowerCase() === searchInput?.fieldName.toLowerCase());
    if (!isVariantExists && searchInput?.fieldName) {
      handleCreateVariant();
    } else if (!editValue?.fieldName) {
      setOptions(options?.map(option => ({
        ...option,
        isActive: option?.isDefault ? true : selectedValues.includes(option?.fieldName),
      })));
    }
  };

  const handleDelete = (event: React.MouseEvent, value: string) => {
    event.stopPropagation();
    const abc = options?.filter(option => option.fieldName !== value);
    const bcd = {...options?.filter(option => option.fieldName === value)?.[0], isDelete: true, isActive: false, isSuggestion: true};
    setOptions([...abc, bcd]);
  };

  const handleSearch = (input: string) => {
    setSearchInput({
      fieldName: input,
    });
  };

  const handleCreateVariant = () => {
    if (!options?.some(option => option?.fieldName?.toLowerCase() === searchInput?.fieldName?.toLowerCase())) {
      setOptions([...options, { fieldName: searchInput?.fieldName, isActive: true, isDefault: false, isDelete: false, fieldType: fieldType || 2, isSuggestion: false, id: options.length, productManagementFieldsId: 0 }]);
      setSearchInput({
        fieldName: "",
      });
    }
  };

  const handleEdit = (event: React.MouseEvent, index: number, fieldName: string) => {
    const ActiveOptions = options.filter((item) => item.isActive);
    event.stopPropagation();
    setEditIndex(index);
    setEditValue({
      fieldType: fieldType || 2,
      fieldName: ActiveOptions[index].fieldName,
      isActive: ActiveOptions[index]?.isActive,
    });
  };

  const handleSaveEdit = (event: React.MouseEvent, index: number, fieldName: string) => {
    // event.stopPropagation();
    setEditIndex(null);
    setEditValue({
      fieldType: fieldType || 2,
      fieldName: "",
      isActive: true,
    });
    setOptions(options?.map(option =>
      option.fieldName === fieldName
        ? { ...option, fieldName: editValue.fieldName, isActive: editValue.isActive, isSuggestion: false }
        : option,
    ));
  };

  const handleCancelEdit = (event: React.MouseEvent) => {
    event.stopPropagation();
    setEditIndex(null);
    setEditValue({
      fieldType: fieldType || 2,
      fieldName: "",
      isActive: true,
    });
  };

  const isVariantExists = options?.some(option => option?.fieldName?.toLowerCase() === searchInput?.fieldName?.toLowerCase());

  const handleCheckboxChange = (event: React.MouseEvent, value: string) => {
    event.stopPropagation();
    if (!editValue?.fieldName) {
      setOptions(options?.map(option =>
        option.fieldName === value
          ? { ...option, isActive: !option?.isActive }
          : option,
      ));
    }
  };

  return (
    <div className="selectWrapper" onBlur={(!editIndex || !isDropdownOpen) ? () => setIsOpen() : () => []}>
      <Select
        maxLength={40}
        mode="multiple"
        aria-placeholder="Create Field"
        style={{ width: "100%" }}
        rootClassName="customfield-select"
        popupClassName="customfield-popup"
        placeholder="Type and search to create"
        onChange={handleSelectChange}
        value={options.filter(option => option?.isActive)?.map(option => option?.fieldName)}
        onSearch={handleSearch}
        onInputKeyDown={handleKeyDown}
        filterOption={(input, option: any) =>
          option?.value?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        }
        maxTagCount="responsive"
        maxTagPlaceholder={false}
        suffixIcon={false}
        autoFocus={isOpen}
        onBlur={(!editIndex || !isDropdownOpen) ? () => setIsOpen() : () => []}
        defaultOpen={isOpen || isDropdownOpen}
        onDropdownVisibleChange={(e) => !e && !isDeleteConfirmPopup && setIsDropdownOpen(e)}
      >
        {options?.filter(item => !item?.isDelete && !item?.isSuggestion).map((option, index) => (
          <Option key={index} value={option?.fieldName}>
            {editIndex === index ? (
              <div className="edit-box">
                <Input maxLength={40} autoFocus value={editValue?.fieldName} onChange={(e) => setEditValue({
                  fieldName: e.target.value,
                  fieldType: fieldType || 2,
                  isActive: true,
                })} style={{ width: "100%" }} />
                <button type="button" className="save-btn" onClick={(event) => handleSaveEdit(event, index, (option?.fieldName))}>Save</button>
                <button type="button" className="remove-icon" onClick={handleCancelEdit}><CrossRoundedIcon /></button>
              </div>
            ): (
              <Space style={{ width: "100%", justifyContent: "space-between" }}>
                <Space>
                  <Checkbox
                    checked={option?.isActive}
                    onClick={(event) => handleCheckboxChange(event, option?.fieldName)}
                    disabled={option?.isDefault}
                  />
                  <span>{option?.fieldName}</span>
                </Space>
                {option?.isDefault ?
                  <></>
                  :
                  <Space className="variant-action-box">
                    <button className="action-btn" onClick={(event) => handleEdit(event, index, option.fieldName)}>
                      <PencileOutlined />
                    </button>
                    <Popconfirm
                      title="Are you sure want to delete this field?"
                      onConfirm={(event: any) => [event?.stopPropagation(), handleDelete(event, option?.fieldName), setIsDropdownOpen(true)]}
                      okText="Yes"
                      cancelText="No"
                      onOpenChange={(e) => setIsDeleteConfirmPopup(e)}
                      onPopupClick={(e) => e?.stopPropagation()}
                    >
                      <button className="action-btn delete-icon" onClick={(event) => event?.stopPropagation()}>
                        <DeleteIcon />
                      </button>
                    </Popconfirm>
                  </Space>  
                }
              </Space>
            )}
          </Option>
        ))}
        {suggestedOption?.map((item: any, index: number) => {
          return (<Space style={{ width: "100%", justifyContent: "space-between" }} key={index}>
            <Space>
              <Checkbox
                checked={item?.isActive}
                onClick={(event) => handleCheckboxChange(event, item?.fieldName)}
                disabled={item?.isDefault}
              />
              <span>{item?.fieldName}</span>
            </Space>
          </Space>);
        }) }
        {!isVariantExists && searchInput?.fieldName && (
          <Option key="create" value={searchInput?.fieldName}>
            Create <span className="create-badge">{searchInput?.fieldName}</span>
          </Option>
        )}
      </Select>
    </div>
  );
};

export default CreateCustomField;
