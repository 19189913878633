import React, { ChangeEvent, useEffect, useState } from "react";

import CommonModal from "../../components/commonModal";
import { InputField } from "../../components";
import { AttachmentIcon, PdfIcon } from "../../assets/images/icons";
import { CrossRoundedIcon } from "../../assets/images";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getAddressByPincode } from "../../redux/services/address";
import { useDebounce } from "../../utils/debounce";
import { useAppSelector } from "../../hooks/useAppSelector";
import { clearAddressData } from "../../redux/slices/addressSlice";
import { orderDispatch } from "../../redux/services/orders";
import useMessageHook from "../../hooks/useMessageHook";

interface Props {
  open: boolean;
  close: () => void;
  orderId: number;
}

type SelectedFile = {
  name: string;
  type: string;
  file: File;
};

const TransportationDetails = ({ open, close, orderId }: Props) => {
  const [error, setError] = useState<any>({});
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
  const [values, setValues] = useState({
    transporterName: "",
    trakingId: "",
    pincode: "",
    location: "",
    address: "",
  });
  const { showMessage, contextHolder } = useMessageHook(); 

  const dispatch = useAppDispatch();

  const { address }: any = useAppSelector(state => state.address);
  const { orderItems } = useAppSelector(state => state.manageOrders);

  const debounce = useDebounce(values?.pincode, 200);

  useEffect(() => {
    if (values?.pincode?.length === 6) dispatch(getAddressByPincode({ pincode: values?.pincode }));
  }, [dispatch, debounce]);

  useEffect(() => {
    if (address?.status === 400) {
      showMessage("error", address?.message || "Something went wrong");

      setError((prev: any) => ({
        ...prev,
        pincodeMessage: address?.message,
        pincode: true,
      }));
    } else {
      setError((prev: any) => ({
        ...prev,
        pincodeMessage: "",
        pincode: false,
      }));
    }
  }, [address]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);

    const validFiles = files.filter((file) => {
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      const sizeLimit = file.type === "image/jpeg" ? 400 * 1024 : 600 * 1024; // Size limit in bytes (KB * 1024)

      if (!allowedTypes.includes(file.type)) {
        showMessage("error", "Invalid file type. Only JPEG, PNG, and PDF files are allowed.");
        return false;
      }

      if (file.size > sizeLimit) {
        showMessage("error", `File must be smaller than ${sizeLimit / 1024}KB!`);
        return false;
      }

      return true;
    });

    if (validFiles.length + selectedFiles.length > 3) {
      showMessage("error", "You can only upload up to 3 files.");
    } else {
      setSelectedFiles((prevFiles) => [
        ...prevFiles,
        ...validFiles.map((file) => ({
          name: file.name,
          type: file.type,
          file: file,
        })),
      ]);
    }

    event.target.value = ""; // Clear the input to allow re-uploading the same file if needed
  };

  const handleDelete = (fileName: string) => {
    setSelectedFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "pincode") {
      const validPincode = value.replace(/[^0-9]/g, "");
      if (validPincode.length <= 6) {
        setValues(prevValues => ({ ...prevValues, [name]: validPincode }));
      }
    } else {
      setValues(prevValues => ({ ...prevValues, [name]: value }));
    }
    if (name === "pincode") dispatch(clearAddressData());
  };

  const handleTransportation = (value: number) => {

    if(error.pincode && value === 1){
      return;
    }

    const dispatchDetails = value === 1 ? {
      orderId: orderId,
      ...(values.transporterName && { transporterName: values.transporterName }),
      ...(values.trakingId && { trackingId: values.trakingId }),
      ...(values.pincode && { pincode: values.pincode }),
      ...(values.address && { addressLine: values.address }),
      dispatchItems: orderItems.data?.map((data) => ({
        orderItemId: data.orderItemId,
        quantity: data.quantityOrdered,
      })),
    } : {
      orderId: orderId,
      dispatchItems: orderItems.data?.map((data) => ({
        orderItemId: data.orderItemId,
        quantity: data.quantityOrdered,
      })),
    };

    const transportationAttachments = selectedFiles.map((data) => data.file);

    const payload = {
      dispatchDetails: dispatchDetails,
      transportationAttachments: transportationAttachments,
    };

    dispatch(orderDispatch(payload));

    close();
  };

  return (
    <CommonModal
      open={open}
      close={close}
      closeIcon
      className="transportation-detail-modal"
      footer
      saveButtonText="Dispatch"
      cancelButtonText="Skip"
      onSave={() => handleTransportation(1)}
      onCancel={() => handleTransportation(0)}
    >
      <div className="transportation-modal-container flex direction-column gap-5">
        {contextHolder}
        <div className="header-container">
          <h3 className="title">Add Transportation Details</h3>
          <span className="info-text">Enter transportation details to complete your order.</span>
        </div>
        <div className="transportation-modal-content flex direction-column">

          <div className="gap-3 flex direction-column transport-info">
            <InputField
              placeholder="Enter Transporter Name"
              label="Transporter Name"
              name="transporterName"
              onChange={handleChange}
              value={values.transporterName}
              autoFocus
              maxLength={100}
            />
            <InputField
              placeholder="Enter ⁠⁠LR/ Tracking ID"
              label="⁠⁠LR/ Tracking ID"
              name="trakingId"
              onChange={handleChange}
              value={values.trakingId}
              maxLength={20}
            />
          </div>
          <div className="delivery-info-container">
            <div className="flex direction-column">
              <h4 className="delivery-header-text">Delivery Info</h4>
              <span className="delivery-info-text">Enter your delivery information to ensure timely and accurate shipping.</span>
            </div>
            <div className="input-field-box flex direction-column gap-2">
              <div className="flex gap-2">
                <InputField
                  onChange={handleChange}
                  placeholder="Enter Pincode"
                  label="Pincode"
                  name="pincode"
                  value={values.pincode}
                  errorMessage={error?.pincodeMessage}
                />
                <InputField
                  onChange={handleChange}
                  placeholder="Enter Location"
                  label="Location"
                  name="location"
                  value={(address?.district && address?.state) ? (address?.district + ", " + address?.state) : ""}
                  disabled
                />
              </div>
              <InputField
                onChange={handleChange}
                placeholder="Enter Address"
                label="Address Line"
                name="address"
                value={values.address}
                maxLength={40}
              />
            </div>
            <button
              className="attach-file-box"
              onClick={() => document.getElementById("fileInput")?.click()}
            >
              <span className="attach-file-text flex alignCenter gap-2">
                <AttachmentIcon /> Attach file
              </span>
            </button>
            <div className="attachments-files-container">
              <input
                id="fileInput"
                type="file"
                accept="image/*,.pdf"
                multiple
                style={{ display: "none" }}
                onChange={handleFileChange}
              />

              <div className="selected-files-list flex gap-4">
                {selectedFiles.map((file, index) => (
                  <div key={index} className="selected-file">
                    {file.type === "application/pdf" ? (
                      <div className="file-preview flex direction-column justifyCenter alignCenter">
                        <div className="file-box">
                          <PdfIcon />
                        </div>
                        <span className="file-name">{file.name}</span>
                      </div>
                    ) : (
                      <div className="file-preview flex direction-column">
                        <div className="file-box">
                          <img src={URL.createObjectURL(file.file)} alt={file.name} className="image-preview" />
                        </div>
                        <span className="file-name">{file.name}</span>
                      </div>
                    )}
                    <button onClick={() => handleDelete(file.name)} className="delete-icon">
                      <CrossRoundedIcon />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default TransportationDetails;