import React from "react";

import CommonModal from "../commonModal";
import HelpIcon from "../../assets/images/helpIcon";

interface Props {
  open: boolean;
  infoContent: string
  close: () => void;
  confirm: () => void;
  cancelButtonText?: string;
  confirmButtonText?: string;
}

const ConfirmationPopup = ({
  open,
  infoContent,
  close,
  confirm,
  cancelButtonText = "No",
  confirmButtonText = "Yes",
}: Props) => {
  return (
    <CommonModal open={open} close={close} className="confirmation-popup">
      <div className="flex direction-column gap-4">
        <div className="content-container flex direction-column justifyCenter alignCenter gap-5">
          <div className="icon-box"><HelpIcon /></div>
          <div className="flex direction-column textCenter gap-1">
            <div className="confirmation-header">Confirm this action</div>
            <div className="confirmation-info">{infoContent}</div>
          </div>
        </div>
        <div className="action-container flex gap-3">
          <button className="secondary large40 rounded-8" onClick={close}>{cancelButtonText}</button>
          <button className="primary large40 rounded-8" onClick={confirm}>{confirmButtonText}</button>
        </div>
      </div>
    </CommonModal>
  );
};

export default ConfirmationPopup;