import React, { useEffect, useState } from "react";
import { Select, UploadFile } from "antd";

import { customFieldSuggesion, getProductFieldManageField } from "../../redux/services/products";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { InputField } from "../../components";
import TextEditor from "../../components/textEditor/richTextEditor";
import { useAppSelector } from "../../hooks/useAppSelector";
import { FieldValues, infoDefaultField, ManagementField } from "../../types/productsType";
import ImageUpload from "./imageUpload";
import CreateCustomField from "./createCustomField";
import { CrossRoundedIcon, PlusIcons } from "../../assets/images";
import { PlusFilledIcon } from "../../assets/images/icons";
import Limits from "../../utils/limits.json";

interface Props {
  productManageFields: ManagementField[];
  fieldValues: FieldValues[];
  fileList: UploadFile[];
  errors: {
    productName: boolean;
    dataSource: { productSize: boolean; pricePerUnit: boolean }[];
  }
  setFieldValues: (values: any) => void;
  setInfoFields: (data: infoDefaultField) => void;
  setFileList: (data: UploadFile[]) => void;
  infoFields: infoDefaultField;
  productId: number;
}

const ProductInfo = ({
  fileList,
  infoFields,
  fieldValues,
  productManageFields,
  errors,
  setInfoFields,
  setFieldValues,
  setFileList,
  productId,
}: Props) => {
  const [toggleManageField, setToggleManageField] = useState(false);
  const [options, setOptions] = useState<any[]>([]);

  const dispatch = useAppDispatch();
  const { suggestedField } = useAppSelector((state) => state.products);

  useEffect(() => {
    const payload = {
      productId: productId || 0,
      search: "",
      limit: 0,
      fieldType: 1,
    };
    const suggesionPayload = {
      search: "",
      fieldType: 1,
      limit: 5,
    };
    dispatch(getProductFieldManageField(payload)).then((result) => {
      const updatedOption = result.payload.data?.filter((item: any) => !item.isDefault || item?.isSuggestion)?.map((data: any, index: number) => ({...data, id: index}));
      const defaultOptions = result.payload.data?.filter((item: any) => item.isDefault);
      setOptions([...defaultOptions, ...updatedOption]);
      dispatch(customFieldSuggesion(suggesionPayload));
    });
  }, [dispatch, productId]);


  useEffect(() => {
    if (suggestedField?.[1]?.data?.length) {
      const uniqueFields: any[] = [];
      const fieldNamesSet = new Set();
      const suggestedOptions = suggestedField?.[1]?.data?.map((item: any) => {
        return (
          {
            fieldName: item?.fieldName,
            isActive: false,
            isDelete: false,
            isDefault: false,
            id: options?.length + 1,
            fieldType: 1,
            productManagementFieldsId: 0,
            isSuggestion: true,
          }
        );
      });
      [...options, ...suggestedOptions].forEach((item, index) => {
        if (!fieldNamesSet.has(item.fieldName)) {
          fieldNamesSet.add(item.fieldName);
          uniqueFields.push(item);
        }
      });
      setOptions(uniqueFields.map((item, index) => ({ ...item, id: index })));
    }
  }, [suggestedField?.[1]]);

  useEffect(() => {
    const updatedFieldValues = options?.filter(field => field?.fieldType === 1).map((field, index) => {
      const existingFieldValue = fieldValues?.find((f) => f.id === field?.id && f.fieldType === 1);
      return existingFieldValue ? {
        productCustomFieldsId: field.productCustomFieldsId,
        id: field.id,
        fieldName: field.fieldName,
        fieldValue: existingFieldValue?.fieldValue || "",
        fieldType: field.fieldType,
        isActive: field.isActive,
        isDefault: field.isDefault,
        isDelete: field.isDelete,
      } : {
        productCustomFieldsId: 0,
        id: field.id,
        fieldName: field.fieldName,
        fieldValue: fieldValues?.[index - options?.filter((field) => field?.productManagementFieldsId)?.length]?.fieldValue,
        fieldType: field.fieldType,
        isActive: field.isActive,
        isDefault: field.isDefault,
        isDelete: field.isDelete,
      };
    });
    const getCField = updatedFieldValues?.filter((item) => !item.isDefault && item.isActive);
    setFieldValues(getCField);
  }, [options]);

  const handleChange = (val: string, id: number) => {
    setFieldValues((prevValues: any[]) =>
      prevValues.map((field: { id: number; productCustomFieldsId?: number }) =>
        (field.productCustomFieldsId || field.id) === id ? { ...field, fieldValue: val } : field,
      ),
    );
  };

  const handleRemove = (id: number) => {
    setFieldValues(fieldValues.map((field) => { return ((field.id) === id ? { ...field, isActive: false, productCustomFieldsId: 0 } : field); }));
    const updatedOptions = options.filter((item) => !item.isDefault)?.map((field, index) => field?.id === id ? { ...field, isActive: false } : field);
    setOptions([...options.filter((item) => item.isDefault), ...updatedOptions]);
  };

  const handleAddCustomField = (id: number) => {
    setFieldValues(fieldValues.map((field) => { return ((field.id === id) ? { ...field, isActive: true, isDelete: false } : field); }));
    const updatedOptions = options.filter((item) => !item.isDefault && !item?.isActive)?.map((field, index) => index === id ? { ...field, isActive: true, isDelete: false, isSuggestion: false } : field);
    setOptions([...options.filter((item) => item.isDefault || item?.isActive), ...updatedOptions]);
  };

  return (
    <div className="card add-product-cards-container gap-4">
      <div className="product-details-container">
        <div className="title-section flex alignCenter justifyBetween">
          <h4 className="title-4">Product Information</h4>
          {toggleManageField ?
            <div className="managefield-container flex alignCenter">
              <CreateCustomField setOptions={setOptions} options={options} fieldType={1} isOpen={toggleManageField} setIsOpen={() => setToggleManageField(false)} />
              <button className="close-icon" onClick={() => setToggleManageField(false)}><CrossRoundedIcon /></button>
            </div>
            :
            <button type="button" className="flex alignCenter gap-1 text" onClick={() => setToggleManageField(true)}>
              <PlusFilledIcon /> Manage Field
            </button>
          }
        </div>
        <ImageUpload fileList={fileList} setFileList={setFileList} />
        <div className="product-form-container flex direction-column gap-3">
          <InputField
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInfoFields({ ...infoFields, productName: e.target.value })}
            value={infoFields.productName}
            placeholder="Enter Product Name"
            label="Product Name"
            name=""
            className={errors.productName ? "error" : ""}
            required
            maxLength={Limits.productName}
            showCount
          />
          {options.find((data) => (data.fieldName === "Product Description" && data.isActive))?.isActive &&
            <div className="description-container gap-1 flex direction-column">
              <span className="flex gap-1 title">Description</span>
              <TextEditor descriptionValue={infoFields.productDesciption} maxLength={Limits.productDesciption} getvalue={(data) => setInfoFields({ ...infoFields, productDesciption: data })} />
              <div className="char-count">
                {Limits.productDesciption - infoFields?.productDesciption?.replace(/<[^>]+>/g, "").length} characters left
              </div>
            </div>
          }
          {options?.find((data) => (data?.fieldName === "Product Alias" && data?.isActive))?.isActive &&
            <div className={"select-dropdown-box"}>
              <label htmlFor="">
                <span className="flex gap-1 title">Product Alias</span>
                <Select
                  placeholder="Enter Product Alias"
                  mode="tags"
                  style={{ width: "100%", backgroundColor: "transparent" }}
                  value={infoFields.productAlias}
                  onChange={(value) => setInfoFields({ ...infoFields, productAlias: value })}
                  tokenSeparators={[","]}
                  dropdownStyle={{ display: "none" }}
                  suffixIcon=""
                  maxCount={Limits.productAlias}
                  maxTagTextLength={Limits.productAliasCharaters}
                />
              </label>
            </div>
          }
          {fieldValues.map((data, index) => {
            return (
              !data?.isDefault && data?.isActive ?
                <InputField
                  key={index}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, data.id || index)}
                  value={data?.fieldValue || ""}
                  placeholder={`Enter ${data.fieldName}`}
                  label={data.fieldName}
                  name={data.fieldName}
                  className={errors.productName ? "error" : ""}
                  maxLength={Limits.productName}
                  handleRemoveField={() => handleRemove(data.id || index)}
                  autoFocus
                />
                :
                <></>
            );
          })}
          <div className="info-custom-field-suggestion-box">
            {options?.filter((data) => (!data.isActive || data?.isSuggestion) && !data?.isDefault).map((data, index) => (
              index < 5 ? <button className="info-custom-field-btn gap-2" key={index} onClick={() => handleAddCustomField(index)}>{data.fieldName} <PlusIcons /></button> : <></>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;
