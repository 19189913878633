import React, { useEffect, useState } from "react";
import { Spin, Tooltip } from "antd";

import NewOrderDetailsModal from "./newOrderDetailsModal";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getCompanyOrderData, getNewOrders } from "../../redux/services/orders";
import { getProfileIcon } from "../../utils/helper";
import NoDataFound from "../../components/NoDataFound";
import { notificationOrderData } from "../../redux/slices/notificationSlice";

const NewOrders = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSlider, setActiveSlider] = useState(0);
  const [customerId, setCustomerId] = useState(0);
  const [orderList, setOrderList] = useState([]);
  const dispatch = useAppDispatch();

  const { newOrders, orderData, companyOrderData } = useAppSelector(state => state.manageOrders);

  const { dataForOrders } = useAppSelector((state) => state.notification);

  const newOrdersData = newOrders?.data?.customers ? [...newOrders.data.customers] : [];

  useEffect(() => {
    const data: any = [];
    const temp = newOrdersData?.filter((item) => {
      if (item.customerId === customerId) {
        data.push({ customerId: item.customerId, isActive: true });
      } else {
        data.push({ customerId: item.customerId, isActive: false });
      }
      setOrderList(data);
      return item.customerId === customerId;
    });
    const diff = (temp?.[0]?.orderCount || 0) - (temp?.[0]?.unseenOrderCount || 0);
    setActiveSlider(diff === (temp?.[0]?.orderCount || 0) ? -1 : diff);
  }, [customerId, newOrders]);

  useEffect(() => {
    if (dataForOrders?.actionParams) {
      handleSeen(dataForOrders?.actionParams?.customerId);
    }
  }, [dataForOrders]);

  const handleSeen = async (id: number) => {
    // const updatedOrders = orders.map((data) =>
    //   data.id === id ? { ...data, isSeen: true,  isNew: false} : data,
    // );

    setCustomerId(id);
    await dispatch(getCompanyOrderData({ customerId: id })).then((result) => {
      if (result?.payload?.status === 200) {
        dispatch(getNewOrders());
        setIsOpen(true);
      }
    });

    // Sort orders to have seen (true) orders at the top
    // const sortedOrders = updatedOrders.sort((a, b) => Number(a.isSeen) - Number(b.isSeen));

    // Update state with the sorted orders
    // setOrders(sortedOrders);
  };
  // const scrollContent = document.querySelector(".order-card-container");

  // scrollContent?.addEventListener("wheel", (event: any) => {
  //   event.preventDefault();
  //   scrollContent?.scrollBy({
  //     left: event?.deltaY < 0 ? -30 : 30,
  //   });
  // });

  return (
    <div className="order-card-container gap-5">
      {newOrders?.isLoading || orderData?.isLoading || companyOrderData?.isLoading ? <div className="page-loader"><Spin /></div> : <></>}
      {newOrders?.data?.customers?.length ? <>
        {newOrdersData.length && newOrdersData.sort((a, b) => {
          // Sort by `isNew` first
          if (a.isNew !== b.isNew) {
            return b.isNew ? 1 : -1;
          }
          // Then by `unseenOrderCount > 0`
          if (a.unseenOrderCount !== b.unseenOrderCount) {
            return b.unseenOrderCount - a.unseenOrderCount;
          }
          // Finally, by `unseenOrderCount === 0`
          return 0;
        })?.map((data) => (
          <div key={data.customerId} className="order-card flex direction-column alignCenter gap-2">
            <div className="product-image-box flex justifyCenter alignCenter">
              <button
                className={`product-image-button ${(data.unseenOrderCount === 0) && "isSeen"}`}
                onClick={() => { handleSeen(data.customerId); }}
              >
                <div className="product-image-container">
                  {data.customerProfileThumbnailImg ? <img className="order-image" src={data.customerProfileThumbnailImg} alt={"company-profile"} /> : <span className="profile-icon">{getProfileIcon(data?.customerName || "")}</span>}
                </div>
              </button>
              {data.isNew && <span className="new-label flex justtifyCenter alignCenter">New</span>}

            </div>
            <div className="flex justifyCenter direction-column alignCenter">
              <Tooltip title={data.customerName}>
                <h3 className="company-name">{data.customerName}</h3>
              </Tooltip>
              {/* <span className="order-info">{data.orderCount} Items</span> */}
              <span className="order-info">{data.orderCount < 2 ? `${data.orderCount} Order` : `${data.orderCount} Orders`}</span>
            </div>
          </div>
        ))}

      </> : <NoDataFound text="No New Orders Found." />}
      <NewOrderDetailsModal
        open={isOpen}
        close={() => [setIsOpen(false), dispatch(notificationOrderData(null))]}
        activeSlider={activeSlider}
        orderList={orderList}
        setActiveSlider={setActiveSlider}
      />
    </div>
  );
};

export default NewOrders;