import React, { useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";

import { BG1, BG2, BG3, BG4, BiizlineLogo, SecureIcon } from "../../assets/images";
import HelpIcon from "../../assets/images/helpIcon";

const AuthLayout = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      navigate("/", { replace: true });
    }
  }, [token]);

  return (
    <>
      <div className="auth-bg-images-container">
        <span className="bg1"><BG1 /></span>
        <span className="bg2"><BG2 /></span>
        <span className="bg3"><BG3 /></span>
        <span className="bg4"><BG4 /></span>
        <span className="glass-overlay"></span>
        {/* <img src={BackgroundImage} alt="background-image" /> */}
      </div>
      <div className="main-container">
        {/* Header */}
        <div className="header-container flex justifyBetween alignCenter">
          <div className="logo-container flex">
            <Link to={"/home"}><img src={BiizlineLogo} alt="logo" /></Link>
          </div>
          <div className="flex gap-2 get-help-text"><HelpIcon /> Get Help</div>
        </div>

        <div className="auth-content">
          {/* <div className="auth-card"> */}
          <Outlet />
          {/* </div> */}
        </div>

        {/* <Footer />   */}

        <div className="footer-container flex justifyBetween alignCenter">
          <div className="safe-box flex alignCenter">
            <SecureIcon />
            <div className="text-box flex direction-column justifyCenter">
              <span className="secure-text">100% Secure</span>
              <span>Your Data Safety, Our Priority</span>
            </div>
          </div>
          <p className="copyright-text">&#169;2024 biizline by Linics Enterprise Private Limited. All Rights Reserved</p>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;