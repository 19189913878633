import React, { useEffect, useRef, useState } from "react";
import { Input, InputRef, Popconfirm, Switch, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { Button } from "../../components";
import AltUnit from "../../components/altUnit/altUnit";
import BulkRateModal from "../../components/bulkRateModal";
import { AltUnitState, BulkRateEntry, ManagementField } from "../../types/productsType";
import SelectDropdown from "../../components/selectDropdown";
import { cleanValue, handleKeyDown } from "../../utils/helper";
import Limit from "../../utils/limits.json";
import { deleteSizes } from "../../redux/services/products";
import ConfirmationPopup from "../../components/actionModals/confirmationPopup";
import CreateCustomField from "./createCustomField";
import { DeleteIcon, PlusFilledIcon } from "../../assets/images/icons";
import { CrossRoundedIcon } from "../../assets/images";
import useMessageHook from "../../hooks/useMessageHook";

interface DataType {
  [key: string]: any;
}

interface ProductsListTableProps<T> {
  editComponent?: boolean;
  productManageFields: ManagementField[];
  dataSource: T[];
  title: string;
  handleAdd: () => void;
  setDataSource: (data: any) => void;
  additionalColumnsData: any;
  setAdditionalColumnsData: any;
  errors: {
    productName: boolean;
    dataSource: { productSize: boolean; pricePerUnit: boolean }[];
  }
  // eslint-disable-next-line no-empty-pattern
  setErrors?: (data: any) => void;
  productId?: number
  setProductManageFields?: any;
}


const ProductsListTable = <T extends DataType>({
  editComponent = false,
  productManageFields,
  dataSource,
  setDataSource,
  handleAdd,
  errors,
  setErrors,
  productId,
  setProductManageFields,
}: ProductsListTableProps<T>) => {
  const [toggleAltUnit, setToggleAltUnit] = useState(false);
  const [toggleBulkRate, setToggleBulkRate] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);
  const [editingKey, setEditingKey] = useState(0);
  const [isEditable, setIsEditable] = useState(false);
  const [isConfirmToggle, setIsConfirmToggle] = useState<string>("");
  const [options, setOptions] = useState<ManagementField[]>([]);
  const [isToggle, setIsToggle] = useState({
    customeField: false,
  });
  const [suggestedOptions, setSuggestedOptions] = useState();
  const { showMessage, contextHolder } = useMessageHook(); 

  const inputMoqRef = useRef<(InputRef | null)[]>([]);
  const dispatch = useAppDispatch();
  const { productUnits, managementFields } = useAppSelector((state) => state.products);

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedRows(selectedRowKeys);
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  useEffect(() => {
    setOptions(managementFields?.data || []);
  }, [managementFields]);

  useEffect(() => {
    setProductManageFields(options);
  }, [options]);

  useEffect(() => {
    const tableCustomFields = options?.filter((data) => !data?.isDefault);
    tableCustomFields?.length && dataSource?.length && setDataSource((prevDataSource: any) => {
      return prevDataSource.map((item: any) => {
        const updatedCustomFields = tableCustomFields.map((field: any, index: number) => {
          const existingField = item?.productSizeCustomFieldsData?.find(
            (customField: { fieldName: string; id: number; }) => (customField.fieldName === field.fieldName || customField?.id === index),
          );
          return existingField ?  {
            id:index,
            productCustomFieldsId: existingField?.productCustomFieldsId,
            fieldName: field?.fieldName,
            fieldValue: existingField?.fieldValue,
            isDefault: field?.isDefault || false,
            isActive: field?.isActive || false,
            isDelete: field?.isDelete || false,
          } : {
            id:index,
            productCustomFieldsId: field?.productCustomFieldsId || 0,
            fieldName: field?.fieldName,
            fieldValue: field?.fieldValue || "",
            isDefault: field?.isDefault || false,
            isActive: field?.isActive || false,
            isDelete: field?.isDelete || false,
          };
        });
        return { ...item, productSizeCustomFieldsData: updatedCustomFields };
      });
    });
  }, [options]);

  const handleSaveAltData = (data: AltUnitState) => {
    if (selectedRowIndex === null || selectedRowIndex < 0) {
      return;
    }

    setDataSource((prevItems: any) => {
      const newItems = [...prevItems];
      newItems[selectedRowIndex] = {
        ...newItems[selectedRowIndex],
        alternateUnitPrimaryUnit: data.alternateUnitPrimaryUnit,
        alternateUnitSecondaryUnit: data.alternateUnitSecondaryUnit,
        alternateUnitQuantity: data.alternateUnitQuantity,
      };
      return newItems;
    });
    setToggleAltUnit(false);
    if (inputMoqRef.current[selectedRowIndex]) {
      inputMoqRef?.current[selectedRowIndex]?.focus();
    }
  };


  const handleBulkRateData = (data: BulkRateEntry[]) => {
    if (selectedRowIndex === null || selectedRowIndex < 0) {
      return;
    }
    setDataSource((prevItems: any) => {
      const newItems = [...prevItems];
      newItems[selectedRowIndex] = {
        ...newItems[selectedRowIndex],
        productBulkRates: data,
      };
      return newItems;
    });
    setToggleBulkRate(false);
    if ((dataSource.length - 1) === selectedRowIndex) {
      setTimeout(() => {
        document.getElementById("add-size-btn-id")?.click();
      }, 100);
    }
  };

  const handleProductSizeUnitChange = (value: string, key: number) => {
    const newData = dataSource.map((item, index) =>
      index === key ? { ...item, productSizeUnit: value } : item,
    );
    setDataSource(newData);
  };

  const onSwitchChange = (data: boolean, index: number) => {
    const value = dataSource.filter((data) => data.isActive);
    if (value.length > 1 || data) {
      setDataSource((prevItems: any) => {
        const newItems = [...prevItems];
        newItems[index] = {
          ...newItems[index],
          isActive: data,
        };
        return newItems;
      });
    } else {
      if (productId) {
        setIsConfirmToggle(index.toString());
      } else {
        showMessage("error", "At least one size should remain.");
      }
    }
  };

  const defaultFields = productManageFields?.filter((fieldItem) => !fieldItem?.isDefault) || [];

  const handleAdditionalColumnChange = (
    value: string,
    fieldName: string,
    index: number,
  ) => {
    setDataSource((prev: DataType) =>
      prev.map((item: any, i: number) => {
        if (i === index) {
          const updatedCustomFields = item?.productSizeCustomFieldsData?.map((customField: any) =>
            customField.fieldName === fieldName
              ? { ...customField, fieldValue: value }
              : customField,
          );
          return { ...item, productSizeCustomFieldsData: updatedCustomFields };
        }
        return item;
      }),
    );
  };

  const defaultColumns = [
    {
      title: "Product Size",
      dataIndex: "productSize",
      width: 450,
      render: (text: string, record: DataType, index: number) => {
        return (
          isEditable && editingKey === index ?
            <Input
              placeholder={index >= 3 ? "-" : "Enter Product Size"}
              style={{ minWidth: 20 }}
              maxLength={Limit.productSize}
              value={record.productSize}
              autoFocus={isEditable}
              onBlur={() => { setIsEditable(false); }}
              className={(errors?.dataSource[index]?.productSize ? "error" : "")}
              onKeyDown={handleKeyDown}
              onChange={(e) => {
                setDataSource((prev: any[]) =>
                  prev.map((item: any, i: number) => (i === index ? { ...item, productSize: e.target.value } : item)),
                );
                setErrors && setErrors({
                  ...errors,
                  dataSource: errors.dataSource.map((data, indexid) => indexid === index ? { ...data, productSize: false } : data),
                });
              }
              }
              addonAfter={
                <SelectDropdown
                  placeholder={index > 3 ? "-" : "Select Unit"}
                  dropdownStyle={{ minWidth: 200 }}
                  value={record.productSizeUnit}
                  onFocus={() => { setIsEditable(true); }}
                  onBlur={() => { setIsEditable(false); }}
                  onChange={(value) => handleProductSizeUnitChange(value, index)}
                  label=""
                  options={productUnits?.data || []}
                  className="size-unit-style"
                />
              }
            />
            : <Input
              style={{ width: "100%" }}
              className={(errors?.dataSource[index]?.productSize ? "error" : "") + " productSize-text table-input"}
              value={text && (text + " " + record.productSizeUnit)}
              placeholder={index >= 3 ? "-" : "Enter Product Size"}
              onClick={() => { setEditingKey(index); setIsEditable(true); }}
            />
        );
      },
    },
    {
      title: "Price per Unit",
      dataIndex: "pricePerUnit",
      width: 150,
      placeholder: "Enter price per unit",
      render: (text: string, record: DataType, index: number) => (
        <Input
          className={"table-input " + (errors?.dataSource[index]?.pricePerUnit ? "error" : "")}
          value={record.pricePerUnit}
          onChange={(e) => {
            const regex = /^\d{0,6}(\.\d{0,2})?$/;
            if (regex.test(e.target.value)) {
              setDataSource((prev: any) =>
                prev.map((item: any, i: number) => (i === index ? { ...item, pricePerUnit: /^\d{1,6}(\.\d{0,2})?$/.test(e.target.value) ? cleanValue(e.target.value.toString()) : "" } : item)),
              );
              setErrors && setErrors({
                ...errors,
                dataSource: errors.dataSource.map((data, indexid) => indexid === index ? { ...data, pricePerUnit: false } : data),
              });
            }
          }
          }
          onKeyDown={handleKeyDown}
          style={{ height: "100%", background: "transparent" }}
          placeholder={index >= 3 ? "-" : "Enter price per unit"}
          autoComplete="off"
          maxLength={Limit.productRate}
          prefix="₹"
        />
      ),
    },
    {
      title: "Order Unit",
      dataIndex: "productSizeOrderUnit",
      placeholder: "Enter Order Unit",
      render: (text: string, record: DataType, index: number) => {
        return (
          <SelectDropdown
            placeholder={index >= 3 ? "-" : "Enter Order Unit"}
            dropdownStyle={{ minWidth: 200 }}
            value={record.productSizeOrderUnit}
            onChange={(value) => {
              setDataSource((prev: any[]) =>
                prev.map((item: any, i: number) => (i === index ? { ...item, productSizeOrderUnit: value, alternateUnitSecondaryUnit: value } : item)),
              );
            }
            }
            label=""
            options={productUnits?.data || []}
            className=""
          />
        );
      },
      width: 150,
    },
    {
      title: "Alternate Unit",
      dataIndex: "alternateUnitPrimaryUnit",
      width: 230,
      placeholder: "Enter alternate unit",
      render: (text: string, record: DataType, index: number) => (
        <Input
          className="table-input"
          value={record.alternateUnitPrimaryUnit ? ("1 " + record.alternateUnitPrimaryUnit + " = " + record.alternateUnitQuantity + " " + record.alternateUnitSecondaryUnit) : ""}
          onClick={() => [setSelectedRowIndex(index), setToggleAltUnit(true)]}
          onChange={() => [setSelectedRowIndex(index), setToggleAltUnit(true)]}
          onFocus={() => [setSelectedRowIndex(index), setToggleAltUnit(true)]}
          onKeyDown={handleKeyDown}
          style={{ height: "100%", background: "transparent" }}
          placeholder={index >= 3 ? "-" : "Enter alternate unit"}
          autoComplete="off"
        />
      ),
    },
    {
      title: "MOQ",
      dataIndex: "moq",
      width: 150,
      placeholder: "Enter MOQ",
      render: (text: string, record: DataType, index: number) => (
        <Input
          ref={(el) => (inputMoqRef.current[index] = el)}
          suffix={record.moq ? record.alternateUnitPrimaryUnit || record.productSizeOrderUnit : " "}
          className="table-input"
          value={record.moq}
          onChange={(e) => {
            const regex = /^\d{0,6}(\.\d{0,2})?$/;
            if (regex.test(e.target.value)) {
              setDataSource((prev: any) =>
                prev.map((item: any, i: number) => (i === index ? { ...item, moq: cleanValue(e.target.value) } : item)),
              );
            }
          }
          }
          onKeyDown={handleKeyDown}
          style={{ height: "100%", background: "transparent" }}
          placeholder={index >= 3 ? "-" : "Enter MOQ"}
          autoComplete="off"
          maxLength={Limit.moq}
        />
      ),
    },
    {
      title: "Bulk Rate",
      dataIndex: "productBulkRates",
      width: 230,
      placeholder: "Enter bulk rate",
      render: (text: string, record: DataType, index: number) => {
        const bulkRate = record?.productBulkRates?.filter((rate: any) => !rate.isDelete)[0];

        const bulkRateDescription = bulkRate?.productQuantity ? (bulkRate?.productQuantity + " " + (record?.alternateUnitPrimaryUnit || record?.productSizeOrderUnit) + " = " + (bulkRate?.isRateInRupees ? "₹" : "") + bulkRate?.productRate) + (!bulkRate?.isRateInRupees ? "%" : "") : "";

        return (
          <Input
            className="table-input"
            value={bulkRateDescription}
            onClick={() => [setSelectedRowIndex(index), setToggleBulkRate(true)]}
            onChange={() => [setSelectedRowIndex(index), setToggleBulkRate(true)]}
            onFocus={() => [setSelectedRowIndex(index), setToggleBulkRate(true)]}
            onKeyDown={handleKeyDown}
            style={{ height: "100%", background: "transparent" }}
            placeholder={index >= 3 ? "-" : "Enter bulk rate"}
            autoComplete="off"
          />
        );
      },
    },
  ];

  const columns = [
    ...defaultColumns,
    // Add dynamically based on the selected options
    ...options
      .filter((item) => item?.isActive && !item?.isDefault && !item?.isDelete)
      .map((item) => ({
        title: item?.fieldName,
        dataIndex: item?.fieldName?.toLowerCase(),
        editable: true,
        width: 150,
        placeholder: `Enter ${item?.fieldName?.toLowerCase()}`,
        render: (text: string, record: DataType, index: number) => {
          const customField = record?.productSizeCustomFieldsData?.find(
            (customField: any) => customField.fieldName === item?.fieldName,
          );
          return (
            <Input
              className="table-input"
              value={customField?.fieldValue || ""}
              onChange={(e) => handleAdditionalColumnChange(e.target.value, item?.fieldName, index)}
              onKeyDown={handleKeyDown}
              style={{ height: "100%", background: "transparent" }}
              placeholder={index >= 3 ? "-" : "Enter " + item?.fieldName}
              autoComplete="off"
            />
          );
        },
      })),
    {
      title: "Size Status",
      dataIndex: "status",
      render: (_: string, record: DataType, index: number) => {
        return (
          <Switch
            value={record.isActive}
            onChange={(data) => onSwitchChange(data, index)}
            className="switch-style" />
        );
      },
      width: 50,
    },
  ];
  const activeColumns = columns.filter(column => {
    const field = productManageFields.find(fieldItem => fieldItem?.fieldName?.toLowerCase() === column?.title?.toLowerCase());
    return field ? field.isActive : true;
  });

  const handleDeleteSize = async () => {
    if (dataSource.length === 1 || dataSource.length === selectedRows.length) {
      showMessage("error", "At least one size should remain.");
      return;
    }

    if (editComponent) {
      const data = dataSource.filter((data, index) => selectedRows.includes(index) && data?.productSizeId);
      if (data.length) {
        const productSizes = dataSource.filter((data, index) => selectedRows.includes(index)).map((data) => data.productSizeId);
        const payload = {
          productSizeIds: productSizes,
        };
        await dispatch(deleteSizes(payload));
        setDataSource((prevItems: any[]) => prevItems.filter((data, index) => !selectedRows.includes(data.key)));
      } else {
        setDataSource((prevItems: any[]) => prevItems.filter((data, index) => !selectedRows.includes(data.key)));
        setSelectedRows([]);
      }
    } else {
      setDataSource((prevItems: any[]) => prevItems.filter((data, index) => !selectedRows.includes(data.key)));
      setSelectedRows([]);
    }
  };

  const handleDeleteProduct = () => {
    setDataSource((prevItems: any) => {
      const newItems = [...prevItems];
      newItems[+isConfirmToggle] = {
        ...newItems[+isConfirmToggle],
        isActive: false,
      };
      return newItems;
    });
    setIsConfirmToggle("");
    // if (productId) {
    // const payload = {
    //   productIds: [productId],
    //   isActive: false,
    // };
    // dispatch(activeInactiveProduct(payload)).then((result) => {
    //   if (result.payload.status === 200) {
    //     closeEditModal && closeEditModal();
    //     message.success(result.payload.message);
    //   } else {
    //     message.error(result.payload.message);
    //   }
    // });
    // }
  };

  const handleAddSize = () => {
    handleAdd();
    setIsEditable(false);
    setEditingKey(dataSource.length);
    setIsEditable(true);
  };

  return (
    <div className="card table-container">
      {contextHolder}
      <div className="products-price-info-table">
        <div className="flex alignCenter justifyBetween product-table-header">
          <div className="flex alignCenter left-header">
            <h3 className="title-4">Price & More Info.</h3>
            <Button onClick={handleAddSize} type="button" name="" className="text primary add-size-btn">
              <PlusOutlined /> Add Size
            </Button>
          </div>
          <div className="flex alignCenter right-header">
            {selectedRows.length > 0 &&
              <Popconfirm
                title="Are you sure you want to delete the selected sizes?"
                onConfirm={handleDeleteSize}
                okText="Yes"
                cancelText="No"
                disabled={selectedRows.length === 0}
              >
                <Button type="button" name="" className="text red ">
                  <DeleteIcon /> Remove Size
                </Button>
              </Popconfirm>
            }
            {isToggle.customeField ?
              <div className="add-custom-columns managefield-container flex alignCenter w-100">
                <CreateCustomField setOptions={setOptions} options={options} fieldType={2} isOpen={isToggle?.customeField} setIsOpen={() => setIsToggle({ ...isToggle, customeField: false })} setSuggestedOptions={setSuggestedOptions} suggestedOption={suggestedOptions} />
                <button className="close-icon" onClick={() => setIsToggle({ ...isToggle, customeField: false })}><CrossRoundedIcon /></button>
              </div>
              :
              <button className="create-custom-button primary text" onClick={() => setIsToggle({ ...isToggle, customeField: true })}><PlusFilledIcon /> Custom Field</button>
            }
          </div>
        </div>
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          rowClassName={() => "editable-row"}
          dataSource={dataSource}
          columns={activeColumns}
          pagination={false}
          scroll={{ x: 1780 }}
        />
        <div className="table-footer">
          <Button onClick={handleAddSize} type="button" name="" className="text primary add-size-btn" id="add-size-btn-id">
            <PlusOutlined /> Add Size
          </Button>
        </div>
        {isConfirmToggle && productId &&
          <ConfirmationPopup
            open={isConfirmToggle !== ""}
            close={() => { setIsConfirmToggle(""); }}
            confirm={() => handleDeleteProduct()}
            infoContent="Are you sure, you want to Inactive this Product?"
            cancelButtonText="No"
            confirmButtonText="Yes, Inactive"
          />}
        {toggleAltUnit && <AltUnit selectedRowIndex={selectedRowIndex} dataSource={dataSource} onSave={handleSaveAltData} open={toggleAltUnit} close={() => setToggleAltUnit(false)} />}
        {toggleBulkRate && <BulkRateModal selectedRowIndex={selectedRowIndex} dataSource={dataSource} onSave={handleBulkRateData} open={toggleBulkRate} close={() => setToggleBulkRate(false)} />}
      </div>
    </div>
  );
};

export default ProductsListTable;