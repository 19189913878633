import React from "react";

const SearchIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M17.5 17.5039L14.5834 14.5872M16.6667 9.58724C16.6667 13.4993 13.4954 16.6706 9.58333 16.6706C5.67132 16.6706 2.5 13.4993 2.5 9.58724C2.5 5.67522 5.67132 2.50391 9.58333 2.50391C13.4954 2.50391 16.6667 5.67522 16.6667 9.58724Z" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SearchIcon;