import React, { useEffect, useState } from "react";
import { Popconfirm, Tooltip } from "antd";

import { useAppSelector } from "../../hooks/useAppSelector";
import CommonModal from "../commonModal";
import SelectDropdown from "../selectDropdown";
import InputWithDropdown from "../inputWithDropdown";
import { AltUnitState } from "../../types/productsType";
import { cleanValue } from "../../utils/helper";
import Limit from "../../utils/limits.json";

interface Props {
  dataSource: any;
  open: boolean;
  close: () => void;
  onSave: (data: AltUnitState) => void;
  selectedRowIndex: number | null;
}

const AltUnit = ({ onSave, dataSource, open, close, selectedRowIndex }: Props) => {

  const [state, setState] = useState<AltUnitState>({
    alternateUnitPrimaryUnit: "",
    alternateUnitSecondaryUnit: "",
    alternateUnitQuantity: "",
  });
  const [errors, setErrors] = useState<Partial<Record<keyof AltUnitState, boolean>>>({});

  const { productUnits } = useAppSelector((state) => state.products);

  useEffect(() => {
    if (dataSource) {
      setState({
        alternateUnitPrimaryUnit: selectedRowIndex !== null ? dataSource[selectedRowIndex]?.alternateUnitPrimaryUnit ?? dataSource[selectedRowIndex]?.alternateUnitPrimaryUnit : "",
        alternateUnitSecondaryUnit: selectedRowIndex !== null ? (dataSource[selectedRowIndex]?.alternateUnitSecondaryUnit ? dataSource[selectedRowIndex]?.alternateUnitSecondaryUnit : dataSource[selectedRowIndex || 0]?.productSizeOrderUnit || "") : "",
        alternateUnitQuantity: selectedRowIndex !== null ? dataSource[selectedRowIndex]?.alternateUnitQuantity ?? dataSource[selectedRowIndex]?.alternateUnitQuantity : "",
      });
    }
  }, [dataSource]);


  const handleChange = (key: keyof AltUnitState) => (value: string | number) => {
    if (key === "alternateUnitQuantity") {
      const regex = /^\d{0,5}(\.\d{0,2})?$/;
      if (regex.test(value.toString())) {
        setState(prevState => ({
          ...prevState,
          alternateUnitQuantity: /^\d{1,5}(\.\d{0,2})?$/.test(value.toString()) ? cleanValue(value.toString()) : "",
        }));
        if (cleanValue(value.toString())) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [key]: undefined,
          }));
        }
      }
      return;
    }
    setState(prevState => ({
      ...prevState,
      [key]: typeof value === "string" && key.includes("Rate") ? parseFloat(value) : value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [key]: undefined,
    }));
  };
  const validate = () => {
    const newErrors: Partial<Record<keyof AltUnitState, boolean>> = {};
    if (!state.alternateUnitPrimaryUnit) newErrors.alternateUnitPrimaryUnit = true;
    if (!state.alternateUnitSecondaryUnit) newErrors.alternateUnitSecondaryUnit = true;
    if (!state.alternateUnitQuantity || parseFloat(state.alternateUnitQuantity.toString()) <= 0 ) newErrors.alternateUnitQuantity = true;
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (validate()) {
      onSave(state);
    }
  };

  const handleRemoveAlt = () => {
    const data = {
      alternateUnitPrimaryUnit: "",
      alternateUnitSecondaryUnit: "",
      alternateUnitQuantity: 0,
    };

    onSave(data);
  };

  return (
    <CommonModal
      open={open}
      close={close}
      closeIcon
      className="altUnit-modal-container modal-container"
      header="Alternate Unit"
      info="Set different units for your items. Enter the quantity and choose the unit type."
    >
      <div className="flex direction-column gap-2 alt-unit-box">
        <div className="flex direction-column gap-2 alt-content">
          <div className="flex gap-6">
            <SelectDropdown
              value={state?.alternateUnitPrimaryUnit}
              onChange={handleChange("alternateUnitPrimaryUnit")}
              required
              label="Primary Unit"
              options={productUnits?.data ? productUnits.data : []}
              placeholder="BOX"
              className={errors.alternateUnitPrimaryUnit ? "error" : ""}
              autoFocus
            />
            <Tooltip placement="topLeft" title={"Update order Unit"}>
              <SelectDropdown
                disabled
                value={state?.alternateUnitSecondaryUnit || "NOS"}
                onChange={handleChange("alternateUnitSecondaryUnit")}
                required
                label="Secondary Unit"
                options={[]}
                placeholder="PCS"
                className={errors.alternateUnitSecondaryUnit ? "error" : ""}
                optionRender={(option) => (
                  <>
                    <span className="unit-dropdown-styles">
                      {option.value} <span className="sub-label">({option.label})</span>
                    </span>
                  </>
                )}
              />
            </Tooltip>
          </div>

          <div className="flex alignEnd gap-3">
            <InputWithDropdown
              onChange={(e) => []}
              label="Select Conversation Rate"
              suffixSelector={state.alternateUnitPrimaryUnit ? state.alternateUnitPrimaryUnit : "BOX"}
              placeholder="Size (Ex. 100)"
              value="1"
              disabled
            />
            <span className="alt-equalto">=</span>
            <InputWithDropdown
              onChange={(e) => handleChange("alternateUnitQuantity")(e.target.value)}
              suffixSelector={state.alternateUnitSecondaryUnit ? state.alternateUnitSecondaryUnit : "BOX"}
              placeholder="Size (Ex. 100)"
              value={state?.alternateUnitQuantity}
              className={errors.alternateUnitQuantity ? "error" : ""}
              maxLength={Limit.altQty}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-3 justifyEnd footer">
        <Popconfirm
          title="Remove Alternate Unit ?"
          description="Are you sure, you want to remove alternate Unit?"
          onConfirm={handleRemoveAlt}
          onCancel={() => []}
          okText="Yes"
          cancelText="No"
        >
          <button
            className="primary secondary rounded-8 large40 modal-button"
          >
            Remove
          </button>
        </Popconfirm>
        <button
          type="button"
          onClick={handleSave}
          className="primary rounded-8 large40 modal-button"
        >
          Save
        </button>
      </div>
    </CommonModal>
  );
};

export default AltUnit;