import React, { useCallback, useEffect, useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DownArrow, DownArrowFull, HeartIcon, NoImagesIcon } from "../../assets/images/icons";
import SearchBar from "../../components/searchBar";
import { getProductDetails } from "../../redux/services/products";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { addToCart, getAllSizesWithSearch, getCartDataByVendorID, removeCartItem } from "../../redux/services/placeOrder";
import { debounce } from "../../utils/debounce";
import { formatRupees } from "../../utils/helper";
import CounterButton from "../../components/counterButton";
import NoDataFound from "../../components/NoDataFound";

// interface Props {
//   productId: number;
//   handleBack: () => void;
// }

interface CompressedItem {
  productId: number;
  productName: string;
  productImage: string;
  pricePerUnit: number;
  sizes: {
    quantity: number;
    productSizeId: number;
    productSize: string;
    productSizeUnit: string;
    productSizeOrderUnit: string;
    moq: number;
    productBulkRates: {
      isRateInRupees: boolean,
      placePosition: number,
      productBulkRateId: number,
      productQuantity: number,
      productRate: number
    }[];
  }[];
}

const ProductDetails = () => {
  const [sizes, setSizes] = useState<{ quantity: number, productSizeId: number }[]>([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [sizeQuery, setSizeQuery] = useState<string>("");
  const [sizeMoreDetails, setSizeMoreDetails] = useState<number | null>(null);
  const [showAll, setShowAll] = useState(false);
  const [bulkRate, setBulkRate] = useState<any>([]);
  const [bulkRateData, setBulkRateData] = useState<any>([]);
  const [tempData, setTempData] = useState<any>([]);

  const { id } = useParams<{ id: string }>();

  const location = useLocation().state;

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const productId = Number(id);

  const { selectedProductDetails } = useAppSelector((state) => state.products);
  const { getAllSizes } = useAppSelector((state) => state.placeorder);
  const { cart } = useAppSelector(state => state.placeorder);

  const visibleFields = showAll ? selectedProductDetails?.productCustomFields : selectedProductDetails?.productCustomFields.slice(0, 2);

  const compressedItems = cart.data?.cartItems?.reduce((acc: CompressedItem[], item) => {
    const existingProduct = acc?.find(product => product?.productId === item?.product?.productId);

    if (existingProduct) {
      existingProduct.sizes.push({
        productSizeId: item.productSize.productSizeId,
        productSize: item.productSize.productSize,
        productSizeUnit: item.productSize.productSizeUnit,
        productSizeOrderUnit: item.productSize.productSizeOrderUnit,
        quantity: item.quantity,
        productBulkRates: item.productSize.productBulkRates,
        moq: item?.productSize.moq,
      });
    } else {
      acc.push({
        productId: item.product.productId,
        productName: item.product.productName,
        productImage: item.product.productImage?.thumbnailURL,
        pricePerUnit: item.pricePerUnit,
        sizes: [
          {
            quantity: item.quantity,
            productSizeId: item.productSize.productSizeId,
            productSize: item.productSize.productSize,
            productSizeUnit: item.productSize.productSizeUnit,
            productSizeOrderUnit: item.productSize.productSizeOrderUnit,
            productBulkRates: item.productSize.productBulkRates,
            moq: item?.productSize.moq,
          },
        ],
      });
    }

    return acc;
  }, []);

  const cartData = compressedItems?.find((item) => (item?.productId === productId));

  useEffect(() => {
    dispatch(getProductDetails(productId));
  }, [dispatch, productId]);

  // Debounced function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((query: string) => {
      const payload = {
        productId: productId,
        search: query,
        limit: 10,
      };
      dispatch(getAllSizesWithSearch(payload));

    }, 500), // 500ms debounce delay
    [dispatch],
  );

  useEffect(() => {
    handleSearch(sizeQuery);
  }, [sizeQuery]);

  useEffect(() => {
    if (selectedProductDetails?.productImage) {
      setSelectedImage(selectedProductDetails?.productImage?.webURLs[0]);
    }
  }, [selectedProductDetails]);

  const newArr: any = [];

  useEffect(() => {
    getAllSizes.data?.map((size) => {
      newArr.push(
        size?.productBulkRates?.filter((item) => item.productQuantity && item.productRate && size.moq <= item.productQuantity).sort((a: any, b: any) => b.productQuantity - a.productQuantity),
      );
      setBulkRateData(newArr);
    });
  }, [getAllSizes]);

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
  };

  useEffect(() => {
    if (getAllSizes.data) {
      const newArr = getAllSizes.data?.map((product) => {
        const tempData = cartData?.sizes?.find((item) => (item.productSizeId === product.productSizeId));
        return {
          quantity: tempData?.quantity || 0,
          productSizeId: product.productSizeId,
        };
      });
      setSizes(newArr);
      setTempData(newArr);
    }
  }, [getAllSizes]);

  function findIndexBetween(arr: any[], value: any) {
    let applicableRate = null;
    if (arr?.length) {
      for (const bulkRate of arr) {
        if (bulkRate.productQuantity !== 0) {
          const quantity = bulkRate.productQuantity ?? 0; // Default to 0 if undefined
          if (quantity <= value) {
            applicableRate = bulkRate;
            break;
          }
        } else {
          applicableRate = null;
          break;
        }
      }
      return applicableRate;
    }
  }

  function updateOrPush(array: any, newItem: any) {
    const index = array?.findIndex((item: any) => item.sizeId === newItem.sizeId);

    if (index !== -1 && array?.length) {
      // Update the existing item
      array[index] = newItem;
    } else {
      // Push the new item if sizeId not found
      array?.push(newItem);
    }
    setBulkRate(array);
    return array;
  }

  const handleSelectBulkRate = (sizeId: number, rate: any) => {
    const tempData = sizes ? sizes.map(size =>
      size.productSizeId === sizeId && size.quantity >= 0 ? { ...size, quantity: Number(rate?.productQuantity) || 0 } : size,
    ) : sizes;
    setSizes(tempData);
    setTempData(tempData);
    updateOrPush(bulkRate, { sizeId: sizeId, rate: rate });

    const data = sizes.map(size =>
      size.productSizeId === sizeId && size.quantity >= 0 ? { ...size, quantity: Number(rate?.productQuantity) || 0 } : size,
    ).filter((data) => data.productSizeId === sizeId);
    handleUpdateCart(data, productId);
  };

  function calculatePrice(oldPrice: number, newPrice?: number) {
    const price = oldPrice ?? 0; // Original price
    const percentage = newPrice ?? 0; // Percentage to subtract

    // Calculate the amount to subtract
    const amountToSubtract = (price * percentage) / 100;
    return formatRupees(newPrice ? price - amountToSubtract : oldPrice);
  }

  const handleUpdateCart = useCallback(
    debounce((sizes: any, productId: number) => {
      dispatch(addToCart({
        vendorId: location?.orderId,
        items: [{
          productId: productId,
          productSizes: sizes,
        }],
      })).then((result) => {
        if (result.payload.status === 200) {
          dispatch(getCartDataByVendorID({
            vendorId: location?.orderId,
          }));
        }
      });
    }, 500), // 500ms debounce delay
    [dispatch],
  );

  const handleIncrement = (id: number, maxValue: number) => {
    const data = sizes.map(size =>
      size.productSizeId === id ? { ...size, quantity: (size.quantity >= maxValue ? size.quantity + 1 : maxValue) } : size,
    );
    setTempData(data);
    setSizes(data);

    const tempNew = sizes.map(size =>
      size.productSizeId === id ? { ...size, quantity: (size.quantity >= maxValue ? size.quantity + 1 : maxValue) } : size,
    ).filter((data) => data.productSizeId === id);
    handleUpdateCart(tempNew, productId);
    setSizeMoreDetails(id);
  };

  const removeCartItemData = (productSizeId: number) => {
    const cartItemId = cart.data?.cartItems.filter((data) => data.productSize.productSizeId === productSizeId)[0]?.cartItemId;
    if (cartItemId) {
      dispatch(removeCartItem({
        cartId: cart.data?.cartId || 0,
        cartItemIds: [cartItemId],
      })).then((result) => {
        if (result?.payload?.status === 200) {
          dispatch(getCartDataByVendorID({
            vendorId: location?.orderId,
          }));
        }
      });
    }
  };

  const handleDecrement = (id: number, maxValue: number) => {
    const data = sizes.map(size =>
      size.productSizeId === id ? { ...size, quantity: (size.quantity - 1 >= maxValue ? size.quantity - 1 : 0) } : size,
    );
    setTempData(data);
    setSizes(data);

    const tempNew = sizes.map(size =>
      size.productSizeId === id ? { ...size, quantity: (size.quantity - 1 >= maxValue ? size.quantity - 1 : 0) } : size,
    ).filter((data) => data.productSizeId === id);

    if (tempNew[0].quantity === 0) {
      removeCartItemData(id);
    } else {
      handleUpdateCart(tempNew, productId);
    }
    setSizeMoreDetails(id);
  };

  const handleInputChange = (id: number, qty: string) => {
    const regex = /^\d{0,6}(\.\d{0,2})?$/;
    if (!qty) {
      setTempData(sizes ? sizes.map(size =>
        size.productSizeId === id && size.quantity >= 0 ? { ...size, quantity: "0" } : size,
      ) : sizes);
    } if (regex.test(qty)) {
      if (Number(qty) === 0) {
        setTempData(sizes ? sizes.map(size =>
          size.productSizeId === id && size.quantity >= 0 ? { ...size, quantity: "0" } : size,
        ) : sizes);
      }
      setTempData(sizes ? sizes.map(size =>
        size.productSizeId === id && size.quantity >= 0 ? { ...size, quantity: "0" } : size,
      ) : sizes);
      setSizes(sizes ? sizes.map(size =>
        size.productSizeId === id && size.quantity >= 0 ? { ...size, quantity: Number(qty) || 0 } : size,
      ) : sizes);
    }
  };

  return (
    <div className="card product-details-wrapper">
      <div className="product-details-container justifyCenter">
        <div className="flex direction-column gap-4 justifyCenter center-container">
          <div className="back-button-container">
            <button className="back-btn" onClick={() => navigate(-1)}>
              <DownArrowFull />
              <span>Back</span>
            </button>
          </div>
          <div className="flex gap-6">
            <div className="flex direction-column gap-4">
              <div className={"image-container flex bg-light"}>
                {selectedImage ?
                  <img src={selectedImage} alt="Product-Image" className="product-image" /> :
                  <div className="flex direction-column alignCenter gap-2 no-images-icon">
                    <NoImagesIcon />
                    <h3>No images available</h3>
                  </div>}
                {/* <div className="offer">56 %<br /> OFF</div> */}
                <div className="heart-box">
                  <HeartIcon />
                </div>
              </div>

              <div className="other-images-container">
                {selectedProductDetails?.productImage?.webURLs &&
                  selectedProductDetails?.productImage?.webURLs?.map((image, index) => <div key={index} className={`image-box ${selectedImage === image ? "active" : ""}`} onClick={() => handleImageClick(image)}>
                    <img src={image} alt="Product-Image" className="product-image" />
                  </div>)}
              </div>
            </div>
            <div className="details-container flex direction-column gap-6">
              <div className="product-name-description gap-2">
                <h2 className="product-name">{selectedProductDetails?.productName}</h2>
                {/* <p className="product-info">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus necLorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus necdolor sit amet</p> */}
              </div>
              <div className="product-details-box flex direction-column">
                {/* <h4 className="product-details-header">Product Details</h4> */}
                {selectedProductDetails?.productDescription ? <div className="flex direction-column gap-1">
                  <div className="content-header">Description</div>
                  <p className="content-info" dangerouslySetInnerHTML={{ __html: selectedProductDetails?.productDescription }}></p>
                </div> : <></>}
                {visibleFields && visibleFields.map((data, index) => (
                  <div key={index} className="flex direction-column gap-1">
                    <div className="content-header">{data.fieldName}</div>
                    <div className="content-info">{data.fieldValue}</div>
                  </div>
                ))}
                {selectedProductDetails && selectedProductDetails?.productCustomFields.length > 2 && (
                  <button className="view-more-btn flex alignCenter" onClick={() => setShowAll(!showAll)}>
                    {showAll ? <span className="up-arrow flex alignCenter">View Less<DownArrow /></span> : <span className="down-arrow flex alignCenter">View More<DownArrow /></span>}
                  </button>
                )}
              </div>
              <div className="flex direction-column gap-4 select-sizes-container">
                <div className="header-box flex direction-column gap-2">
                  <h4 className="select-size-header">Select Size</h4>
                  <SearchBar onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSizeQuery(e.target.value)} value={sizeQuery} placeholder="Search Size" />
                </div>

                {getAllSizes.data?.length ?
                  getAllSizes.data?.map((data, index) => {
                    const activeBulkRate = (findIndexBetween(bulkRateData?.[index], sizes[index]?.quantity));
                    const moreData = data.productCustomFields.filter((data) => data.fieldValue.length);
                    return (
                      <div key={index} className="product-sizes-container flex direction-column gap-2">
                        <div className={`product-size-details-box ${sizeMoreDetails === data.productSizeId ? "active-size" : ""} `}>
                          <div className="size-main-details-box">
                            <div className="product-size-name">{data.productSize} {data.productSizeUnit}</div>
                            <div className="product-size-price-box">
                              {activeBulkRate?.isRateInRupees ? formatRupees(activeBulkRate?.productRate || data?.pricePerUnit) : calculatePrice(data?.pricePerUnit, activeBulkRate?.productRate)} <span className="size-unit">/ {data.productSizeOrderUnit}</span>
                              {/* <span className="discounted-price"><del>{formatRupees(data.pricePerUnit)}</del></span> */}
                            </div>

                            {bulkRateData?.[index]?.length ?
                              (<Popover
                                placement="bottom"
                                content={(
                                  <div className="bulkrate-inner-container">
                                    {bulkRateData?.[index]?.map((rate: any, ind: any) => {
                                      return (
                                        <label
                                          key={rate.productBulkRateId}
                                          className={`bulkrate-box flex justifyBetween ${0 >= rate?.productQuantity && "selected"}`}
                                          onClick={() => handleSelectBulkRate(data?.productSizeId, rate)}
                                        >
                                          {rate?.productQuantity} {data?.alternateUnitPrimaryUnit || data?.productSizeOrderUnit} = {rate?.isRateInRupees ? formatRupees(rate?.productRate) : calculatePrice(data?.pricePerUnit, rate?.productRate)} / per {data?.alternateUnitSecondaryUnit || data?.productSizeOrderUnit}
                                          {activeBulkRate?.productBulkRateId && activeBulkRate?.productQuantity === rate?.productQuantity && <CheckOutlined className="selected-icon" />}
                                        </label>
                                      );
                                    })}
                                  </div>
                                )}
                                trigger="click"
                                rootClassName="bulkrate-list-popover-container">
                                <button className="bulkrate-btn flex alignCenter gap-1">Bulk Rate <DownArrow /> </button>
                              </Popover>) : <div className="bulkrate-btn"></div>}
                            {/* <button className="add-size-button" onClick={() => []}>Add</button> */}
                            {/* <div className="unavailable-size"><span className="badge">Currently Unavailable</span></div> */}
                            {data?.isActive ? (!tempData[index]?.quantity) ?
                              (<button className="add-size-button" onClick={() => handleIncrement(data.productSizeId, data.moq)}>Add</button>) :
                              (<div className="counter-button-container flex alignStart">
                                {<CounterButton
                                  handleDecrement={() => handleDecrement(data.productSizeId, data.moq)}
                                  handleIncrement={() => handleIncrement(data.productSizeId, data.moq)}
                                  value={sizes[index]?.quantity || 0}
                                  handleInputChange={(e) => handleInputChange(data.productSizeId, e.target.value)}
                                  onBlur={() => {
                                    setSizes(sizes ? sizes.map(size =>
                                      size.productSizeId === data.productSizeId && size?.quantity >= 0 ? { ...size, quantity: (size?.quantity > 0 && size?.quantity <= data.moq) ? data.moq : size?.quantity } : size,
                                    ) : sizes);
                                    setTempData(sizes ? sizes.map(size =>
                                      size.productSizeId === data.productSizeId && size?.quantity >= 0 ? { ...size, quantity: (size?.quantity > 0 && size?.quantity <= data.moq) ? data.moq : size?.quantity === 0 ? "" : size?.quantity } : size,
                                    ) : sizes);

                                    const tempData = sizes.map(size =>
                                      size.productSizeId === data.productSizeId && size?.quantity >= 0 ? { ...size, quantity: (size?.quantity > 0 && size?.quantity <= data.moq) ? data.moq : size?.quantity } : size,
                                    ).filter((temp) => temp.productSizeId === data.productSizeId);

                                    if (tempData[0].quantity === 0) {
                                      removeCartItemData(data.productSizeId);
                                    } else {
                                      handleUpdateCart(tempData, productId);
                                    }
                                  }}
                                  moq={data?.moq}
                                  // setIsToggleOpen={setIsToggleOpen}
                                  // isToggleOpen={isToggleOpen}
                                  sizeId={sizes[index]?.productSizeId}
                                />}
                              </div>) : <div className="unavailable-size"><span className="badge">Currently Unavailable</span></div>}
                          </div>
                          {(sizeMoreDetails === data.productSizeId && moreData.length) ?
                            <div className="size-details-container">
                              <div className="size-details-header">Size Details</div>
                              {data.productCustomFields.map((data, index) => (
                                data.fieldValue.length ?
                                  <div key={index} className="flex alignCenter gap-1">
                                    <div className="size-item-name">{data.fieldName}</div>
                                    <div className="size-item-value">{data.fieldValue}</div>
                                  </div> : <></>
                              ))}
                            </div> : <></>}
                        </div>
                      </div>
                    );
                  })
                  : <><NoDataFound /></>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;