import React from "react";

import { NoDataIcon } from "../../assets/images";

interface IProps {
  text?: string;
}

const NoDataFound = ({ text }: IProps) => {
  return (
    <div className="no-data-wrapper w-100">
      <div className="no-data-icon">
        <img src={NoDataIcon} alt="nodata-image" />
      </div>
      <p className="caption">{ text || "No data found." }</p>
    </div>
  );
};

export default NoDataFound;