import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Switch, Table, Typography } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";

import Page from "../../components/page";
import SelectedTableRowArea from "../../components/SelectedTableRowArea";
import SearchBar from "../../components/searchBar";
import AddProductModal from "./AddProductModal";
import { AppDispatch, RootState } from "../../redux/store";
import { activeInactiveProduct, deleteProduct, deleteSizes, getListOfProducts, updateProductField, updateProductName, updateProductSizeBulkRateField } from "../../redux/services/products";
import { cleanValue, formatRupees, handleKeyDown } from "../../utils/helper";
import { AltUnitState, BulkRateEntry, DataType, ProductList, ProductTableDataType } from "../../types/productsType";
import AltUnit from "../../components/altUnit/altUnit";
import BulkRateModal from "../../components/bulkRateModal";
import EditProduct from "./editProduct";
import { getExpandedTableData } from "../../redux/services/table";
import endPoins from "../../redux/services/endPoints.json";
import { useAppSelector } from "../../hooks/useAppSelector";
import { CrossRoundedIcon, Logo } from "../../assets/images";
import Limit from "../../utils/limits.json";
import CreateGroup from "./createGroup";
import { debounce } from "../../utils/debounce";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { ChevronDown, ChevronRight } from "../../assets/images/icons";
import SelectDropdown from "../../components/selectDropdown";
import { tableSortingIconToggle } from "../../utils/tableSortingIconToggle";
import useMessageHook from "../../hooks/useMessageHook";
import { ActiveSwitch } from "../../components";

interface Props {
  getGroupId: number;
  className?: string;
}

const ProoductsData = ({ getGroupId, className }: Props) => {
  const [errors, setErrors] = useState<{
    productName: boolean;
    dataSource: { productSize: boolean; pricePerUnit: boolean; productSizeUnit: boolean; productSizeOrderUnit: boolean }[];
  }>({
    productName: false,
    dataSource: [],
  });
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<{ type: string, list: React.Key[] }>({
    type: "product",
    list: [],
  });
  const [addProductModal, setAddProductModal] = useState<boolean>(false);
  const [toggleAltUnit, setToggleAltUnit] = useState(false);
  const [toggleBulkRate, setToggleBulkRate] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [toggleEditProduct, setToggleEditProduct] = useState<boolean>(false);
  const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
  const [productNameInFocus, setProductNameInFocus] = useState<boolean>(false);
  const [editingKey, setEditingKey] = useState(0);
  const [isEditable, setIsEditable] = useState(false);
  const [tempPrice, setTempPrice] = useState("");
  const [isExpanded, setIsExpanded] = useState({
    id: 0,
    isOpen: false,
  });
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [createGroupToggle, setCreateGroupToggle] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [sort, setSort] = useState({
    order: "ASC",
  });
  const { showMessage, contextHolder } = useMessageHook();

  const { productsList, productUnits, productGroups } = useSelector((state: RootState) => state.products);

  const dispatch: AppDispatch = useDispatch();

  const convertToDataSource = (data: ProductList[]) => {
    return data?.map((product) => {
      const baseProduct: any = {
        key: product.productId,
        productName: product.productName,
        moreSizes: product.moreSizes,
        productId: product.productId,
        productStatus: product.isActive,
        isDraft: product.isDraft,
        productDescription: product.productDescription,
        pricePerUnit: product.productSizes[0]?.pricePerUnit?.toString() !== "0" ? (Number(product.productSizes[0]?.pricePerUnit) === Math.floor(Number(product.productSizes[0]?.pricePerUnit)) ? Math.floor(Number(product.productSizes[0]?.pricePerUnit)).toString() : product.productSizes[0]?.pricePerUnit?.toString()) : "" || "",
        productSize: product.productSizes[0]?.productSize || "",
        unit: product.productSizes[0]?.productSizeUnit || "",
        alternateUnit: product.productSizes[0]?.alternateUnitPrimaryUnit || "",
        productSizeOrderUnit: product.productSizes[0]?.productSizeOrderUnit || "",
        moq: product.productSizes[0]?.moq?.toString() !== "0" ? product.productSizes[0]?.moq?.toString() : "" || "",
        productSizeUnit: product.productSizes[0]?.productSizeUnit || "",
        alternateUnitPrimaryUnit: product.productSizes[0]?.alternateUnitPrimaryUnit || "",
        alternateUnitSecondaryUnit: product.productSizes[0]?.alternateUnitSecondaryUnit || product.productSizes[0]?.productSizeOrderUnit || "",
        alternateUnitQuantity: product.productSizes[0]?.alternateUnitQuantity?.toString() !== "0" ? product.productSizes[0]?.alternateUnitQuantity?.toString() : "" || "",
        productBulkRates: product.productSizes[0]?.productBulkRates.map((rate) => ({
          productBulkRateId: rate.productBulkRateId,
          productRate: rate.productRate?.toString() !== "0" ? rate.productRate?.toString() : "" ?? "",
          isRateInRupees: rate.isRateInRupees,
          productQuantity: rate.productQuantity?.toString() !== "0" ? rate.productQuantity?.toString() : "" ?? "",
          isActive: rate.isActive,
          isDelete: rate.isDelete ?? false,
        })) || [],
        productImage: product.productImage,
        productSizeId: product.productSizes[0]?.productSizeId,
      };
      return baseProduct;
    });
  };

  const tableHeight = window.innerHeight;

  useEffect(() => {
    if (productsList && ((page - 1) * Math.round((tableHeight || 0) / 52) <= productsList?.total)) {
      fetchData(page);
    }
  }, [page]);

  useEffect(() => {
    if (productsList !== null) {
      const convertedData = convertToDataSource(productsList.products);
      setDataSource(convertedData);
    }
  }, [dispatch, productsList]);

  useEffect(() => {
    if (toggleEditProduct === false) {
      const payload = {
        search: searchQuery,
        groupId: getGroupId ? Number(getGroupId) : 0,
        vendorId: 0,
        limit: Math.round((tableHeight || 0) / 52),
        offset: 0,
        forGroup: false,
        sort: sort.order,
      };
      dispatch(getListOfProducts(payload));
    }
  }, [getGroupId, dispatch, tableHeight, toggleEditProduct, sort]);

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedRowsData.list,
    onChange: (selectedRowKeys: React.Key[], selectedRows: ProductTableDataType[]) => {
      const allSelectedRowKeys = new Set(selectedRowKeys);
      selectedRows.forEach(row => {
        if (row?.children) {
          row.children.forEach((child: { key: React.Key; }) => {
            allSelectedRowKeys.add(child.key);
          });
        }
      });

      setSelectedRowsData({ type: "product", list: (Array.from(allSelectedRowKeys)) });
    },
  };

  const onExpand = (expanded: boolean, record: DataType) => {
    setIsExpanded((prev) => { return { ...prev, id: !prev.isOpen ? record.key : 0, isOpen: !prev.isOpen }; });
    setExpandedRowKeys(expanded ? [record.key] : []);
    dispatch(getExpandedTableData({ path: `${endPoins.getProductSizes}/${record.key}` }));
  };

  const onSwitchChange = (checked: boolean, Index: number, productId: number) => {

    const payload = {
      productIds: [productId],
      isActive: checked,
    };
    dispatch(activeInactiveProduct(payload)).then((result) => {
      if (result.payload.status === 200) {
        setDataSource((prevItems: any) => {
          const newItems = [...prevItems];
          newItems[Index] = {
            ...newItems[Index],
            productStatus: checked,
          };
          return newItems;
        });
        const listPayload = {
          search: searchQuery,
          groupId: getGroupId ? Number(getGroupId) : 0,
          vendorId: 0,
          limit: page * Math.round((tableHeight || 0) / 52),
          offset: 0,
          sort: sort.order,
        };
        dispatch(getListOfProducts(listPayload));
        showMessage("success", result?.payload?.message);
      } else {
        showMessage("error", result?.payload.message || "Something went wrong");
      }
    });
    // updateOnChange({ isActive: checked }, dataSource[Index].productSizeId);
    setToggleAltUnit(false);
  };

  const renameProductSave = (inputId: string, productId: number) => {
    const inputElement = document.getElementById(inputId) as HTMLInputElement;
    const newProductName = inputElement ? inputElement.value : "";
    const payload = {
      productId: productId,
      productName: newProductName,
    };
    const listPayload = {
      search: searchQuery,
      groupId: getGroupId ? Number(getGroupId) : 0,
      vendorId: 0,
      limit: Math.round((tableHeight || 0) / 52),
      offset: 0,
      sort: sort.order,
    };
    dispatch(updateProductName(payload)).then(result => {
      if (result.payload.status === 200) {
        showMessage("success", result?.payload?.message);
        setSelectedProductId(null);
        setProductNameInFocus(false);
        dispatch(getListOfProducts(listPayload));
      } else {
        showMessage("error", result?.payload.message || "Something went wrong");
      }
    });
  };

  const handleProductSizeUnitChange = (value: string, key: number) => {
    const newData = dataSource?.map((item, index) =>
      index === key ? { ...item, productSizeUnit: value } : item,
    );
    setDataSource(newData);
  };

  const handleSaveAltData = (data: AltUnitState) => {
    if (selectedRowIndex === null || selectedRowIndex < 0) {
      return;
    }

    setDataSource((prevItems: any) => {
      const newItems = [...prevItems];
      newItems[selectedRowIndex] = {
        ...newItems[selectedRowIndex],
        alternateUnitPrimaryUnit: data.alternateUnitPrimaryUnit,
        alternateUnitSecondaryUnit: data.alternateUnitSecondaryUnit,
        alternateUnitQuantity: data.alternateUnitQuantity,
      };
      return newItems;
    });
    updateOnChange({
      alternateUnitPrimaryUnit: data.alternateUnitPrimaryUnit,
      alternateUnitSecondaryUnit: data.alternateUnitSecondaryUnit,
      alternateUnitQuantity: data.alternateUnitQuantity,
    }, dataSource[selectedRowIndex].productSizeId, "status");
    fetchData(page);
    setToggleAltUnit(false);
  };

  const convertToNumber = (value: string | number): number => {
    return typeof value === "string" && value.trim() ? parseFloat(value) : 0;
  };


  const handleBulkRateData = (data: BulkRateEntry[]) => {
    const filteredData = data.filter((Bulkrate) => Bulkrate.productBulkRateId || (!Bulkrate.productBulkRateId && !Bulkrate.isDelete));

    const newBulkRateData = filteredData?.map((item) => ({ ...item, productQuantity: Number(item.productQuantity), productRate: Number(item.productRate) }));
    if (selectedRowIndex === null || selectedRowIndex < 0) {
      return;
    }

    setDataSource((prevItems: any) => {
      const newItems = [...prevItems];
      newItems[selectedRowIndex] = {
        ...newItems[selectedRowIndex],
        productBulkRates: filteredData,
      };
      return newItems;
    });

    // const newBulkTempDataForedit = data.map(({ key, ...rest }) => rest);
    updateBulkRateOnChange(newBulkRateData, dataSource[selectedRowIndex].productSizeId);
    fetchData(page);
    setToggleBulkRate(false);
  };

  const handleChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setDataSource((prev: any[]) =>
      prev.map((item: any, i: number) => (i === index ? {
        ...item,
        [name]: value,
      } : item)),
    );
  };


  const updateOnChange = (data: any, productSizeId: number, status?: string) => {
    (isChanged || status === "status") && dispatch(updateProductField({ productSizeId, data })).then(result => {
      if (result.payload.status === 200) {
        showMessage("success", result?.payload.message);
        fetchData(page);
      } else {
        showMessage("error", result?.payload.message || "Something went wrong");
      }
    });
  };

  const updateBulkRateOnChange = (data: any, productSizeId: number) => {
    dispatch(updateProductSizeBulkRateField({ productSizeId, data })).then(result => {
      if (result.payload.status === 200) {
        showMessage("success", result?.payload.message);
      } else {
        showMessage("error", result?.payload.message || "Something went wrong");
      }
    });
  };

  const defaultColumns = [
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    {
      title: "Product Name",
      dataIndex: "productName",
      width: 420,
      placeholder: "Enter product size",
      sorter: dataSource?.length ? true : false,
      sortIcon: (sortOrder: any) => tableSortingIconToggle(sortOrder),
      render: (_: string, record: ProductTableDataType, index: number) => {
        return (
          <div className="edit-table-input-wrapper gap-3 flex alignCenter h-100">
            <div className="product-image rounded-4 flex alignCenter justifyCenter">
              {record?.productImage?.thumbnailURL ? <img src={record?.productImage?.thumbnailURL} alt="product-image" /> : <Logo />}
            </div>
            {productNameInFocus && selectedProductId === record.key ? <div className="product-name-input-container flex alignCenter w-100 rounded-8">
              <input
                id={`productName-${record.productId}`}
                type="text"
                name=""
                placeholder="Enter Product Name"
                onKeyDown={(e) => {
                  handleKeyDown(e);
                  if (e.key === "Enter") {
                    renameProductSave(`productName-${record.productId}`, record.productId);
                  }
                }}
                value={record?.productName}
                onChange={(e) => {
                  setDataSource((prev: any[]) =>
                    prev.map((item: any, i: number) => (i === index ? { ...item, productName: e.target.value } : item)),
                  );
                }}
                className="rouned-8"
              />
              <div className="icon-container gap-1 alignCenter flex">
                <button type="button" className="save-field-btn flex alignCenter" onClick={() => renameProductSave(`productName-${record.productId}`, record.productId)}>
                  Save
                </button>
                <button type="button" className="save-field-btn flex alignCenter" onClick={() => { setSelectedProductId(null); setProductNameInFocus(false); }}>
                  <CrossRoundedIcon />
                </button>
              </div>
            </div> :
              <div className="w-100 flex alignCenter justifyBetween">
                <Typography
                  className={"flex alignCenter h-100 w-100"}
                  onClick={() => { setSelectedProductId(record.key); setToggleEditProduct(true); setProductNameInFocus(false); }}
                  style={{ height: "100%", background: "transparent" }}
                >{record?.productName?.length > 50 ? record?.productName?.slice(0, 50) + "..." : record?.productName}</Typography>
                <div className={isExpanded?.isOpen && isExpanded.id === record.key ? "table-buttons-container gap-1 isOpen alignCenter" : "table-buttons-container alignCenter gap-1"}>
                  <button className="edit-button flex alignCenter justifyCenter rounded-6" onClick={() => {
                    document.getElementById(`productName-${record.productId}`)?.focus();
                    setSelectedProductId(record.key);
                    setProductNameInFocus(true);
                  }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <g clipPath="url(#clip0_4411_12559)">
                        <path d="M10.2729 5.90921L8.0911 3.72739M1.81836 12.1819L3.66438 11.9768C3.88992 11.9518 4.00269 11.9392 4.10809 11.9051C4.20161 11.8748 4.2906 11.8321 4.37266 11.778C4.46515 11.717 4.54538 11.6367 4.70585 11.4763L11.9093 4.27285C12.5118 3.67036 12.5118 2.69352 11.9093 2.09103C11.3068 1.48854 10.33 1.48854 9.72746 2.09103L2.52403 9.29445C2.36357 9.45491 2.28333 9.53515 2.22234 9.62764C2.16823 9.7097 2.12546 9.79869 2.09519 9.89221C2.06106 9.99761 2.04853 10.1104 2.02347 10.3359L1.81836 12.1819Z" stroke="#667085" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_4411_12559">
                          <rect width="13.0909" height="13.0909" fill="white" transform="translate(0.45459 0.45459)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  {record.moreSizes ? <button className="expand-size rounded-4" onClick={() => { onExpand(!isExpanded.isOpen, record); }}>{(isExpanded.isOpen && isExpanded.id === record.key) ? <span>Hide Sizes <ArrowUpOutlined /></span> : <span>View Sizes <ArrowDownOutlined /></span>}</button> : <></>}
                </div>
              </div>
            }
          </div>
        );
      },
    },
    {
      title: "Product Size",
      dataIndex: "productSize",
      width: 180,
      placeholder: "Enter product size",
      render: (text: string, record: ProductTableDataType, index: number) => {
        return (
          isEditable && editingKey === record?.key ?
            <Input
              value={record.productSize}
              className={errors?.dataSource[index]?.productSize ? "error" : ""}
              name="productSize"
              onChange={(e) => handleChange(e, index)}
              onBlur={() => {
                const payload = { productSize: record.productSize };
                setIsEditable(false);
                updateOnChange(payload, record.productSizeId);
                setIsChanged(false);
              }}
              autoFocus={isEditable}
              style={{ backgroundColor: "transparent" }}
              addonAfter={
                <SelectDropdown
                  placeholder={index > 3 ? "-" : "Select Unit"}
                  dropdownStyle={{ minWidth: 200 }}
                  value={record.productSizeUnit}
                  onFocus={() => { setIsEditable(true); }}
                  onBlur={() => { setIsEditable(false); }}
                  onChange={(value) => {
                    const payload = { productSizeUnit: record.productSizeUnit };
                    updateOnChange(payload, record.productSizeId, "status");
                    handleProductSizeUnitChange(value, index);
                  }}
                  label=""
                  options={productUnits?.data || []}
                  className="size-unit-style"
                />
              }
            /> : <button className="productSize-text" onClick={() => { setEditingKey(record?.key); setIsEditable(true); setSelectedProductId(record.key); }}>{text + " " + record.productSizeUnit}</button>
        );
      },
    },
    {
      title: "Price per Unit",
      dataIndex: "pricePerUnit",
      width: 120,
      placeholder: "Enter price per unit",
      render: (_: string, record: ProductTableDataType, index: number) => (
        <Input
          className={"table-input" + (errors?.dataSource[index]?.pricePerUnit ? " error" : "")}
          value={record?.pricePerUnit}
          onChange={(e) => {
            const regex = /^\d{0,6}(\.\d{0,2})?$/;
            if (regex.test(e.target.value)) {
              setDataSource((prev: any) =>
                prev.map((item: any, i: number) => (i === index ? { ...item, pricePerUnit: /^\d{1,6}(\.\d{0,2})?$/.test(e.target.value) ? cleanValue(e.target.value.toString()) : "" } : item)),
              );
              setIsChanged(true);
            }
          }
          }
          onFocus={() => setTempPrice(record.pricePerUnit)}
          onBlur={() => {
            if (convertToNumber(record.pricePerUnit) > 0) {
              const payload = { pricePerUnit: convertToNumber(record.pricePerUnit) };
              setIsEditable(false);
              updateOnChange(payload, record.productSizeId);
              setIsChanged(false);
            } else {
              showMessage("error", "Please enter a valid value");
              setDataSource((prev: any) =>
                prev.map((item: any, i: number) => (i === index ? { ...item, pricePerUnit: cleanValue(tempPrice) } : item)),
              );
            }
          }}
          onKeyDown={handleKeyDown}
          onClick={() => { setSelectedProductId(record.key); }}
          style={{ height: "100%", background: "transparent" }}
          placeholder="Enter price per unit"
          autoComplete="off"
          maxLength={Limit.productRate}
          prefix="₹"
        />
      ),
    },
    {
      title: "Alternate Unit",
      dataIndex: "alternateUnitPrimaryUnit",
      width: 210,
      placeholder: "Enter alternate unit",
      render: (text: string, record: ProductTableDataType, index: number) => {
        return (
          <Input
            className="table-input"
            value={record.alternateUnitPrimaryUnit ? ("1 " + record.alternateUnitPrimaryUnit + " = " + record.alternateUnitQuantity + " " + record.alternateUnitSecondaryUnit) : "-"}
            onClick={() => { setSelectedRowIndex(index); setToggleAltUnit(true); setSelectedProductId(record.key); }}
            onKeyDown={handleKeyDown}
            style={{ height: "100%", background: "transparent" }}
            placeholder="Enter alternate unit"
            autoComplete="off"
          />
        );
      },
    },
    {
      title: "MOQ",
      dataIndex: "moq",
      width: 144,
      placeholder: "Enter MOQ",
      render: (text: string, record: ProductTableDataType, index: number) => (
        <Input
          suffix={record.moq ? record.alternateUnitPrimaryUnit || record.productSizeOrderUnit : " "}
          className="table-input"
          value={record.moq}
          name="moq"
          onChange={(e) => handleChange(e, index)}
          onBlur={() => {
            const payload = { moq: record.moq };
            setIsEditable(false);
            updateOnChange(payload, record.productSizeId);
            setIsChanged(false);
          }}
          style={{ height: "100%", background: "transparent" }}
          placeholder={record.moq ? "Enter MOQ" : "-"}
          maxLength={Limit.moq}
          autoComplete="off"
        />
      ),
    },
    {
      title: "Bulk Rate",
      dataIndex: "productBulkRates",
      width: 210,
      placeholder: "Enter bulk rate",
      render: (text: string, record: ProductTableDataType, index: number) => {
        const activeBulkrates = record.productBulkRates.filter((data)=> data.isActive);
        const bulkrateValue = activeBulkrates[0]?.productQuantity ? (activeBulkrates[0]?.productQuantity + " " + (record.alternateUnitPrimaryUnit || record.productSizeOrderUnit) + " = " + (activeBulkrates[0]?.isRateInRupees ? "₹" : "") + activeBulkrates[0]?.productRate) + (!activeBulkrates[0]?.isRateInRupees ? "%" : "") : "";
        return (
          <Input
            className="table-input"
            value={bulkrateValue}
            onClick={() => { setSelectedRowIndex(index); setToggleBulkRate(true); setSelectedProductId(record.key); }}
            // onClick={() => {setSelectedProductId(record.key); setToggleEditProduct(true);}}
            onKeyDown={handleKeyDown}
            style={{ height: "100%", background: "transparent" }}
            placeholder={index < 3 ? "Enter bulk rate" : "-"}
            autoComplete="off"
          />
        );
      },
    },
    {
      title: "Product Status",
      dataIndex: "status",
      render: (text: string, record: ProductTableDataType, index: number) => {
        return record.isDraft ? <ActiveSwitch>Draft</ActiveSwitch> : <Switch defaultChecked checked={record?.productStatus} onChange={(e) => { onSwitchChange(e, index, record.productId); }} className="switch-style" />;
      },
      width: 110,
    },
  ];

  const CustomExpandIcon = ({ expanded, onExpand, record }: any) => {
    return (
      record?.moreSizes ? (expanded ? (
        <div onClick={e => onExpand(record, e)} className="arrow-icon flex"><ChevronDown /> </div>
      ) : (
        <div onClick={e => onExpand(record, e)} className="arrow-icon flex"><ChevronRight /> </div>
      )) : ""
    );
  };


  const handleAction = async ({ action, actionFor, isActive }: { action: string, actionFor: string, isActive?: boolean }) => {
    const ids: any = selectedRowsData.list?.map((data) => data);
    const payload = {
      search: searchQuery,
      groupId: 0,
      vendorId: 0,
      limit: page * Math.round((tableHeight || 0) / 52),
      offset: 0,
      sort: sort.order,
    };
    if (action === "delete") {
      if (actionFor === "sizes") {
        await dispatch(deleteSizes({ productSizeIds: ids })).then((result) => {
          if (result.payload.status === 200) {
            showMessage("success", result?.payload?.message);
            setSelectedRowsData({
              list: [],
              type: "sizes",
            });
          }
          if (result.payload?.status === 400) {
            showMessage("error", result?.payload.message || "Something went wrong");
          }
        }).catch((error) => showMessage("error", error || error.message || "Something went wrong"));

        await dispatch(getExpandedTableData({ path: `${endPoins.getProductSizes}/${expandedRowKeys[0]}` })).then((result) => {
          if (result?.payload?.data?.productSizes?.length === 0) {
            dispatch(getListOfProducts(payload));
          }
        });
      }
      if (actionFor === "product") {
        await dispatch(deleteProduct(ids)).then((result) => {
          if (result.payload.status === 200) {
            showMessage("success", result?.payload?.message);
            setSelectedRowsData({
              list: [],
              type: "product",
            });
          }
          if (result.payload?.status === 400) {
            showMessage("error", result?.payload.message || "Something went wrong");
          }
        }).catch((error) => showMessage("error", error || error.message || "Something went wrong"));
        await dispatch(getListOfProducts(payload));
      }
    }
  };

  const fetchData = async (page: number) => {
    setLoading(true);
    // Simulating an API call
    const payload = {
      search: searchQuery,
      groupId: 0,
      vendorId: 0,
      limit: page * Math.round((tableHeight || 0) / 52),
      offset: 0,
      sort: sort.order,
    };
    await dispatch(getListOfProducts(payload));
    setLoading(false);
  };

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (Math.ceil(scrollTop + clientHeight) >= scrollHeight && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  // Debounced function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((query: string) => {
      const payload = {
        search: query,
        groupId: getGroupId ? Number(getGroupId) : 0,
        vendorId: 0,
        limit: Math.round((tableHeight || 0) / 52),
        offset: 0,
        forGroup: false,
        sort: sort.order,
      };
      dispatch(getListOfProducts(payload));

    }, 500), // 500ms debounce delay
    [dispatch],
  );

  useEffect(() => {
    handleSearch(searchQuery);
  }, [searchQuery, handleSearch]);

  return (
    <Page
      title={[]}
      header={{
        pageTitle: <p className="flex alignCenter gap-2">Products<button className="total-count-button"><span>{productsList && productsList?.total > 1 ? productsList?.total + " Products" : (productsList?.total || 0) + " Product"}</span></button></p>,
        buttons: {
          addProduct: true,
          onClick() {
            setAddProductModal(true);
          },
          onCreate() {
            setCreateGroupToggle(true);
          },
          createGroup: (productsList?.total === 0 ? false : productGroups && productGroups?.length < 2) || false,
        },
      }}
      className={className ? `products-list flex gap-3 direction-column ${className}` : "products-list flex gap-3 direction-column"}
    >
      {contextHolder}
      <div className="card table-container products-list-table">
        {(searchQuery || productsList?.products?.length || !loading) ? <div className="table-header-container">
          <SearchBar placeholder="Search Product" onChange={(e) => setSearchQuery(e.target.value)} value={searchQuery} />
        </div> : ""}
        <div className="products-price-info-table product-list-table" style={{
          height: `${tableHeight}px`,
          overflowY: "auto",
        }} onScroll={handleScroll}>
          <Table
            rowKey={"productId"}
            rowSelection={{
              ...rowSelection,
              checkStrictly: true,
            }}
            scroll={{ x: "max-content" }}
            expandable={{
              expandedRowRender: (record) => {
                return <ExpandedTable
                  selectedRowIndex={selectedRowIndex}
                  toggleBulkRate={toggleBulkRate}
                  productId={record?.productId}
                  setDataSource={setDataSource}
                  dataSource={dataSource}
                  selectedRowsData={selectedRowsData}
                  setSelectedRowIndex={setSelectedRowIndex}
                  setToggleBulkRate={setToggleBulkRate}
                  setSelectedRowsData={setSelectedRowsData}
                />;
              },
              rowExpandable: (record) => record.moreSizes,
              expandedRowKeys,
              onExpand,
            }}
            rowClassName={() => "editable-row"}
            dataSource={dataSource}
            columns={defaultColumns}
            pagination={false}
            // scroll={{ x: 1780 }}
            loading={loading}
            expandIcon={CustomExpandIcon}
            onChange={(pagination, filter, sorter: any) => {
              setSort({
                order: sorter?.order === "descend" ? "DESC" : "ASC",
              });
            }}
          />
        </div>
      </div>
      {selectedRowsData?.list?.length ? <SelectedTableRowArea clearData={() => setSelectedRowsData({
        list: [],
        type: "product",
      })} count={selectedRowsData.list?.length} handleAction={handleAction} actionFor={selectedRowsData.type} /> : ""}
      {addProductModal && <AddProductModal open={addProductModal} close={() => setAddProductModal(false)} />}
      {toggleAltUnit && <AltUnit selectedRowIndex={selectedRowIndex} dataSource={dataSource} onSave={handleSaveAltData} open={toggleAltUnit} close={() => setToggleAltUnit(false)} />}
      {toggleBulkRate && <BulkRateModal selectedRowIndex={selectedRowIndex} dataSource={dataSource} onSave={handleBulkRateData} open={toggleBulkRate} close={() => setToggleBulkRate(false)} />}
      {toggleEditProduct && <EditProduct productId={selectedProductId} close={() => { setToggleEditProduct(false); setIsExpanded({ id: 0, isOpen: false }); setExpandedRowKeys([]); }} open={toggleEditProduct} />}
      {createGroupToggle &&
        <CreateGroup
          groupId={0}
          open={createGroupToggle}
          type={"create"}
          close={() => setCreateGroupToggle(false)}
        />
      }
    </Page>
  );
};

interface ExpandedTableProps {
  productId: number,
  setDataSource: any,
  dataSource: any[],
  selectedRowsData: any,
  setSelectedRowsData: any,
  setToggleBulkRate: any,
  toggleBulkRate: boolean,
  setSelectedRowIndex: any,
  selectedRowIndex: number | null,
}

const ExpandedTable = ({ productId, dataSource, setDataSource, selectedRowsData, setSelectedRowsData, setToggleBulkRate, setSelectedRowIndex }: ExpandedTableProps) => {
  const [editingKey, setEditingKey] = useState(0);
  const [isEditable, setIsEditable] = useState(false);
  const { productUnits } = useSelector((state: RootState) => state.products);
  const [data, setData] = useState<any>();
  const [dataIndex, setDataIndex] = useState(0);
  const [isToggle, setIsToggle] = useState({
    isAlternetUnit: false,
    isBulkRate: false,
  });
  const [isChanged, setIsChanged] = useState(false);
  const [tempPrice, setTempPrice] = useState("");

  const { expandedTable }: any = useAppSelector((state) => state.table);
  const { showMessage, contextHolder } = useMessageHook();

  const dispatch = useAppDispatch();

  const handleProductSizeUnitChange = (value: string, key: number) => {

    // const updatedDataSource = dataSource.slice(1);
    const newData = dataSource?.map((item, index) =>
      index === key ? { ...item, productSizeUnit: value } : item,
    );
    setDataSource(newData);
  };

  useEffect(() => {
    const UpdatedSizes = expandedTable?.data?.productSizes?.filter((_: any, index: number) => index !== 0);
    setData(UpdatedSizes);
  }, [expandedTable]);

  const updateOnChange = (data: any, productSizeId: number, status?: string) => {
    (isChanged || status === "status") && dispatch(updateProductField({ productSizeId, data })).then(res => {
      if (res.payload.status === 200) {
        showMessage("success", res.payload.message);
      } else {
        showMessage("error", res.payload.message || "Something went wrong");
      }
    });
  };

  const columns: any = [
    {
      title: "",
      dataIndex: "",
      width: 56,
    },
    {
      title: "",
      dataIndex: "",
      width: 420,
    },
    {
      title: "productSize",
      dataIndex: "productSize",
      width: 180,
      render: (text: string, record: any, index: number) => {
        return (
          isEditable && editingKey === record?.productSizeId ?
            <Input
              value={record.productSize}
              name="productSize"
              onChange={(e) => handleChange(e, index)}
              onBlur={() => {
                const payload = { productSize: record.productSize };
                setIsEditable(false);
                updateOnChange(payload, record.productSizeId);
                setIsChanged(false);
              }}
              autoFocus={isEditable}
              style={{ backgroundColor: "transparent" }}
              addonAfter={
                <SelectDropdown
                  placeholder={index > 3 ? "-" : "Select Unit"}
                  dropdownStyle={{ minWidth: 200 }}
                  value={record.productSizeUnit}
                  onFocus={() => { setIsEditable(true); }}
                  onBlur={() => { setIsEditable(false); }}
                  onChange={(value) => {
                    const payload = { productSizeUnit: record.productSizeUnit };
                    updateOnChange(payload, record.productSizeId, "status");
                    handleProductSizeUnitChange(value, index);
                  }}
                  label=""
                  options={productUnits?.data || []}
                  className="size-unit-style"
                />
              }
            /> : <button className="productSize-text" onClick={() => { setEditingKey(record?.productSizeId); setIsEditable(true); }}>{text + " " + record.productSizeUnit}</button>
        );
      },
    },
    {
      title: "pricePerUnit",
      dataIndex: "pricePerUnit",
      width: 120,
      render: (_: any, record: any, index: number) => (
        <Input
          className="table-input"
          value={record?.pricePerUnit}
          name="pricePerUnit"
          onChange={(e) => {
            const regex = /^\d{0,6}(\.\d{0,2})?$/;
            if (regex.test(e.target.value)) {
              setData((prev: any) =>
                prev.map((item: any, i: number) => (i === index ? { ...item, pricePerUnit: /^\d{1,6}(\.\d{0,2})?$/.test(e.target.value) ? cleanValue(e.target.value.toString()) : "" } : item)),
              );
              setIsChanged(true);
            }
          }}
          onFocus={() => setTempPrice(record.pricePerUnit)}
          onBlur={() => {
            if (Number(record.pricePerUnit) > 0) {
              const payload = { pricePerUnit: Number(record.pricePerUnit) };
              setIsEditable(false);
              updateOnChange(payload, record.productSizeId);
              setIsChanged(false);
            } else {
              showMessage("error", "Please enter a valid value");
              setData((prev: any[]) =>
                prev.map((item: any, i: number) => (i === index ? {
                  ...item,
                  pricePerUnit: tempPrice,
                } : item)),
              );
            }
          }}
          onClick={() => [setSelectedRowIndex(index)]}
          // onKeyDown={handleKeyDown}
          style={{ height: "100%", background: "transparent" }}
          placeholder="Price Per Unit"
          autoComplete="off"
          prefix="₹"
        />
      ),
    },
    {
      title: "AlternetUniitQuantity",
      dataIndex: "alternateUnitQuantity",
      width: 210,
      render: (_: any, record: any, index: number) => {
        return (
          <Input
            className="table-input"
            value={record.alternateUnitPrimaryUnit ? ("1 " + record.alternateUnitPrimaryUnit + " = " + record.alternateUnitQuantity + " " + record.alternateUnitSecondaryUnit) : "-"}
            onClick={() => {
              setDataIndex(index); setIsToggle({
                isAlternetUnit: true,
                isBulkRate: false,
              });
            }}
            onBlur={() => { setIsEditable(false); }}
            style={{ height: "100%", background: "transparent" }}
            placeholder="Enter Alternet unit"
            autoComplete="off"
          />
        );
      },
    },
    {
      title: "moq",
      dataIndex: "moq",
      width: 144,
      render: (_: any, record: any, index: number) => (
        <Input
          className="table-input"
          name="moq"
          value={record?.moq || ""}
          onChange={(e) => handleChange(e, index)}
          style={{ height: "100%", background: "transparent" }}
          placeholder={index < 3 ? "Enter moq" : "-"}
          autoComplete="off"
          suffix={record?.moq ? record.alternateUnitPrimaryUnit || record.productSizeOrderUnit : " "}
          onBlur={() => {
            const payload = { moq: record.moq };
            setIsEditable(false);
            updateOnChange(payload, record.productSizeId);
            setIsChanged(false);
          }}
          onKeyDown={handleKeyDown}
          maxLength={Limit.moq}
        />
      ),
    },
    {
      title: "Bulk Rate",
      dataIndex: "productBulkRates",
      width: 210,
      placeholder: "Enter bulk rate",
      render: (text: string, record: ProductTableDataType, index: number) => (
        <Input
          className="table-input"
          value={record.productBulkRates[0]?.productQuantity ? (record.productBulkRates[0]?.productQuantity + " " + (record.alternateUnitPrimaryUnit || record.productSizeOrderUnit) + " = " + (record.productBulkRates[0]?.isRateInRupees ? "₹" : "") + record.productBulkRates[0]?.productRate) + (!record.productBulkRates[0]?.isRateInRupees ? "%" : "") : "-"}
          onClick={() => {
            setDataIndex(index); setIsToggle({
              isAlternetUnit: false,
              isBulkRate: true,
            });
          }}
          // onKeyDown={handleKeyDown}
          style={{ height: "100%", background: "transparent" }}
          placeholder={index < 3 ? "Enter bulk rate" : "-"}
          autoComplete="off"
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (text: string, record: ProductTableDataType, index: number) => {
        return ""; // <Switch defaultChecked checked={record?.isActive} onChange={(e) => { onSwitchChange(e, index); }} className="switch-style" />
      },
      width: 110,
    },
  ];

  const handleChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setData((prev: any[]) =>
      prev.map((item: any, i: number) => (i === index ? {
        ...item,
        [name]: value,
      } : item)),
    );
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedRowsData?.list,
    onChange: (selectedRowKeys: React.Key[], selectedRows: ProductTableDataType[]) => {
      setSelectedRowsData({
        list: selectedRowKeys,
        type: "sizes",
      });
    },
  };

  const handleSaveAlternateUnit = (updatedData: AltUnitState) => {
    const tempData = data.map((item: any, index: number) => {
      if (index === dataIndex) {
        return {
          ...item,
          ...updatedData,
        };
      } else {
        return item;
      }
    });
    setData(tempData);
    updateOnChange(updatedData, tempData[dataIndex]?.productSizeId);
    setIsToggle({
      isAlternetUnit: false,
      isBulkRate: false,
    });
  };

  const handleSaveBulkRateData = (bulkRateData: BulkRateEntry[]) => {
    const filteredData = bulkRateData.filter((Bulkrate) => Bulkrate.productBulkRateId || (!Bulkrate.productBulkRateId && !Bulkrate.isDelete));
    const newBulkRateData = filteredData?.map((item) => ({ ...item, productQuantity: Number(item.productQuantity), productRate: Number(item.productRate) }));

    const tempData = data.map((item: any, index: number) => {
      if (index === dataIndex) {
        return {
          ...item,
          productBulkRates: filteredData,
        };
      } else {
        return item;
      }
    });
    setData(tempData);
    dispatch(updateProductSizeBulkRateField({ productSizeId: tempData[dataIndex]?.productSizeId, data: newBulkRateData })).then((result) => {
      if (result.payload.status === 200) {
        dispatch(getExpandedTableData({ path: `${endPoins.getProductSizes}/${productId}` }));
      } else {
        showMessage("error", result?.payload.message || "Something went wrong");
      }
    }).catch((error) => showMessage("error", error || error.message || "Something went wrong"));
    setIsToggle({
      isAlternetUnit: false,
      isBulkRate: false,
    });
  };

  return (
    <div className="expanded-table">
      {contextHolder}
      <Table
        rowKey={"productSizeId"}
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={expandedTable?.isLoading}
        rowSelection={{
          ...rowSelection,
          checkStrictly: true,
        }}
      />
      {isToggle.isAlternetUnit && <AltUnit selectedRowIndex={dataIndex} dataSource={data} onSave={handleSaveAlternateUnit} open={isToggle.isAlternetUnit} close={() => setIsToggle({ isAlternetUnit: false, isBulkRate: false })} />}
      {isToggle.isBulkRate && <BulkRateModal selectedRowIndex={dataIndex} dataSource={[...data]} onSave={handleSaveBulkRateData} open={isToggle.isBulkRate} close={() => setIsToggle({ isAlternetUnit: false, isBulkRate: false })} />}
    </div>
  );
};

export default ProoductsData;