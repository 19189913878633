import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../redux/store";
import CommonModal from "../../components/commonModal";
import ProductInfo from "./productInfo";
import ProductsListTable from "./productsListTable";
import { BulkRateEntry, DataType, FieldValues, infoDefaultField, ManagementField } from "../../types/productsType";
import { editProduct, getListOfProducts, getProductDetails, manageField } from "../../redux/services/products";
import { CrossIcon } from "../../assets/images";
import useMessageHook from "../../hooks/useMessageHook";

interface Props {
  open: boolean;
  close: () => void;
  productId: number | null
}

const EditProduct = ({ close, open, productId }: Props) => {
  const [productManageFields, setProductManageFields] = useState<ManagementField[]>([]);
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [fieldValues, setFieldValues] = useState<FieldValues[]>([]);
  const [additionalColumnsData, setAdditionalColumnsData] = useState<any[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [infoFields, setInfoFields] = useState<infoDefaultField>({
    productName: "",
    productDesciption: "",
    productAlias: [],
  });
  const [errors, setErrors] = useState<{
    productName: boolean;
    dataSource: { productSize: boolean; pricePerUnit: boolean }[];
  }>({
    productName: false,
    dataSource: [],
  });

  const { showMessage, contextHolder } = useMessageHook();

  const dispatch: AppDispatch = useDispatch();
  const { selectedProductDetails } = useSelector((state: RootState) => state.products);
  const tableHeight = window.innerHeight;

  useEffect(() => {
    const payload = {
      productId: productId || 0,
      search: "",
      limit: 0,
      fieldType: 2,
    };
    dispatch(manageField(payload));
  }, [productId, dispatch]);

  useEffect(() => {
    if (productId) {
      dispatch(getProductDetails(productId));
    }
  }, [productId, dispatch]);
  async function urlToFile(url: string, filename: string, mimeType: string): Promise<any> {

    try {
      // Fetch the image
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "image/jpeg", // Adjust the content type as needed
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Convert the image to a blob
      const blob = await response.blob();

      const file = new File([blob], filename, { type: blob.type });
      return file;
    } catch (error) {
      return null;
    }
  }

  async function convertUrlsToFiles(urls: string[]): Promise<File[]> {

    const files: File[] = [];

    for (const url of urls) {

      const filename = url.split("/").pop() || "image.jpg"; // Extract filename or use default

      const mimeType = `image/${filename.split(".").pop()?.toLowerCase()}` || "image/jpeg"; // Determine MIME type

      const file = await urlToFile(url, filename, mimeType);
      files.push(file);
    }

    return files;
  }

  const handleImages = async (fileList: any) => {
    const fileteredImages: string[] = fileList.filter((data: any) => typeof data.url === "string").map((data: { url: any; }) => data.url);
    const data = await convertUrlsToFiles(fileteredImages);

    const NewFiledata: any = await fileList.filter((data: any) => data.originFileObj).map((data: any) => data.originFileObj);

    const finalData = [...data, ...NewFiledata];

    return finalData;
  };

  useEffect(() => {
    if (selectedProductDetails && productManageFields) {
      const images = selectedProductDetails?.productImage?.webURLs ?? [];
      // if (images.length) {
      const temp = [];
      if (images.length) {
        for (let i = 0; i < images.length; i++) {
          const image = images[i];
          if (image) {
            const imagespliting = image.split("/");
            const imageName = imagespliting[imagespliting?.length - 1];
            temp.push({
              uid: i + 1,
              name: imageName,
              status: "done",
              url: image,
            });
          }
        }
      }

      setFileList(temp);

      setInfoFields({
        productName: selectedProductDetails?.productName,
        productDesciption: selectedProductDetails?.productDescription,
        productAlias: selectedProductDetails?.productAlias,
      });

      const newProductSizes = selectedProductDetails?.productSizes?.map((size: any, index) => {
        const { productCustomFields, ...rest } = size;
        return {
          key: index,
          ...rest,
          alternateUnitSecondaryUnit: size.productSizeOrderUnit,
          moq: size.moq?.toString() !== "0" ? size.moq?.toString() : "",
          pricePerUnit: size.pricePerUnit?.toString() !== "0" ? size.pricePerUnit?.toString() : "",
          alternateUnitQuantity: size.alternateUnitQuantity?.toString() !== "0" ? size.alternateUnitQuantity?.toString() : "",
          productBulkRates: size.productBulkRates.map((rate: BulkRateEntry) => ({
            ...rate,
            productQuantity: rate.productQuantity.toString() !== "0" ? rate.productQuantity?.toString() : "",
            productRate: rate.productRate?.toString() !== "0" ? rate.productRate?.toString() : "",
          })),
          productSizeCustomFieldsData: productCustomFields.map((data: any) => {
            return {
              productCustomFieldsId: data?.productCustomFieldsId,
              fieldName: data?.fieldName,
              fieldValue: data.fieldValue,
              isActive: true,
              isDefault: false,
              isDelete: false,
            };
          }),
        };
      });

      const fieldData = selectedProductDetails?.productCustomFields?.map((data, index) => {
        return ({
          id: index,
          fieldName: data?.fieldName,
          fieldValue: data?.fieldValue,
          fieldType: data?.fieldType || 1,
          isActive: data?.isActive || false,
          isDefault: data?.isDefault || false,
          isDelete: data?.isDelete || false,
          productCustomFieldsId: data?.productCustomFieldsId || 0,
        });
      });
      setFieldValues(fieldData);
      setDataSource(newProductSizes);
    }
  }, [selectedProductDetails]);

  const validateProductFields = (fields: DataType[]) => {
    let isValid = true;
    const fieldErrors = fields
      .map((field, index) => {
        const hasAnyValue = field.productSize || (field.pricePerUnit);

        return hasAnyValue
          ? {
            index,
            productSize: !field.productSize,
            pricePerUnit: (!field.pricePerUnit),
          }
          : {
            index,
            productSize: false,
            pricePerUnit: false,
          };
      });

    const hasAtLeastOneFilledField = fields.some(
      (field) => field.productSize || field.pricePerUnit,
    );

    if (!hasAtLeastOneFilledField) {
      fieldErrors[0] = {
        ...fieldErrors[0],
        productSize: true,
        pricePerUnit: true,
      };
    }

    const hasError = fieldErrors.some((error) => error.productSize || error.pricePerUnit);

    if (!hasAtLeastOneFilledField || hasError || !infoFields.productName) {
      isValid = false;
      setErrors({
        productName: !infoFields.productName,
        dataSource: fieldErrors,
      });
    } else {
      setErrors({
        productName: false,
        dataSource: [],
      });
    }

    return isValid;
  };

  const validatePricePerUnit = (fields: DataType[]) => {
    let isValid = true;
    const fieldErrors = fields
      .map((field, index) => {
        const hasAnyValue =
          field.productSize ||
          (field.pricePerUnit);

        return hasAnyValue
          ? {
            index,
            productSize: !field.productSize,
            pricePerUnit: (!field.pricePerUnit || (parseFloat(field?.pricePerUnit?.toString()) <= 0 ? true : false)),
          }
          : {
            index,
            productSize: false,
            pricePerUnit: false,
          };
      });

    const hasError = fieldErrors.some((error) => error.productSize || error.pricePerUnit);

    if (hasError || !infoFields.productName) {
      isValid = false;
      setErrors({
        productName: !infoFields.productName,
        dataSource: fieldErrors,
      });
    } else {
      setErrors({
        productName: false,
        dataSource: [],
      });
    }

    return isValid;
  };

  const handleAdd = () => {
    const newData: DataType = {
      key: dataSource[dataSource?.length || 1 - 1]?.key + 1,
      productSize: "",
      pricePerUnit: "",
      productSizeUnit: dataSource[dataSource.length - 1]?.productSizeUnit || "",
      productSizeOrderUnit: dataSource[dataSource.length - 1]?.productSizeOrderUnit || "",
      alternateUnitQuantity: "",
      alternateUnitPrimaryUnit: "",
      alternateUnitSecondaryUnit: dataSource[dataSource.length - 1]?.productSizeOrderUnit || "",
      moq: "",
      isActive: true,
      isDelete: false,
      productBulkRates: [
        {
          isActive: true,
          isDelete: false,
          productRate: "",
          isRateInRupees: true,
          productQuantity: "",
        },
      ],
      productSizeCustomFieldsData: productManageFields
        .filter(field => !field.isDefault && field.fieldType === 2)
        .map(field => ({
          fieldName: field.fieldName,
          fieldValue: "",
          isActive: field.isActive,
          isDefault: field.isDefault,
          isDelete: field.isDelete,
        })),
    };
    setDataSource([...dataSource, newData]);
  };

  const handleUpdateProduct = async () => {
    if (!validateProductFields(dataSource)) {
      showMessage("error", "Please enter mandatory fields");
      return;
    }

    if (!validatePricePerUnit(dataSource)) {
      showMessage("error", "Please enter valid values");
      return;
    }

    const product = await handleImages(fileList);

    const convertToNumber = (value: string | number): number => {
      return Number(value);
    };

    const productSizes = dataSource.map(({ key, productBulkRates, ...rest }) => {
      const hasBulkRateWithQuantity = productBulkRates?.some(rate => rate?.productQuantity > "0");

      return {
        ...rest,
        ...(hasBulkRateWithQuantity && { productBulkRates }),
      };
    });

    const formatedData = productSizes.filter((size) => size.productSize && size.pricePerUnit).map((size) => ({
      ...size,
      moq: convertToNumber(size.moq),
      pricePerUnit: convertToNumber(size.pricePerUnit),
      alternateUnitQuantity: convertToNumber(size.alternateUnitQuantity),
      productSizeCustomFieldsData: size.productSizeCustomFieldsData.map(({ id, ...rest }: any) => rest),
      ...(size.productBulkRates?.length && {
        productBulkRates: size.productBulkRates?.map((rate) => ({
          ...rate,
          productQuantity: convertToNumber(rate.productQuantity),
          productRate: convertToNumber(rate.productRate),
        })),
      }),
    }));

    const productData = {
      productName: infoFields?.productName?.trim(),
      productDescription: infoFields?.productDesciption?.trim(),
      productAlias: infoFields?.productAlias,
      productCustomFieldsData: fieldValues?.map(({ id, fieldType, ...rest }) => rest),
      productSizes: formatedData,
      isDraft: false,
    };


    await dispatch(editProduct({ id: productId, product, productData })).then((result) => {
      if (result.payload.status === 200) {
        localStorage.removeItem("isDraftProduct");
        localStorage.removeItem("dataSource");
        close();
      } else {
        showMessage("error", result?.payload.message || "Something went wrong");
      }
    }).catch((error) => { showMessage("error", error || "Something went wrong"); });

    const payload = {
      search: "",
      groupId: 0,
      vendorId: 0,
      limit: 1 * Math.round((tableHeight || 0) / 52),
      offset: 0,
    };
    await dispatch(getListOfProducts(payload));
  };

  return (
    <CommonModal close={close} open={open} onSave={() => []} className="edit-product-modal">
      {contextHolder}
      <div className="edit-product-header-container flex alignCenter justifyBetween">
        <header className="edit-product-header-text">Update Product</header>
        <div className="edit-product-actions flex alignCenter gap-3">
          <button type="button" className="primary large rounded-8" onClick={handleUpdateProduct}>Save Changes</button>
          <div onClick={close} className="close-icon flex alignCenter"><CrossIcon width={24} height={24} /></div>
        </div>
      </div>
      <div className="add-products edit-product">
        <ProductInfo
          fieldValues={fieldValues}
          setFieldValues={setFieldValues}
          productManageFields={productManageFields}
          setInfoFields={setInfoFields}
          infoFields={infoFields}
          errors={errors}
          setFileList={setFileList}
          fileList={fileList}
          productId={productId || 0}
        />

        <ProductsListTable
          editComponent
          productManageFields={productManageFields}
          dataSource={dataSource}
          setDataSource={setDataSource}
          handleAdd={handleAdd}
          title="Product Information"
          setAdditionalColumnsData={setAdditionalColumnsData}
          setProductManageFields={setProductManageFields}
          additionalColumnsData={additionalColumnsData}
          errors={errors}
          productId={productId || 0}
          setErrors={setErrors}
        />
      </div>
    </CommonModal>
  );
};

export default EditProduct;