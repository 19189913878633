import { message } from "antd";
import { useState } from "react";

const useMessageHook = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [key, setKey] = useState("updatable");

  const showMessage = (
    type: "success" | "error" | "info" | "warning",
    content: string,
    duration = 3,
  ) => {
    messageApi.open({
      key,
      type,
      content,
      duration,
    });
  };

  return { showMessage, contextHolder };
};

export default useMessageHook;
