import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tabs } from "antd";

import { CalendarIcon, DownArrowFull, DownloadRecieptIcon } from "../../assets/images/icons";
import UserInfo from "../../components/userInfo";
import EntireOrderSummary from "./entireOrderSummary";
import DispatchDetailSummary from "./dispatchDetailSummary";
import { getOrderDetails } from "../../redux/services/orders";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { formatDate } from "../../utils/helper";
import StatusSwitch from "./statusSwitch";
import DispatchOrderModal from "./dispatchOrderModal";
import TransportationDetails from "./transportationDetails";

interface dispatchType {
  isDispatch: boolean;
  orderId: number | null;
  isTransport: boolean
}

const OrderDetails = () => {
  const [toggleDispatch, setToggleDispatch] = useState<dispatchType>({ isDispatch: false, isTransport: false, orderId: null });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation().state;
  const { id } = useParams();

  const { statusDetails } = useAppSelector(state => state.manageOrders);
  const { orderDetails } = useAppSelector(state => state?.manageOrders);

  const activeTab = location.activeTab === "My-order" ? 1 : 0;

  useEffect(() => {
    if (id) {
      dispatch(getOrderDetails(Number(id)));
    }
  }, [id, dispatch, statusDetails.isSuccess]);


  const items: any = [
    {
      key: "1",
      label: "Order Details",
      children: <EntireOrderSummary />,
    },
    orderDetails.data?.status === "Dispatched" && {
      key: "2",
      label: "Dispatch Details",
      children: <DispatchDetailSummary />,
    },
  ];

  return (
    <>
      <div className="order-container">
        <div className="order-header-container flex direction-column">
          <div className="basic-order-info flex justifyBetween alignCenter">
            <div className="order-id flex alignCenter gap-2">
              <button className="back-btn" onClick={() => navigate(-1)}>
                <DownArrowFull />
              </button>
              <p>Order <span className="id">{orderDetails?.data?.orderNumber ? orderDetails?.data.orderNumber : `#${orderDetails?.data?.refId}`}</span></p>
            </div>
            <div className="date-container flex alignCenter gap-1">
              <CalendarIcon />
              {formatDate(orderDetails.data?.createdOn || "")}
            </div>
          </div>
          <div className="user-info-container flex justifyBetween alignCenter">
            <UserInfo
              profileImage={activeTab ? orderDetails.data?.vendor.profileThumbnailImg || "" : orderDetails.data?.customer.profileThumbnailImg || ""}
              comanyName={activeTab ? orderDetails.data?.vendor.name : orderDetails.data?.customer.name}
              userName={activeTab ? `${orderDetails.data?.vendor?.createdBy?.firstName} ${orderDetails.data?.vendor?.createdBy?.lastName}` || "" : `${orderDetails.data?.customer?.createdBy?.firstName} ${orderDetails.data?.customer?.createdBy?.lastName}` || ""}
            />
            <div className="flex gap-3">
              <StatusSwitch
                status={(orderDetails.data?.status === "In Progress" ? "inProgress" : orderDetails.data?.status) || ""}
                activeTab={location.activeTab}
                orderId={Number(id)}
                onDispatch={() => setToggleDispatch({ orderId: orderDetails.data?.orderId || 0, isDispatch: true, isTransport: false })}
              />
              <button className="download-reciept-icon flex alignCenter gap-2">
                <DownloadRecieptIcon />Download Reciept
              </button>
            </div>
          </div>
        </div>

        {/* Order Details */}

        <div className="card orders-content">
          <Tabs
            defaultActiveKey="1"
            items={items}
          />
        </div>
      </div>
      {toggleDispatch?.isDispatch &&
        <DispatchOrderModal
          open={toggleDispatch?.isDispatch}
          close={() => setToggleDispatch({ isDispatch: false, isTransport: false, orderId: null })}
          orderId={toggleDispatch.orderId || 0}
          handleConfirm={() => setToggleDispatch({ isDispatch: false, isTransport: true, orderId: toggleDispatch.orderId })}
        />
      }
      {toggleDispatch?.isTransport &&
        <TransportationDetails
          open={toggleDispatch.isTransport}
          close={() => setToggleDispatch({ isDispatch: false, isTransport: false, orderId: null })}
          orderId={toggleDispatch.orderId || 0}
        />}
    </>
  );
};

export default OrderDetails;