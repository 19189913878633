import React from "react";

const RecentOrderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
      <g clipPath="url(#clip0_2224_243)">
        <path d="M11.125 5C11.125 5 10.1225 3.63411 9.30808 2.81912C8.49366 2.00413 7.3682 1.5 6.125 1.5C3.63972 1.5 1.625 3.51472 1.625 6C1.625 8.48528 3.63972 10.5 6.125 10.5C8.17655 10.5 9.90744 9.12714 10.4491 7.25M11.125 5V2M11.125 5H8.125" stroke="#0169E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2224_243">
          <rect width="12" height="12" fill="white" transform="translate(0.625)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RecentOrderIcon;