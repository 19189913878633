import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Select } from "antd";

import { useAppSelector } from "../../hooks/useAppSelector";
import RecentOrderCard from "../../components/recentOrderCard";
import Page from "../../components/page";
import ProductCard from "../../components/productcard";
import ListOfGroups from "./listOfGroups";
import SearchBar from "../../components/searchBar";
import { CartIcon } from "../../assets/images";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getProductListByVendorId } from "../../redux/services/vendor";
import NoDataFound from "../../components/NoDataFound";
import { debounce } from "../../utils/debounce";
import { getProductsGroups } from "../../redux/services/products";
import ProductSizesModal from "./productSizesModal";
import OrderCart from "./orderCart";
import { getCartDataByVendorID, recentOrders } from "../../redux/services/placeOrder";
import { formatRupees, getProfileIcon, getRelativeTime } from "../../utils/helper";
import { getAddressListByCompany } from "../../redux/services/address";
import ProductDetails from "./productDetails";


interface CompressedItem {
  productId: number;
  productName: string;
  productImage: string;
  pricePerUnit: number;
  sizes: {
    quantity: number;
    productSizeId: number;
    productSize: string;
    productSizeUnit: string;
    productSizeOrderUnit: string;
    moq: number;
    productBulkRates: {
      isRateInRupees: boolean,
      placePosition: number,
      productBulkRateId: number,
      productQuantity: number,
      productRate: number
    }[];
  }[];
}

const PlaceOrder = () => {
  const [searchValue, setSearchValue] = useState("");
  const [sortProduct, setSortProduct] = useState({ value: "ASC", label: "A - Z" });
  const [page, setPage] = useState(0);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [groupId, setGroupId] = useState(0);
  const [productDetail, setProductDetail] = useState<number | null>();
  const [isToggleSizePopup, setIsToggleSizePopup] = useState({
    id: 0,
    isToggle: false,
  });
  const [cartBasicInfo, setCartBasicInfo] = useState({
    totalItems: 0,
    totalAmount: 0,
  });

  const location = useLocation()?.state;
  const dispatch = useAppDispatch();
  const { vendorsProductList } = useAppSelector(state => state.vendors);
  const { connectionDetails } = useAppSelector(state => state.connections);
  const { productGroups } = useAppSelector(state => state.products);
  const { cart, recentOrdersData } = useAppSelector(state => state.placeorder);

  const navigate = useNavigate();

  const pageHeight = window.innerHeight;

  useEffect(() => {
    dispatch(getAddressListByCompany());
    if (location?.orderId) {
      // dispatch(getConnectionDetailsById(location?.orderId));
      dispatch(recentOrders(location?.orderId));
      dispatch(getProductsGroups({ search: "", vendorId: location?.orderId }));
      dispatch(getCartDataByVendorID({
        vendorId: location?.orderId,
      }));
    } else {
      navigate("/vendors");
    }
  }, [dispatch, location]);

  useEffect(() => {
    let totalAmount = 0;
    let totalItems = 0;

    cart.data?.cartItems.forEach((item) => {
      totalAmount += item.subtotal - item.discount;
      totalItems += item.quantity;
    });

    setCartBasicInfo({
      totalItems: totalItems,
      totalAmount: totalAmount,
    });
  }, [cart]);

  useEffect(() => {
    if (vendorsProductList?.data && ((page - 1) * Math.round((pageHeight || 0) / 20) <= vendorsProductList?.data?.total)) {
      fetchData(page);
    }
  }, [page]);

  useEffect(() => {
    fetchData(page);
  }, [location, dispatch, groupId, sortProduct]);


  const compressedItems = cart.data?.cartItems?.reduce((acc: CompressedItem[], item) => {
    const existingProduct = acc?.find(product => product?.productId === item?.product?.productId);

    if (existingProduct) {
      existingProduct.sizes.push({
        productSizeId: item.productSize.productSizeId,
        productSize: item.productSize.productSize,
        productSizeUnit: item.productSize.productSizeUnit,
        productSizeOrderUnit: item.productSize.productSizeOrderUnit,
        quantity: item.quantity,
        productBulkRates: item.productSize.productBulkRates,
        moq: item?.productSize.moq,
      });
    } else {
      acc.push({
        productId: item.product.productId,
        productName: item.product.productName,
        productImage: item.product.productImage?.thumbnailURL,
        pricePerUnit: item.pricePerUnit,
        sizes: [
          {
            quantity: item.quantity,
            productSizeId: item.productSize.productSizeId,
            productSize: item.productSize.productSize,
            productSizeUnit: item.productSize.productSizeUnit,
            productSizeOrderUnit: item.productSize.productSizeOrderUnit,
            productBulkRates: item.productSize.productBulkRates,
            moq: item?.productSize.moq,
          },
        ],
      });
    }

    return acc;
  }, []);

  const fetchData = async (page: number) => {
    // setLoading(true);
    // Simulating an API call
    const payload = {
      search: "",
      groupId: groupId,
      vendorId: location?.orderId,
      limit: 0,
      offset: 0,
      sort: sortProduct.value,
    };
    await dispatch(getProductListByVendorId(payload));
    // setLoading(false);
  };

  // Debounced function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchProducts = useCallback(
    debounce((query: string) => {
      const payload = {
        search: query,
        groupId: groupId,
        vendorId: location?.orderId,
        limit: 0,
        offset: 0,
        sort: sortProduct.value,
      };
      dispatch(getProductListByVendorId(payload));
    }, 500),
    [dispatch],
  );

  useEffect(() => {
    if (searchValue) {
      debouncedFetchProducts(searchValue);
    }
  }, [searchValue, debouncedFetchProducts]);

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleChange = (newValue: { value: string; label: string }) => {
    setSortProduct(newValue);
  };

  const handleAddProduct = (product: any) => {
    if (product?.moreSizes) {
      setIsToggleSizePopup({ id: product.productId, isToggle: !isToggleSizePopup.isToggle });
    }
  };

  return (
    <Page title={[]} className="place-order-page flex gap-2 direction-column">
      {/* {vendorsProductList.isLoading || connectionDetails?.isLoading ? <div className="page-loader">
        <Spin /></div> : <></>} */}
      <div className="flex gap-2 alignStart">
        {(productGroups && productGroups?.length > 1) ? <div className="card h-100 sidebar-container">
          <ListOfGroups setGroupId={setGroupId} />
        </div> : ""}
        <div className="order-page-main-container flex">
          <div className="orders-container flex direction-column">
            <div className="vendor-details-cart-button-container">
              <div className="gap-3 vendor-details">
                <div className="profile-box flex alignCenter justifyCenter">
                  {(connectionDetails?.data?.profileThumbnailImg || location?.companyProfile) ?
                    <img src={connectionDetails?.data?.profileThumbnailImg || location?.companyProfile} alt="profile-icon" /> : getProfileIcon(location?.companyName || "")
                  }
                </div>
                <h3 className="profile-title">{location?.companyName}</h3>
              </div>
              {cart.data?.cartItems?.length ? <button type="button" className="rounded-8 primary large flex gap-2 direction-row cart-button" onClick={() => { setIsCartOpen(!isCartOpen); }}>
                <CartIcon />
                <span className="flex direction-column cart-text">
                  {cartBasicInfo.totalItems ?? 0} items
                  <br />
                  {cartBasicInfo.totalAmount ? formatRupees(cartBasicInfo.totalAmount) : <></>}
                </span>
              </button> : <></>}
              <OrderCart isOpen={isCartOpen} setIsOpen={setIsCartOpen} />
            </div>
            {!productDetail ? <div className="page-scroll flex alignStart direction-column">
              {recentOrdersData?.data?.length ? <div className="card recent-order-box">
                <h3 className="title">Recent Orders</h3>
                <div className="list-of-orders">
                  {recentOrdersData?.data?.map((order, index) => (
                    <RecentOrderCard
                      key={index}
                      orderNumber={order.orderNumber}
                      status={order?.status}
                      itemCount={order?.orderItemsCount || 0}
                      timeAgo={getRelativeTime(order?.createdOn)}
                      orderId={order?.orderId}
                    />
                  ))}
                </div>
              </div> : <></>}
              {/* {vendorsProductList?.data?.products?.length ? <div className="card suggested-product-container">
                <div className="flex suggested-sub-container">
                  <div className="tab-box">
                    <Tabs defaultActiveKey="1">
                      <TabPane
                        tab={
                          <span className="tab-title flex gap-2 alignCenter justifyCenter" style={{ color: "inherit" }}>
                            <FrequentlyOrderIcon />
                            Most Ordered Products
                          </span>
                        }
                        key="1"
                      >
                        <div className="list-of-products">
                          {vendorsProductList?.data?.products?.map((product, index) => {
                            return (
                              <ProductCard
                                key={index}
                                image={product.productImage?.thumbnailURL || ""}
                                offer={"0"}
                                name={product.productName}
                                size={product?.productSizes?.[0]}
                                moreSizes={product?.moreSizes ? true : false}
                                oldPrice={""}
                                onclick={() => product?.moreSizes ? setIsToggleSizePopup({ id: product.productId, isToggle: !isToggleSizePopup.isToggle }) : {}}
                                price={product?.productSizes?.[0]?.pricePerUnit || 0}
                              >
                                {
                                  (isToggleSizePopup.isToggle && isToggleSizePopup.id === product.productId) ?
                                    <ProductSizesModal
                                      productInfo={{
                                        productImage: product.productImage?.thumbnailURL || "",
                                        productName: product.productName,
                                        productId: product.productId,
                                        close: () => setIsToggleSizePopup({ id: 0, isToggle: false }),
                                      }}
                                      getSizes={(data) => console.log("Data", data)}
                                    /> : ""
                                }
                              </ProductCard>
                            );
                          })}
                        </div>
                      </TabPane>
                      <TabPane
                        tab={
                          <span className="tab-title flex gap-2 alignCenter justifyCenter" style={{ color: "inherit" }}>
                            <HeartIcon />
                            My Favourite Products
                          </span>
                        }
                        key="2"
                      >
                        <div className="list-of-products">
                          {vendorsProductList?.data?.products?.map((product, index) => {
                            return (
                              <ProductCard
                                key={index}
                                image={product.productImage?.thumbnailURL || ""}
                                offer={"0"}
                                name={product.productName}
                                size={product?.productSizes?.[0]}
                                moreSizes={product?.moreSizes ? true : false}
                                oldPrice={""}
                                onclick={() => product?.moreSizes ? setIsToggleSizePopup({ id: product.productId, isToggle: !isToggleSizePopup.isToggle }) : {}}
                                price={product?.productSizes?.[0]?.pricePerUnit || 0}
                              >
                                {
                                  (isToggleSizePopup.isToggle && isToggleSizePopup.id === product.productId) ?
                                    <ProductSizesModal
                                      productInfo={{
                                        productImage: product.productImage?.thumbnailURL || "",
                                        productName: product.productName,
                                        productId: product.productId,
                                        close: () => setIsToggleSizePopup({ id: 0, isToggle: false }),
                                      }}
                                      getSizes={(data) => console.log("Data", data)}
                                    /> : ""
                                }
                              </ProductCard>
                            );
                          })}
                        </div>
                      </TabPane>
                    </Tabs>
                  </div>
                </div>
              </div> : ""} */}
              <div className="card products-lists-container" onScroll={handleScroll}>
                <div className="flex header-container justifyBetween">
                  <div className="flex alignCenter gap-2">
                    <h3 className="title">List of Products</h3>
                    <SearchBar
                      value={searchValue}
                      onChange={handleSearchChange}
                      placeholder="Search Product"
                    />
                  </div>
                  <div className="sorting-selection gap-1">
                    <span>Sort by</span>
                    <Select
                      style={{ width: 120 }}
                      labelInValue
                      value={sortProduct}
                      onChange={handleChange}
                      options={[
                        {
                          value: "ASC",
                          label: "A - Z",
                        },
                        {
                          value: "DESC",
                          label: "Z - A",
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="product-lists">
                  {vendorsProductList?.data?.products && vendorsProductList?.data?.products?.length > 0 ?
                    vendorsProductList?.data?.products?.map((product, index) => {
                      const temp = compressedItems?.find((item) => (item?.productId === product?.productId));
                      return (
                        <ProductCard
                          productId={product.productId}
                          key={index}
                          image={product.productImage?.thumbnailURL || ""}
                          offer={"0"}
                          name={product.productName}
                          moreSizes={product?.moreSizes ? true : false}
                          size={temp ? temp?.sizes[0] as any : product?.productSizes?.[0]}
                          oldPrice={""}
                          onclick={() => handleAddProduct(product)}  
                          price={temp ? temp.pricePerUnit : product?.productSizes?.[0]?.pricePerUnit || 0}
                          itemQty={
                            cart.data?.cartItems?.length ? temp?.sizes[0]?.quantity : 0
                          }
                          moq={temp ? temp?.sizes[0]?.moq : 0}
                          isActive={product?.isActive}
                          showProductDetail={() => navigate(`/placeorder/${product.productId}`)}
                        >
                          {
                            (isToggleSizePopup.isToggle && isToggleSizePopup.id === product.productId) ?
                              <ProductSizesModal
                                productInfo={{
                                  productImage: product.productImage?.thumbnailURL || "",
                                  productName: product.productName,
                                  productId: product.productId,
                                  close: () => setIsToggleSizePopup({ id: 0, isToggle: false }),
                                }}
                                data={temp}
                                getSizes={() => []}
                              /> : ""
                          }
                        </ProductCard>
                      );
                    })
                    :
                    <NoDataFound />}
                </div>
              </div>
            </div> : <ProductDetails /> }
          </div>
        </div>
      </div>
    </Page>
  );
};

export default PlaceOrder;