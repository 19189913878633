import { createSlice } from "@reduxjs/toolkit";

import { getAllCompany, getCompanyBusinessType, verifyOtp } from "../services/auth";
import { BusinessTypes } from "../../types/types";

interface allCompaniesData {
  "companyId": number,
  "name": string,
  "profileThumbnailImg": string | null,
  "city": string,
  "state": string,
  "createdBy": {
    "firstName": string,
    "lastName": string,
    "role": string
  },
  "customers": [],
  "sentRequest": boolean,
  "receivedRequest": boolean,
  "requestId": null,
  "isConnected": boolean,
  "isVendor": boolean
}

interface IProps {
  isLoading: boolean,
  isSuccess: boolean,
  profile: any | null,
  companyData: {
    user: {
      userId: number,
      firstName: string,
      lastName: string,
      email: string,
      role: string,
      phoneNo: string,
      isActive: boolean,
      isVerified: boolean
    },
    companies: {
      companyId: number,
      name: string,
      email: string | null,
      aboutCompany: string | null,
      profileImg: string,
      profileThumbnailImg: string,
      city: string,
      state: string,
      companyType: {
        businessTypeId: number,
        businessType: string
      }[],
      createdBy: {
        firstName: string,
        lastName: string,
        role: string
      }
    }[],
    token: string
  } | null,
  businessTypes: {
    data: BusinessTypes[],
    isLoading: boolean,
    isSuccess: boolean,
    message: string,
  }
  allCompanies: allCompaniesData[] | null,
  step: number,
}

const initialState: IProps = {
  isLoading: false,
  isSuccess: false,
  profile: null,
  companyData: null,
  allCompanies: null,
  businessTypes: {
    data: [],
    isLoading: true,
    isSuccess: true,
    message: "",
  },
  step: 0,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearStep: (state) => {
      state.step = initialState.step;
    },
  },
  extraReducers: (builder) => {
    // verify otp
    builder.addCase(verifyOtp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(verifyOtp.fulfilled, (state, { payload }: any) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.companyData = payload.data;
    });
    builder.addCase(verifyOtp.rejected, (state, { payload }: any) => {
      state.isSuccess = false;
      state.isLoading = false;
      state.companyData = payload || "Failed to login";
      state.step = 1;
    });

    // get all companies
    builder.addCase(getAllCompany.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllCompany.fulfilled, (state, { payload }: any) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.allCompanies = payload.data;
    });
    builder.addCase(getAllCompany.rejected, (state, { payload }: any) => {
      state.isSuccess = false;
      state.isLoading = false;
      state.allCompanies = payload;
    });

    // get all business Types
    builder.addCase(getCompanyBusinessType.pending, (state) => {
      state.businessTypes.isLoading = true;
      state.businessTypes.isSuccess = false;
      state.businessTypes.data = [];
    });
    builder.addCase(getCompanyBusinessType.fulfilled, (state, { payload }: any) => {
      state.businessTypes.isLoading = false;
      state.businessTypes.isSuccess = true;
      state.businessTypes.data = payload.data;
    });
    builder.addCase(getCompanyBusinessType.rejected, (state, { payload }: any) => {
      state.businessTypes.isSuccess = false;
      state.businessTypes.isLoading = false;
      state.businessTypes.data = payload?.data;
      state.businessTypes.message = payload?.message;
    });
  },
});

export const { clearStep } = authSlice.actions;
export default authSlice.reducer;