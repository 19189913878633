import React, { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";

import animationData from "../../assets/images/animation.json";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { verifyOtp } from "../../redux/services/auth";
import useMessageHook from "../../hooks/useMessageHook";

interface Props {
  phoneNumber: string
  onResend: () => void;
  setStep: (value: number) => void;
}

const OTPScreen = ({ setStep, phoneNumber, onResend }: Props) => {
  const [otp, setOtp] = useState<string[]>(Array(5).fill(""));
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState<number>(45);
  const [canResend, setCanResend] = useState<boolean>(false);
  const [otpError, setOtpError] = useState<string>("");
  const [otpSuccess, setOtpSuccess] = useState<boolean>(false);
  const firstOtpInputRef = useRef<HTMLInputElement>(null);

  const { showMessage, contextHolder } = useMessageHook();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Focus on the first OTP input when the component mounts
    if (firstOtpInputRef.current) {
      firstOtpInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    } else {
      setCanResend(true);
    }
  }, [timer]);

  useEffect(() => {
    if (otp.length === 5 && validateOtp()) {
      handleVerifyOTP();
    }
  }, [otp]);

  const validateOtp = () => {
    if (otp.includes("")) {
      return false;
    } else {
      return true;
    }
  };

  const handleVerifyOTP = async () => {
    setIsLoading(true);
    try {
      const payload = {
        phoneNumber: phoneNumber,
        otp: otp.join(""),
      };
      dispatch(verifyOtp(payload)).then((result) => {
        if (result?.payload?.status === 200) {
          setOtpSuccess(true);
          setTimeout(() => {
            if (result.payload?.data.companies.length > 1) {
              setStep(2);
            } else {
              navigate("/", { state: { phoneNo: phoneNumber } });
            }
          }, 1000);
        } else {
          setOtpError(result.payload?.message || "Login failed");
          showMessage("error", result?.payload.message || "Login failed");
        }
      });
    } catch (error: any) {
      setOtpError(error.response?.data?.message || "Login failed");
      showMessage("error", error.response?.data.message || "Login failed");

    } finally {
      setOtpSuccess(false);
      setIsLoading(false);
    }
  };

  const handleResend = () => {
    onResend();
    setOtpError("");
    setTimer(45);
    setCanResend(false);
    setOtp(Array(5).fill(""));
  };

  const handleChangeNumber = () => {
    setStep(0);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^\d$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 5 - 1) {
        const nextSibling = document.querySelector<HTMLInputElement>(`#otp-input-${index + 1}`);
        nextSibling?.focus();
      }
      setOtpError("");
      setOtpSuccess(false);
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    // Get the pasted data and ensure it is at most 5 characters long
    const pasteData = e.clipboardData?.getData("text")?.slice(0, 5);

    // Check if the paste data is numeric and has up to 5 characters
    const isValidPaste = /^[0-9]{1,5}$/.test(pasteData);

    if (isValidPaste) {
      // Create an OTP array from the paste data, filling with empty strings if less than 6 digits
      const newOtp = pasteData.split("").concat(Array(5 - pasteData?.length).fill(""));
      setOtp(newOtp.length === 5 ? newOtp : Array(5).fill(""));

      // Move focus to the last filled input
      const lastFilledIndex = pasteData?.length;
      const lastFilledInput = document.querySelector<HTMLInputElement>(`#otp-input-${lastFilledIndex}`);
      lastFilledInput?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const currentInput = document.querySelector<HTMLInputElement>(`#otp-input-${index}`);

    if (!currentInput) return;

    if (e.key === "Backspace" && !otp[index]) {
      const prevSibling = document.querySelector<HTMLInputElement>(`#otp-input-${index - 1}`);
      prevSibling?.focus();
    } else if (e.key === "ArrowLeft" && index > 0) {
      e.preventDefault();
      const prevSibling = document.querySelector<HTMLInputElement>(`#otp-input-${index - 1}`);
      prevSibling?.focus();
      prevSibling?.select();
    } else if (e.key === "ArrowRight" && index < 5 - 1) {
      e.preventDefault();
      const nextSibling = document.querySelector<HTMLInputElement>(`#otp-input-${index + 1}`);
      nextSibling?.focus();
      nextSibling?.select();
    }
  };

  return (
    <div className="auth-card">
      {contextHolder}
      <div className="otp-container flex">
        <div className="flex direction-column white-box">
          <h2 className="title-2 fontWeight-600">Verify Your Account</h2>
          <span className="otp-sent-text">OTP sent to +91 {phoneNumber}.
            <button className="change-number" onClick={handleChangeNumber}> Change</button>
          </span>
          <div className="flex gap-4">
            {otp && otp.map((value, index) => (
              <input
                disabled={isLoading}
                ref={index === 0 ? firstOtpInputRef : null}
                key={index}
                id={`otp-input-${index}`}
                type="text"
                inputMode="numeric"
                maxLength={1}
                placeholder="-"
                value={value}
                onChange={(e) => handleChange(e, index)}
                onPaste={handlePaste}
                onKeyDown={(e) => handleKeyDown(e, index)}
                className={`otp-input ${otpError ? "error" : ""} ${otpSuccess ? "green-border" : ""}`}
                autoComplete="off"
              />
            ))}
          </div>
          {otpError && <span className="otp-error-text">{otpError}</span>}
          {canResend && <span className="resend-text">Didn&apos;t receive code? <button onClick={handleResend}>Resend</button></span>}
          {!canResend && <span className="resend-text">Valid till <button>00:{timer.toString().padStart(2, "0")}  </button></span>}

        </div>
        <div className="svg-container">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={400}
            width={400}
          />
        </div>
      </div>
    </div>
  );
};

export default OTPScreen;