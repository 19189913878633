import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import { Popover, Tooltip } from "antd";

import { useAppDispatch } from "../../hooks/useAppDispatch";
import { addToCart, getCartDataByVendorID, removeCartItem } from "../../redux/services/placeOrder";
import { productSizes } from "../../types/vendorType";
import PlusMinusButton from "../counterButton";
import { Logo } from "../../assets/images";
import { DownArrow, NoImagesIcon } from "../../assets/images/icons";
import { useAppSelector } from "../../hooks/useAppSelector";
import { formatRupees } from "../../utils/helper";
// import { HeartIcon } from "../../assets/images/icons";

interface ProductCardProps {
  image: string;
  offer: string | number;
  name: string;
  size: productSizes | undefined;
  oldPrice: string | number;
  price: string | number;
  children?: React.ReactNode;
  onclick?: any;
  moreSizes?: boolean;
  itemQty?: number | any;
  productId: number;
  isActive?: boolean;
  moq?: number;
  showProductDetail?: () => void;
}

const ProductCard: React.FC<ProductCardProps> = ({ moreSizes, image, offer, name, size, oldPrice, price, children, onclick, itemQty, productId, isActive, moq, showProductDetail }) => {
  const [quantity, setQuantity] = useState(itemQty || 0);
  const [tempData, setTempData] = useState<any>();
  const [isToggle, setIsToggle] = useState(0);
  const [bulkRate, setBulkRate] = useState<any>([]);
  const [isActiveBulkRate, setIsActiveBulkRate] = useState(0);

  const dispatch = useAppDispatch();
  const location = useLocation().state;
  const { cart } = useAppSelector(state => state.placeorder);

  const newArr: any = [];

  useEffect(() => {
    size?.productBulkRates?.map((item) => {
      if (item.productQuantity && item.productRate) {
        newArr.push(item);
      }
    });
    setBulkRate(newArr);
  }, [size]);

  function findIndexBetween(arr: any, value: any) {
    for (let i = 0; i < arr?.length - 1; i++) {
      if (value >= arr[i + 1]?.productQuantity && value <= arr[i]?.productQuantity) {
        return i; // Return the index where the value is between arr[i] and arr[i+1]
      }
    }
    return -1; // Return -1 if the value doesn't fall between any two values in the array
  }

  const selectedBulkRateIndex = findIndexBetween(bulkRate, quantity);

  const timeoutRef: any = useRef(null);

  // useEffect(() => {
  //   if (moq && itemQty > 0 && moq > 0 && itemQty === moq) {
  //     setIsToggle(0);
  //   }
  // }, [moq, itemQty]);

  useEffect(() => {
    if (isToggle === productId) {
      timeoutRef.current = setTimeout(() => {
        setIsToggle(0);
      }, 5000);
    }

    // Clean up the timeout if the component re-renders before the timeout completes
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isToggle, productId]);

  const handleIncreament = (quantitydata: number) => {
    if (quantitydata === size?.moq) setIsToggle(productId); else setIsToggle(0);
    const tempQty = size?.moq ? (quantitydata <= size?.moq ? size?.moq : quantitydata) : quantitydata;
    setQuantity(tempQty);
    setTempData(tempQty);
    handleUpdateCart(tempQty, size, productId);
    setIsActiveBulkRate(0);
  };

  const handleUpdateCart = (qty: number, sizes: any, productId: number) => {
    if (qty !== 0) {
      dispatch(addToCart({
        vendorId: location?.orderId,
        items: [{
          productId: productId,
          productSizes: [{
            quantity: qty,
            productSizeId: sizes?.productSizeId,
          }],
        }],
      })).then((result) => {
        if (result.payload.status === 200) {
          dispatch(getCartDataByVendorID({
            vendorId: location?.orderId,
          }));
        }
      });
    } else {
      dispatch(getCartDataByVendorID({
        vendorId: location?.orderId,
      }));
    }
  };

  useEffect(() => {
    if (itemQty) {
      setQuantity(itemQty);
      setTempData(itemQty);
    } else {
      setQuantity(0);
      setTempData(0);
    }
  }, [itemQty]);

  const handleDecrement = () => {
    const tempQty = quantity > (size?.moq || 0) ? quantity - 1 : 0;
    const cartItemId = cart.data?.cartItems.filter((data) => data.product.productId === productId)[0]?.cartItemId;
    if (tempQty === 0 && cartItemId) {
      dispatch(removeCartItem({
        cartId: cart.data?.cartId || 0,
        cartItemIds: [cartItemId],
      })).then((result) => {
        if (result?.payload?.status === 200) {
          dispatch(getCartDataByVendorID({
            vendorId: location?.orderId,
          }));
        }
      });
    } else {
      handleUpdateCart(tempQty, size, productId);
    }
    setQuantity(tempQty);
    setTempData(tempQty);
    setIsActiveBulkRate(0);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const qty = e.target.value;
    if (!qty) {
      setTempData("0");
      setQuantity("");
    } else {
      const regex = /^\d{0,6}?$/;
      if (regex.test(qty)) {
        setQuantity(quantity >= 0 ? Number(qty) : quantity);
      }
    }
  };

  const handleOnblur = (moq: number) => {
    const tempQty = quantity <= moq ? moq : quantity;
    setQuantity(tempQty);
    if (!tempQty) {
      setTempData("");
    }
    handleUpdateCart(tempQty, size, productId);
    const cartItemId = cart.data?.cartItems.filter((data) => data.product.productId === productId)[0]?.cartItemId;

    if (tempQty === 0 && cartItemId) {
      dispatch(removeCartItem({
        cartId: cart.data?.cartId || 0,
        cartItemIds: [cartItemId],
      })).then((result) => {
        if (result?.payload?.status === 200) {
          dispatch(getCartDataByVendorID({
            vendorId: location?.orderId,
          }));
        }
      });
    }
  };

  const handleSelectBulkRate = (rate: any, sizes: any) => {
    setIsActiveBulkRate(rate?.productBulkRateId);
    setQuantity(rate?.productQuantity);
    setTempData("0");
    handleUpdateCart(rate?.productQuantity, sizes, productId);
  };

  return (
    <div className="product-card-container rounded-8">
      <div className={image ? "image-container flex bg-light" : "image-container flex"} onClick={() => showProductDetail &&showProductDetail()}>
        {image ? <img src={image} alt="Product-Image" className="product-image" /> : <div className="flex direction-column alignCenter gap-2 no-images-icon"><NoImagesIcon /> <h3>No images available</h3></div>}
        {Number(offer) > 0 && <div className="offer">{offer}<br /> off</div>}
      </div>
      {/* <div className="heart-box">
        <HeartIcon />
      </div> */}
      <div className="flex product-content">
        <Tooltip title={name}><h3 className="product-name common-padding" onClick={() => showProductDetail &&showProductDetail()}>{name?.length > 35 ? (name.slice(0, 35) + "...") : name}</h3></Tooltip>
        <div className="common-padding">
          {moreSizes ?
            <div className="product-size-dropdown" onClick={(e) => [e.stopPropagation(), onclick()]}>
              <span className="product-size-txt">{size?.productSize} {size?.productSizeUnit}</span>
              {moreSizes ? <DownArrow /> : <></>}
            </div> :
            <div className="flex justifyBetween" onClick={() => showProductDetail &&showProductDetail()}>
              <span className="product-size-txt single-size">
                {size?.productSize} {size?.productSizeUnit}
              </span>

              {(bulkRate?.length) ? <Popover
                placement="bottom"
                content={(
                  <div className="bulkrate-inner-container">
                    {bulkRate?.map((rate: any, index: number) => {
                      return (
                        <label
                          key={rate?.productBulkRateId}
                          className={`bulkrate-box flex justifyBetween ${0 >= rate?.productQuantity && "selected"}`}
                          onClick={() => handleSelectBulkRate(rate, size)}
                        >
                          {rate?.productQuantity} {size?.alternateUnitPrimaryUnit || size?.productSizeOrderUnit} = {formatRupees(rate?.productRate)} / {size?.productSizeOrderUnit}
                          {(rate?.productQuantity <= quantity && (rate?.productBulkRateId === isActiveBulkRate || (!isActiveBulkRate && selectedBulkRateIndex === index - 1))) && <CheckOutlined className="selected-icon" />}
                        </label>
                      );
                    })}
                  </div>
                )}
                trigger="click"
                rootClassName="bulkrate-list-popover-container"
              >
                <button
                  className="bulkrate-button flex alignCenter">
                  Bulk Rate <DownArrow />
                </button>
              </Popover> : <></>}
            </div>
          }
        </div>
        {!isActive ? <div className="unavailable-product"><span className="badge">Currently Unavailable</span></div> : <></>}
        <div className="flex price-action common-padding gap-1" onClick={() => showProductDetail &&showProductDetail()}>
          <div className="flex price-box">
            {
              oldPrice &&
              <span className="old-price">
                <del>{formatRupees(Number(oldPrice))}</del>
              </span>
            }
            <span className="price">
              {bulkRate?.length ? (formatRupees(Number(bulkRate?.filter((item: any) => item?.productBulkRateId === isActiveBulkRate)?.[0]?.productRate || price))) : formatRupees(Number(price))}
            </span>
          </div>
          {(!tempData) ?
            <button className="addButton" onClick={(e) => [e.stopPropagation(), moreSizes ? onclick() : handleIncreament(quantity + 1)]}>Add</button> :
            <div className="product-plus-minus-button" onClick={(e) => [e.stopPropagation(), moreSizes ? onclick() : []]}>
              <PlusMinusButton
                handleDecrement={(e) => [e.stopPropagation(), moreSizes ? onclick() : handleDecrement()]}
                handleIncrement={(e) => [e.stopPropagation(), moreSizes ? onclick() : handleIncreament(quantity + 1)]}
                value={bulkRate?.length && isActiveBulkRate ? bulkRate?.filter((item: any) => item?.productBulkRateId === isActiveBulkRate)?.[0]?.productQuantity : quantity}
                handleInputChange={(e) => handleInputChange(e)}
                onBlur={() => handleOnblur(size?.moq || 0)}
                onFocus={() => setIsActiveBulkRate(0)}
                setIsToggle={setIsToggle}
                productId={productId}
                moq={moq}
              />
            </div>}
        </div>
      </div>
      {children}
    </div>
  );
};

export default ProductCard;