import React, { useEffect, useState } from "react";
import { Drawer, Table, TableProps } from "antd";
import dayjs from "dayjs";

import { DownloadReceipt, ReorderIcon, UserIcon } from "../../assets/images/icons";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { addToCart, clearCartItem, getCartDataByVendorID, recentOrders, reOrderByOrderId } from "../../redux/services/placeOrder";
import { useAppSelector } from "../../hooks/useAppSelector";
import { formatRupees } from "../../utils/helper";
import { getOrderItemsByOrder } from "../../redux/services/orders";
import { Logo } from "../../assets/images";

interface DataType {
  key: string;
  productName: string;
  qty: number;
  rate: number;
  subtotal: number;
  productImg?: string;
}

const RecentOrdersDrawer = ({ setToggle, toggle, data, setIsCartOpen }: any) => {
  const [isExpanded, setIsExpanded] = useState({
    index: 0,
    isActive: false,
  });
  const dispatch = useAppDispatch();
  const { recentOrdersData } = useAppSelector((state) => state.placeorder);
  const { orderItems } = useAppSelector((state) => state.manageOrders);

  const companyData = localStorage.getItem("companies");

  const columns: TableProps<any>["columns"] = [
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      render: (_, item) => <div className="flex alignStart gap-2"><div className="product-img">{item?.product?.productImage?.thumbnailURL ? <img src={item?.product?.productImage?.thumbnailURL} alt="product" className="product-img" /> : <Logo />}</div>{item.product.productName}</div>,
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      render: (_, item) => <div className="flex alignStart gap-2">{item?.quantityOrdered}</div>,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (_, item) => <div className="flex alignStart gap-2">{formatRupees(item?.pricePerUnit)}</div>,
    },
    {
      title: "Subtotal",
      dataIndex: "subtotal",
      key: "subtotal",
      render: (_, item) => <div className="flex alignStart gap-2">{formatRupees(item?.subtotal)}</div>,
    },
  ];

  useEffect(() => {
    if (toggle) {
      getRecentOrdersData();
    }
  }, [toggle]);

  const getRecentOrdersData = () => {
    dispatch(recentOrders(data?.companyId));
  };

  const handleViewOrder = (id: number) => {
    dispatch(getOrderItemsByOrder(id));
  };

  const handleReorder = async (orderId: number) => {
    await dispatch(reOrderByOrderId(orderId)).then((result) => {
      if (result?.payload?.status === 200) {
        if (result.payload?.data?.length) {
          const payload = {
            vendorId: data?.companyId,
            items: result?.payload?.data,
          };
          dispatch(getCartDataByVendorID({
            vendorId: data?.companyId,
          })).then((result) => {
            if (result.payload?.data?.cartItems?.length) {
              dispatch(clearCartItem({ cartId: result.payload?.data?.cartId })).then((result) => {
                if (result?.payload?.status === 200) {
                  dispatch(addToCart(payload)).then((result) => {
                    if (result.payload?.status === 200) {
                      setToggle(false);
                      setIsCartOpen(true);
                      dispatch(getCartDataByVendorID({
                        vendorId: data?.companyId,
                      }));
                    }
                  });
                }
              });
            } else {
              dispatch(addToCart(payload)).then((result) => {
                if (result.payload?.status === 200) {
                  setToggle(false);
                  setIsCartOpen(true);
                  dispatch(getCartDataByVendorID({
                    vendorId: data?.companyId,
                  }));
                }
              });
            }
          });
        }
      }
    });
  };

  return (
    <Drawer title="Recent Orders" onClose={() => setToggle(false)} open={toggle} rootClassName="recent-orders-drawer">
      <div className="business-info">
        <div className="business-image">
          {data?.profileThumbnailImg ?
            <img src={data?.profileThumbnailImg} alt="profile" />
            :
            <UserIcon />
          }
        </div>
        <div className="business-text">
          <h4 className="title-4">{data?.name}</h4>
          <p className="caption">{data?.createdBy?.firstName + " " + data?.createdBy?.lastName}</p>
        </div>
      </div>
      <div className="orders-container">
        {recentOrdersData?.data?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div className="recent-order-wrapper">
                <div className="order-summary">
                  <div className="left">
                    <div className="pricing">
                      <p className="items caption">{item?.orderItemsCount} Items</p>
                      <span className="seprator">|</span>
                      <p className="amount caption">{formatRupees(item.grandTotal)}</p>
                    </div>
                    <p className="order-no">Order No. {item.orderNumber || "-"}</p>
                  </div>
                  <div className="status">
                    <p className={item.status}>{item.status}</p>
                  </div>
                </div>
                <div className="view-order-details">
                  <p className="order-date">{dayjs(item.createdOn).format("D MMM YYYY | HH:MM")}</p>
                  {!(isExpanded.isActive && isExpanded.index === index + 1) && <button className="view-order" onClick={() => setIsExpanded({ index: index + 1, isActive: true })} onClickCapture={() => handleViewOrder(item.orderId)}>View Order</button>}
                </div>
              </div>
              {(isExpanded.index === index + 1 && isExpanded.isActive) && <div className="order-details-container">
                <div className="order-table-container">
                  <Table
                    columns={columns}
                    dataSource={orderItems?.data || []}
                    pagination={false}
                  />
                </div>
                <div className="buttons-container">
                  {/* <button className="download-button">
                    <DownloadReceipt />
                      Download Receipt
                  </button> */}
                  {(data?.blockByCompany === JSON.parse(companyData || "")?.companyId || !data?.isBlock) ? <button className="reOrder-button" onClick={() => handleReorder(item.orderId)}>
                    <ReorderIcon />
                    Re-order
                  </button> :
                    <></>
                  }
                </div>
              </div>}
            </React.Fragment>
          );
        })}
      </div>
    </Drawer>
  );
};

export default RecentOrdersDrawer;