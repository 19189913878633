import React, { useState } from "react";

import Page from "../../components/page";

const Home = () => {
  const profile = localStorage.getItem("user");
  const companyData = localStorage.getItem("companies");
  const company1 = companyData && JSON.parse(companyData || "");
  const [messages, setMessages] = useState<string[]>([]);

  return (
    <Page title={[]}
      header={{
        pageTitle: company1[0]?.name || "",
      }}
    >
      <br />
      <hr />
      <br />
      <p>Owner Name: <b>{company1[0]?.createdBy?.firstName + " " + company1[0]?.createdBy?.lastName}</b></p>
      <br />
      <p>Contact No: <b>{JSON.parse(profile || "")?.phoneNo}</b></p>
      {/* <button onClick={sendMessage}>Send Message</button> */}

      <ul>
        {messages.map((msg, index) => (
          <li key={index}>{msg}</li>
        ))}
      </ul>
    </Page>
  );
};

export default Home;