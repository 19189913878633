import React from "react";

export const BG4 = () => {
  return (
    <svg width="985" height="901" viewBox="0 0 985 901" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f_2039_12148)">
        <ellipse cx="526.411" cy="450.5" rx="365.697" ry="290.5" fill="#01F437"/>
      </g>
      <defs>
        <filter id="filter0_f_2039_12148" x="0.713379" y="0" width="1051.39" height="901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="80" result="effect1_foregroundBlur_2039_12148"/>
        </filter>
      </defs>
    </svg>
  );
};