import React, { useEffect } from "react";

import "./common.css";
import "./assets/styles/common.scss";
import Routing from "./router/router";
import { ThemeProvider } from "./utils/themeContext";
import socket from "./socket";
import { useAppDispatch } from "./hooks/useAppDispatch";
import { allNotificationData } from "./redux/slices/notificationSlice";
import { NotificationData } from "./components/notification/NotificationTypes";

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    socket.on("connection", () => {
      console.log("Connected to socket server");
    });

    socket.on("notificationHistoryResponse", (data: NotificationData[]) => {
      dispatch(allNotificationData(data));
    });
  }, [socket]);

  return (
    <ThemeProvider>
      <Routing />
    </ThemeProvider>
  );
}

export default App;
