import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

import endPoins from "./endPoints.json";
import axiosInstance from "../axios";

export const getVendorList = createAsyncThunk("vendor/list", async (args, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(endPoins.getVendorList);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const AddVendor = createAsyncThunk("vendor/add", async (args: { selectedVendors: Array<number> }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.addVendor, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const BlockUnblockRemoveConnection = createAsyncThunk("connection/blockOrUnblockOrRemove", async (args: { connectionId: number, action: string }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.blockUnblockRemove, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const RemoveVendor = createAsyncThunk("vendor/remove", async (args: { vendorIds: Array<number> }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.removeVendor, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getProductListByVendorId = createAsyncThunk("vendor/productsList", async (args: {
  search: string,
  groupId: number,
  vendorId: number,
  limit: number,
  offset: number,
  sort?: string
}, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.getProductsList, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});