import { createSlice } from "@reduxjs/toolkit";

import { getAllCompany, verifyOtp } from "../services/auth";
import { NotificationData } from "../../components/notification/NotificationTypes";

interface allCompaniesData {
  "companyId": number,
  "name": string,
  "profileThumbnailImg": string | null,
  "city": string,
  "state": string,
  "createdBy": {
    "firstName": string,
    "lastName": string,
    "role": string
  },
  "customers": [],
  "sentRequest": boolean,
  "receivedRequest": boolean,
  "requestId": null,
  "isConnected": boolean,
  "isVendor": boolean
}

interface IProps {
  dataForOrders: NotificationData | null;
  notifications: NotificationData[];
  isNotificationDrawerOpen: boolean
}

const initialState: IProps = {
  dataForOrders: null,
  notifications: [],
  isNotificationDrawerOpen: false,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    notificationOrderData: (state, { payload }) => {
      state.dataForOrders = payload;
    },
    allNotificationData: (state, { payload }) => {
      state.notifications = payload;
    },
    handleNotificationDrawer: (state) => {
      state.isNotificationDrawerOpen = !state.isNotificationDrawerOpen;
    },
  },
});

export const { notificationOrderData, allNotificationData, handleNotificationDrawer } = notificationSlice.actions;
export default notificationSlice.reducer;